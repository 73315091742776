package com.crowpay.sdk

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningserver.LSError
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.StringArrayFormat
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.serialization.ClientModule
import com.lightningkite.uuid
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlinx.coroutines.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

val json = Json {
    serializersModule = ClientModule
    ignoreUnknownKeys = true
}

val stringArrayFormat = StringArrayFormat(ClientModule)
inline fun <reified T> T.urlify(): String = stringArrayFormat.encodeToString(this)
suspend inline fun <reified T> RequestResponse.readJson() = json.decodeFromString<T>(text())
suspend fun RequestResponse.discard() = Unit
suspend inline fun <reified T> T.toJsonRequestBody() = json.encodeToString(this)

object HttpClient {
    val GET: HttpMethod = HttpMethod.GET
    val POST: HttpMethod = HttpMethod.POST
    val PATCH: HttpMethod = HttpMethod.PATCH
    val DELETE: HttpMethod = HttpMethod.DELETE
    val PUT: HttpMethod = HttpMethod.PUT
}


val queuedRequests = HashMap<String, HashMap<String, HashMap<String, BulkHandler>>>()

class BulkHandler(val request: BulkRequest, val resume: Continuation<BulkResponse>)

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    headers: HttpHeaders = httpHeaders(),
    bodyJson: String?,
): OUT {
    val tokenReal = token?.invoke()
    return suspendCoroutineCancellable<BulkResponse> { cont ->
        val api = selectedApi.value.api
        val domain = api.httpUrl
        val path = url.removePrefix(api.httpUrl)
        val id = uuid().toString()
        val bulk = BulkHandler(
            request = BulkRequest(
                path,
                method = method.name,
                body = bodyJson
            ),
            resume = cont
        )
        val domainQueuedRequests = queuedRequests.getOrPut(domain) { HashMap() }
        val q = domainQueuedRequests.getOrPut(tokenReal ?: "") { HashMap() }
        q[id] = bulk

        if (q.size == 1) {
            launchGlobal {
                delay(32L)
                val domainQueuedRequests = queuedRequests.getOrPut(domain) { HashMap() }
                val todo = domainQueuedRequests.remove(tokenReal ?: "") ?: emptyMap()
                com.lightningkite.kiteui.fetch(
                    url = "$domain/meta/bulk",
                    method = HttpMethod.POST,
                    headers = headers.apply {
                        tokenReal?.let { append("Authorization", "Bearer ${it}") }
                    },
                    body = RequestBodyText(json.encodeToString(todo.mapValues { it.value.request }), "application/json")
                ).let { it: RequestResponse ->
                    if (!it.ok) {
                        val failed = Exception(it.text())
                        todo.values.forEach { it.resume.resumeWithException(failed) }
                    } else {
                        val responses = json.decodeFromString<Map<String, BulkResponse>>(it.text())
                        todo.forEach {
                            responses[it.key]
                                ?.let { response -> it.value.resume.resume(response) }
                                ?: it.value.resume.resumeWithException(Exception("Bulk key ${it.key} not found"))
                        }
                    }
                }
            }
        }
        return@suspendCoroutineCancellable {}
    }.let { it: BulkResponse ->

        when {
            it.error == null && OUT::class == Unit::class -> Unit as OUT
            it.result != null -> json.decodeFromString(it.result!!)

            else -> throw LsErrorException(it.error?.http?.toShort() ?: 0.toShort(), it.error ?: LSError(0))
        }

    }
}


suspend inline fun <reified OUT> handleResponse(response: RequestResponse): OUT =
    when {
        response.ok && OUT::class == Unit::class -> Unit as OUT
        response.ok -> response.readJson()
        else -> throw LsErrorException(response.status, json.decodeFromString<LSError>(response.text()))
    }


suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    headers: HttpHeaders = httpHeaders(),
    body: IN,
//): OUT = fetch(url, method, token, headers, json.encodeToString(body))
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = RequestBodyText(json.encodeToString(body), "application/json")
).let { handleResponse(it) }

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    headers: HttpHeaders = httpHeaders(),
//): OUT = fetch(url, method, token, headers, null)
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        append("Content-Type", "application/json")
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = null
).let { handleResponse(it) }