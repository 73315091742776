package com.crowpay.views.theming

import com.crowpay.AppColors
import com.crowpay.actuals.AppDimensions
import com.crowpay.views.components.space
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

data object PrimaryButtonSemantic: Semantic {
    override val key: String = "priBtn"
    override fun default(theme: Theme): ThemeAndBack = theme[ButtonSemantic].theme.copy(
        id = key,
        background = AppColors.secondary.main,
        foreground = Color.white,
        outlineWidth = 0.dp,
        derivations = mapOf(
            HoverSemantic to {
                it
                    .copy(background = AppColors.primaryButtonHover)
                    .withBack
            },
            DownSemantic to {
                it
                    .copy(background = AppColors.secondary.dark)
                    .withBack
            },
        )
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.primaryButton: ViewWrapper get() = PrimaryButtonSemantic.onNext

object SecondaryButtonSemantic : Semantic {
    override val key: String = "secBtn"
    override fun default(theme: Theme): ThemeAndBack {
        val backIsDark = theme.background.closestColor().toHSP().brightness < 0.95

        return theme[ButtonSemantic].theme.copy(
            id = key,
            background = if (backIsDark) AppColors.grey.light3 else AppColors.grey.light2,
            foreground = Color.black,
            derivations = mapOf(
                HoverSemantic to {
                    it
                        .copy(background = if (backIsDark) AppColors.grey.light2 else AppColors.grey.light1)
                        .withBack
                },
                DownSemantic to {
                    it
                        .copy(background = if (backIsDark) AppColors.grey.light1 else AppColors.grey.main)
                        .withBack
                },
            )
        ).withBack
    }
}

@ViewModifierDsl3
val ViewWriter.secondaryButton: ViewWrapper get() = SecondaryButtonSemantic.onNext

data object TertiaryButtonSemantic: Semantic {
    override val key: String = "terBtn"
    override fun default(theme: Theme): ThemeAndBack = theme[ButtonSemantic].theme.copy(
        id = key,
        foreground = Color.black,
        outline = Color.black, //TODO: This being black is correct, but it now looks odd against all the grey outlines else where.
        outlineWidth = AppDimensions.outlineWidth,
        derivations = mapOf(
            HoverSemantic to { it.withoutBack }
        )
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.tertiaryButton: ViewWrapper get() = TertiaryButtonSemantic.onNext

data object WarningButtonSemantic: Semantic {
    override val key: String = "wrnBtn"
    override fun default(theme: Theme): ThemeAndBack = theme[ButtonSemantic].theme.copy(
        id = key,
        background = AppColors.red.main,
        foreground = Color.white,
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.warningButton: ViewWrapper get() = WarningButtonSemantic.onNext

data object CreamButtonSemantic: Semantic {
    override val key: String = "crmBtn"
    override fun default(theme: Theme): ThemeAndBack = theme[ButtonSemantic].theme.copy(
        id = key,
        background = AppColors.accent.light2,
        foreground = Color.black
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.creamButton: ViewWrapper get() = CreamButtonSemantic.onNext

data object TextButtonSemantic: Semantic {
    override val key: String = "txtBtn"
    override fun default(theme: Theme): ThemeAndBack = theme[ButtonSemantic].theme.copy(
        id = key,
        foreground = Color.black,
        outlineWidth = 0.dp,
        derivations = mapOf(
            HoverSemantic to {
                it.copy(
                    foreground = AppColors.primary.main
                ).withoutBack
            }
        )
    ).withBack
}

@ViewModifierDsl3
val ViewWriter.textButton: ViewWrapper get() = TextButtonSemantic.onNext


fun Button.specCenteredText(
    content: String,
) {
    sizeConstraints(AppDimensions.buttonMinimumWidth) - row {
        spacing = 0.px
        expanding - space(AppDimensions.buttonHorizontalSpacing)
        centered - body(content)
        expanding - space(AppDimensions.buttonHorizontalSpacing)
    }
}

fun Button.specCenteredText(message: ReactiveContext.() -> String) {
    sizeConstraints(AppDimensions.buttonMinimumWidth) - row {
        spacing = 0.px
        expanding - space(AppDimensions.buttonHorizontalSpacing)
        centered - body { ::content { message() } }
        expanding - space(AppDimensions.buttonHorizontalSpacing)
    }
}

fun Button.specCenteredContent(setup: RView.() -> Unit) {
    sizeConstraints(AppDimensions.buttonMinimumWidth) - row {
        spacing = 0.px
        expanding - space(AppDimensions.buttonHorizontalSpacing)
        this.setup()
        expanding - space(AppDimensions.buttonHorizontalSpacing)
    }
}

@ViewDsl
fun ViewWriter.textButton(content: String, setup: Button.() -> Unit) {
    textButton - button {
        specCenteredText(content)
        setup()
    }
}