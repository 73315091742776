// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareUserMembershipFields() {
    val props: Array<SerializableProperty<UserMembership, *>> = arrayOf(UserMembership_contractor, UserMembership_role)
    UserMembership.serializer().properties { props }
}
val <K> DataClassPath<K, UserMembership>.contractor: DataClassPath<K, UUID> get() = this[UserMembership_contractor]
val <K> DataClassPath<K, UserMembership>.role: DataClassPath<K, MemberRole> get() = this[UserMembership_role]
inline val UserMembership.Companion.path: DataClassPath<UserMembership, UserMembership> get() = path<UserMembership>()


object UserMembership_contractor: SerializableProperty<UserMembership, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: UserMembership): UUID = receiver.contractor
    override fun setCopy(receiver: UserMembership, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = UserMembership.serializer().tryFindAnnotations("contractor")
}
object UserMembership_role: SerializableProperty<UserMembership, MemberRole> {
    override val name: String = "role"
    override fun get(receiver: UserMembership): MemberRole = receiver.role
    override fun setCopy(receiver: UserMembership, value: MemberRole) = receiver.copy(role = value)
    override val serializer: KSerializer<MemberRole> = MemberRole.serializer()
    override val annotations: List<Annotation> = UserMembership.serializer().tryFindAnnotations("role")
}
