@file:UseContextualSerialization(UUID::class)

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization


//@Serializable
//data class Address(
//    val line1: String? = null,
//    val line2: String? = null,
//    val city: String? = null,
//    val state: String? = null,
//    val country: String? = null,
//    val postalCode: String? = null,
//)

//@Serializable
//data class Card(
//    val creditCardNumber: String,
//    val expMonth: Long,
//    val expYear: Long,
//    val address: Address,
//    val nameOnCard: String? = null,
//    val brand: CreditCardBrand,
//)


@Serializable
enum class BankAccountType {
    checking,
    savings,
}

@Serializable
enum class BankAccountHolderType {
    company,
    individual,
}

@Serializable
data class Bank(
    val accountHolderType: BankAccountHolderType,
    val accountType: BankAccountType,
    val accountId: String,
    val accountNumber: String,
    val routingNumber: String,
    val bankName: String,
)

@Serializable
@GenerateDataClassPaths
data class PaymentMethod(
    val id: String,
    val name: String?,
//    val card: Card?,
    val bank: Bank?,
    val default: Boolean,
)

@Serializable
data class MicroDepositVerification(
    val arrivalDate: Instant,
    val verificationUrl: String,
    val type: String,
)

@Serializable
data class PendingAction(
    val type: String,
    val verifyWithMicroDeposits: MicroDepositVerification?,
)

@Serializable
data class PendingPaymentMethod(
    val secret: String,
    val method: PaymentMethod,
    val action: PendingAction,
)


//@Serializable
//enum class CreditCardBrand {
//    amex,
//    diners_club,
//    discover,
//    jcb,
//    mastercard,
//    visa,
//    unionpay,
//    other
//}


@Serializable
data class PaymentRequest(
    val amount: Long,
    val method: String,
    val idempotencyKey: UUID,
)


@Serializable
data class Payment(
    val amount: Long,
    val method: String,
    val idempotencyKey: UUID,
    val ledgerId: UUID,
)

@Serializable
enum class PaymentStatus {
    failed,
    pending,
    succeeded,
}