// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorDocumentFields() {
    val props: Array<SerializableProperty<ContractorDocument, *>> = arrayOf(ContractorDocument__id, ContractorDocument_contractor, ContractorDocument_name, ContractorDocument_fileType, ContractorDocument_file, ContractorDocument_preview)
    ContractorDocument.serializer().properties { props }
}
val <K> DataClassPath<K, ContractorDocument>._id: DataClassPath<K, UUID> get() = this[ContractorDocument__id]
val <K> DataClassPath<K, ContractorDocument>.contractor: DataClassPath<K, UUID> get() = this[ContractorDocument_contractor]
val <K> DataClassPath<K, ContractorDocument>.name: DataClassPath<K, String> get() = this[ContractorDocument_name]
val <K> DataClassPath<K, ContractorDocument>.fileType: DataClassPath<K, String> get() = this[ContractorDocument_fileType]
val <K> DataClassPath<K, ContractorDocument>.file: DataClassPath<K, ServerFile> get() = this[ContractorDocument_file]
val <K> DataClassPath<K, ContractorDocument>.preview: DataClassPath<K, ServerFile?> get() = this[ContractorDocument_preview]
inline val ContractorDocument.Companion.path: DataClassPath<ContractorDocument, ContractorDocument> get() = path<ContractorDocument>()


object ContractorDocument__id: SerializableProperty<ContractorDocument, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ContractorDocument): UUID = receiver._id
    override fun setCopy(receiver: ContractorDocument, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ContractorDocument_contractor: SerializableProperty<ContractorDocument, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: ContractorDocument): UUID = receiver.contractor
    override fun setCopy(receiver: ContractorDocument, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("contractor")
}
object ContractorDocument_name: SerializableProperty<ContractorDocument, String> {
    override val name: String = "name"
    override fun get(receiver: ContractorDocument): String = receiver.name
    override fun setCopy(receiver: ContractorDocument, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("name")
}
object ContractorDocument_fileType: SerializableProperty<ContractorDocument, String> {
    override val name: String = "fileType"
    override fun get(receiver: ContractorDocument): String = receiver.fileType
    override fun setCopy(receiver: ContractorDocument, value: String) = receiver.copy(fileType = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("fileType")
}
object ContractorDocument_file: SerializableProperty<ContractorDocument, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: ContractorDocument): ServerFile = receiver.file
    override fun setCopy(receiver: ContractorDocument, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("file")
}
object ContractorDocument_preview: SerializableProperty<ContractorDocument, ServerFile?> {
    override val name: String = "preview"
    override fun get(receiver: ContractorDocument): ServerFile? = receiver.preview
    override fun setCopy(receiver: ContractorDocument, value: ServerFile?) = receiver.copy(preview = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = ContractorDocument.serializer().tryFindAnnotations("preview")
    override val default: ServerFile? = null
}
