package com.crowpay

import com.lightningkite.kiteui.models.Color

data class ColorSet(
    val dark: Color,
    val main: Color,
    val light1: Color,
    val light2: Color,
    val light3: Color,
) {
    fun darker(other: Color, min: Color? = null): Color {
        val brightness = other.toHSP().brightness
        return when {
            min != null && brightness > min.toHSP().brightness -> min
            brightness > light3.toHSP().brightness -> light3
            brightness > light2.toHSP().brightness -> light2
            brightness > light1.toHSP().brightness -> light1
            brightness > main.toHSP().brightness -> main
            else -> dark
        }
    }

    fun lighter(other: Color, min: Color? = null): Color {
        val brightness = other.toHSP().brightness
        return when {
            min != null && brightness < min.toHSP().brightness -> min
            brightness < dark.toHSP().brightness -> dark
            brightness < main.toHSP().brightness -> main
            brightness < light1.toHSP().brightness -> light1
            brightness < light2.toHSP().brightness -> light2
            else -> light3
        }
    }

}

object AppColors {

    // Purple
    val primary = ColorSet(
        dark = Color.fromHexString("210840"),
        main = Color.fromHexString("421976"),
        light1 = Color.fromHexString("715298"),
        light2 = Color.fromHexString("C0B3D2"),
        light3 = Color.fromHexString("EDEAF3"),
    )


    // Green
    val secondary = ColorSet(
        dark = Color.fromHexString("005526"),
        main = Color.fromHexString("00ab4d"),
        light1 = Color.fromHexString("50C685"),
        light2 = Color.fromHexString("A1E1BD"),
        light3 = Color.fromHexString("E9F8F0"),
    )

    // Yellow
    val accent = ColorSet(
        dark = Color.fromHexString("B48400"),
        main = Color.fromHexString("f2ba1c"),
        light1 = Color.fromHexString("F7D575"),
        light2 = Color.fromHexString("FBEBBC"),
        light3 = Color.fromHexString("FEFAED"),
    )

    // Neutrals
    val grey = ColorSet(
        dark = Color.fromHexString("201F1F"),
        main = Color.fromHexString("8D8B8B"),
        light1 = Color.fromHexString("D6D6D6"),
        light2 = Color.fromHexString("F0F1F1"),
        light3 = Color.fromHexString("FAFAFA"),
    )

    val red = ColorSet(
        dark = Color.fromHexString("7C1400"),
        main = Color.fromHexString("F72700"),
        light1 = Color.fromHexString("FA755C"),
        light2 = Color.fromHexString("FCA899"),
        light3 = Color.fromHexString("FFF2F0"),
    )

    val yellow = ColorSet(
        dark = Color.fromHexString("B48400"),
        main = Color.fromHexString("f2ba1c"),
        light1 = Color.fromHexString("F7D575"),
        light2 = Color.fromHexString("FBEBBC"),
        light3 = Color.fromHexString("FEFAED"),
    )

    val blue = ColorSet(
        dark = Color.fromHexString("263062"),
        main = Color.fromHexString("5271ff"),
        light1 = Color.fromHexString("92A3F1"),
        light2 = Color.fromHexString("C8D1F8"),
        light3 = Color.fromHexString("EFF2FF"),
    )

    val purple = ColorSet(
        dark = Color.fromHexString("4F1994"),
        main = Color.fromHexString("7b22ed"),
        light1 = Color.fromHexString("AA6FF6"),
        light2 = Color.fromHexString("D1B3F8"),
        light3 = Color.fromHexString("E9E1F4"),
    )


    val primaryButtonHover = Color.fromHexString("02843D")
}