package com.crowpay

import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.fullName
import com.crowpay.sdk.*
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.HelpDialog
import com.crowpay.views.dialogs.dialogView
import com.crowpay.views.screens.auth.AuthScreen
import com.crowpay.views.screens.auth.ContractorRegistration
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.screens.client.ClientPayments
import com.crowpay.views.screens.client.ClientProfile
import com.crowpay.views.screens.client.ClientProjects
import com.crowpay.views.screens.common.ClientWorkLists
import com.crowpay.views.screens.common.ContractorWorkLists
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.common.SelectDevProjectScreen
import com.crowpay.views.screens.contractor.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.exceptions.ExceptionToMessage
import com.lightningkite.kiteui.exceptions.ExceptionToMessages
import com.lightningkite.kiteui.exceptions.installLsError
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.l2.navigatorView
import com.lightningkite.kiteui.views.l2.navigatorViewDialog
import com.lightningkite.kiteui.views.l2.overlayStack
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.inside
import com.lightningkite.lightningdb.sort
import com.lightningkite.serialization.ClientModule

private val _selectedContractor = PersistentProperty<String?>("SelectedContractor", null)
val selectedContractor: Writable<UUID?> = _selectedContractor.lens(
    get = { it?.let { UUID.parse(it) } },
    set = { it?.toString() },
)
val currentContractor = shared {
    val session = currentSession() ?: return@shared null
    val selected = selectedContractor() ?: return@shared null
    session.contractors[selected]()
}
val contractorActive = shared(useLastWhileLoading = true) {
    currentContractor()?.active.also { println("Got new active state: $it") }
}
fun ReactiveContext.contractorInfoComplete(): Boolean = currentContractor()?.active != ContractorActive.Registering

private var _guaranteedContractor: Readable<UUID>? = null
val ViewWriter.guaranteedContractor: Readable<UUID>
    get() = _guaranteedContractor ?: run {
        _guaranteedContractor = shared {
            selectedContractor() ?: run {
                mainScreenNavigator.navigate(LandingScreen())
                throw CancelledException("")
            }
        }
        _guaranteedContractor!!
    }
val CalculationContext.notNullContractor: Readable<UUID>
    get() = _guaranteedContractor ?: run {
        _guaranteedContractor = shared {
            selectedContractor() ?: run {
                mainScreen.awaitNotNull().navigate(LandingScreen())
                throw CancelledException("")
            }
        }
        _guaranteedContractor!!
    }

// Kinda jank ya, but this is for navigating back to login/landing screen whn session is not available
val mainScreen = Property<ScreenNavigator?>(null)


fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    prepareModelsShared()
    ExceptionToMessages.root.installLsError()
    com.lightningkite.prepareModelsShared()

    DefaultSerializersModule = ClientModule
    screenNavigator = main
    mainScreenNavigator = main
    launchGlobal { mainScreen.set(main) }
    dialogScreenNavigator = dialog
    main.bindToPlatform(context)

    stack {
        fun ReactiveContext.navExists(): Boolean {
            return currentSession() != null
        }

        overlayStack = this
        spacing = 0.dp
        col {
            spacing = 0.dp

            col {
                spacing = 0.dp
                ::exists {
                    screenNavigator.currentScreen() !is AuthScreen && navExists()
                }
                padded - stack {
                    spacing = 2.rem
                    row {
                        sizeConstraints(height = 2.rem) - centered - image {
                            source = Resources.imagesFullLogoColor
                            scaleType = ImageScaleType.Fit
                        }

                        expanding - space()

                        row {
                            ::exists { navExists() }
                            centered - button {
                                ::exists { contractorInfoComplete() }
                                themeChoice += ThemeDerivation {
                                    it[ButtonSemantic].theme.copy(cornerRadii = CornerRadii.Constant(1.rem)).withoutBack
                                }
                                spacing = 0.dp
                                sizeConstraints(height = 2.rem) - image {
                                    source = Icon.settingsInverse.toImageSource(AppColors.primary.main)
                                }
                                onClick {
                                    if (selectedContractor.awaitOnce() != null) {
                                        screenNavigator.reset(ContractorProfile())
                                    } else {
                                        screenNavigator.reset(ClientProfile())
                                    }
                                }
                            }

                            buttonTheme - centered - sizeConstraints(height = 2.rem) -
                                    menuButton {
                                        this.requireClick = true
                                        spacing = 0.dp

                                        val memberships = shared {
                                            val session = currentSession() ?: return@shared emptyList()
                                            val ships =
                                                session.self().memberships.map { it.contractor }
                                                    .toSet()
                                            session.contractors
                                                .query(
                                                    Query(
                                                        condition { it._id inside ships },
                                                        orderBy = sort { it.name.ascending() })
                                                )()
                                        }
                                        row {
                                            sizeConstraints(height = 1.5.rem) - centered - icon(
                                                Icon.profile,
                                                "Switch Profile"
                                            )

                                            centered - subTitle {
                                                align = Align.Center
                                                ::content{
                                                    selectedContractor()
                                                        ?.let { selected -> memberships().find { it._id == selected }?.name }
                                                        ?: currentSession()?.self?.let { it() }?.fullName
                                                        ?: ""
                                                }
                                            }

                                        }
                                        preferredDirection = PopoverPreferredDirection.belowLeft
                                        this.opensMenu {
                                            val close = {
                                                closePopovers()
                                            }
                                            stack {
//                                                themeFromLast { appTheme } -
                                                col {
                                                    spacing = 0.dp

                                                    fun ViewWriter.navOption(
                                                        image: Readable<ImageSource>,
                                                        text: Readable<String>,
                                                        hasNext: Boolean,
                                                        onClick: suspend () -> Unit,
                                                    ) {
                                                        button {
                                                            row {
                                                                sizeConstraints(
                                                                    width = 1.5.rem,
                                                                    height = 1.5.rem
                                                                ) - centered - image {
                                                                    ::source{ image() }
                                                                }
                                                                centered - body {
                                                                    ::content{ text() }
                                                                }
                                                            }

                                                            this.onClick(action = onClick)
                                                        }
                                                        if (hasNext)
                                                            separator()
                                                    }

                                                    fun ViewWriter.navOption(
                                                        icon: Icon,
                                                        description: String,
                                                        text: Readable<String>,
                                                        hasNext: Boolean,
                                                        onClick: suspend () -> Unit,
                                                    ) {
                                                        button {
                                                            row {
                                                                sizeConstraints(
                                                                    width = 1.5.rem,
                                                                    height = 1.5.rem
                                                                ) - centered - icon(icon, description)
                                                                centered - body {
                                                                    ::content{ text() }
                                                                }
                                                            }
                                                            onClick(action = onClick)
                                                        }
                                                        if (hasNext)
                                                            separator()
                                                    }

                                                    navOption(
                                                        icon = Icon.profile,
                                                        description = "Client View",
                                                        text = shared {
                                                            currentSession()?.self?.let { it() }?.fullName ?: ""
                                                        },
                                                        hasNext = true,
                                                    ) {
                                                        screenNavigator.reset(ClientDashboard())
                                                        selectedContractor.set(null)
                                                        close()
                                                    }
                                                    col {
                                                        spacing = 0.dp
                                                        forEach(memberships) { contractor ->

                                                            navOption(
                                                                image = Constant(contractor.image?.let {
                                                                    ImageRemote(
                                                                        it.location
                                                                    )
                                                                }
                                                                    ?: Icon.empty.toImageSource(Color.transparent)),
                                                                text = Constant(contractor.name),
                                                                hasNext = true,
                                                            ) {
                                                                selectedContractor.set(contractor._id)
                                                                screenNavigator.reset(ContractorDashboard())
                                                                close()
                                                            }
                                                        }
                                                    }
                                                    navOption(
                                                        icon = Icon.logout,
                                                        description = "Log Out",
                                                        text = Constant("Logout"),
                                                        hasNext = false,
                                                    ) {
                                                        try {
//                                                            currentSession()?.nonCached?.userSession?.terminateSession()
                                                        } catch (_: Exception) {
                                                        }
                                                        sessionToken.set(null)
                                                        screenNavigator.reset(LogInScreen())
                                                        close()
                                                    }
                                                }
                                            }
                                        }
                                    }
                        }
                    }
                }
            }

            sizeConstraints(
                height = 2.dp,
                maxHeight = 2.dp
            ) - themeFromLast { it.copy(background = AppColors.secondary.main, spacing = 1.dp) } - stack {}

            expanding - row {
                spacing = 0.dp
                nav - sizeConstraints(minWidth = 12.rem) - col {
                    spacing = 0.dp
                    ::exists{
                        val c = screenNavigator.currentScreen()
                        (c !is AuthScreen) && navExists()
                    }

                    space(AppDimensions.fullIndent)

                    fun ViewWriter.renderNavItem(
                        icon: Icon,
                        text: String,
                        exists: ReactiveContext.()->Boolean = { true },
                        onClick: suspend () -> Unit,
                    ) {
                        col {
                            spacing = 0.dp
                            existsDefaultFalse(exists)
                            button {
                                spacing = 0.25.rem
                                row {
                                    spacing = 0.rem
                                    space(AppDimensions.fullIndent / 2)
                                    centered - sizeConstraints(height = 1.5.rem, width = 1.5.rem) -
                                            icon(icon, text)
                                    space(1.rem)
                                    centered - subTitle {
                                        content = text
                                    }
                                    space(AppDimensions.fullIndent / 2)
                                    onClick(action = onClick)
                                }
                            }
                            space(1.rem)
                        }
                    }

                    renderNavItem(
                        Icon.simpleHome,
                        "Home",
                    ) {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorDashboard())
                        } else {
                            screenNavigator.reset(ClientDashboard())
                        }
                    }

                    renderNavItem(Icon.projects, "Projects") {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorProjects())
                        } else {
                            screenNavigator.reset(ClientProjects())
                        }
                    }

                    renderNavItem(
                        Icon.clients,
                        "Clients",
                        exists = { selectedContractor() != null }
                    ) {
                        screenNavigator.reset(ContractorClients())
                    }
                    renderNavItem(Icon.workList, "Work Lists") {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorWorkLists())
                        } else {
                            screenNavigator.reset(ClientWorkLists())
                        }
                    }
                    renderNavItem(Icon.finances, "Finances") {
                        if (selectedContractor.awaitOnce() != null) {
                            screenNavigator.reset(ContractorPayments())
                        } else {
                            screenNavigator.reset(ClientPayments())
                        }
                    }
                    renderNavItem(Icon.messages, "Messages") {}
                    renderNavItem(
                        Icon.edit,
                        "Dev Projects",
                        exists = { selectedApi() in ApiOption.SAFE }
                    ) {
                        screenNavigator.reset(SelectDevProjectScreen())
                    }

                    expanding - space()

                    renderNavItem(Icon.help, "Help") {
                        dialogScreenNavigator.navigate(HelpDialog())
                    }

                    space(AppDimensions.fullIndent - 1.25.rem)
                }
                expanding - padded - stack {
                    ::spacing{
                        if (screenNavigator.currentScreen() !is AuthScreen && navExists())
                            AppDimensions.fullIndent
                        else
                            0.dp
                    }
                    navigatorView(screenNavigator)
                }
            }
        }

        navigatorViewDialog()
    }
}