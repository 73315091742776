@file: UseContextualSerialization(UUID::class, ServerFile::class, Instant::class)

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization


@Serializable
@GenerateDataClassPaths
data class ChangeRequest(
    override val _id: UUID = uuid(),
    @References(Project::class) val project: UUID,
    val title: String = "",
    val projectDescription: String? = null,
    val newItems: List<LineItem> = emptyList(),
    val itemChanges: List<ItemChange> = emptyList(),
    val feedback: ClientFeedback? = null,
    val published: Instant? = null,
    val accepted: AccessInfo? = null,
    val denied: AccessInfo? = null,
    val approved: Boolean? = null, // approved is here because you cant query on accepted or denied due to masking
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ClientTermsAndConditionsAgreement(
    override val _id: UUID = uuid(),
    val accessInfo: AccessInfo,
    @References(ClientTermsAndConditionsVersion::class) val version: Instant,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ClientTermsAndConditionsVersion(
    override val _id: Instant = now(),
    val establishedAt: Instant = now(),
    val contents: String,
) : HasId<Instant>

@Serializable
@GenerateDataClassPaths
data class Client(
    override val _id: UUID = uuid(),
    @References(Contractor::class) val contractor: UUID,
    override val name: String,
    override val email: String,
    override val phoneNumber: String,
    override val address: Address,
//    val archived: Boolean = false,
) : HasId<UUID>, Contact {
    override val image: ServerFile? get() = null
}

@Serializable
@GenerateDataClassPaths
data class Contractor(
    override val _id: UUID = uuid(),
    override val name: String,
    override val email: String,
    override val phoneNumber: String,
    override val address: Address,
    override val image: ServerFile? = null,
    val preferredTitle: PreferredTitle = PreferredTitle.Contractor,

    val contactFirstName: String,
    val contactLastName: String,
    val contactEmail: String,
    val contactPhoneNumber: String,
    val contactAddress: Address,

    val stateEntityNumber: String,
    val ein: String,
    @References(Trade::class) val trade: String? = null,

    val active: ContractorActive = ContractorActive.Registering,

    @Denormalized val lastAgreement: ContractorTermsAndConditionsAgreement? = null,
) : HasId<UUID>, Contact


@Serializable
@GenerateDataClassPaths
data class ContractorDocument(
    override val _id: UUID = uuid(),
    @References(Contractor::class) val contractor: UUID,
    override val name: String,
    override val fileType: String,
    override val file: ServerFile,
    override val preview: ServerFile? = null,
) : HasId<UUID>, Attachment


@Serializable
@GenerateDataClassPaths
data class ContractorTermsAndConditionsAgreement(
    override val _id: UUID = uuid(),
    @References(Contractor::class) val contractor: UUID,
    val accessInfo: AccessInfo,
    @References(ContractorTermsAndConditionsVersion::class) val version: Instant,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class ContractorTermsAndConditionsVersion(
    override val _id: Instant = now(),
    val establishedAt: Instant = now(),
    val contents: String,
) : HasId<Instant>


@Serializable
@GenerateDataClassPaths
data class DisputeLog(
    override val _id: UUID = uuid(),
    val project: UUID,
    val accessInfo: AccessInfo,
    val feedback: ClientFeedback? = null,
    val filedForArbitration: FiledForArbitration? = null,
    val ended: AccessInfo,
) : HasId<UUID>


@Serializable
@GenerateDataClassPaths
data class License(
    override val _id: UUID = uuid(),
    @References(Contractor::class) val contractor: UUID,
    val number: String,
    val city: String? = null,
    val state: State,
    override val file: ServerFile,
    override val fileType: String,
    override val preview: ServerFile? = null,
    val type: LicenseType,
) : HasId<UUID>, Attachment {

    override val name: String
        get() = number
}

@Serializable
@GenerateDataClassPaths
data class LineItemLog(
    override val _id: UUID = UUID.random(),
    @References(LineItem::class) val lineItem: UUID,
    @Denormalized @References(Project::class) val project: UUID,
    val message: String,
    val files: List<ProjectAttachment> = emptyList(),
    val at: Instant = now(),
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class LineItem(
    override val _id: UUID = uuid(),
    @References(Project::class) val project: UUID,
    @References(ChangeRequest::class) val changeRequest: UUID? = null, // The Change Request this was created from.
    val name: String = "",
    val description: String = "",
    @IntegerRange(0L, Long.MAX_VALUE) val price: Long = 0L,
    val order: Int = 0,
    val files: List<ProjectAttachment> = listOf(),
    val started: Instant? = null,

    val state: LineItemState = LineItemState.NotStarted,
    val notApproved: ClientFeedback? = null,
    val requestingReview: AccessInfo? = null,
    val actionRequiredBy: Instant? = null,
    val complete: AccessInfo? = null,
    @References(ProjectLedgerItem::class) val ledgerItem: UUID? = null, // This is a redundancy field. We need to guarantee money works. If the post Change fails we can use this to start again.
    val cancelled: Instant? = null,
) : HasId<UUID> {
    val safePrice: Long get() = max(0L, price)
}

@Serializable
@GenerateDataClassPaths
data class Payout(
    override val _id: Instant = System.now(),
    val nachaFile: ServerFile,
    val totalOut: Int,
    val onePercent: Int,
) : HasId<Instant> {
    companion object {
        const val filePath = "payouts"
    }
}


@Serializable
@GenerateDataClassPaths
data class Project(
    override val _id: UUID = uuid(),
    @References(Contractor::class) val contractor: UUID,
    val customer: UUID? = null,

    // Manager Editable during creation
    @References(Client::class) val client: UUID? = null,
    val name: String,
    val description: String,
    val address: Address,
    val location: String? = null,
    val jobNumber: String? = null,
    val files: List<ProjectAttachment> = listOf(),
    @FloatRange(0.0, 1.0) val retention: Float? = null,
    val privateNotes: String,
    val published: Instant? = null,

    // User editable during acceptance and completion
    val feedback: ClientFeedback? = null,
//    val allowPreview: Instant? = null,

    // Events that determine State Non User Editable
    val created: Instant = now(),
    val cancelled: AccessInfo? = null,
    val viewedAnonymously: Instant? = null,
    val approved: AccessInfo? = null,
    val fullFundingRequested: AccessInfo? = null,
    val dispute: Dispute? = null,
    val terminated: Instant? = null,
    val complete: CompletionRequest? = null,
//    val holdPlace: Long? = null,

    // Denormalized values Non User Editable
    @Denormalized val state: ProjectState = ProjectState.Creating,
    @Denormalized val price: Long = 0,
    @Denormalized val fundingNeeded: Long = 0L,
    @Denormalized val balance: Long = 0L,
    @Denormalized val pendingClientPayments: Long = 0L,
    @Denormalized val contractorReceived: Long = 0L,
    @Denormalized val funded: Instant? = null,
    @Denormalized val started: Instant? = null,
    @Denormalized val substantialCompletion: Instant? = null,
//    @Denormalized val clientPaid: Long = 0L,
//    @Denormalized val placeHeld: Instant? = null,
) : HasId<UUID>


/*
DANGEROUS CASES:

- Pay more out of CrowPay than the value of the project
- Pay out to the wrong person
- Payment is not recorded
 */
@Suppress("RUNTIME_ANNOTATION_NOT_SUPPORTED")
@Serializable
@GenerateDataClassPaths
data class ProjectLedgerItem(
    override val _id: UUID = uuid(),
    @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,
    val contractor: Boolean = false,
    @Description("Positive indicates a payment into CrowPay, negative indicates a payment out.")
    val amount: Long = 0,
    val intentId: IntentId? = null,
    val state: PaymentState,
    val message: String? = null,
    val lastStateChange: Instant = now(),
    val created: Instant = now(),
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class PunchListItem(
    override val _id: UUID = uuid(),
    @References(Project::class) val project: UUID,
    @References(LineItem::class) val lineItem: UUID? = null,
    val order: Double = 0.5,
    val content: String,
    val required: Boolean,
    val complete: AccessInfo? = null,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class Trade(
    override val _id: String,
) : HasId<String>

@Serializable
@GenerateDataClassPaths
data class User(
    override val _id: UUID = uuid(),
    @Unique val email: String,
    val phoneNumber: String,
    val firstName: String,
    val lastName: String,
    val address: Address,
    val memberships: Set<UserMembership> = setOf(),
    val paymentId: String? = null,
    val role: UserRole = UserRole.Customer,

    val receivesNewContractorEmails: Boolean = true,    // Only applies to admins, only editable in admin
//    val phoneMfaEnabled: Boolean = false,
//    val phoneVerified: Boolean = false,

    @Denormalized val lastAgreement: ClientTermsAndConditionsAgreement? = null,
) : HasId<UUID>
