package com.crowpay.views.components.files

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.views.theming.alphaTheme
import com.crowpay.views.theming.appTheme
import com.crowpay.views.theming.subTitle
import com.crowpay.views.theming.tertiaryButton
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningserver.files.UploadInformation
import kotlinx.coroutines.launch


fun ViewWriter.fileManager(
    files: Writable<List<FileData>>,
    emptyText: String,
    mimeTypes: List<String> = listOf("*/*"),
    uploader: suspend () -> (suspend () -> UploadInformation),
) {
    row {
        spacing = AppDimensions.buttonRowSpacing
        var recycler: RecyclerView?

        reactiveSuspending {
            val l = files()
                .filter { it.preview is FilePreview.LocalFile && it.preview.uploadStarted == null }

            l.forEach { item: FileData ->
                val preview = item.preview as? FilePreview.LocalFile ?: return@forEach
                preview.uploadAttachment(uploader())
            }
        }

        themeFromLast {
            it.copy(
                id = "${it.id}-files",
                outlineWidth = 0.dp,
                spacing = 0.dp,
                background = AppColors.grey.light2.applyAlpha(0f),
                outline = Color.transparent,
                cornerRadii = CornerRadii.Constant(0.px),
                selected = { it },
            )
        } - stack {

            horizontalRecyclerView {
                spacing = 8.dp
                recycler = this
                children(files) { file: Readable<FileData> ->
                    stack {
                        stack {
                            val split = split()
                            reactiveScope {
                                this@stack.clearChildren()
                                val filePreview = file().preview
                                with(split) {
                                    renderFilePreview(filePreview, 15.rem)
                                }
                            }
                        }

                        button {
                            spacing = 0.25.rem
                            icon {
                                ::source { Icon.close }
                            } in
                                    sizeConstraints(width = 2.rem, height = 2.rem)
                            onClick {
                                val removed = file.await()
                                val f = files.await()
                                files.set(f.minus(removed))
                            }
                        } in
                                gravity(Align.End, Align.Start) in
                                themeFromLast(alphaTheme)

                        textField {
                            this.spacing = appTheme.spacing
                            hint = "File Name"
                            content bind shared { file().name() }.withWrite {
                                file.await().name.value = it
                            }
                        } in
                                gravity(Align.Center, Align.End) in
                                sizeConstraints(width = AppDimensions.largeImagePreview) in
                                themeFromLast(alphaTheme)

                    } in sizeConstraints(
                        width = AppDimensions.largeImagePreview,
                        height = AppDimensions.largeImagePreview
                    )
                }
            }

            centered - bold - subTitle {
                exists = false
                ::exists{ files().isEmpty() }
                content = emptyText
            }

        } in expanding

        tertiaryButton - button {
            centered - icon(Icon.add, "Add File")
            onClick {
                ExternalServices.requestFiles(mimeTypes) { results: List<FileReference> ->
                    if (results.isNotEmpty()) {
                        launch {
                            val f = files.await()
                            val currentSize = f.size
                            files.set(
                                f + results.map {
                                    FileData(
                                        name = Property(""),
                                        preview = FilePreview.LocalFile(it),
                                        original = FilePreview.LocalFile(it),
                                    )
                                }
                            )
                            launch {
                                recycler?.scrollToIndex(currentSize)
                            }
                        }
                    }
                }
            }
        } in sizeConstraints(width = AppDimensions.largeImagePreview / 3, height = AppDimensions.largeImagePreview)
    }
}