package com.crowpay.views.screens.client

import com.crowpay.actuals.AppDimensions
import com.crowpay.edit
import com.crowpay.extensions.fullName
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.label2
import com.crowpay.views.components.space
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.modification

@Routable("client/profile")
class ClientProfile : Screen {

    override val title: Readable<String> = Constant("Profile")

    val cached = shared {
        val session = notNullSession()
        session.users[session.userId]
    }

    val self = LazyProperty {
        cached()()!!
    }

    val editingUser = Property(false)

    val clientForm = ClientForm(self, AppDimensions.pageWidth)

    override fun ViewWriter.render() {

        scrolls - col {
            spacing = 0.dp

            title("Profile")

            space(AppDimensions.backgroundIndent)

            sizeConstraints(width = AppDimensions.pageWidth) - padded - row {
                spacing = 0.dp
                space(AppDimensions.fullIndent)

                expanding - col {
                    onlyWhen { !editingUser() } - col {

                        label2("Legal Name") {
                            body {
                                ::content{ self().fullName }
                            }
                        }

                        label2("Email") {
                            body {
                                ::content{ self().email }
                            }
                        }

                        label2("Phone Number") {
                            body {
                                ::content{ PhoneNumberFormat.USA.format(self().phoneNumber.filter { it.isDigit() }) }
                            }
                        }

                        label2("Address") {
                            body {
                                ::content{ self().address.toString() }
                            }
                        }
                        tertiaryButton - button {
                            icon(Icon.edit, "Edit Profile")
                            onClick {
                                editingUser.set(true)
                            }
                        } in gravity(Align.End, Align.End)

                    }

                    onlyWhen { editingUser() } - col {

                        clientForm.render(this@col)

                        row {
                            spacing = AppDimensions.buttonRowSpacing
                            secondaryButton - button {
                                specCenteredText("Cancel")
                                onClick {
                                    editingUser.set(false)
                                    clientForm.userDraft.cancel()
                                }
                            }

                            primaryButton - button {
                                ::enabled{ clientForm.allValid() }
                                specCenteredText("Save")
                                onClick {

                                    val mod = modification(
                                        clientForm.userDraft.published.awaitOnce(),
                                        clientForm.userDraft()
                                    ) as? Modification.Chain
                                        ?: Modification.Chain(emptyList())

                                    if (mod.modifications.isNotEmpty()) {
                                        cached.await().modify(mod)
                                        clientForm.userDraft.publish()
                                    }
                                    editingUser.set(false)
                                }
                            }
                        } in gravity(Align.End, Align.End)
                    }
                }
                space(AppDimensions.fullIndent)
            }
        }
    }

}
