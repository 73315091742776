// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientFeedbackFields() {
    val props: Array<SerializableProperty<ClientFeedback, *>> = arrayOf(ClientFeedback_at, ClientFeedback_message)
    ClientFeedback.serializer().properties { props }
}
val <K> DataClassPath<K, ClientFeedback>.at: DataClassPath<K, Instant> get() = this[ClientFeedback_at]
val <K> DataClassPath<K, ClientFeedback>.message: DataClassPath<K, String> get() = this[ClientFeedback_message]
inline val ClientFeedback.Companion.path: DataClassPath<ClientFeedback, ClientFeedback> get() = path<ClientFeedback>()


object ClientFeedback_at: SerializableProperty<ClientFeedback, Instant> {
    override val name: String = "at"
    override fun get(receiver: ClientFeedback): Instant = receiver.at
    override fun setCopy(receiver: ClientFeedback, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ClientFeedback.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
object ClientFeedback_message: SerializableProperty<ClientFeedback, String> {
    override val name: String = "message"
    override fun get(receiver: ClientFeedback): String = receiver.message
    override fun setCopy(receiver: ClientFeedback, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ClientFeedback.serializer().tryFindAnnotations("message")
}
