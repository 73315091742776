@file: UseContextualSerialization(UUID::class, ServerFile::class, Instant::class)
package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization


// Type aliases to give meaning behind function arguments, return types, and property types.
// Everything from plaid is a string, but all have very special uses.
typealias PaymentToken = String
typealias PaymentAccountId = String
typealias PaymentId = String
typealias TransferId = String
typealias SweepId = String
typealias IntentId = String

@Serializable
@GenerateDataClassPaths
data class AccessInfo(
    @References(User::class) val user: UUID,
    val ip: String,
    val sessionId: UUID? = null,
    val userAgent: String,
    val timestamp: Instant,
)

@Serializable
data class AccountInfo(
    val bankName: String,
    val accountName: String,
)

@Serializable
@GenerateDataClassPaths
data class Address(
    val line1: String = "",
    val line2: String? = null,
    val city: String = "",
    val state: State = State.Utah,
    val zipcode: String = "",
) {
    override fun toString(): String = "$line1${line2?.let { " $it" } ?: ""}, $city, ${state.abbreviated} $zipcode"
}

interface Attachment {
    val name: String
    val fileType: String
    val file: ServerFile
    val preview: ServerFile?
}

@Serializable
@GenerateDataClassPaths
data class ProjectAttachment(
    override val name: String,
    override val fileType: String,
    override val file: ServerFile,
    override val preview: ServerFile? = null,
) : Attachment

//@Serializable
//data class BankAccount(
//    val id: String,
//    val name: String,
//    val numberMask: String?,
//    val balance: Double?,
//    val currency: String?,
//    val type: String?,
//)

//@Serializable
//data class BankInfo(
//    val name: String,
//    val logo: String?,
//    val color: String?,
//    val accounts: List<BankAccount>,
//)

@Serializable
@GenerateDataClassPaths
data class ClientFeedback(
    val at: Instant = now(),
    val message: String
)

@Serializable
data class ChangeDenyRequest(
    val hash: String,
    val feedback: String? = null,
)


@Serializable
@GenerateDataClassPaths
data class ItemChange(
    val id: UUID = uuid(),
    @References(LineItem::class) val itemId: UUID,
    val description: String = "",
    val price: Long = 0L,
    val cancelled: Boolean = false,
)


@Serializable
@GenerateDataClassPaths
data class CompletionRequest(
    val contractor: AccessInfo,
    val customer: AccessInfo? = null,
)

interface Contact {
    val name: String?
    val email: String?
    val phoneNumber: String?
    val address: Address?
    val image: ServerFile?
}

@Serializable
@GenerateDataClassPaths
data class Dispute(
    val accessInfo: AccessInfo,
    val feedback: ClientFeedback? = null,
//    @References(ChangeRequest::class) val changeRequest: UUID? = null,
    val filedForArbitration: FiledForArbitration? = null,
)

@Serializable
@GenerateDataClassPaths
data class FiledForArbitration(
    val accessInfo: AccessInfo,
    val evidence: List<ServerFile>,
)


@Serializable
data class FullProject(
    val project: Project,
    val client: Client,
    val contractor: Contractor,
    val lineItems: List<LineItem>,
)


@Serializable
data class PaymentSetupRequest(
    val bankName: String,
    val routingNumber: Int,
    val accountNumber: String,
    val accountName: String,
)

@Serializable
data class PendingCashOut(
    val available: Long,
    val pending: Long,
)


@Serializable
@GenerateDataClassPaths
data class UserMembership(
    @References(Contractor::class) val contractor: UUID,
    val role: MemberRole
)

@Serializable
data class UserRegistration(
    val email: String,
    val legalName: String,
    val phoneNumber: String,
)

@Serializable
data class RegisterContractor(
    val contractor: Contractor,
    val proof: Proof
)