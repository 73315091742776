package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.Action
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.screens.auth.ContractorRegistration
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.body
import com.crowpay.views.theming.notificationBar
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.tertiaryButton
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactive
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.atEnd
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import kotlinx.coroutines.launch

suspend fun ViewWriter.verifyContractor() {
    val self = notNullSession().self()
    val selected = selectedContractor()

    if (self.memberships.isEmpty() || selected == null) mainScreenNavigator.reset(LandingScreen())
    else if (self.memberships.none { it.contractor == selected }) {
        selectedContractor set null
        screenNavigator.reset(ClientDashboard())
    }
}

interface ContractorScreen : Screen {
    // If null then it just shoots them back to the registration screen
    val finishRegistrationMessage: String? get() = "You need to finish registering before you can see this"
    val awaitingApprovalMessage: String get() =
        "Your account is currently awaiting approval. You will have limited access until approved."

    fun ViewWriter.renderFinishRegistration() {
        sizeConstraints(width = AppDimensions.pageWidth) - col {
            notificationBar(AppColors.yellow) - col {
                finishRegistrationMessage?.let {
                    body(it)
                } ?: screenNavigator.reset(ContractorRegistration())
                atEnd - tertiaryButton - button {
                    specCenteredText("Finish Registration")
                    action = Action("Go To Registration") {
                        screenNavigator.reset(ContractorRegistration())
                    }
                }
            }
        }
    }
    fun ViewWriter.renderAwaitingApproval() {
        col {
            spacing = AppDimensions.majorColumnSpacing
            sizeConstraints(width = AppDimensions.pageWidth) - notificationBar(AppColors.blue) - row {
                body(awaitingApprovalMessage)
            }

            renderMainContent()
        }
    }

    fun ViewWriter.renderMainContent()

    override fun ViewWriter.render() {
        expanding - stack {
            spacing = 0.px
            launch { verifyContractor() }
            reactive {
                clearChildren()
                val active = contractorActive() ?: run {
                    screenNavigator.reset(LandingScreen())
                    throw CancelledException("No Contractor")
                }
                when (active) {
                    ContractorActive.Registering -> renderFinishRegistration()
                    ContractorActive.AwaitingApproval -> renderAwaitingApproval()
                    ContractorActive.Approved -> renderMainContent()
                }
            }
        }
    }
}

interface MightBeContractorScreen : Screen {
    val lens: ProjectLens

    // If null then it just shoots them back to the registration screen
    val finishRegistrationMessage: String? get() = "You need to finish registering before you can see this"
    val awaitingApprovalMessage: String get() = "Your account is currently awaiting approval. You will have limited access until approved."

    fun ViewWriter.renderFinishRegistration() {
        sizeConstraints(width = AppDimensions.pageWidth) - col {
            notificationBar(AppColors.yellow) - col {
                finishRegistrationMessage?.let {
                    body(it)
                } ?: screenNavigator.reset(ContractorRegistration())
                atEnd - tertiaryButton - button {
                    specCenteredText("Finish Registration")
                    action = Action("Go To Registration") {
                        screenNavigator.reset(ContractorRegistration())
                    }
                }
            }
        }
    }
    fun ViewWriter.renderAwaitingApproval() {
        col {
            spacing = AppDimensions.majorColumnSpacing
            sizeConstraints(width = AppDimensions.pageWidth) - notificationBar(AppColors.blue) - row {
                body(awaitingApprovalMessage)
            }

            renderMainContent()
        }
    }

    fun ViewWriter.renderMainContent()

    override fun ViewWriter.render() {
        when (lens) {
            ProjectLens.Contractor -> {
                expanding - stack {
                    launch { verifyContractor() }
                    reactive {
                        clearChildren()
                        val active = contractorActive() ?: run {
                            screenNavigator.reset(LandingScreen())
                            throw CancelledException("No Contractor")
                        }
                        when (active) {
                            ContractorActive.Registering -> renderFinishRegistration()
                            ContractorActive.AwaitingApproval -> renderAwaitingApproval()
                            ContractorActive.Approved -> renderMainContent()
                        }
                    }
                }
            }

            ProjectLens.Customer, ProjectLens.Preview -> {
                renderMainContent()
            }
        }
    }
}