package com.crowpay.views.screens.auth

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.selectedApi
import com.crowpay.views.screens.AutoRoutes
import com.crowpay.views.screens.client.ClientForm
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


@Routable("auth/user-registration")
class UserRegistration : Screen, AuthScreen {

    override val title: Readable<String> = shared {
        projectData.project.name
    }

    @QueryParameter
    val next: Property<String?> = Property(null)
    var nextScreen: Screen? = null
        set(value) {
            field = value
            next.value = value?.let { AutoRoutes.render(it)?.urlLikePath?.render() }
        }

    lateinit var projectData: FullProject
    lateinit var apiOption: ApiOption
    val userData = Property(
        User(
            email = "",
            phoneNumber = "",
            firstName = "",
            lastName = "",
            address = Address()
        )
    )

    val clientForm = ClientForm(
        user = userData,
        widthLimit = 40.rem
    )

    override fun ViewWriter.render() {

        try {
            projectData
            apiOption
        } catch (e: Exception) {
            screenNavigator.reset(LandingScreen())
            return
        }


        stack {
            spacing = AppDimensions.cornerRadii
            themeChoice += ThemeDerivation {
                it.copy(background = AppColors.grey.light1, revert = true).withoutBack
            }
            centered - sizeConstraints(width = AppDimensions.pageWidth) - stack {
                themeChoice += ThemeDerivation { AuthDialogSemantic(it) }
                spacing = AppDimensions.fullIndent
                scrolls - col {
                    themeChoice = ThemeDerivation(appTheme)

                    centered - image {
                        source = Resources.imagesFullLogoColor
                    } in sizeConstraints(height = 4.rem)

                    centered - body {
                        align = Align.Center
                        content =
                            "Welcome to CrowPay, your escrow solution. You need to create a user before you can move forward. After registering you will be asked to sign in, and then you will be able to accept the project."
                    }

                    clientForm.render(this)

                    space()

                    centered - sizeConstraints(width = 25.rem) - primaryButton - button {
                        ::enabled{ clientForm.allValid() }
                        specCenteredText("Register")
                        onClick {
                            clientForm.userDraft.publish()
                            val newUser = clientForm.userDraft.published.awaitOnce()
                            apiOption.api.userAuth.register(
                                newUser
                            )

                            this.screenNavigator.reset(LogInScreen().apply {
                                nextScreen = this@UserRegistration.nextScreen
                                selectedApi.set(apiOption)
                            })
                        }
                    }

                    centered - smallBody("You will be redirected to login after registration")
                }
            }
        }
    }
}