package com.crowpay.views.screens.common

import com.crowpay.User
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.selectedApi
import com.crowpay.utils.lazy
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.ProjectSection
import com.crowpay.views.components.project.projectView
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.auth.UserRegistration
import com.crowpay.views.screens.client.ClientTermsAndServices
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.gravity
import com.lightningkite.kiteui.views.direct.scrolls

interface PreviewScreen

@Routable("projects/anonymous")
class ProjectAnonymousViewing() : Screen, PreviewScreen {
    override val title: Readable<String> = Constant("Project Preview")

    @QueryParameter
    val token: LateInitProperty<String> = LateInitProperty()

    @QueryParameter
    val server: LateInitProperty<String> = LateInitProperty()

    val apiOption = shared {
        println("In ApiOptions")
        val s = ApiOption.entries.find { it.http == server() } ?: ApiOption.Dev
        println("Found $s")
        selectedApi.value = s
        println("Set $s")
//        delay(1500)
        println("Returning $s")
        s
    }

    val projectData = sharedSuspending{
        println("Retrieving Project")
        apiOption().api.project
            .viewProjectAnonymously { token() }
            .also { println("Have Project") }
    }

    val project = shared {
        projectData().project
    }
    val lineItems = shared {
        projectData().lineItems
    }
    val client = shared {
        projectData().client
    }
    val contractor = shared {
        projectData().contractor
    }

    @QueryParameter
    val selectedTab = Property(ProjectSection.Details)

    override fun ViewWriter.render() {
        scrolls - col {
            println("Rendering Anonymous Project")
            reactiveScope {
                println("ApiOption: ${apiOption()}")
            }
            reactiveScope {
                println("Session: ${currentSession()}")
            }
            lazy(apiOption, currentSession) { api, session ->
                if (session == null)
                    gravity(Align.Center, Align.Center)
                else
                    gravity(Align.Start, Align.Start)
                projectView(
                    project,
                    client,
                    contractor,
                    lineItems,
                    Constant(emptyList()),
                    Constant(emptyList()),
                    Constant(emptyList()),
                    ProjectLens.Preview,
                    selectedTab,
                    onLogin = {
                        screenNavigator.navigate(LogInScreen().apply {
                            nextScreen = this@ProjectAnonymousViewing
                            selectedApi.set(api)
                        })
                    },
                    onAccept = {
                        dialogScreenNavigator.navigate(GenericConfirmationDialog(
                            header = "Accept Project?",
                            message = "Accepting this project confirms that you have reviewed and agreed to the project details. Please check everything is correct, as changes after this step will need both parties to agree. Click 'Accept' and proceed to review CrowPay's Terms of Service for escrow project protection.",
                            confirmationText = "Accept Project",
                            declineText = "Go Back",
                            messageType = MessageType.Confirmation,
                            onSubmit = {
                                if (it) {
                                    val p = projectData.awaitOnce()
                                    screenNavigator.navigate(ClientTermsAndServices(p.project._id).apply {
                                        fullProject = p
                                    })
                                }
                            }
                        ))
                    },
                    onRegister = {
                        println("In register")
                        screenNavigator.navigate(UserRegistration().apply {
                            nextScreen = this@ProjectAnonymousViewing
                            projectData = this@ProjectAnonymousViewing.projectData.await()
                            apiOption = api

                            val client = client()
                            userData.value = User(
                                email = client.email,
                                phoneNumber = client.phoneNumber,
                                address = client.address,
                                firstName = client.name.substringBefore(' '),
                                lastName = client.name.substringAfter(' '),
                            )
                        })
                    }
                )
            }
        }
    }
}