// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareAddressFields() {
    val props: Array<SerializableProperty<Address, *>> = arrayOf(Address_line1, Address_line2, Address_city, Address_state, Address_zipcode)
    Address.serializer().properties { props }
}
val <K> DataClassPath<K, Address>.line1: DataClassPath<K, String> get() = this[Address_line1]
val <K> DataClassPath<K, Address>.line2: DataClassPath<K, String?> get() = this[Address_line2]
val <K> DataClassPath<K, Address>.city: DataClassPath<K, String> get() = this[Address_city]
val <K> DataClassPath<K, Address>.state: DataClassPath<K, State> get() = this[Address_state]
val <K> DataClassPath<K, Address>.zipcode: DataClassPath<K, String> get() = this[Address_zipcode]
inline val Address.Companion.path: DataClassPath<Address, Address> get() = path<Address>()


object Address_line1: SerializableProperty<Address, String> {
    override val name: String = "line1"
    override fun get(receiver: Address): String = receiver.line1
    override fun setCopy(receiver: Address, value: String) = receiver.copy(line1 = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("line1")
    override val default: String = ""
}
object Address_line2: SerializableProperty<Address, String?> {
    override val name: String = "line2"
    override fun get(receiver: Address): String? = receiver.line2
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(line2 = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("line2")
    override val default: String? = null
}
object Address_city: SerializableProperty<Address, String> {
    override val name: String = "city"
    override fun get(receiver: Address): String = receiver.city
    override fun setCopy(receiver: Address, value: String) = receiver.copy(city = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("city")
    override val default: String = ""
}
object Address_state: SerializableProperty<Address, State> {
    override val name: String = "state"
    override fun get(receiver: Address): State = receiver.state
    override fun setCopy(receiver: Address, value: State) = receiver.copy(state = value)
    override val serializer: KSerializer<State> = State.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("state")
    override val default: State = State.Utah
}
object Address_zipcode: SerializableProperty<Address, String> {
    override val name: String = "zipcode"
    override fun get(receiver: Address): String = receiver.zipcode
    override fun setCopy(receiver: Address, value: String) = receiver.copy(zipcode = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("zipcode")
    override val default: String = ""
}
