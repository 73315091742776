package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.*
import com.crowpay.views.components.changeOrders.ChangeOrderLineEdit
import com.crowpay.views.components.changeOrders.ChangeRequestForm
import com.crowpay.views.components.project.AdjustLineItem
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.minus
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.lightningdb.*

abstract class AbstractChangeOrder : ContractorScreen {

    override val title: Readable<String>
        get() = shared {
            "${project().name} Change Order"
        }
    abstract val change: Readable<ChangeRequest>
    abstract val allowDelete: Readable<Boolean>
    abstract val project: Readable<Project>

    val originalLineItems = sharedSuspending {
        notNullSession().nonCached.lineItem
            .query(Query(condition {
                (it.project eq project()._id) and (it.state notInside setOf(
                    LineItemState.Cancelled,
                    LineItemState.RequestingReview
                ))
            }))
    }

    val form by lazy { ChangeRequestForm(project, change, originalLineItems) }

    abstract suspend fun save()

    // You cannot call super in an overridden extension function
    // so we can't have this be ViewWriter.publish
    open suspend fun publish() {

        val session = currentSession() ?: return
        val changeId = change.await()._id
        val result = session.nonCached.changeRequest.publishChangeRequest(changeId)

        session.changeRequests.localSignalUpdate(
            matching = { it._id == result._id },
            modify = { result }
        )
    }

    suspend fun shouldPublish(dialog: ScreenNavigator): Boolean {

        val invalidItems = mutableListOf<AdjustLineItem>()
        val incompleteItems = mutableListOf<AdjustLineItem>()
        val incompleteNewItems = mutableListOf<ChangeOrderLineEdit>()

        form.itemChangeForms.value.values.forEach { form ->

            val ogId = form.ogId.awaitOnce()
            originalLineItems.awaitOnce()
                .find { it._id == ogId }
                ?: run {
                    invalidItems.add(form)
                    return@forEach
                }
            if (!form.allValid.await()) {
                incompleteItems.add(form)
            }
        }

        form.newLineForms.value.values.forEach { form: ChangeOrderLineEdit ->
            if (!form.validator.allValid())
                incompleteNewItems.add(form)
        }

        val invalidItemMessage = if (invalidItems.isNotEmpty()) {
            val names = invalidItems.map { it.originalName.awaitOnce() }.joinToString("\n") { it }

            "The adjustments for:\n\n$names\n\nare no longer valid. This can happen if you create two change orders that modify the same Work Item. Remove or fix these changes before continuing."
        } else null


        val incompleteItemsMessage = if (incompleteItems.isNotEmpty()) {
            val names = incompleteItems.map { it.originalName.awaitOnce() }.joinToString("\n") { it }
            "The adjustments for:\n\n$names\n\nare incomplete. Fill out the all the fields before continuing."
        } else null

        val incompleteNewItemsMessage = if (incompleteNewItems.isNotEmpty()) {
            val names = incompleteNewItems.map { it.form.name.awaitOnce() }.joinToString("\n") { it }
            "The now scope items:\n\n$names\n\nare incomplete. Fill out the all the fields before continuing."
        } else null

        if (invalidItemMessage != null ||
            incompleteItemsMessage != null ||
            incompleteNewItemsMessage != null
        ) {
            val messages = listOfNotNull(invalidItemMessage, incompleteItemsMessage, incompleteNewItemsMessage)
            dialog.navigate(
                GenericDialog("The following error must be resolved before you can publish.\n${messages.joinToString("\n------\n") { it }}")
            )
            return false
        }

        return true

    }


    override fun ViewWriter.renderMainContent() {
        scrolls - col {

            sizeConstraints(width = AppDimensions.pageWidth) - col {

                title { 
                    ::content { "${project().name} Change Order" }
                }

                darkSection - row {
                    spacing = AppDimensions.buttonRowSpacing

                    centered - secondaryButton - button {
                        specCenteredText("Save")
                        onClick { save() }
                    }
                    centered - secondaryButton - button {
                        specCenteredText("Save & Close")
                        onClick {
                            save()
                            screenNavigator.dismiss()
                        }
                    }
                    centered - textButton - button {
                        specCenteredText("Discard")
                        onClick {
                            val allowDelete = allowDelete.awaitOnce()
                            dialogScreenNavigator.navigate(GenericConfirmationDialog(
                                header = if (allowDelete)
                                    "Discard Changes?"
                                else
                                    "Discard Change Order?",
                                message = if (allowDelete)
                                    "Discarding unsaved work cannot be undone. Are you sure you want to discard any changes?"
                                else
                                    "Discarding unsaved work cannot be undone. Are you sure you want to discard this Change Order?",
                                confirmationText = if (allowDelete)
                                    "Discard Unsaved Work"
                                else
                                    "Discard Change Order",
                                messageType = MessageType.Danger,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        screenNavigator.dismiss()
                                    }
                                }
                            ))
                        }
                    }
                    onlyWhen { allowDelete<Boolean>() } - centered - textButton - button {
                        specCenteredText("Delete")
                        onClick {
                            dialogScreenNavigator.navigate(GenericConfirmationDialog(
                                header = "Delete Change Order?",
                                message = "Deleting a Change Order cannot be undone. Are you sure you want to delete this Change Order?",
                                confirmationText = "Delete Change Order",
                                messageType = MessageType.Danger,
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        notNullSession().changeRequests[change.await()._id].delete()
                                        screenNavigator.dismiss()
                                    }
                                }
                            ))
                        }
                    }

                    expanding - space()

                    centered - primaryButton - button {
                        ::enabled{
                            val newLines = form.newLineForms()
                            val itemChanges = form.itemChangeForms()
                            form.titleValidator.allValid() &&
                                    (form.projectDescription.awaitOnce().isNotBlank() ||
                                            newLines.isNotEmpty() ||
                                            itemChanges.isNotEmpty()) &&
                                    newLines.all { it.value.validator.allValid() } &&
                                    itemChanges.all { it.value.allValid() }
                        }
                        specCenteredText("Publish")
                        onClick {
                            if (shouldPublish(dialogScreenNavigator))
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Publish Change Order",
                                        message = "Publishing this Change Order will make it available to the client for approving or denying. You cannot make changes to Change Orders that have been published.",
                                        confirmationText = "Publish Change Order",
                                        messageType = MessageType.Confirmation,
                                        onSubmit = {
                                            if (it) {
                                                save()
                                                publish()
                                                screenNavigator.dismiss()
                                            }
                                        }
                                    )
                                )
                        }
                    }

                    space(AppDimensions.fullIndent - (AppDimensions.buttonRowSpacing * 2))
                }
                form.render(this@col)
            }
        }
    }
}