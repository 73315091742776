package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.projectList
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.lightningdb.*


@Routable("/contractor/projects")
class ContractorProjects() : ContractorScreen {

    override val title: Readable<String> = Constant("Projects")

    val projects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition { (it.contractor eq notNullContractor()) },
                )
            )()
            .sortedWith(compareBy({ it.state }, { it.name }))
    }

    val clients = shared {
        val ids = projects().mapNotNull { it.client }.toSet()
        notNullSession().clients
            .query(Query(condition { it._id inside ids }))()
    }

    override fun ViewWriter.renderMainContent() {
        projectList(
            lens = ProjectLens.Contractor,
            projects,
            clients
        )
    }
}