package com.crowpay.utils

import com.crowpay.LineItem
import com.lightningkite.UUID
import com.crowpay.views.components.files.FileData
import com.lightningkite.kiteui.reactive.Draft
import com.lightningkite.kiteui.reactive.LazyProperty


data class LineItemDraft(
    val id: UUID,
    val draft: Draft<LineItem>,
    val files: LazyProperty<List<FileData>?>,
)


enum class ReorderDirection {
    UP,
    DOWN,
}