package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.views.components.*
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.dialogs.*
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.modification
import com.lightningkite.now
import kotlinx.coroutines.delay
import kotlin.coroutines.resume

private fun ViewWriter.renderLineItem(
    item: Readable<LineItem>,
    logs: Readable<List<LineItemLog>>,
    punchLists: Readable<List<PunchListItem>>,
    changes: Readable<List<ItemChange>>,
    project: Readable<Project>,
    lens: ProjectLens,
) {
    val expanded = Property(false)
    col {
        button {
            val rowSpace = 0.5.rem
            col {
                space(rowSpace / 2)
                // Item header
                row {
                    weight(3f) - row {
                        centered - expandIcon(expanded)
                        centered - subTitle { ::content { item().name } }
                    }
                    weight(1f) - stack {
                        centered - row {
                            spacing = 0.2.rem
                            space(0.8.rem)
                            centered - body {
                                align = Align.Center
                                ::content { item().price.renderDollars() }
                            }
                            centered - priceArrowIcon {
                                changes().sumOf { it.price }
                            }
                        }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        existsDefaultFalse { project().state >= ProjectState.PendingStart }

                        if (lens == ProjectLens.Preview) content = ""
                        else {
                            ::content { item().state.displayValue }
                            dynamicTheme {
                                LineItemStateSemantic(item().state)
                            }
                        }
                    }
                    weight(1f) - centered - body {
                        align = Align.Center
                        ::content {
                            val changes = changes()
                            if (changes.isEmpty()) "Unchanged"
                            else changes.sumOf { it.price }.let {
                                if (it == 0L) "Unchanged"
                                else if (it > 0) "Increased"
                                else "Decreased"
                            }
                        }
                    }
                }
                space(rowSpace / 2)
            }
            onClick { expanded.toggle() }
        }

        onlyWhen { expanded() } - fullIndentCol {
            spacing = 4.rem

            // Notification bars
            col {
                val notificationExists = Property(false)
                existsDefaultFalse { notificationExists() }

                space(1.rem)
                col {
                    lazy(item.lens { it.state }) { state ->
                        if (lens == ProjectLens.Customer && state == LineItemState.RequestingReview)
                            notificationBar(AppColors.yellow) - row {
                                notificationExists.value = true
                                sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                                    Icon.notify,
                                    "Disclose Dates"
                                )
                                body("If not approved funds will auto-release in 14 days.")
                            }

                        if (lens == ProjectLens.Contractor && state == LineItemState.InProgress)
                            notificationBar(AppColors.yellow) - body {
                                notificationExists.value = true
                                content =
                                    "Don't request sign off before the item is completed. Doing so can build mistrust in your clients."
                            }

                        if (lens == ProjectLens.Contractor && state == LineItemState.RequestingReview)
                            notificationBar(AppColors.yellow) - row {
                                notificationExists.value = true
                                sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                                    Icon.notify,
                                    "Disclose Dates"
                                )
                                body("Disclose Dates. Sign of request sent.")
                            }

                        if (state == LineItemState.Complete)
                            notificationBar(AppColors.blue) - row {
                                notificationExists.value = true
                                sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                                    Icon.notify,
                                    "Complete Info"
                                )
                                body {
                                    content =
                                        if (lens == ProjectLens.Contractor)
                                            "Congrats. Don’t forget to record any loose items here on the Project Punch List"
                                        else
                                            "Hype Message"
                                }
                            }

                        if (state == LineItemState.NotApproved)
                            notificationBar(AppColors.red) - col {
                                notificationExists.value = true
                                if (lens == ProjectLens.Contractor) {
                                    col {
                                        spacing = 0.dp
                                        subTitle("Your client has left feedback")

                                        smallBody("Make sure you address the clients feedback before you request approval again.")
                                    }
                                } else subTitle("Your feedback")
                                body {
                                    ::content { item().notApproved?.message ?: "" }
                                }
                            }
                    }
                }
            }

            col {
                spacing = 2.rem

                keyValue("Description") { item().description }

                // Files
                label2("Files (images, Bids, Plans, Specs)") {
                    val files = item.lens { it.files }
                    existsDefaultFalse { files().isNotEmpty() }
                    renderFiles(files, AppDimensions.smallImagePreview)
                }
            }

            val notUnderReview = item.lens { it.requestingReview == null }

            // Punch list
            col {
                existsDefaultFalse{
                    project().state >= ProjectState.Accepted &&
                            item().state !in setOf(LineItemState.Cancelled, LineItemState.NotStarted) &&
                            (lens == ProjectLens.Contractor || punchLists().isNotEmpty())
                }

                renderPunchListRow(
                    title = Constant("Work List"),
                    items = punchLists,
                    lineId = item.lens { it._id },
                    projectId = project.lens { it._id },
                    allowNew = project.share { it.state < ProjectState.Complete },
                    allowRequired = notUnderReview,
                    lens = lens,
                    indent = Indent.Section
                )
            }

            // Item Log bar
            if (lens == ProjectLens.Contractor) col {
                spacing = 1.rem
                val logsExpanded = Property(false)

                row {
                    expanding - button {
                        row {
                            centered - expandIcon(logsExpanded)
                            centered - subTitle { ::content { "Contractor Notes (${logs().size})" } }
                        }
                        onClick { logsExpanded.toggle() }
                    }

                    if (lens == ProjectLens.Contractor) sizeConstraints(width = 11.rem) - tertiaryButton - button {
                        ::enabled { notUnderReview() }
                        specCenteredText("+ Note")
                        onClick {
                            logsExpanded.set(true)
                            dialogScreenNavigator.navigate(CreateEditLogDialog(null, item))
                        }
                    }
                }

                onlyWhen { logsExpanded() } - sectionIndentCol {
                    sizeConstraints(height = 5.rem) - stack {
                        ::exists { logs().isEmpty() }
                        centered - colored(AppColors.grey.light1) - h3 {
                            content = "No Notes"
                        }
                    }
                    withSpacing(1.rem) - col {
                        exists = false
                        ::exists { logs().isNotEmpty() }

                        forEachUpdating(logs, 0) { itemLog ->
                            col {
                                spacing = 1.rem
                                val index = shared { logs().indexOf(itemLog()) }
                                space()
                                row {
                                    subTitle {
                                        ::content {
                                            "${item().name} Note ${index() + 1} [${itemLog().at.format()}]"
                                        }
                                    }
                                    row {
                                        spacing = 0.5.rem
                                        buttonTheme - button {
                                            spacing = 1.dp
                                            ::enabled { notUnderReview() }
                                            centered - icon(Icon.edit.copy(width = 1.5.rem, height = 1.5.rem), "Edit")
                                            onClick {
                                                dialogScreenNavigator.navigate(CreateEditLogDialog(itemLog(), item))
                                            }
                                        }
                                        buttonTheme - button {
                                            spacing = 1.dp
                                            centered - icon(
                                                Icon.trash.copy(width = 1.5.rem, height = 1.5.rem),
                                                "Delete"
                                            )
                                            onClick {
                                                dialogScreenNavigator.navigate(
                                                    GenericConfirmationDialog(
                                                        "Delete Note",
                                                        "Are you sure you want to delete this note?",
                                                        messageType = MessageType.Danger,
                                                        confirmationText = "Delete"
                                                    ) {
                                                        notNullSession().lineItemLogs[item()._id].delete()
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }

                                body { ::content{ itemLog().message } }

                                stack {
                                    val files = itemLog.lens { it.files }
                                    ::exists{ files().isNotEmpty() }
                                    label2("Files: ") {
                                        renderFiles(
                                            files,
                                            AppDimensions.smallImagePreview
                                        )
                                    }
                                }

                                space()
                                separator {
                                    ::exists { index() + 1 != logs().size }
                                }
                            }
                        }
                    }
                }

                separator()
            }

            // Change log
            col {
                spacing = 1.rem
                val logExpanded = Property(false)
                existsDefaultFalse { changes().isNotEmpty() }

                button {
                    row {
                        centered - expandIcon(logExpanded)
                        centered - subTitle("Change Log")
                    }
                    onClick { logExpanded.toggle() }
                }

                onlyWhen { logExpanded() } - sectionIndentCol {
                    space(1.rem)
                    row {
                        weight(3f) - bodyBold("Item Name")
                        weight(1f) - bodyBold("ID") { align = Align.Center }
                        weight(1f) - bodyBold("Amount") { align = Align.Center }
                        weight(1f) - bodyBold("Status") { align = Align.Center }
                        weight(1f) - bodyBold("Date") { align = Align.Center }
                        weight(1f) - space()
                    }
                    separator()
                    val expandedItems = Property(emptyList<UUID>())
                    col {
                        forEachUpdating(changes) { change ->
                            val itemExpanded = expandedItems.containsDynamic { change().id }
                            col {
                                sizeConstraints(height = 4.rem) - row {
                                    weight(3 + 1 + 1 + 1 + 1f) - button {
                                        row {
                                            weight(3f) - centered - row {
                                                centered - expandIcon(itemExpanded)
                                                centered - body { ::content { item().name } }
                                            }
                                            weight(1f) - centered - body {
                                                ::content { "UC" }
                                                align = Align.Center
                                            }
                                            weight(1f) - stack {
                                                centered - renderPriceChange { change().price }
                                            }
                                            weight(1f) - centered - body {
                                                align = Align.Center
                                                ::content { "CONS" }
                                            }
                                            weight(1f) - centered - body {
                                                align = Align.Center
                                                ::content { "CONS" }
                                            }
                                        }
                                        onClick { itemExpanded.toggle() }
                                    }
                                    weight(1f) - stack {
                                        centered - secondaryButton - button {
                                            specCenteredText("View")
                                        }
                                    }
                                }
                                separator()
                            }
                        }
                    }
                }

                separator { ::exists { !logExpanded() } }
            }

            // Action Bar
            sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - stack {
                spacing = AppDimensions.sectionIndent / 2
                val primaryAction = shared {
                    val project = project()
                    val lineItem = item()

                    val isCustomer = (lens != ProjectLens.Contractor)
                    val isContractor = (lens == ProjectLens.Contractor)
                    val notComplete = (lineItem.state < LineItemState.Complete)
                    val started = (lineItem.started != null)
                    val cancelled = (lineItem.cancelled != null)
                    val notDisputed = (project.state != ProjectState.Disputed)

                    when {
                        isContractor &&
                                notComplete &&
                                !started &&
                                project.state in setOf(ProjectState.PendingStart, ProjectState.InProgress) ->
                            Action("Start Work") {
                                suspend fun startItem(id: UUID) {
                                    val session = notNullSession()
                                    session
                                        .lineItems[id]
                                        .modify(modification { it.started assign now() })
                                    session.projects[project._id].invalidate()
                                }

                                if (project.started == null)
                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Start Work Item?",
                                            message = "Start work item and project?",
                                            confirmationText = "Start Item and Project",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = { confirmed ->
                                                if (confirmed) {
                                                    startItem(lineItem._id)
                                                }
                                            }
                                        )
                                    )
                                else {
                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Start Work Item?",
                                            message = "Confirm that you want to start this work item.",
                                            confirmationText = "Start Work Item",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = { confirmed ->
                                                if (confirmed) {
                                                    startItem(lineItem._id)
                                                }
                                            }
                                        )
                                    )
                                }
                            }

                        isCustomer &&
                                notComplete &&
                                notDisputed &&
                                lineItem.state in setOf(
                            LineItemState.RequestingReview,
                            LineItemState.NotApproved
                        ) ->
                            Action("Approve Work") {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Approve & Pay Work Item?",
                                        message = "Approving a work item releases the funds for this work item.",
                                        question = "Approve Work Item?",
                                        confirmationText = "Approve & Pay",
                                        messageType = MessageType.Confirmation,
                                        onSubmit = { confirmed ->
                                            if (confirmed) {
                                                val session = notNullSession()
                                                val result = session.nonCached.lineItem.complete(lineItem._id)
                                                session.lineItems.localSignalUpdate(
                                                    matching = { it._id == result._id },
                                                    modify = { result }
                                                )
                                                session.projects[project._id].invalidate()
                                                delay(200)
                                            }
                                        }
                                    )
                                )
                            }

                        isCustomer &&
                                notComplete &&
                                notDisputed &&
                                lineItem.state == LineItemState.RequestingReview ->
                            Action("Add Feedback") {
                                dialogScreenNavigator.navigate(
                                    GenericFeedBackDialog(
                                        header = "Work Item Feedback",
                                        initialFeedback = lineItem.notApproved?.message,
                                        required = true,
                                        allowRemoving = false,
                                        onSubmit = {
                                            it?.let { feedback ->
                                                val session = notNullSession()
                                                val result = session.nonCached.lineItem.rejectComplete(
                                                    lineItem._id,
                                                    feedback
                                                )
                                                session.lineItems.localSignalUpdate(
                                                    matching = { it._id == result._id },
                                                    modify = { result }
                                                )
                                            }
                                        },
                                        onDismiss = {}
                                    )
                                )
                            }

                        isContractor &&
                                notComplete &&
                                project.state == ProjectState.InProgress &&
                                lineItem.state in setOf(LineItemState.InProgress, LineItemState.NotApproved) ->
                            Action("Request Sign Off") {
                                if (punchLists().any { it.required && it.complete == null })
                                    dialogScreenNavigator.navigate(
                                        GenericDialog(
                                            message = "You still have required punch list items pending. You cannot request review until all required punch list items are complete.",
                                            onDismiss = { }
                                        )
                                    )
                                else
                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Ready to Sign Off?",
                                            message = "You are about to request review of this line item. You will still be able to modify the contractor notes until it is accepted by the client.",
                                            question = null,
                                            confirmationText = "Request Sign Off",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = { confirmed ->
                                                if (confirmed) {
                                                    val id = lineItem._id
                                                    val session = notNullSession()
                                                    val result = session.nonCached.lineItem.requestReview(id)
                                                    session.lineItems.localSignalUpdate(
                                                        matching = { it._id == result._id },
                                                        modify = { result }
                                                    )
                                                }
                                            }
                                        )
                                    )
                            }

                        else -> null
                    }
                }

                val secondaryAction = shared {
                    val project = project()
                    val lineItem = item()

                    val isCustomer = (lens != ProjectLens.Contractor)
                    val notComplete = (lineItem.state < LineItemState.Complete)
                    val notDisputed = (project.state != ProjectState.Disputed)

                    when {
                        isCustomer &&
                                notComplete &&
                                notDisputed &&
                                lineItem.state == LineItemState.RequestingReview ->
                            Action("Not Ready") {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "What's The Hold Up?",
                                        message = "Tell your contractor why you aren’t approving yet so he can respond and after he feels he’s fully ready, he will resubmit for approval.",
                                        confirmationText = "Hold Approval",
                                        messageType = MessageType.Danger,
                                        onSubmit = { confirmed ->
                                            if (confirmed) {
                                                suspendCoroutineCancellable { cont ->
                                                    dialogScreenNavigator.navigate(
                                                        GenericFeedBackDialog(
                                                            header = "Work Item Feedback",
                                                            required = true,
                                                            allowRemoving = false,
                                                            onSubmit = {
                                                                it?.let { feedback ->
                                                                    val session = notNullSession()
                                                                    val result =
                                                                        session.nonCached.lineItem.rejectComplete(
                                                                            lineItem._id,
                                                                            feedback
                                                                        )
                                                                    session.lineItems.localSignalUpdate(
                                                                        matching = { it._id == result._id },
                                                                        modify = { result }
                                                                    )
                                                                }
                                                                cont.resume(Unit)
                                                            },
                                                            onDismiss = {
                                                                cont.resume(Unit)
                                                            }
                                                        )
                                                    )
                                                    return@suspendCoroutineCancellable { dialogScreenNavigator.clear() }
                                                }
                                            }
                                        }
                                    )
                                )
                            }

                        else -> null
                    }
                }
                exists = false
                existsDefaultFalse { primaryAction() != null || secondaryAction() != null }
                row {
                    spacing = AppDimensions.cornerRadii

                    expanding - space()

                    secondaryButton - button {
                        ::exists { secondaryAction() != null && primaryAction() != null }
                        specCenteredText { secondaryAction()?.title ?: "" }
                        ::action { secondaryAction() }
                    }

                    space(AppDimensions.buttonRowSpacing)

                    primaryButton - button {
                        ::exists { primaryAction() != null || secondaryAction() != null }
                        specCenteredText { primaryAction()?.title ?: secondaryAction()?.title ?: "" }
                        ::action{ primaryAction() ?: secondaryAction() }
                    }

                    space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)
                }
            }

            space(AppDimensions.sectionIndent)
        }

        separator()
    }
}

fun ViewWriter.scopeV2(
    project: Readable<Project>,
    lineItems: Readable<List<LineItem>>,
    itemLogs: Readable<List<LineItemLog>>,
    punchLists: Readable<List<PunchListItem>>,
    changeOrders: Readable<List<ChangeRequest>>,
    jumpTo: Readable<UUID?>,
    lens: ProjectLens,
) {
    withSpacing(0.px) - col {


        if (lens == ProjectLens.Contractor) gravity(Align.End, Align.Center) - row {
            spacing = 1.rem
            tertiaryButton - button {
                specCenteredText("+ Note")

                onClick {
                    dialogScreenNavigator.navigate(RootCreateLogDialog(lineItems))
                }
            }
            tertiaryButton - button {
                ::enabled {
                    lineItems().any { it.state > LineItemState.NotStarted && it.state < LineItemState.Complete }
                }
                specCenteredText("+ Punch List Item")

                onClick {
                    dialogScreenNavigator.navigate(
                        PunchListItemDialog(
                            lineItems
                                .lens { items -> items.filter { it.state > LineItemState.NotStarted && it.state < LineItemState.Complete } }
                        )
                    )
                }
            }
        }
        space(AppDimensions.sectionIndent)
        row {
            weight(3f) - title("Item Name")
            weight(1f) - title {
                align = Align.Center
                content = "Amount"
            }
            weight(1f) - title {
                align = Align.Center
                existsDefaultFalse{ project().state >= ProjectState.PendingStart }
                content = "Status"
            }
            weight(1f) - title {
                align = Align.Center
                content = "Change"
            }
        }
        space(0.5.rem)
        separator()
        col {
            forEachUpdating(lineItems) { item ->
                renderLineItem(
                    item,
                    itemLogs.share { list -> list.filter { it.lineItem == item()._id } },
                    punchLists.share { list -> list.filter { it.lineItem == item()._id } },
                    changeOrders.share { cos -> cos.flatMap { it.itemChanges }.filter { it.itemId == item()._id } },
                    project,
                    lens
                )
            }
        }
    }
}