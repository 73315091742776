package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.toFileData
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.utils.pushChanges
import com.crowpay.utils.renderDollars
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.*
import com.crowpay.views.components.files.FilePreview
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.components.project.EditLineItem
import com.crowpay.views.components.project.EditLineItems
import com.crowpay.views.components.project.projectDetailForm
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import kotlin.coroutines.resume

@Serializable
enum class FormSection {
    Details,
    Scope,
//    SpecLibrary,
}

class CreateProjectForm() : ContractorScreen by ProjectForm(null)

@Routable("/contractor/project/{projectId}/edit")
class EditProject(val projectId: UUID) : ContractorScreen {
    @QueryParameter
    val selectedTab = Property(FormSection.Details)

    private val form = ProjectForm(projectId, selectedTab)

    override fun ViewWriter.renderMainContent() = form.run { render() }
}

private class ProjectForm(
    val projectId: UUID? = null,
    val selectedTab: Property<FormSection> = Property(FormSection.Details),
) : ContractorScreen {
    val safeProjectID = projectId ?: UUID.random()

    val saved = Property(projectId != null)

    val projectValidator = Validator()
    val clientValidator = Validator()
    val projectDraft = Draft {
        projectId?.let {
            notNullSession().projects[it]()
        } ?: Project(
            _id = safeProjectID,
            contractor = notNullContractor(),
            name = "",
            description = "",
            privateNotes = "",
            address = Address(),
            location = "",
        )
    }

    val clientProp = LazyProperty {
        Client(
            contractor = projectDraft().contractor,
            name = "",
            email = "",
            phoneNumber = "",
            address = Address()
        )
    }

    val files = LazyProperty { projectDraft().files.toFileData() }

    val editLineItems = EditLineItems(safeProjectID, saved)

    val newClientItem = Client(
        SharedUtils.nullUUID,
        SharedUtils.nullUUID,
        name = "+ New Client",
        email = "",
        phoneNumber = "",
        address = Address()
    )
    val totalPrice = shared {
        editLineItems.forms()
            .sumOf { it.draft().price }
    }

    val clients = shared {
        notNullSession().clients
            .query(Query(condition { it.contractor eq projectDraft().contractor }))()
    }

    val selectedClient: Writable<Client?> = LazyProperty {
        val p = projectDraft().client ?: return@LazyProperty null
        val c = clients()
        c.find { it._id == p }
    }

    suspend fun saveProject(includeLineItems: Boolean) {
        coroutineScope {
            projectDraft.modify {
                it.copy(
                    files = files().resolveProjectAttachments(),
                    client = selectedClient()?._id?.takeUnless { it == SharedUtils.nullUUID },
                )
            }
            projectDraft.pushChanges(notNullSession().projects)     // Handles both insertion and modification
            saved.value = true

            if (includeLineItems) {
                editLineItems.forms.awaitOnce().forEach { form: EditLineItem ->
                    launch {
                        val f = form.files.awaitOnce()
                        val og = form.draft.awaitOnce()
                        if (form.draft.changesMade.await() ||
                            og.files.size != f.size ||
                            f.any { it.preview is FilePreview.LocalFile }
                        )
                            form.save()
                    }
                }
            }
        }
    }

    suspend fun ViewWriter.saveProject() {

        val includeLineItems = when {
            !saved() -> true
            editLineItems.hasUnsavedChanges() -> suspendCoroutineCancellable temp@{ cont ->
                dialogScreenNavigator.navigate(GenericConfirmationDialog(
                    header = "Save Work Items",
                    message = "You have unsaved changes in your work items. Do you wish to save those now as well?",
                    confirmationText = "Include Work Items",
                    declineText = "Only Project",
                    messageType = MessageType.Confirmation,
                    onSubmit = { includeWorkItems ->
                        cont.resume(includeWorkItems)
                    }
                ))
                return@temp {
                    dialogScreenNavigator.dismiss()
                    cont.resume(false)
                }
            }
            else -> false
        }

        saveProject(includeLineItems = includeLineItems)
    }

    override fun ViewWriter.renderMainContent() {

        fun ViewWriter.actionBar() {
            sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - stack {
                spacing = AppDimensions.sectionIndent / 2

                val projectClient = projectDraft.lensPath { it.client }
                reactiveSuspending {
                    val selected = selectedClient()
                    val fromProject = projectClient()
                    if (selected?._id != fromProject && selected?._id != newClientItem._id)
                        projectClient.set(selected?._id)
                }

                row {
                    spacing = AppDimensions.cornerRadii

                    space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)

                    primaryButton - button {
                        specCenteredText("Save Work")

                        onClick {
                            try {
                                saveProject()
                            } catch (e: Exception) {
                                dialogScreenNavigator.navigate(GenericDialog("Failed to save project"))
                            }
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    secondaryButton - button {
                        specCenteredText("Save & Close")

                        onClick {
                            try {
                                saveProject()
                                screenNavigator.reset(ContractorProjects())
                            } catch (e: Exception) {
                                dialogScreenNavigator.navigate(GenericDialog("Failed to save project"))
                            }
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    textButton - button {
                        specCenteredText("Discard")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Discard Changes?",
                                    message = "You have changes that have not been submitted. Discarded changes cannot be recovered. Discard changes and leave?",
                                    confirmationText = "Discard & Leave",
                                    messageType = MessageType.Confirmation,
                                    onSubmit = submit@{ confirmed ->
                                        if (!confirmed) return@submit

                                        screenNavigator.reset(ContractorProjects())
                                    }
                                )
                            )
                        }
                    }

                    space(AppDimensions.buttonRowSpacing - AppDimensions.cornerRadii * 2)

                    onlyWhen { saved() } - textButton - button {
                        specCenteredText("Delete")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Delete Draft?",
                                    message = "You are about to delete this project draft. Deleted drafts cannot be recovered. Delete this draft?",
                                    confirmationText = "Delete Draft",
                                    messageType = MessageType.Danger,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            notNullSession().projects[safeProjectID].delete()
                                            screenNavigator.reset(ContractorProjects())

                                        }
                                    }
                                )
                            )
                        }
                    }

                    expanding - space()

                    buttonTheme - primaryButton - button {
                        specCenteredText("Publish")
                        onClick {
                            val p = projectDraft.awaitOnce()
                            val f = files.awaitOnce()
                            if (projectDraft.changesMade.awaitOnce() ||
                                selectedClient.awaitOnce() == newClientItem ||
                                p.files.size != f.size ||
                                files.awaitOnce().any { it.preview is FilePreview.LocalFile } ||
                                editLineItems.hasUnsavedChanges()
                            ) {
                                val confirmed = suspendCoroutineCancellable<Boolean> { cont ->

                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Save Project?",
                                            message = "This project has unsaved changes. You must save the these changes before you can publish.",
                                            confirmationText = "Save Project",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = { confirmed ->
                                                cont.resume(confirmed)
                                            },
                                        )
                                    )
                                    return@suspendCoroutineCancellable { dialogScreenNavigator.dismiss() }
                                }
                                if (confirmed) {
                                    saveProject(includeLineItems = true)
                                    delay(1000)
                                } else
                                    return@onClick
                            }

                            when {
                                editLineItems.forms.await().isEmpty() -> {
                                    dialogScreenNavigator.navigate(
                                        GenericDialog("You must include at least one Work Item in your project.")
                                    )
                                }

                                projectValidator.allValid.await() && editLineItems.forms.awaitOnce()
                                    .all { form -> form.validator.allValid.await() } -> {

                                    dialogScreenNavigator.navigate(
                                        GenericConfirmationDialog(
                                            header = "Publish Project?",
                                            message = "Publishing a new project will make it available to view and be accepted by the homeowner. Please make sure all project details are correct before proceeding. Once the homeowner accepts, any changes will need mutual agreement.",
                                            confirmationText = "Publish Project",
                                            messageType = MessageType.Confirmation,
                                            onSubmit = {
                                                if (it) {
                                                    val session = notNullSession()
                                                    session.nonCached.project.publishProject(safeProjectID)
                                                    session.projects[safeProjectID].invalidate()
                                                    screenNavigator.replace(ContractorProject(safeProjectID))
                                                }
                                            }
                                        )
                                    )
                                }

                                else -> {
                                    dialogScreenNavigator.navigate(
                                        GenericDialog(
                                            "One or more of the projects fields is invalid. Resolve any issues and try again."
                                        )
                                    )
                                }
                            }
                        }
                    }

                    space(AppDimensions.sectionIndent / 2 - AppDimensions.cornerRadii)
                }
            }
        }

        fun ViewWriter.projectInfo() {
            col {
                with(projectValidator) {
                    titleLabel("Project Information") {
                        separator()
                    }

                    label2("Project Name") {
                        val value = projectDraft
                            .lens(
                                get = { it.name },
                                modify = { og, it -> og.copy(name = it) }
                            )
                            .validate { it.isNotBlank() }
                        fieldTheme - validate(value) - textField {
                            hint = "Project Name"
                            content bind value
                        }
                    }

                    label2("Job#/PO (Optional)") {
                        fieldTheme - textField {
                            hint = "Project Name"
                            content bind projectDraft
                                .lens(
                                    get = { it.jobNumber ?: "" },
                                    modify = { og, it -> og.copy(jobNumber = it.takeIf { it.isNotBlank() }) }
                                )
                        }
                    }

                    space()

                    subTitle {
                        content = "Address"
                    }

                    label2("Line 1") {
                        val value =
                            projectDraft
                                .lens(
                                    get = { it.address.line1 },
                                    modify = { og, it -> og.copy(address = og.address.copy(line1 = it)) }
                                )
                                .validate { it.isNotBlank() }
                        fieldTheme - validate(value) - textField {
                            hint = "Line 1"
                            content bind value
                        }
                    }
                    label2("Line 2 (Optional)") {
                        fieldTheme - textField {
                            hint = "Line 2"
                            content bind projectDraft
                                .lens(
                                    get = { it.address.line2 ?: "" },
                                    modify = { og, it -> og.copy(address = og.address.copy(line2 = it.takeIf { it.isNotBlank() })) }
                                )
                        }
                    }
                    label2("City") {
                        val value =
                            projectDraft
                                .lens(
                                    get = { it.address.city },
                                    modify = { og, it -> og.copy(address = og.address.copy(city = it)) }
                                )
                                .validate { it.isNotBlank() }
                        fieldTheme - validate(value) - textField {
                            hint = "City"
                            content bind value
                        }
                    }
                    rowCollapsingToColumn(AppDimensions.pageWidth * 0.7) {
                        expanding - label2("State") {
                            fieldTheme - select {
                                bind(
                                    projectDraft
                                        .lens(
                                            get = { it.address.state },
                                            modify = { og, it -> og.copy(address = og.address.copy(state = it)) }
                                        ),
                                    Constant(State.entries)
                                ) { it.display }
                            }
                        }
                        expanding - label2("Zipcode") {
                            val value = projectDraft
                                .lens(
                                    get = { it.address.zipcode },
                                    modify = { og, it -> og.copy(address = og.address.copy(zipcode = it)) }
                                )
                                .validate { it.isNotBlank() }
                            fieldTheme - validate(value) - textField {
                                hint = "Zipcode"
                                content bind value
                            }
                        }
                    }

                    label2("Location (Optional)") {

                        fieldTheme - textField {
                            hint = "Project Location"
                            content bind projectDraft
                                .lens(
                                    get = { it.location ?: "" },
                                    modify = { og, it -> og.copy(location = it.takeIf { it.isNotBlank() }) }
                                )
                        }
                    }
                }
            }
        }

        fun ViewWriter.billingInfo() {
            col {
                titleLabel("Billing Information") {
                    separator()
                }

                with(projectValidator) {
                    label2("Client") {
                        val value = selectedClient.validate { it != null }
                        fieldTheme - validate(value) - select {
                            bind(
                                value,
                                shared {
                                    listOf(
                                        null,
                                        newClientItem
                                    ) + clients()
                                }) {
                                it?.name?.takeIf { it.isNotBlank() }
                                    ?: it?.email?.takeIf { it.isNotBlank() }
                                    ?: it?.phoneNumber?.takeIf { it.isNotBlank() }
                                        ?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) }
                                    ?: "Select Client"
                            }
                        }
                    }
                }

                onlyWhen {
                    val s = selectedClient()
                    s != null && s !== newClientItem
                } - col {
                    keyValue("Client Name") { selectedClient()?.name }
                    keyValue("Address") { selectedClient()?.address?.toString() }
                    keyValue("Email") { selectedClient()?.email }
                    keyValue("Phone") { selectedClient()?.phoneNumber?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) } }
                }

                onlyWhen {
                    val s = selectedClient()
                    s === newClientItem
                } - col {


                    with(clientValidator) {
                        subTitle {
                            content = "Basic Info"
                        }

                        label2("Name") {
                            val value = clientProp
                                .lens(
                                    get = { it.name },
                                    modify = { og, it -> og.copy(name = it) }
                                )
                                .validate { it.isNotBlank() }
                            fieldTheme - validate(value) - textField {
                                hint = "Name"
                                content bind value
                            }
                        }
                        label2("Email") {
                            val value = clientProp
                                .lens(
                                    get = { it.email },
                                    modify = { og, it -> og.copy(email = it) }
                                )
                                .validate { it.matches(SharedUtils.emailPattern) }
                            fieldTheme - validate(value) - textInput {
                                hint = "Email"
                                content bind value
                            }
                        }
                        label2("Phone Number") {
                            val value = clientProp
                                .lensPath { it.phoneNumber }
                                .validate { it.length == 10 }
                            fieldTheme - validate(value) - formattedTextInput {
                                hint = "Phone Number"
                                format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                                content bind value
                            }
                        }

                        space()

                        subTitle {
                            content = "Address"
                        }

                        label2("Line 1") {
                            val value =
                                clientProp
                                    .lens(
                                        get = { it.address.line1 },
                                        modify = { og, it -> og.copy(address = og.address.copy(line1 = it)) }
                                    )
                                    .validate { it.isNotBlank() }
                            fieldTheme - validate(value) - textField {
                                hint = "Line 1"
                                content bind value
                            }
                        }
                        label2("Line 2 (Optional)") {
                            fieldTheme - textField {
                                hint = "Line 2"
                                content bind clientProp
                                    .lens(
                                        get = { it.address.line2 ?: "" },
                                        modify = { og, it -> og.copy(address = og.address.copy(line2 = it.takeIf { it.isNotBlank() })) }
                                    )

                            }
                        }
                        label2("City") {
                            val value =
                                clientProp
                                    .lens(
                                        get = { it.address.city },
                                        modify = { og, it -> og.copy(address = og.address.copy(city = it)) }
                                    )
                                    .validate { it.isNotBlank() }
                            fieldTheme - validate(value) - textField {
                                hint = "City"
                                content bind value
                            }
                        }
                        rowCollapsingToColumn(AppDimensions.pageWidth * 0.7) {
                            expanding - label2("State") {
                                fieldTheme - select {
                                    bind(
                                        clientProp
                                            .lens(
                                                get = { it.address.state },
                                                modify = { og, it ->
                                                    og.copy(address = og.address.copy(state = it))
                                                }
                                            ),
                                        Constant(State.entries)
                                    ) { it.display }
                                }
                            }
                            expanding - label2("Zipcode") {
                                val value =
                                    clientProp
                                        .lens(
                                            get = { it.address.zipcode },
                                            modify = { og, it ->
                                                og.copy(
                                                    address = og.address.copy(
                                                        zipcode = it
                                                    )
                                                )
                                            }
                                        )
                                        .validate { it.isNotBlank() }
                                fieldTheme - validate(value) - textField {
                                    hint = "Zipcode"
                                    content bind value
                                }
                            }
                        }
                        tertiaryButton - button {
                            ::enabled{ allValid() }
                            specCenteredText("Save Client")
                            onClick {
                                val newClient = clientProp()
                                selectedClient.set(newClient)
                                notNullSession().clients.insert(newClient)
                                selectedClient.set(newClient)
                                clientProp.reset()
                            }
                        } in gravity(Align.End, Align.Center)
                    }
                }
            }
        }

        scrolls - col {
            spacing = 0.dp

            launch {
                val session = notNullSession.await()
                val p = projectDraft.published()
                if (selectedContractor.await() != p.contractor) {
                    val self = session.self.await()
                    if (self.memberships.any { it.contractor == p.contractor })
                        selectedContractor.set(p.contractor)
                    else
                        screenNavigator.reset(LandingScreen())
                }
            }

            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = 0.dp

                title {
                    content = "Create Project"
                }

                space(AppDimensions.majorColumnSpacing)

                actionBar()

                space(AppDimensions.majorColumnSpacing)

                rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {
                    expanding - projectInfo()
                    expanding - billingInfo()
                }

                space(AppDimensions.majorColumnSpacing)

                with(projectValidator) {
                    val value = projectDraft
                        .lensPath { it.retention }
                        .lens(
                            get = { it?.toDouble()?.times(100) },
                            set = { it?.toFloat()?.div(100) }
                        )
                        .validate {
                            if (it == null) true
                            else 0 < it && it <= 10
                        }

                    label2("Retention (Percent)") {
                        fieldTheme - validate(value) - numberInput {
                            content bind value
                            range = 0.0..10.0
                            hint = "0% to 10%"
                            keyboardHints = KeyboardHints.integer
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                col {
                    label2("Total Price (Calculated from line items)") {
                        body {
                            ::content{ totalPrice().renderDollars() }
                        }
                    }
                    onlyWhen { projectDraft().retention != null } - col {
                        label2("Total Retention") {
                            body {
                                ::content content@{
                                    val t = totalPrice()
                                    val r = projectDraft().retention ?: 0f
                                    (t * r).toLong().renderDollars()
                                }
                            }
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                tabRow - row {
                    spacing = 4.dp
                    expanding - buttonTheme - radioToggleButton {
                        checked bind selectedTab.equalTo(FormSection.Details)
                        body {
                            align = Align.Center
                            content = "Details"
                        }
                    }
                    expanding - buttonTheme - radioToggleButton {
                        checked bind selectedTab.equalTo(FormSection.Scope)
                        body {
                            align = Align.Center
                            content = "Scope"
                        }
                    }
                }

                col {
                    lazy(AppState.windowInfo) { window ->
                        swapView {
                            swapping(
                                current = { selectedTab<FormSection>() },
                                views = { tab ->
                                    when (tab) {
                                        FormSection.Details -> projectDetailForm(
                                            description = projectDraft.lens(
                                                get = { it.description },
                                                modify = { og, it -> og.copy(description = it) }),
                                            privateNotes = projectDraft.lens(
                                                get = { it.privateNotes },
                                                modify = { og, it -> og.copy(privateNotes = it) }),
                                            files = files,
                                            validator = projectValidator,
                                        )

                                        FormSection.Scope -> editLineItems.render(this@swapView)
                                    }
                                }
                            )
                        } in sizeConstraints(minHeight = window.height - AppDimensions.topNavHeight - (AppDimensions.fullIndent * 4) - AppDimensions.actionBarHeight)
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                actionBar()
            }
        }
    }
}