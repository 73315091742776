package com.crowpay.views.components.project

import com.crowpay.Project
import com.crowpay.views.theming.body
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.projectLog(project: Readable<Project>) {
    stack {
        centered -
                col {
                    centered - h1("Under Construction")
                    centered - body("This area is not completed.")
                }
    } in sizeConstraints(height = 20.rem)
}