package com.crowpay.views.screens

import com.lightningkite.kiteui.navigation.*
import com.crowpay.views.screens.auth.ContractorRegistration
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.auth.PaymentSetup
import com.crowpay.views.screens.auth.UserRegistration
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.screens.client.ClientPayments
import com.crowpay.views.screens.client.ClientProfile
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.client.ClientProjects
import com.crowpay.views.screens.client.ClientTermsAndServices
import com.crowpay.views.screens.common.SelectDevProjectScreen
import com.crowpay.views.screens.common.DevProjectView
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.common.ProjectAnonymousViewing
import com.crowpay.views.screens.common.ContractorWorkLists
import com.crowpay.views.screens.common.ClientWorkLists
import com.crowpay.views.screens.contractor.ContractorClients
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.crowpay.views.screens.contractor.ContractorPayments
import com.crowpay.views.screens.contractor.ContractorProfile
import com.crowpay.views.screens.contractor.ContractorProject
import com.crowpay.views.screens.contractor.ContractorProjects
import com.crowpay.views.screens.contractor.CreateChangeOrder
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.EditChangeOrder
import com.crowpay.views.screens.contractor.EditProject
import com.lightningkite.kiteui.navigation.Screen.Empty
import com.crowpay.views.components.project.ProjectSection
import com.crowpay.views.screens.contractor.FormSection
import com.lightningkite.UUID


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "register") return@label null
            ContractorRegistration(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "log-in") return@label null
            LogInScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String?>("next", it.parameters)?.let { this.next.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "payment-setup") return@label null
            PaymentSetup(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String?>("next", it.parameters)?.let { this.next.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "user-registration") return@label null
            UserRegistration(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String?>("next", it.parameters)?.let { this.next.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "client") return@label null
            ClientDashboard(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "payments") return@label null
            ClientPayments(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "profile") return@label null
            ClientProfile(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "projects") return@label null
            ClientProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
                UrlProperties.decodeFromStringMap<com.crowpay.views.components.project.ProjectSection>("selectedTab", it.parameters)?.let { this.selectedTab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "projects") return@label null
            ClientProjects(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "accept-project") return@label null
            ClientTermsAndServices(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "select-dev-project") return@label null
            SelectDevProjectScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "dev-project") return@label null
            DevProjectView(
                project = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            LandingScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "projects") return@label null
            if (it.segments[1] != "anonymous") return@label null
            ProjectAnonymousViewing(
            ).apply {
                UrlProperties.decodeFromStringMap<com.crowpay.views.components.project.ProjectSection>("selectedTab", it.parameters)?.let { this.selectedTab.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("server", it.parameters)?.let { this.server.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("token", it.parameters)?.let { this.token.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "worklists") return@label null
            ContractorWorkLists(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "client") return@label null
            if (it.segments[1] != "worklists") return@label null
            ClientWorkLists(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "clients") return@label null
            ContractorClients(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "contractor") return@label null
            ContractorDashboard(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "payments") return@label null
            ContractorPayments(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "profile") return@label null
            ContractorProfile(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "projects") return@label null
            ContractorProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
                UrlProperties.decodeFromStringMap<com.crowpay.views.components.project.ProjectSection>("selectedTab", it.parameters)?.let { this.selectedTab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "projects") return@label null
            ContractorProjects(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "project") return@label null
            if (it.segments[3] != "create-change-order") return@label null
            CreateChangeOrder(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "project") return@label null
            if (it.segments[2] != "create") return@label null
            CreateProject(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "change-order") return@label null
            if (it.segments[3] != "edit") return@label null
            EditChangeOrder(
                changeOrderId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "contractor") return@label null
            if (it.segments[1] != "project") return@label null
            if (it.segments[3] != "edit") return@label null
            EditProject(
                projectId = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
                UrlProperties.decodeFromStringMap<com.crowpay.views.screens.contractor.FormSection>("selectedTab", it.parameters)?.let { this.selectedTab.value = it }
            }
        },
    ),
    renderers = mapOf(
        ContractorRegistration::class to label@{
            if (it !is ContractorRegistration) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "register"),
                parameters = p
            ), listOf())
        },
        LogInScreen::class to label@{
            if (it !is LogInScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "log-in"),
                parameters = p
            ), listOf(it.next))
        },
        PaymentSetup::class to label@{
            if (it !is PaymentSetup) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "payment-setup"),
                parameters = p
            ), listOf(it.next))
        },
        UserRegistration::class to label@{
            if (it !is UserRegistration) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.next.value, "next", p)
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "user-registration"),
                parameters = p
            ), listOf(it.next))
        },
        ClientDashboard::class to label@{
            if (it !is ClientDashboard) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client"),
                parameters = p
            ), listOf())
        },
        ClientPayments::class to label@{
            if (it !is ClientPayments) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "payments"),
                parameters = p
            ), listOf())
        },
        ClientProfile::class to label@{
            if (it !is ClientProfile) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "profile"),
                parameters = p
            ), listOf())
        },
        ClientProject::class to label@{
            if (it !is ClientProject) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "selectedTab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("client", "projects", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf(it.selectedTab))
        },
        ClientProjects::class to label@{
            if (it !is ClientProjects) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "projects"),
                parameters = p
            ), listOf())
        },
        ClientTermsAndServices::class to label@{
            if (it !is ClientTermsAndServices) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "accept-project", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf())
        },
        SelectDevProjectScreen::class to label@{
            if (it !is SelectDevProjectScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("select-dev-project"),
                parameters = p
            ), listOf())
        },
        DevProjectView::class to label@{
            if (it !is DevProjectView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dev-project", UrlProperties.encodeToString(it.project)),
                parameters = p
            ), listOf())
        },
        LandingScreen::class to label@{
            if (it !is LandingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        ProjectAnonymousViewing::class to label@{
            if (it !is ProjectAnonymousViewing) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "selectedTab", p)
            UrlProperties.encodeToStringMap(it.server.value, "server", p)
            UrlProperties.encodeToStringMap(it.token.value, "token", p)
            RouteRendered(UrlLikePath(
                segments = listOf("projects", "anonymous"),
                parameters = p
            ), listOf(it.selectedTab, it.server, it.token))
        },
        ContractorWorkLists::class to label@{
            if (it !is ContractorWorkLists) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "worklists"),
                parameters = p
            ), listOf())
        },
        ClientWorkLists::class to label@{
            if (it !is ClientWorkLists) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("client", "worklists"),
                parameters = p
            ), listOf())
        },
        ContractorClients::class to label@{
            if (it !is ContractorClients) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "clients"),
                parameters = p
            ), listOf())
        },
        ContractorDashboard::class to label@{
            if (it !is ContractorDashboard) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor"),
                parameters = p
            ), listOf())
        },
        ContractorPayments::class to label@{
            if (it !is ContractorPayments) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "payments"),
                parameters = p
            ), listOf())
        },
        ContractorProfile::class to label@{
            if (it !is ContractorProfile) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "profile"),
                parameters = p
            ), listOf())
        },
        ContractorProject::class to label@{
            if (it !is ContractorProject) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "selectedTab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "projects", UrlProperties.encodeToString(it.projectId)),
                parameters = p
            ), listOf(it.selectedTab))
        },
        ContractorProjects::class to label@{
            if (it !is ContractorProjects) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "projects"),
                parameters = p
            ), listOf())
        },
        CreateChangeOrder::class to label@{
            if (it !is CreateChangeOrder) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "project", UrlProperties.encodeToString(it.projectId), "create-change-order"),
                parameters = p
            ), listOf())
        },
        CreateProject::class to label@{
            if (it !is CreateProject) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "project", "create"),
                parameters = p
            ), listOf())
        },
        EditChangeOrder::class to label@{
            if (it !is EditChangeOrder) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "change-order", UrlProperties.encodeToString(it.changeOrderId), "edit"),
                parameters = p
            ), listOf())
        },
        EditProject::class to label@{
            if (it !is EditProject) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "selectedTab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("contractor", "project", UrlProperties.encodeToString(it.projectId), "edit"),
                parameters = p
            ), listOf(it.selectedTab))
        },
    ),
    fallback = Empty
)
