package com.crowpay.views.components

import com.crowpay.Contact
import com.crowpay.views.theming.body
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.contactInfo(data: Readable<Contact?>) {
    col {
        spacing = 0.dp
        body {
            ::content{ data()?.name ?: " " }
        }
        body {
            ::content{ data()?.email ?: " " }
        }
        body {
            ::content{ data()?.phoneNumber?.let { PhoneNumberFormat.USA.format(it.filter { it.isDigit() }) } ?: " " }
        }
    }
}