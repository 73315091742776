package com.crowpay.views.components.changeOrders

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.rotation
import com.crowpay.extensions.toFileData
import com.crowpay.extensions.withSpacing
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.LineItemForm
import com.crowpay.views.theming.body
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon


class ChangeOrderLineEdit(
    val ogLine: LineItem,
    val onRemove: suspend ViewWriter.(UUID) -> Unit,
) : Screen {

    val draft = Draft(Property(ogLine))
    val files = Property(ogLine.files.toFileData())
    val expanded = Property(false)
    val form = LineItemForm(draft, files)
    val validator: Validator get() = form

    override fun ViewWriter.render() {

        col {
            spacing = 0.px

            withSpacing(0.dp) - row {
                expanding - buttonTheme - button {
                    spacing = 0.dp
                    row {
                        centered - icon(Icon.chevronRight, "") {
                            ::rotation{ if (expanded()) (-90).degrees else 0.degrees }
                        }
                        centered - body {
                            ::content{ form.name().takeUnless { it.isBlank() } ?: "New Work Item" }
                        }
                        expanding - buttonTheme - stack {
                            visible = false
                            icon(Icon.chevronRight, "")
                        }
                    }
                    onClick {
                        expanded.set(!expanded.await())
                    }
                }
                buttonTheme - button {
                    sizeConstraints(width = 2.rem, height = 2.rem) - icon(Icon.trash, "Remove Work Item")
                    onClick { onRemove(ogLine._id) }
                }
            }
            onlyWhen { expanded() } - padded - stack {
                spacing = AppDimensions.fullIndent
                form.render(this)
            }
        }
    }
}