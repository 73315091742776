// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorTermsAndConditionsAgreementFields() {
    val props: Array<SerializableProperty<ContractorTermsAndConditionsAgreement, *>> = arrayOf(ContractorTermsAndConditionsAgreement__id, ContractorTermsAndConditionsAgreement_contractor, ContractorTermsAndConditionsAgreement_accessInfo, ContractorTermsAndConditionsAgreement_version)
    ContractorTermsAndConditionsAgreement.serializer().properties { props }
}
val <K> DataClassPath<K, ContractorTermsAndConditionsAgreement>._id: DataClassPath<K, UUID> get() = this[ContractorTermsAndConditionsAgreement__id]
val <K> DataClassPath<K, ContractorTermsAndConditionsAgreement>.contractor: DataClassPath<K, UUID> get() = this[ContractorTermsAndConditionsAgreement_contractor]
val <K> DataClassPath<K, ContractorTermsAndConditionsAgreement>.accessInfo: DataClassPath<K, AccessInfo> get() = this[ContractorTermsAndConditionsAgreement_accessInfo]
val <K> DataClassPath<K, ContractorTermsAndConditionsAgreement>.version: DataClassPath<K, Instant> get() = this[ContractorTermsAndConditionsAgreement_version]
inline val ContractorTermsAndConditionsAgreement.Companion.path: DataClassPath<ContractorTermsAndConditionsAgreement, ContractorTermsAndConditionsAgreement> get() = path<ContractorTermsAndConditionsAgreement>()


object ContractorTermsAndConditionsAgreement__id: SerializableProperty<ContractorTermsAndConditionsAgreement, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ContractorTermsAndConditionsAgreement): UUID = receiver._id
    override fun setCopy(receiver: ContractorTermsAndConditionsAgreement, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ContractorTermsAndConditionsAgreement.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ContractorTermsAndConditionsAgreement_contractor: SerializableProperty<ContractorTermsAndConditionsAgreement, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: ContractorTermsAndConditionsAgreement): UUID = receiver.contractor
    override fun setCopy(receiver: ContractorTermsAndConditionsAgreement, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ContractorTermsAndConditionsAgreement.serializer().tryFindAnnotations("contractor")
}
object ContractorTermsAndConditionsAgreement_accessInfo: SerializableProperty<ContractorTermsAndConditionsAgreement, AccessInfo> {
    override val name: String = "accessInfo"
    override fun get(receiver: ContractorTermsAndConditionsAgreement): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: ContractorTermsAndConditionsAgreement, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> = AccessInfo.serializer()
    override val annotations: List<Annotation> = ContractorTermsAndConditionsAgreement.serializer().tryFindAnnotations("accessInfo")
}
object ContractorTermsAndConditionsAgreement_version: SerializableProperty<ContractorTermsAndConditionsAgreement, Instant> {
    override val name: String = "version"
    override fun get(receiver: ContractorTermsAndConditionsAgreement): Instant = receiver.version
    override fun setCopy(receiver: ContractorTermsAndConditionsAgreement, value: Instant) = receiver.copy(version = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ContractorTermsAndConditionsAgreement.serializer().tryFindAnnotations("version")
}
