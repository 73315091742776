package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.contactInfo
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.space
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.contractor.ContractorProject
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon


fun ViewWriter.projectList(
    lens: ProjectLens,
    projects: Readable<List<Project>>,
    contacts: Readable<List<Contact>>,
) {
    col {
        spacing = 0.dp
        title("Projects")

        space(AppDimensions.majorColumnSpacing)

        row {
            spacing = 0.dp
            space(AppDimensions.fullIndent - AppDimensions.buttonSpacing)
            primaryButton - button {
                existsDefaultFalse{ selectedContractor() != null && contractorActive() == ContractorActive.Approved }
                specCenteredText("+ Project")
                onClick {
                    screenNavigator.navigate(CreateProject())
                }
            }
        }

        space(AppDimensions.majorColumnSpacing)

        subTitle {
            existsDefaultFalse{ projects().isEmpty() }
            content = when (lens) {
                ProjectLens.Contractor -> "You have no contracts yet. Create a new Contract."
                ProjectLens.Customer -> "You have no Projects"
                ProjectLens.Preview -> ""
            }
        } in centered

        expanding - recyclerView {
            children(projects) { project ->
                val contact = shared {
                    val p = project()
                    contacts().find {
                        when (it) {
                            is Client -> {
                                it._id == p.client
                            }

                            is Contractor -> {
                                it._id == p.contractor
                            }

                            else -> false
                        }
                    }
                }
                sizeConstraints(width = AppDimensions.pageWidth) - row {
                    spacing = 0.dp
                    space(AppDimensions.backgroundIndent)

                    outlined - expanding - button {
                        spacing = AppDimensions.sectionIndent
                        onClick {
                            val p = project.await()
                            val to = when {
                                lens == ProjectLens.Customer -> ClientProject(p._id)
                                p.state == ProjectState.Creating -> EditProject(p._id)
                                else -> ContractorProject(p._id)
                            }
                            screenNavigator.navigate(to)

                        }
                        col {
                            row {
                                centered - subTitle {
                                    ::content { project().name }
                                }

                                separator()

                                expanding -
                                        row {
                                            ::visible{ contact() != null }
                                            centered - image {
                                                ::exists{ contact()?.image != null }
                                                ::source{ ImageRemote(contact()?.image?.location ?: "") }
                                            } in sizeConstraints(width = 3.rem, height = 3.rem)

                                            centered - contactInfo(contact)
                                        }

                                centered - icon(Icon.chevronRight, "Open")
                            }

                            separator()

                            row {
                                expanding - col {
                                    col {
                                        exists = false
                                        ::exists{ project().fundingNeeded > 0 }
                                        keyValue("Funding Needed") { project().fundingNeeded.renderDollars() }
                                    }
                                    keyValue("Total Price") { project().price.renderDollars() }
                                    keyValue("Contractor Payments") { project().contractorReceived.renderDollars() }
                                    keyValue("Escrow Balance") { project().balance.renderDollars() }
                                }

                                body {
                                    ::content{ project().state.displayValue }
                                    ::themeChoice{
                                        val p = project()
                                        ThemeDerivation {
                                            stateTheme(it, Color.fromHexString(p.state.displayColor)).withoutBack
                                        }
                                    }
                                }
                            }
                        }
                    }
                    space(AppDimensions.backgroundIndent)
                }
            }
        }
    }
}
