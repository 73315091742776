package com.crowpay.extensions

import com.crowpay.actuals.renderImageThumbnail
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.mimeType


suspend fun FileReference.renderThumbnailOrNull(maxSize: Int): FileReference? {
    val type = mimeType()
    return when {
        type.startsWith("image") -> try {
            renderImageThumbnail(maxSize)
        } catch (e: Exception) {
            null
        }
        type.startsWith("video") -> null

        else -> null
    }
}