// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareContractorFields() {
    val props: Array<SerializableProperty<Contractor, *>> = arrayOf(Contractor__id, Contractor_name, Contractor_email, Contractor_phoneNumber, Contractor_address, Contractor_image, Contractor_preferredTitle, Contractor_contactFirstName, Contractor_contactLastName, Contractor_contactEmail, Contractor_contactPhoneNumber, Contractor_contactAddress, Contractor_stateEntityNumber, Contractor_ein, Contractor_trade, Contractor_active, Contractor_lastAgreement)
    Contractor.serializer().properties { props }
}
val <K> DataClassPath<K, Contractor>._id: DataClassPath<K, UUID> get() = this[Contractor__id]
val <K> DataClassPath<K, Contractor>.name: DataClassPath<K, String> get() = this[Contractor_name]
val <K> DataClassPath<K, Contractor>.email: DataClassPath<K, String> get() = this[Contractor_email]
val <K> DataClassPath<K, Contractor>.phoneNumber: DataClassPath<K, String> get() = this[Contractor_phoneNumber]
val <K> DataClassPath<K, Contractor>.address: DataClassPath<K, Address> get() = this[Contractor_address]
val <K> DataClassPath<K, Contractor>.image: DataClassPath<K, ServerFile?> get() = this[Contractor_image]
val <K> DataClassPath<K, Contractor>.preferredTitle: DataClassPath<K, PreferredTitle> get() = this[Contractor_preferredTitle]
val <K> DataClassPath<K, Contractor>.contactFirstName: DataClassPath<K, String> get() = this[Contractor_contactFirstName]
val <K> DataClassPath<K, Contractor>.contactLastName: DataClassPath<K, String> get() = this[Contractor_contactLastName]
val <K> DataClassPath<K, Contractor>.contactEmail: DataClassPath<K, String> get() = this[Contractor_contactEmail]
val <K> DataClassPath<K, Contractor>.contactPhoneNumber: DataClassPath<K, String> get() = this[Contractor_contactPhoneNumber]
val <K> DataClassPath<K, Contractor>.contactAddress: DataClassPath<K, Address> get() = this[Contractor_contactAddress]
val <K> DataClassPath<K, Contractor>.stateEntityNumber: DataClassPath<K, String> get() = this[Contractor_stateEntityNumber]
val <K> DataClassPath<K, Contractor>.ein: DataClassPath<K, String> get() = this[Contractor_ein]
val <K> DataClassPath<K, Contractor>.trade: DataClassPath<K, String?> get() = this[Contractor_trade]
val <K> DataClassPath<K, Contractor>.active: DataClassPath<K, ContractorActive> get() = this[Contractor_active]
val <K> DataClassPath<K, Contractor>.lastAgreement: DataClassPath<K, ContractorTermsAndConditionsAgreement?> get() = this[Contractor_lastAgreement]
inline val Contractor.Companion.path: DataClassPath<Contractor, Contractor> get() = path<Contractor>()


object Contractor__id: SerializableProperty<Contractor, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Contractor): UUID = receiver._id
    override fun setCopy(receiver: Contractor, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Contractor_name: SerializableProperty<Contractor, String> {
    override val name: String = "name"
    override fun get(receiver: Contractor): String = receiver.name
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("name")
}
object Contractor_email: SerializableProperty<Contractor, String> {
    override val name: String = "email"
    override fun get(receiver: Contractor): String = receiver.email
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("email")
}
object Contractor_phoneNumber: SerializableProperty<Contractor, String> {
    override val name: String = "phoneNumber"
    override fun get(receiver: Contractor): String = receiver.phoneNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("phoneNumber")
}
object Contractor_address: SerializableProperty<Contractor, Address> {
    override val name: String = "address"
    override fun get(receiver: Contractor): Address = receiver.address
    override fun setCopy(receiver: Contractor, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("address")
}
object Contractor_image: SerializableProperty<Contractor, ServerFile?> {
    override val name: String = "image"
    override fun get(receiver: Contractor): ServerFile? = receiver.image
    override fun setCopy(receiver: Contractor, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("image")
    override val default: ServerFile? = null
}
object Contractor_preferredTitle: SerializableProperty<Contractor, PreferredTitle> {
    override val name: String = "preferredTitle"
    override fun get(receiver: Contractor): PreferredTitle = receiver.preferredTitle
    override fun setCopy(receiver: Contractor, value: PreferredTitle) = receiver.copy(preferredTitle = value)
    override val serializer: KSerializer<PreferredTitle> = PreferredTitle.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("preferredTitle")
    override val default: PreferredTitle = PreferredTitle.Contractor
}
object Contractor_contactFirstName: SerializableProperty<Contractor, String> {
    override val name: String = "contactFirstName"
    override fun get(receiver: Contractor): String = receiver.contactFirstName
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactFirstName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("contactFirstName")
}
object Contractor_contactLastName: SerializableProperty<Contractor, String> {
    override val name: String = "contactLastName"
    override fun get(receiver: Contractor): String = receiver.contactLastName
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactLastName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("contactLastName")
}
object Contractor_contactEmail: SerializableProperty<Contractor, String> {
    override val name: String = "contactEmail"
    override fun get(receiver: Contractor): String = receiver.contactEmail
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactEmail = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("contactEmail")
}
object Contractor_contactPhoneNumber: SerializableProperty<Contractor, String> {
    override val name: String = "contactPhoneNumber"
    override fun get(receiver: Contractor): String = receiver.contactPhoneNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(contactPhoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("contactPhoneNumber")
}
object Contractor_contactAddress: SerializableProperty<Contractor, Address> {
    override val name: String = "contactAddress"
    override fun get(receiver: Contractor): Address = receiver.contactAddress
    override fun setCopy(receiver: Contractor, value: Address) = receiver.copy(contactAddress = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("contactAddress")
}
object Contractor_stateEntityNumber: SerializableProperty<Contractor, String> {
    override val name: String = "stateEntityNumber"
    override fun get(receiver: Contractor): String = receiver.stateEntityNumber
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(stateEntityNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("stateEntityNumber")
}
object Contractor_ein: SerializableProperty<Contractor, String> {
    override val name: String = "ein"
    override fun get(receiver: Contractor): String = receiver.ein
    override fun setCopy(receiver: Contractor, value: String) = receiver.copy(ein = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("ein")
}
object Contractor_trade: SerializableProperty<Contractor, String?> {
    override val name: String = "trade"
    override fun get(receiver: Contractor): String? = receiver.trade
    override fun setCopy(receiver: Contractor, value: String?) = receiver.copy(trade = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("trade")
    override val default: String? = null
}
object Contractor_active: SerializableProperty<Contractor, ContractorActive> {
    override val name: String = "active"
    override fun get(receiver: Contractor): ContractorActive = receiver.active
    override fun setCopy(receiver: Contractor, value: ContractorActive) = receiver.copy(active = value)
    override val serializer: KSerializer<ContractorActive> = ContractorActive.serializer()
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("active")
    override val default: ContractorActive = ContractorActive.Registering
}
object Contractor_lastAgreement: SerializableProperty<Contractor, ContractorTermsAndConditionsAgreement?> {
    override val name: String = "lastAgreement"
    override fun get(receiver: Contractor): ContractorTermsAndConditionsAgreement? = receiver.lastAgreement
    override fun setCopy(receiver: Contractor, value: ContractorTermsAndConditionsAgreement?) = receiver.copy(lastAgreement = value)
    override val serializer: KSerializer<ContractorTermsAndConditionsAgreement?> = ContractorTermsAndConditionsAgreement.serializer().nullable2
    override val annotations: List<Annotation> = Contractor.serializer().tryFindAnnotations("lastAgreement")
    override val default: ContractorTermsAndConditionsAgreement? = null
}
