package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.renderDollars
import com.crowpay.utils.toNullableDouble
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.uuid


class MakePaymentDialog(
    val project: Project,
) : Validator(), Screen {

    val partial = Property<Long>(0)
    val payFull = Property(true)
    val newItemMethod = PaymentMethod(id = "", name = "+ New Method", bank = null, default = false)

    override fun ViewWriter.render() {

        val refresh = BasicListenable()
        val methods = sharedSuspending {
            rerunOn(refresh)
            notNullSession().nonCached.userAuth.getPaymentMethods().sortedBy { it.default }
        }
        val selectedMethod = Property<PaymentMethod?>(null)


        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                 col {
                     themeChoice = ThemeDerivation(appTheme)
                    title {
                        content = "Pay into ${project.name}"
                    }

                    row {
                        centered - radioButton {
                            checked bind payFull
                        }
                        centered - body(project.fundingNeeded.renderDollars())
                    }

                    row {
                        centered - radioButton {
                            checked bind payFull.equalTo(false)
                        }
                        val value = partial.validate { payFull() || (it > 0 && it <= project.fundingNeeded) }
                        reactiveSuspending {
                            rerunOn(payFull)
                            value.refresh()
                        }
                        centered - fieldTheme - validate(value) - numberInput {
                            keyboardHints = KeyboardHints.integer
                            hint = "Partial Amount"
                            content bind value.toNullableDouble()
                        }
                    }

                    fieldTheme - select {
                        ::exists{ methods().isNotEmpty() }
                        bind(
                            selectedMethod,
                            shared {
                                listOf(null, newItemMethod) + (methods())
                            }
                        ) { it?.name ?: "Select Method" }
                    }

                    body {
                        exists = false
                        ::exists{ methods().isEmpty() }
                        align = Align.Center
                        content =
                            "You have no payment methods."
                    }

                    secondaryButton - button {
                        exists = false
                        ::exists{ selectedMethod() === newItemMethod || methods().isEmpty() }
                        specCenteredText("Add Method")
                        onClick {
                            screenNavigator.navigate(SetupMethodDialog { newMethod ->
                                refresh.invokeAll()
                                selectedMethod.value = methods.awaitOnce().find { it.id == newMethod.id }
                            })
                        }
                    }

                    row {
                        spacing = AppDimensions.buttonRowSpacing

                        primaryButton - button {
                            ::enabled{
                                (payFull() || allValid()) &&
                                        (methods().isNotEmpty() && selectedMethod() != null)
                            }
                            specCenteredText("Continue")
                            onClick {
                                val method = selectedMethod.await() ?: return@onClick
                                try {
                                    notNullSession().nonCached.project.payIntoEscrow(
                                        project._id,
                                        PaymentRequest(
                                            if (payFull.value) project.fundingNeeded else partial.value,
                                            method.id,
                                            uuid(),
                                        )
                                    )
                                } catch (e: LsErrorException) {
                                    screenNavigator.dismiss()
                                    if (e.status >= 500) {
                                        screenNavigator.reset(
                                            GenericDialog(
                                                message = "An unknown error occurred. A payment may have still been initiated. If a payment is not shown after 5 minutes contact support to confirm if a payment was initiated.",
                                            )
                                        )
                                    } else {
                                        screenNavigator.reset(
                                            GenericDialog(
                                                message = e.message ?: "An Unknown Error Occurred.",
                                            )
                                        )
                                    }
                                    return@onClick
                                } catch (_: Exception) {
                                    screenNavigator.reset(
                                        GenericDialog(
                                            message = "An unknown error occurred. A payment may have still been initiated. If a payment is not shown after 5 minutes contact support to confirm if a payment was initiated.",
                                        )
                                    )
                                    return@onClick
                                }

                                notNullSession().projects[project._id].invalidate()

                                screenNavigator.reset(
                                    GenericDialog(
                                        message = "Payment Confirmed. It can take up to 5 minutes for the payment to show in our system."
                                    )
                                )
                            }
                        }

                        textButton - button {
                            specCenteredText("Go Back")
                            onClick {
                                screenNavigator.dismiss()
                            }
                        }
                    }

                }
            }
            onClick {
            }
        }
    }
}