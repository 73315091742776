package com.crowpay.views.components.changeOrders

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.renderDollars
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.label2
import com.crowpay.views.components.project.AdjustLineItem
import com.crowpay.views.components.titleLabel
import com.crowpay.views.theming.body
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.tertiaryButton
import com.crowpay.views.theming.title
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


class ChangeRequestForm(
    val project: Readable<Project>,
    val request: Readable<ChangeRequest>,
    val originalLineItems: Readable<List<LineItem>>,
) : Screen {

    val titleValidator = Validator()
    val requestTitle = LazyProperty { request().title }
    val projectDescription = LazyProperty { request().projectDescription ?: "" }
    val newLineItems = LazyProperty { request().newItems }
    val itemChanges = LazyProperty { request().itemChanges }
    val newLineForms: Property<Map<UUID, ChangeOrderLineEdit>> = Property(emptyMap())
    val itemChangeForms: Property<Map<UUID, AdjustLineItem>> = Property(emptyMap())
    val editedItems = shared {
        itemChangeForms()
            .map { it.value.ogId() }
            .toSet()
            .minus(SharedUtils.nullUUID)
    }

    override fun ViewWriter.render() {
        col {

            reactiveSuspending {

                val existing = newLineForms.awaitOnce()

                val updated = mapOf(
                    *newLineItems().map { line ->
                        existing[line._id]?.let { line._id to it } ?: run {

                            (line._id to ChangeOrderLineEdit(
                                ogLine = line,
                                onRemove = { lineId ->
                                    newLineItems.value = newLineItems.value.filter { it._id != lineId }
                                },
                            ))
                        }
                    }
                        .toTypedArray()
                )

                newLineForms.set(updated)
            }

            reactiveSuspending {

                val existingForms = itemChangeForms.awaitOnce()
                val changes = itemChanges()

                val updated = mapOf(
                    *changes.map { line ->
                        existingForms[line.id]?.let { line.id to it } ?: run {

                            (line.id to AdjustLineItem(
                                ogChange = line,
                                allLines = originalLineItems,
                                editedLines = editedItems,
                                onRemove = { itemChanges.set(itemChanges.value - line) },
                            ))
                        }
                    }
                        .toTypedArray()
                )

                itemChangeForms.set(updated)
            }

            with(titleValidator) {
                label2("Change Order Title") {
                    val value = requestTitle.validate { it.isNotBlank() }
                    fieldTheme - validate(value) - textField {
                        hint = "Title"
                        content bind value
                    }
                }
            }

            titleLabel("Details") {
                separator()
            }
            label2("Project Description (Optional)") {
                fieldTheme - textArea {
                    hint = "Changes to the overall scope of the project"
                    content bind projectDescription
                }
            }

            space()

            label2("Price Change") {
                body {
                    ::content{
                        val ogLines = originalLineItems()
                        newLineForms()
                            .values
                            .sumOf { it.form.price() }
                            .plus(
                                itemChangeForms().values
                                    .sumOf {
                                        if (it.cancelled())
                                            -(ogLines.find { og -> og._id == it.ogId() }?.price ?: 0)
                                        else
                                            it.draft().price
                                    })
                            .renderDollars()
                    }
                }
            }

            space()

            col {
                spacing = AppDimensions.cornerRadii
                row {
                    gravity(Align.Start, Align.End) - expanding - title("Scope Changes")
                    tertiaryButton - button {
                        specCenteredText("+ New Scope Changes")
                        onClick { itemChanges.value += ItemChange(itemId = SharedUtils.nullUUID) }
                    }
                }

                separator()
            }

            col {
                spacing = 0.dp

                forEach(shared {
                    rerunOn(itemChanges)
                    val f = itemChangeForms()
                    itemChanges().mapNotNull { f[it.id] }
                }) { form ->
                    col {
                        spacing = 0.px
                        form.render(this)
                        separator()
                    }
                }
            }

            space()

            col {
                spacing = AppDimensions.cornerRadii
                row {
                    gravity(Align.Start, Align.End) - expanding - title("New Work Scope")
                    tertiaryButton - button {
                        specCenteredText("+ Scope")
                        onClick {
                            newLineItems.value += LineItem(
                                project = project.awaitOnce()._id,
                                changeRequest = request.awaitOnce()._id
                            )
                        }
                    }
                }

                separator()
            }

            col {
                spacing = 0.dp

                forEach(shared {
                    rerunOn(newLineItems)
                    val f = newLineForms()
                    newLineItems().mapNotNull { f[it._id] }
                }) { form ->
                    col {
                        spacing = 0.px
                        form.render(this)
                        separator()
                    }
                }
            }
        }
    }
}