package com.crowpay.extensions


internal fun Long.toByteArray(): ByteArray = byteArrayOf(
    toByte(),
    shr(8).toByte(),
    shr(16).toByte(),
    shr(24).toByte(),
    shr(32).toByte(),
    shr(40).toByte(),
    shr(48).toByte(),
    shr(56).toByte()
)