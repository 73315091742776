package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.renderDollars
import com.crowpay.views.components.ProgressDelegate
import com.crowpay.views.components.space
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*


@Routable("/contractor")
class ContractorDashboard() : ContractorScreen {

    override val title: Readable<String> = Constant("Home")

    override fun ViewWriter.renderMainContent() {
        val contractor = shared {
            notNullSession().contractors[guaranteedContractor<UUID>()]() ?: run {
                screenNavigator.reset(ClientDashboard())
                throw CancelledException("No Session Found")
            }
        }

        val projectCondition = shared {
            Condition.And<Project>(
                listOf(
                    condition { (it.contractor eq guaranteedContractor()) },
                    condition {
                        it.state notInside setOf(
                            ProjectState.Creating,
                            ProjectState.Invited,
                            ProjectState.WaitingApproval,
                            ProjectState.Complete,
                            ProjectState.Warranty,
                            ProjectState.Archived,
                            ProjectState.Terminated,
                            ProjectState.Cancelled
                        )
                    }
                )
            )
        }

        val projectCount = sharedSuspending {
            notNullSession().nonCached.project
                .count(projectCondition())
                .toString()
        }
        val underEscrow = sharedSuspending {
            notNullSession().nonCached.project
                .aggregate(
                    AggregateQuery(
                        aggregate = Aggregate.Sum,
                        condition = projectCondition<Condition.And<Project>>(),
                        property = Project.path.balance
                    )
                )
                ?.toLong()
                ?.toString()
                ?: "N/A"
        }
        val availableCash = sharedSuspending {
            notNullSession().nonCached.contractor
                .pendingCashOut(guaranteedContractor()).available
                .toString()
        }

        scrolls - col {
            spacing = 0.dp
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = 0.dp

                col {
                    spacing = 1.rem
                    title {
                        ::content{ contractor().name }
                    }
                    subTitle("Welcome to your CrowPay Dashboard")
                }

                space(AppDimensions.majorColumnSpacing - AppDimensions.backgroundIndent)

                padded - col {
                    spacing = AppDimensions.backgroundIndent

                    section - col {
                        spacing = AppDimensions.sectionIndent

                        subTitle("Account Overview")

                        rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {
                            card - expanding - link {
                                col {
                                    spacing = AppDimensions.sectionIndent
                                    subTitle {
                                        align = Align.Center
                                        content = "Total Projects"
                                    }
                                    body {
                                        align = Align.Center
                                        ::content { projectCount() }
                                    }
                                }
                                to = { ContractorProjects() }
                            }
                            card - expanding - link {
                                col {
                                    spacing = AppDimensions.sectionIndent
                                    subTitle {
                                        align = Align.Center
                                        content = "Under Escrow"
                                    }
                                    body {
                                        align = Align.Center
                                        ::content{ underEscrow() }
                                    }
                                }
                                to = { ContractorPayments() }
                            }
                            card - expanding - link {
                                col {
                                    spacing = AppDimensions.sectionIndent
                                    subTitle {
                                        align = Align.Center
                                        content = "Available"
                                    }
                                    body {
                                        align = Align.Center
                                        ::content{ availableCash() }
                                    }
                                }
                                to = { ContractorPayments() }
                            }
                        }
                    }

                    section - col {

                        spacing = AppDimensions.sectionIndent

                        subTitle("Project Snapshots")

                        val projects = shared {
                            val session = notNullSession()
                            val projects = session.projects
                                .query(Query(
                                    condition = projectCondition(),
                                    orderBy = sort {
                                        it.state.ascending()
                                        it.name.ascending()
                                    }
                                ))
                                .await()
                            val lineItems = session.lineItems
                                .query(
                                    Query(
                                        condition = Condition.And(
                                            listOf(
                                                condition { it.project inside projects.map { it._id } },
                                                condition { it.cancelled eq null },
                                            )
                                        )
                                    )
                                )
                                .await()

                            projects
                                .associateWith { project -> lineItems.filter { line -> project._id == line.project } }
                                .entries.toList()
                                .sortedBy {
                                    it.key.state.ordinal + (it.value.count { it.complete != null }
                                        .toFloat() / it.value.size.toFloat())
                                }
                                .chunked(3)
                        }

                        col {
                            fun ViewWriter.renderSnapshot(data: Map.Entry<Project, List<LineItem>>?) {
                                if (data == null) {
                                    section - expanding - link {
                                        spacing = AppDimensions.sectionIndent
                                        visible = false
                                    }
                                    return
                                }
                                val project = data.key
                                val items = data.value
                                section - expanding - link {
                                    spacing = AppDimensions.sectionIndent
                                    this.to = { ContractorProject(project._id) }
                                    col {
                                        subTitle {
                                            content = project.name
                                        }
                                        row {
                                            spacing = 0.5.rem
                                            body {
                                                content = "State:"
                                            }
                                            expanding - body {
                                                content = project.state.displayValue
                                            } in tweakTheme {
                                                stateTheme(it, Color.fromHexString(project.state.displayColor))
                                            }
                                        }


                                        if (project.started != null) {
                                            col {
                                                spacing = 0.px
                                                body("Work Item Progress")
                                                val done = items.count { it.complete != null }
                                                sizeConstraints(height = 1.rem) - canvas {
                                                    delegate = ProgressDelegate(
                                                        color = AppColors.secondary.main,
                                                        progress = done.toFloat() / items.size.toFloat()
                                                    )
                                                }
                                                smallBody("${done}/${items.size} work items met")
                                            } in gravity(Align.Start, Align.Center)
                                        } else {
                                            col {
                                                spacing = 0.px
                                                body("Payment Progress")
                                                sizeConstraints(height = 1.rem) - canvas {
                                                    delegate = ProgressDelegate(
                                                        color = AppColors.secondary.main,
                                                        progress = project.balance.toFloat() / project.price.toFloat()
                                                    )
                                                }
                                                smallBody("${project.balance.renderDollars()} / ${project.price.renderDollars()}")
                                            } in gravity(Align.Start, Align.Center)
                                        }
                                    }
                                }
                            }
                            forEach(projects) { chunk ->
                                rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {
                                    renderSnapshot(chunk.getOrNull(0))
                                    renderSnapshot(chunk.getOrNull(1))
                                    renderSnapshot(chunk.getOrNull(2))
                                }
//                            space()
                            }
                        }
                    }
                }
            }
        }
    }
}