package com.crowpay.actuals

import com.lightningkite.kiteui.models.*

actual object AppDimensions {
    actual val xlTitleTextSize = 32.dp
    actual val titleTextSize = 28.dp

    actual val subtitleTextSize = 22.dp
    actual val bodyTextSize = 16.dp
    actual val smallBodyTextSize = 14.dp

    actual val fullIndent = 4.5.rem
    actual val sectionIndent = 2.rem
    actual val backgroundIndent = fullIndent - sectionIndent

    actual val majorColumnSpacing = 3.rem

    actual val pageWidth = 70.rem
    actual val wideCollapseWidth = pageWidth + 17.rem
    actual val normalCollapseWidth = pageWidth
    actual val thinCollapseWidth = pageWidth - 17.rem

    actual val buttonRowSpacing = 20.dp
    actual val buttonSpacing = 10.dp
    actual val buttonHorizontalSpacing = 22.dp
    actual val buttonMinimumWidth = 100.dp

    actual val outlineWidth = 2.dp
    actual val cornerRadii = 3.dp

    actual val largeImagePreview = 15.rem
    actual val smallImagePreview = 10.rem

    actual val leftNavWidth = 13.rem
    actual val topNavHeight = 7.rem

    actual val actionBarHeight: Dimension = 4.5.rem
}