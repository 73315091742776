package com.crowpay

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.crowpay.prepareChangeRequestFields()
        com.crowpay.prepareClientTermsAndConditionsAgreementFields()
        com.crowpay.prepareClientTermsAndConditionsVersionFields()
        com.crowpay.prepareClientFields()
        com.crowpay.prepareContractorFields()
        com.crowpay.prepareContractorDocumentFields()
        com.crowpay.prepareContractorTermsAndConditionsAgreementFields()
        com.crowpay.prepareContractorTermsAndConditionsVersionFields()
        com.crowpay.prepareDisputeLogFields()
        com.crowpay.prepareLicenseFields()
        com.crowpay.prepareLineItemLogFields()
        com.crowpay.prepareLineItemFields()
        com.crowpay.preparePayoutFields()
        com.crowpay.prepareProjectFields()
        com.crowpay.prepareProjectLedgerItemFields()
        com.crowpay.preparePunchListItemFields()
        com.crowpay.prepareTradeFields()
        com.crowpay.prepareUserFields()
        com.crowpay.preparePaymentMethodFields()
        com.crowpay.prepareAccessInfoFields()
        com.crowpay.prepareAddressFields()
        com.crowpay.prepareProjectAttachmentFields()
        com.crowpay.prepareClientFeedbackFields()
        com.crowpay.prepareItemChangeFields()
        com.crowpay.prepareCompletionRequestFields()
        com.crowpay.prepareDisputeFields()
        com.crowpay.prepareFiledForArbitrationFields()
        com.crowpay.prepareUserMembershipFields()
    }
}

fun SerializationRegistry.registerShared() {
    register(com.crowpay.ChangeRequest.serializer())
    register(com.crowpay.ClientTermsAndConditionsAgreement.serializer())
    register(com.crowpay.ClientTermsAndConditionsVersion.serializer())
    register(com.crowpay.Client.serializer())
    register(com.crowpay.Contractor.serializer())
    register(com.crowpay.ContractorDocument.serializer())
    register(com.crowpay.ContractorTermsAndConditionsAgreement.serializer())
    register(com.crowpay.ContractorTermsAndConditionsVersion.serializer())
    register(com.crowpay.DisputeLog.serializer())
    register(com.crowpay.License.serializer())
    register(com.crowpay.LineItemLog.serializer())
    register(com.crowpay.LineItem.serializer())
    register(com.crowpay.Payout.serializer())
    register(com.crowpay.Project.serializer())
    register(com.crowpay.ProjectLedgerItem.serializer())
    register(com.crowpay.PunchListItem.serializer())
    register(com.crowpay.Trade.serializer())
    register(com.crowpay.User.serializer())
    register(com.crowpay.LicenseType.serializer())
    register(com.crowpay.LineItemResolutionType.serializer())
    register(com.crowpay.LineItemState.serializer())
    register(com.crowpay.PaymentState.serializer())
    register(com.crowpay.PreferredTitle.serializer())
    register(com.crowpay.ProjectState.serializer())
    register(com.crowpay.MemberRole.serializer())
    register(com.crowpay.UserRole.serializer())
    register(com.crowpay.ContractorActive.serializer())
    register(com.crowpay.State.serializer())
    register(com.crowpay.BankAccountType.serializer())
    register(com.crowpay.BankAccountHolderType.serializer())
    register(com.crowpay.Bank.serializer())
    register(com.crowpay.PaymentMethod.serializer())
    register(com.crowpay.MicroDepositVerification.serializer())
    register(com.crowpay.PendingAction.serializer())
    register(com.crowpay.PendingPaymentMethod.serializer())
    register(com.crowpay.PaymentRequest.serializer())
    register(com.crowpay.Payment.serializer())
    register(com.crowpay.PaymentStatus.serializer())
    register(com.crowpay.AccessInfo.serializer())
    register(com.crowpay.AccountInfo.serializer())
    register(com.crowpay.Address.serializer())
    register(com.crowpay.ProjectAttachment.serializer())
    register(com.crowpay.ClientFeedback.serializer())
    register(com.crowpay.ChangeDenyRequest.serializer())
    register(com.crowpay.ItemChange.serializer())
    register(com.crowpay.CompletionRequest.serializer())
    register(com.crowpay.Dispute.serializer())
    register(com.crowpay.FiledForArbitration.serializer())
    register(com.crowpay.FullProject.serializer())
    register(com.crowpay.PaymentSetupRequest.serializer())
    register(com.crowpay.PendingCashOut.serializer())
    register(com.crowpay.UserMembership.serializer())
    register(com.crowpay.UserRegistration.serializer())
    register(com.crowpay.RegisterContractor.serializer())
}
