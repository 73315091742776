package com.crowpay.views.components.files

import com.crowpay.Attachment
import com.crowpay.ContractorDocument
import com.crowpay.ProjectAttachment
import com.crowpay.extensions.renderThumbnailOrNull
import com.crowpay.views.theming.title
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.themeFromLast
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant


sealed interface FilePreview {
    data class RemoteAttachment(val attachment: Attachment) : FilePreview
    data class RemoteFile(val location: String) : FilePreview
    data class LocalFile(val value: FileReference) : FilePreview {
        private var _uploaded: Property<Readable<Boolean?>> = Property(Constant(null))
        val uploaded: Readable<Readable<Boolean?>> get() = _uploaded
        var mainFile: ServerFile? = null
        var thumbnail: ServerFile? = null
        var uploadStarted: Instant? = null
        var uploadFinished: Instant? = null

        fun reset() {
            uploadStarted = null
            uploadFinished = null
        }

        suspend fun upload(uploader: suspend () -> UploadInformation) {
            _uploaded.value = Constant(null)
            _uploaded.value = sharedSuspending {
                if (uploadFinished != null) return@sharedSuspending mainFile != null
                uploadStarted = now()
                val file = value
                val uploadedFile = try {
                    val upload = uploader()
                    fetch(
                        url = upload.uploadUrl,
                        method = HttpMethod.PUT,
                        body = file
                    )
                    ServerFile(upload.futureCallToken)
                } catch (e: CancelledException) {
                    null
                } catch (e: Exception) {
                    null
                }

                mainFile = uploadedFile
                uploadFinished = now()
                mainFile != null
            }
        }

        suspend fun uploadAttachment(uploader: suspend () -> UploadInformation) {
            _uploaded.value = Constant(null)
            _uploaded.value = sharedSuspending {
                if (uploadFinished != null) return@sharedSuspending mainFile != null
                uploadStarted = now()
                val file = value
                val uploadedFile = try {
                    val upload = uploader()
                    fetch(
                        url = upload.uploadUrl,
                        method = HttpMethod.PUT,
                        body = file
                    )
                    ServerFile(upload.futureCallToken)
                } catch (e: CancelledException) {
                    null
                } catch (e: Exception) {
                    null
                }
                val uploadedPreview = try {
                    file.renderThumbnailOrNull(300)?.let { thumbnail ->
                        val upload = uploader()
                        fetch(
                            url = upload.uploadUrl,
                            method = HttpMethod.PUT,
                            body = thumbnail
                        )
                        ServerFile(upload.futureCallToken)
                    }
                } catch (e: CancelledException) {
                    null
                } catch (e: Exception) {
                    null
                }

                mainFile = uploadedFile
                thumbnail = uploadedPreview
                uploadFinished = now()
                mainFile != null
            }
        }
    }

    data object Empty : FilePreview

    fun getImage(): ImageSource? = when (this) {
        Empty -> null
        is LocalFile -> ImageLocal(value)
        is RemoteAttachment -> ImageRemote(attachment.preview?.location ?: attachment.file.location)
        is RemoteFile -> ImageRemote(location)
    }
}

class FileData(
    val id: UUID = uuid(),
    val name: Property<String>,
    val preview: FilePreview,
    val original: FilePreview? = null,
)

suspend fun List<FileData>.resolveProjectAttachments(): List<ProjectAttachment> = map { item ->
    when (val p = item.preview) {
        is FilePreview.LocalFile -> {
            val uploaded = p.uploaded.await().awaitNotNull()
            if (uploaded)
                p.mainFile?.let { mainFile ->
                    ProjectAttachment(name = item.name.await(), p.value.mimeType(), mainFile, p.thumbnail)
                }
            else null
        }

        is FilePreview.RemoteAttachment -> ProjectAttachment(
            item.name.await().trim(),
            p.attachment.fileType,
            p.attachment.file,
            p.attachment.preview
        )

        is FilePreview.RemoteFile -> ProjectAttachment(
            "",
            "",
            ServerFile(p.location),
            null
        )

        FilePreview.Empty -> null
    }
}
    .filterNotNull()

suspend fun List<FileData>.resolveContractorDocuments(contractor: UUID): List<ContractorDocument> = map { item ->
    when (val p = item.preview) {
        is FilePreview.LocalFile -> {
            val uploaded = p.uploaded.await().awaitNotNull()
            if (uploaded)
                p.mainFile?.let { mainFile ->
                    ContractorDocument(
                        _id = item.id,
                        contractor = contractor,
                        name = item.name.await(),
                        fileType = p.value.mimeType(),
                        file = mainFile,
                        preview = p.thumbnail
                    )
                }
            else null
        }

        is FilePreview.RemoteAttachment -> ContractorDocument(
            _id = item.id,
            contractor = contractor,
            name = item.name.await().trim(),
            fileType = p.attachment.fileType,
            file = p.attachment.file,
            preview = p.attachment.preview
        )

        is FilePreview.RemoteFile -> ContractorDocument(
            _id = item.id,
            contractor = contractor,
            name = "",
            fileType = "",
            file = ServerFile(p.location),
            preview = null
        )

        FilePreview.Empty -> null
    }
}
    .filterNotNull()

fun RView.renderAttachmentImage(attachment: Readable<Attachment>) {
    stack {
        val split = split()
        reactiveScope {
            this@stack.clearChildren()
            val a = attachment()
            with(split) {
                renderAttachmentImage(a)
            }
        }
    }
}

fun RView.renderAttachmentImage(attachment: Attachment) {
    when {
        attachment.preview != null ->
            image {
                scaleType = ImageScaleType.Fit
                source = ImageRemote(attachment.preview!!.location)
            }

        attachment.fileType.startsWith("image") -> {
            image {
                scaleType = ImageScaleType.Fit
                source = ImageRemote(attachment.file.location)
            }
        }

        else -> {
            stack {
                title { 
                    content = attachment.fileType.substringAfter('/').uppercase()
                } in centered
            } in themeFromLast {
                it.copy(
                    background = it.background.closestColor().darken(0.2f).copy(alpha = 0.8f),
                )
            }
        }
    }
}