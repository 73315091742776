// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun preparePunchListItemFields() {
    val props: Array<SerializableProperty<PunchListItem, *>> = arrayOf(PunchListItem__id, PunchListItem_project, PunchListItem_lineItem, PunchListItem_order, PunchListItem_content, PunchListItem_required, PunchListItem_complete)
    PunchListItem.serializer().properties { props }
}
val <K> DataClassPath<K, PunchListItem>._id: DataClassPath<K, UUID> get() = this[PunchListItem__id]
val <K> DataClassPath<K, PunchListItem>.project: DataClassPath<K, UUID> get() = this[PunchListItem_project]
val <K> DataClassPath<K, PunchListItem>.lineItem: DataClassPath<K, UUID?> get() = this[PunchListItem_lineItem]
val <K> DataClassPath<K, PunchListItem>.order: DataClassPath<K, Double> get() = this[PunchListItem_order]
val <K> DataClassPath<K, PunchListItem>.content: DataClassPath<K, String> get() = this[PunchListItem_content]
val <K> DataClassPath<K, PunchListItem>.required: DataClassPath<K, Boolean> get() = this[PunchListItem_required]
val <K> DataClassPath<K, PunchListItem>.complete: DataClassPath<K, AccessInfo?> get() = this[PunchListItem_complete]
inline val PunchListItem.Companion.path: DataClassPath<PunchListItem, PunchListItem> get() = path<PunchListItem>()


object PunchListItem__id: SerializableProperty<PunchListItem, UUID> {
    override val name: String = "_id"
    override fun get(receiver: PunchListItem): UUID = receiver._id
    override fun setCopy(receiver: PunchListItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object PunchListItem_project: SerializableProperty<PunchListItem, UUID> {
    override val name: String = "project"
    override fun get(receiver: PunchListItem): UUID = receiver.project
    override fun setCopy(receiver: PunchListItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("project")
}
object PunchListItem_lineItem: SerializableProperty<PunchListItem, UUID?> {
    override val name: String = "lineItem"
    override fun get(receiver: PunchListItem): UUID? = receiver.lineItem
    override fun setCopy(receiver: PunchListItem, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("lineItem")
    override val default: UUID? = null
}
object PunchListItem_order: SerializableProperty<PunchListItem, Double> {
    override val name: String = "order"
    override fun get(receiver: PunchListItem): Double = receiver.order
    override fun setCopy(receiver: PunchListItem, value: Double) = receiver.copy(order = value)
    override val serializer: KSerializer<Double> = Double.serializer()
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("order")
    override val default: Double = 0.5
}
object PunchListItem_content: SerializableProperty<PunchListItem, String> {
    override val name: String = "content"
    override fun get(receiver: PunchListItem): String = receiver.content
    override fun setCopy(receiver: PunchListItem, value: String) = receiver.copy(content = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("content")
}
object PunchListItem_required: SerializableProperty<PunchListItem, Boolean> {
    override val name: String = "required"
    override fun get(receiver: PunchListItem): Boolean = receiver.required
    override fun setCopy(receiver: PunchListItem, value: Boolean) = receiver.copy(required = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("required")
}
object PunchListItem_complete: SerializableProperty<PunchListItem, AccessInfo?> {
    override val name: String = "complete"
    override fun get(receiver: PunchListItem): AccessInfo? = receiver.complete
    override fun setCopy(receiver: PunchListItem, value: AccessInfo?) = receiver.copy(complete = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = PunchListItem.serializer().tryFindAnnotations("complete")
    override val default: AccessInfo? = null
}
