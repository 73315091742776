package com.crowpay.views.screens.auth

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.sdk.selectedApi
import com.crowpay.sdk.sessionToken
import com.crowpay.utils.diff
import com.crowpay.utils.iconAndAction
import com.crowpay.utils.pushChanges
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.field2
import com.crowpay.views.components.files.renderContractorDocuments
import com.crowpay.views.components.files.renderLicenses
import com.crowpay.views.components.label2
import com.crowpay.views.components.space
import com.crowpay.views.screens.common.LandingScreen
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.and
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

fun ViewWriter.addressForm(
    address: Writable<Address>,
    validator: Validator,

    widthLimit: Dimension = AppDimensions.normalCollapseWidth
) = with(validator) {
    col {
        subTitle("Address")
        rowCollapsingToColumn(widthLimit) {
            expanding - field2("Line 1") {
                val value = address.lensPath { it.line1 }.validateNotBlank()
                validate(value) - textInput {
                    hint = "Line 1"
                    content bind value
                }
            }
            expanding - field2("Line 2 (Optional)") {
                textInput {
                    hint = "Line 2"
                    content bind address.lensPath { it.line2 }.nullToBlank()
                }
            }
        }
        rowCollapsingToColumn(widthLimit) {
            expanding - field2("City") {
                val value = address.lensPath { it.city }.validateNotBlank()
                validate(value) - textInput {
                    hint = "City"
                    content bind value
                }
            }
            expanding - field2("State") {
                select {
                    bind(
                        address.lensPath { it.state },
                        Constant(State.entries)
                    ) { it.display }
                }
            }
            expanding - field2("Zipcode") {
                val value = address.lensPath { it.zipcode }.validateNotBlank()
                validate(value) - textInput {
                    hint = "Zipcode"
                    content bind value
                }
            }
        }
    }
}

@Routable("contractor/register")
class ContractorRegistration() : Screen {
    val preExisting = Property(false)
    val contractor = Draft {
        selectedContractor()?.let {
            currentSession()?.contractors?.get(it)?.invoke()?.also { preExisting.value = true }
        } ?: Contractor(
            name = "",
            email = "",
            phoneNumber = "",
            address = Address(),
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactPhoneNumber = "",
            contactAddress = Address(),
            stateEntityNumber = "",
            ein = ""
        )
    }
    val contractorID = contractor.lens { it._id }

    val currentScreen = Property<Screen>(BasicInfo())

    sealed interface CenteredScreen

    override fun ViewWriter.render() {
        expanding - swapView {
            launch {
                if (
                    (sessionToken() != null && selectedContractor() == null)
                    or (sessionToken() != null && currentContractor()?.active != ContractorActive.Registering)
                    ) screenNavigator.reset(LandingScreen())
            }

            spacing = 0.px
            swapping(
                current = {
                    (currentSession() != null) to currentScreen()
                },
                views = { (loggedIn, view) ->
                    // This weird stuff handles the different theming needed for when logged in vs not logged in.
                    with(view) {
                        if (!loggedIn) {
                            if (view is CenteredScreen) scrolls - authStack { render() }
                            else scrolls - authCol { render() }
                        }
                        else scrolls - stack { render() }
                    }
                }
            )
        }
    }

    fun ViewWriter.authCol(setup: RowOrCol.()->Unit) {
        expanding - AuthBackgroundSemantic.onNext - col {
            space()
            centered - AuthDialogSemantic.onNext - stack {
                spacing = AppDimensions.fullIndent
                col(setup)
            }
            space()
        }
    }
    fun ViewWriter.authStack(setup: Stack.() -> Unit) {
        expanding - AuthBackgroundSemantic.onNext - stack {
            centered - AuthDialogSemantic.onNext - stack {
                spacing = AppDimensions.fullIndent
                stack(setup)
            }
        }
    }

    inner class BasicInfo : Screen, Validator() {
        override fun ViewWriter.render() {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing
                col {
                    xlTitle {
                        ::content { if (preExisting()) "Continue Registration" else "Contractor Registration" }
                    }
                    separator()
                }

                col {
                    title("Business Info")

                    field2("Business/Contractor Name") {
                        val name = contractor.lensPath { it.name }.validateNotBlank()
                        validate(name) - textInput {
                            hint  = "Your Business Name Here"
                            content bind name
                        }
                    }

                    field2("Email") {
                        val email = contractor.lensPath { it.email }.validate { it.matches(SharedUtils.emailPattern) }
                        validate(email) - textInput {
                            hint = "Email"
                            content bind email
                        }
                    }

                    field2("Phone Number") {
                        val phone = contractor.lensPath { it.phoneNumber }.validate { it.length == 10 }
                        validate(phone) - formattedTextInput {
                            hint = "Phone Number"
                            format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                            content bind phone
                        }
                    }

                    space()

                    addressForm(contractor.lensPath { it.address }, this@BasicInfo)
                }

                col {
                    title("Contact Info")
                    body {
                        ::exists { !preExisting() }
                        content = "This will be the information for your user"
                    }

                    label2("Name") {
                        val first = contractor.lensPath { it.contactFirstName }.validateNotBlank()
                        val last = contractor.lensPath { it.contactLastName }.validateNotBlank()

                        row {
                            expanding - fieldTheme - validate(first) - textInput {
                                hint = "First"
                                content bind first
                            }
                            expanding - fieldTheme - validate(last) - textInput {
                                hint = "Last"
                                content bind last
                            }
                        }
                    }

                    field2("Email") {
                        val email =
                            contractor.lensPath { it.contactEmail }.validate { it.matches(SharedUtils.emailPattern) }
                        validate(email) - textInput {
                            hint = "Email"
                            content bind email
                        }
                    }

                    field2("Phone Number") {
                        val phone = contractor.lensPath { it.contactPhoneNumber }.validate { it.length == 10 }
                        validate(phone) - formattedTextInput {
                            hint = "Phone Number"
                            format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                            content bind phone
                        }
                    }

                    space()

                    addressForm(contractor.lensPath { it.contactAddress }, this@BasicInfo)
                }

                lightSection - row {
                    expanding - space()
                    secondaryButton - button {
                        ::exists { currentSession() == null }
                        specCenteredText("Cancel")
                        onClick {
                            screenNavigator.reset(LogInScreen())
                        }
                    }
                    primaryButton - button {
                        ::enabled { allValid() }
                        specCenteredText("Next")
                        onClick {
                            currentScreen.value =
                                if (sessionToken() == null) {
                                    val token =
                                        selectedApi().api.contractor.sendRegistrationEmail(contractor().contactEmail)
                                    AuthenticateBasicInfoAndLogin(token)
                                } else {
                                    contractor.pushChanges(notNullSession().contractors)
                                    BusinessLicenseInfo()
                                }
                        }
                    }
                }
            }
        }
    }

    inner class AuthenticateBasicInfoAndLogin(val token: String) : Screen, Validator(), CenteredScreen {
        val pinChars = ('A'..'Z').toList() - setOf('I', 'O')
        val passcode = Property("").validate { str -> (str.length == 6) and (str.all { it in pinChars }) }

        val login = Action(
            title = "Log In",
            icon = Icon.login
        ) {
            if (!passcode.valid()) throw IllegalStateException("Enter Viable Passcode")
            val api = selectedApi().api
            val proof = api.emailProof.proveEmailOwnership(
                FinishProof(
                    token,
                    passcode()
                )
            )
            api.contractor.register(
                RegisterContractor(contractor(), proof)
            )

            val session = api.userAuth.logIn(listOf(proof))
            sessionToken set session.session!!
            selectedContractor set contractor()._id

            currentScreen.value = BusinessLicenseInfo()
        }

        override fun ViewWriter.render() {
            col {
                xlTitle("Confirm Email")

                sizeConstraints(width = 30.rem) - body {
                    ::content { "An email was just sent to ${contractor().contactEmail}. Enter the passcode below to confirm your email and continue registering." }
                }

                field2("Passcode") {
                    validate(passcode) - textInput {
                        hint = "Passcode"
                        keyboardHints = KeyboardHints.id
                        content bind passcode

                        action = login
                    }
                }

                primaryButton - button {
                    ::enabled { allValid() }
                    body {
                        align = Align.Center
                        content = "Submit"
                    }
                    action = login
                }
            }
        }
    }

    inner class BusinessLicenseInfo : Screen, Validator() {
        val licenses = shared {
            notNullSession().licenses.query(Query(condition { it.contractor eq contractorID() }))()
        }
        val documents = shared {
            notNullSession().contractorDocuments
                .query(
                    Query(condition {
                        (it.contractor eq contractorID()) and (it.fileType eq "application/pdf")
                    })
                )()
        }

        override fun ViewWriter.render() {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing
                col {
                    row {
                        button {
                            spacing = 0.5.rem
                            iconAndAction(Icon.arrowBack, "Back To Basic Info") {
                                currentScreen.value = BasicInfo()
                            }
                        }
                        centered - xlTitle("Business License Info")
                    }
                    separator()
                }
                row {
                    expanding - field2("Trade") {
                        val trades = shared {
                            listOf(null) + notNullSession().trades.query(Query(limit = Int.MAX_VALUE))().map { it._id }
                        }
                        val trade = contractor.lensPath { it.trade }.validateNotNull()
                        validate(trade) - select {
                            bind(trade, trades) { it ?: "Unselected" }
                        }
                    }

                    expanding - field2("EIN/Social") {
                        val ein = contractor.lensPath { it.ein }.validateNotBlank()
                        validate(ein) - textInput {
                            hint = "EIN/Social"
                            content bind ein
                        }
                    }
                }
                field2("State Entity Number") {
                    val sen = contractor.lensPath { it.stateEntityNumber }.validateNotBlank()
                    validate(sen) - textInput {
                        hint = "State Entity Number"
                        content bind sen
                    }
                }
                label2("State License(s)") {
                    section - validate { licenses().isNotEmpty() } - renderLicenses(licenses)
                    errorText()
                }
                label2("Articles of Incorporation") {
                    section - validate { documents().isNotEmpty() } - renderContractorDocuments(
                        documents,
                        listOf("application/pdf")
                    )
                    errorText()
                }

                lightSection - row {
                    expanding - space()
                    secondaryButton - button {
                        specCenteredText("Save")
                        onClick {
                            contractor.pushChanges(notNullSession().contractors)
                        }
                    }
                    primaryButton - button {
                        ::enabled { allValid() }
                        specCenteredText("Next")
                        onClick {
                            contractor.pushChanges(notNullSession().contractors)
                            currentScreen.value = VerificationAndOFAC()
                        }
                    }
                }
            }
        }
    }

    inner class VerificationAndOFAC : Screen {
        override fun ViewWriter.render() {
            sizeConstraints(width = AppDimensions.pageWidth) - col {
                spacing = AppDimensions.majorColumnSpacing
                col {
                    row {
                        button {
                            spacing = 0.5.rem
                            iconAndAction(Icon.arrowBack, "Back To License Info") {
                                currentScreen.value = BusinessLicenseInfo()
                            }
                        }
                        centered - xlTitle("Verification")
                    }
                    separator()
                }
                subTitle("Under Construction")
                lightSection - row {
                    expanding - space()
                    primaryButton - button {
                        specCenteredText("Finish")
                        onClick {
                            val session = notNullSession()
//                            contractor.pushChanges(session.contractors)
                            session.nonCached.contractor.submitForApproval(contractor()._id)
                            session.contractors.totallyInvalidate()
                            delay(500)
                            mainScreenNavigator.reset(ContractorDashboard())
                        }
                    }
                }
            }
        }
    }
}