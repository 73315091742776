package com.crowpay.views.components.project

import com.crowpay.actuals.AppDimensions
import com.crowpay.LineItem
import com.crowpay.actuals.rotation
import com.crowpay.extensions.toFileData
import com.crowpay.extensions.withSpacing
import com.crowpay.files
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.trash
import com.crowpay.utils.ReorderDirection
import com.crowpay.utils.diff
import com.crowpay.utils.pushChanges
import com.crowpay.utils.toggle
import com.crowpay.utils.validation.Validator
import com.crowpay.views.components.files.FilePreview
import com.crowpay.views.components.LineItemForm
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.body
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.textButton
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.modification
import com.lightningkite.lightningserver.files.ServerFile
import kotlinx.serialization.json.Json


class EditLineItem(
    ogLine: LineItem?,
    projectId: UUID,
    val allowSave: Readable<Boolean> = Constant(true),
    val onRemove: suspend ViewWriter.(UUID) -> Unit,
    val reorder: (suspend (UUID, ReorderDirection) -> Unit)? = null,
) : Screen {

    var savedToDB = Property(ogLine != null)
    val draft = Draft(ogLine ?: LineItem(project = projectId))
    val files = Property(ogLine?.files?.toFileData() ?: emptyList())
    val expanded = Property(false)
    val form = LineItemForm(draft, files)
    val validator: Validator get() = form

    suspend fun save() {
        val session = currentSession() ?: return
        if (savedToDB.value) {
            val filesChanged =
                files.value.size != draft.awaitOnce().files.size ||
                        files.value.any {
                            it.preview is FilePreview.LocalFile ||
                                    it.name.value != (it.original as? FilePreview.RemoteAttachment)?.attachment?.name
                        }
            if (!draft.changesMade.awaitOnce() && !filesChanged) return
            val current = draft.awaitOnce()

            val newFiles = files.awaitOnce().resolveProjectAttachments()
            val newFilesSimplified = newFiles.map {
                it.copy(
                    file = ServerFile(it.file.location.substringBefore('?')),
                    preview = it.preview?.let {
                        ServerFile(
                            it.location.substringBefore('?')
                        )
                    }
                )
            }
            val oldFiles = current.files.map {
                it.copy(
                    file = ServerFile(it.file.location.substringBefore('?')),
                    preview = it.preview?.let {
                        ServerFile(
                            it.location.substringBefore('?')
                        )
                    }
                )
            }

            if (oldFiles != newFilesSimplified) {
                draft.modify {
                    it.copy(files = newFiles)
                }
            }

            val result = draft.pushChanges(notNullSession().lineItems)
            files.set(result.files.toFileData())
        } else {
            println("Inserting line item")
            val current = draft.awaitOnce()
            val newFiles = files.awaitOnce().resolveProjectAttachments()

            val result = session.lineItems.insert(
                current.copy(files = newFiles)/*.also {
                    println(Json.encodeToString(LineItem.serializer(), it))
                }*/
            ).await()!!
            draft.published.set(result)
            draft.cancel()
            files.set(result.files.toFileData())
            savedToDB.set(true)
        }
        expanded.set(false)
    }

    suspend fun ViewWriter.remove() {

        val (header, removeMessage) = if (savedToDB.await()) {
            "Delete Work Item?" to "Deleting a work item can not be undone. Are you sure you want to delete this Work Item?"
        } else {
            "Discard Work Item?" to "Discarding unsaved work cannot be undone. Are you sure you want to discard this unsaved Work Item?"
        }
        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = header,
                message = removeMessage,
                question = null,
                confirmationText = header.trim('?'),
                declineText = "Go Back",
                messageType = MessageType.Danger,
                onSubmit = { confirmed ->
                    if (confirmed) {
                        val id = draft()._id
                        if (savedToDB.await())
                            notNullSession().nonCached.lineItem.delete(id)
                        onRemove(id)
                    }
                }
            ))
    }

    override fun ViewWriter.render() {

        col {
            spacing = 0.px

            withSpacing(0.dp) - row {
                expanding - buttonTheme - button {
                    spacing = 0.dp
                    row {
                        centered - icon(Icon.chevronRight, "") {
                            ::rotation{ if (expanded()) (-90).degrees else 0.degrees }
                        }
                        centered - body {
                            ::content{ form.name().takeUnless { it.isBlank() } ?: "New Work Item" }
                        }
                        expanding - buttonTheme - stack {
                            visible = false
                            icon(Icon.chevronRight, "")
                        }
                    }
                    onClick {
                        expanded.toggle()
                    }
                }
                onlyWhen { savedToDB() } - buttonTheme - button {
                    sizeConstraints(width = 2.rem, height = 2.rem) - icon(Icon.trash, "Remove Work Item")
                    onClick { remove() }
                }
                row {
                    exists = reorder != null
                    separator()
                    centered - buttonTheme - button {
                        icon(Icon.chevronRight, "Move item up 1") {
                            rotation = (-90).degrees
                        }
                        onClick {
                            reorder?.invoke(draft()._id, ReorderDirection.UP)
                        }
                    }
                    centered - buttonTheme - button {
                        icon(Icon.chevronRight, "Move item down 1") {
                            rotation = 90.degrees
                        }
                        onClick {
                            reorder?.invoke(draft()._id, ReorderDirection.DOWN)
                        }
                    }
                }
            }
            onlyWhen { expanded() } - col {
                spacing = 0.dp
                space(AppDimensions.backgroundIndent)
                row {
                    spacing = 0.dp
                    space(AppDimensions.fullIndent)
                    expanding - col {
                        form.render(this)
                        row {
                            spacing = AppDimensions.buttonRowSpacing
                            expanding - space()
                            textButton - button {
                                specCenteredText("Discard")
                                onClick {
                                    if (savedToDB.await()) {
                                        draft.cancel()
                                        files.set(draft.awaitOnce().files.toFileData())
                                        expanded.set(false)
                                    } else {
                                        remove()
                                    }
                                }
                            }
                            textButton - gravity(Align.End, Align.Center) - button {
                                ::enabled{ validator.allValid() }
                                specCenteredText("Save")
                                onClick(action = ::save)
                            }
                        }
                    }
                    space(AppDimensions.fullIndent)
                }
                space(AppDimensions.backgroundIndent)
            }
        }
    }
}