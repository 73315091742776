package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.crowpay.views.theming.bodyBold
import com.crowpay.views.theming.subTitle
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.SubtextSemantic
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText


@ViewDsl
fun ViewWriter.titleLabel(value: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        title {
            content = value
        }
        setup()
    }
}

@ViewDsl
fun ViewWriter.titleLabel(value: Readable<String>, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        title {
            ::content{ value() }
        }
        setup()
    }
}


@ViewDsl
fun ViewWriter.subTitleLabel(value: String, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        subTitle {
            content = value
        }
        setup()
    }
}

@ViewDsl
fun ViewWriter.subTitleLabel(value: Readable<String>, setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        subTitle {
            ::content{ value() }
        }
        setup()
    }
}


@ViewDsl
inline fun ViewWriter.label2(label: String, crossinline setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold(label)
        setup()
    }
}

@ViewDsl
inline fun ViewWriter.label2(value: Readable<String>, crossinline setup: RowOrCol.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold { ::content{ value() } }
        setup()
    }
}

@ViewDsl
inline fun ViewWriter.field2(label: String, crossinline field: RView.() -> Unit) {
    col {
        spacing = AppDimensions.cornerRadii
        bodyBold(label)
        fieldTheme - field()
        SubtextSemantic.onNext - errorText()
    }
}