package com.crowpay.views.dialogs

import com.crowpay.PaymentMethod
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.label2
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


class EditMethodDialog(
    val method: PaymentMethod,
    val onUpdate: suspend ViewWriter.() -> Unit,
) : Screen {

    val name = Property(method.name ?: "")

    override fun ViewWriter.render() {

        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    title("Edit Method")

                    space()

                    label2("Name") {
                        fieldTheme - textField {
                            hint = "Method Name"
                            content bind name
                        }
                    }

                    label2("Bank Name") {
                        body(method.bank?.bankName ?: "")
                    }
                    label2("Account Number") {
                        body("*** ***" + (method.bank?.accountNumber ?: ""))
                    }
                    label2("Routing Number") {
                        body(method.bank?.routingNumber ?: "")
                    }

                    space()

                    row {

                        textButton - button {
                            specCenteredText("Delete")
                            onClick {
                                notNullSession().nonCached.userAuth.deletePaymentMethod(method.id)
                                screenNavigator.dismiss()
                                onUpdate()
                            }
                        }

                        expanding - space()

                        textButton - button {
                            specCenteredText("Cancel")
                            onClick {
                                screenNavigator.dismiss()
                            }
                        }

                        tertiaryButton - button {
                            ::enabled{ name().isNotBlank() }
                            specCenteredText("Save")
                            onClick {
                                notNullSession().nonCached.userAuth.updatePaymentMethodName(
                                    method.id,
                                    name.value
                                )
                                screenNavigator.dismiss()
                                onUpdate()
                            }
                        }
                    }

                }
            }
            onClick {
            }
        }
    }
}