// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareTradeFields() {
    val props: Array<SerializableProperty<Trade, *>> = arrayOf(Trade__id)
    Trade.serializer().properties { props }
}
val <K> DataClassPath<K, Trade>._id: DataClassPath<K, String> get() = this[Trade__id]
inline val Trade.Companion.path: DataClassPath<Trade, Trade> get() = path<Trade>()


object Trade__id: SerializableProperty<Trade, String> {
    override val name: String = "_id"
    override fun get(receiver: Trade): String = receiver._id
    override fun setCopy(receiver: Trade, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Trade.serializer().tryFindAnnotations("_id")
}
