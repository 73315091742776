// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_email, User_phoneNumber, User_firstName, User_lastName, User_address, User_memberships, User_paymentId, User_role, User_receivesNewContractorEmails, User_lastAgreement)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.phoneNumber: DataClassPath<K, String> get() = this[User_phoneNumber]
val <K> DataClassPath<K, User>.firstName: DataClassPath<K, String> get() = this[User_firstName]
val <K> DataClassPath<K, User>.lastName: DataClassPath<K, String> get() = this[User_lastName]
val <K> DataClassPath<K, User>.address: DataClassPath<K, Address> get() = this[User_address]
val <K> DataClassPath<K, User>.memberships: DataClassPath<K, Set<UserMembership>> get() = this[User_memberships]
val <K> DataClassPath<K, User>.paymentId: DataClassPath<K, String?> get() = this[User_paymentId]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole> get() = this[User_role]
val <K> DataClassPath<K, User>.receivesNewContractorEmails: DataClassPath<K, Boolean> get() = this[User_receivesNewContractorEmails]
val <K> DataClassPath<K, User>.lastAgreement: DataClassPath<K, ClientTermsAndConditionsAgreement?> get() = this[User_lastAgreement]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String = "_id"
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object User_email: SerializableProperty<User, String> {
    override val name: String = "email"
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("email")
}
object User_phoneNumber: SerializableProperty<User, String> {
    override val name: String = "phoneNumber"
    override fun get(receiver: User): String = receiver.phoneNumber
    override fun setCopy(receiver: User, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("phoneNumber")
}
object User_firstName: SerializableProperty<User, String> {
    override val name: String = "firstName"
    override fun get(receiver: User): String = receiver.firstName
    override fun setCopy(receiver: User, value: String) = receiver.copy(firstName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("firstName")
}
object User_lastName: SerializableProperty<User, String> {
    override val name: String = "lastName"
    override fun get(receiver: User): String = receiver.lastName
    override fun setCopy(receiver: User, value: String) = receiver.copy(lastName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("lastName")
}
object User_address: SerializableProperty<User, Address> {
    override val name: String = "address"
    override fun get(receiver: User): Address = receiver.address
    override fun setCopy(receiver: User, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("address")
}
object User_memberships: SerializableProperty<User, Set<UserMembership>> {
    override val name: String = "memberships"
    override fun get(receiver: User): Set<UserMembership> = receiver.memberships
    override fun setCopy(receiver: User, value: Set<UserMembership>) = receiver.copy(memberships = value)
    override val serializer: KSerializer<Set<UserMembership>> = SetSerializer(UserMembership.serializer())
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("memberships")
    override val default: Set<UserMembership> = setOf()
}
object User_paymentId: SerializableProperty<User, String?> {
    override val name: String = "paymentId"
    override fun get(receiver: User): String? = receiver.paymentId
    override fun setCopy(receiver: User, value: String?) = receiver.copy(paymentId = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("paymentId")
    override val default: String? = null
}
object User_role: SerializableProperty<User, UserRole> {
    override val name: String = "role"
    override fun get(receiver: User): UserRole = receiver.role
    override fun setCopy(receiver: User, value: UserRole) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole> = UserRole.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("role")
    override val default: UserRole = UserRole.Customer
}
object User_receivesNewContractorEmails: SerializableProperty<User, Boolean> {
    override val name: String = "receivesNewContractorEmails"
    override fun get(receiver: User): Boolean = receiver.receivesNewContractorEmails
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(receivesNewContractorEmails = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("receivesNewContractorEmails")
    override val default: Boolean = true
}
object User_lastAgreement: SerializableProperty<User, ClientTermsAndConditionsAgreement?> {
    override val name: String = "lastAgreement"
    override fun get(receiver: User): ClientTermsAndConditionsAgreement? = receiver.lastAgreement
    override fun setCopy(receiver: User, value: ClientTermsAndConditionsAgreement?) = receiver.copy(lastAgreement = value)
    override val serializer: KSerializer<ClientTermsAndConditionsAgreement?> = ClientTermsAndConditionsAgreement.serializer().nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("lastAgreement")
    override val default: ClientTermsAndConditionsAgreement? = null
}
