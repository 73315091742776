package com.crowpay.views.components.project

import com.crowpay.actuals.AppDimensions
import com.crowpay.ChangeRequest
import com.crowpay.Project
import com.crowpay.actuals.rotation
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.components.label2
import com.crowpay.views.components.files.renderFiles
import com.crowpay.views.components.space
import com.crowpay.views.theming.body
import com.crowpay.views.theming.lightSection
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun ViewWriter.projectDetails(
    project: Readable<Project>,
    descriptionHistory: Readable<List<ChangeRequest>>,
    lens: ProjectLens,
) {

    col {
        spacing = AppDimensions.majorColumnSpacing

        space(0.dp)

        title("Info")
        row {
            spacing = 0.dp
            space(AppDimensions.backgroundIndent)
            lightSection - padded - expanding - row {
                spacing = 1.rem
                space(0.dp)
                expanding - col {

                    label2("Description") {
                        body {
                            ::content{
                                project().description
                            }
                        }
                    }

                    col {
                        spacing = AppDimensions.cornerRadii
                        existsDefaultFalse { descriptionHistory().isNotEmpty() }
                        val expanded = Property(false)

                        buttonTheme - button {
                            row {
                                space(AppDimensions.fullIndent - (AppDimensions.buttonSpacing * 2))
                                bold - centered - body("Change History")
                                centered - icon(Icon.chevronRight, "Open/Close Change History") {
                                    ::rotation{
                                        if (expanded())
                                            (-90f).degrees
                                        else
                                            90f.degrees
                                    }
                                }
                                onClick {
                                    expanded.value = !expanded.value
                                }
                            }
                        }

                        separator()

                        onlyWhen { expanded() } - padded - stack {
                            spacing = AppDimensions.fullIndent
                            col {
                                forEach(descriptionHistory) { historical ->
                                    val description = historical.projectDescription ?: return@forEach
                                    val time = historical.accepted?.timestamp ?: return@forEach
                                    body(
                                        "${
                                            time.toLocalDateTime(TimeZone.currentSystemDefault()).date.renderToString(
                                                RenderSize.Numerical
                                            )
                                        }: $description"
                                    )
                                    separator()
                                }
                            }
                        }

                        space(0.dp)
                    }

                    if (lens == ProjectLens.Contractor) col {
                        label2("Contractor Notes (Visible only to you)") {
                            body {
                                ::content{
                                    if (lens == ProjectLens.Contractor) project().privateNotes else ""
                                }
                            }
                        }

                        space(0.dp)
                    }


                    label2("Project Files (Images, Bids, Plans, Specs)") {
                        renderFiles(shared { project().files }, AppDimensions.largeImagePreview)
                    }
                }
                space(0.dp)
            }
            space(AppDimensions.backgroundIndent)
        }
    }
}
