package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.crowpay.LineItem
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.toNullableDouble
import com.crowpay.utils.validation.ValidatedWritable
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.utils.zeroToNull
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.fileManager
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme


class LineItemForm(
    val line: Draft<LineItem>,
    val files: Writable<List<FileData>>,
) : Validator(), Screen {

    private val _name: ValidatedWritable<String> = line
        .lens(
            get = { it.name },
            modify = { og, it -> og.copy(name = it) }
        )
        .validate { it.isNotBlank() }
    val name: Writable<String> get() = _name
    private val _description: ValidatedWritable<String> = line
        .lens(
            get = { it.description },
            modify = { og, it -> og.copy(description = it) }
        )
        .validate { it.isNotBlank() }
    val description: Writable<String> get() = _description
    private val _price: ValidatedWritable<Long> = line
        .lens(
            get = { it.price },
            modify = { og, it -> og.copy(price = it) }
        )
        .validate { it >= 0 }
    val price: Writable<Long> get() = _price

    override fun ViewWriter.render() {

        col {

            rowCollapsingToColumn(AppDimensions.thinCollapseWidth) {
                expanding - label2("Work Item Title") {
                    fieldTheme - validate(_name) - textField {
                        keyboardHints = KeyboardHints.title
                        hint = "Name"
                        content bind _name
                    }
                }
                expanding - label2("Price (Dollars)") {
                    fieldTheme - validate(_price) - numberInput {
                        keyboardHints = KeyboardHints.integer
                        hint = "Price"
                        content bind _price.zeroToNull()
                    }
                }
            }
            label2("Description") {
                fieldTheme - validate(_description) - textArea {
                    hint = "Description"
                    content bind _description
                } in sizeConstraints(minHeight = 7.rem)
            }

            col {
                label2(shared { "Files (Images, Specs, Info Related to Work this Item)" }) {
                    fieldTheme - fileManager(
                        files = files,
                        emptyText = "No Files Uploaded"
                    ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
                }
            }
        }
    }
}