package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.Action
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.share
import com.crowpay.utils.toggle
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.fullIndentCol
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.projectPunchList
import com.crowpay.views.screens.contractor.ContractorScreen
import com.crowpay.views.screens.contractor.MightBeContractorScreen
import com.crowpay.views.theming.subTitle
import com.crowpay.views.theming.title
import com.crowpay.views.theming.xlTitle
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.forEachUpdating
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.launch

@Routable("contractor/worklists")
class ContractorWorkLists : WorkListsScreen(ProjectLens.Contractor) {
    override val projects: Readable<List<Project>> = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition { (it.contractor eq notNullContractor()) },
                )
            )()
            .sortedWith(compareBy({ it.state }, { it.name }))
    }
}

@Routable("client/worklists")
class ClientWorkLists : WorkListsScreen(ProjectLens.Customer) {
    override val projects: Readable<List<Project>> = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition { it.customer eq session.userId },
                )
            )()
            .sortedWith(compareBy({ it.state }, { it.name }))
    }
}

abstract class WorkListsScreen(override val lens: ProjectLens = ProjectLens.Customer) : Screen, MightBeContractorScreen {
    abstract val projects : Readable<List<Project>>
    val projectIDs = shared { projects().map { it._id } }

    val lineItems = shared {
        notNullSession().lineItems
            .query(Query(
                condition = condition<LineItem> { it.project inside projectIDs() },
                orderBy = sort { it.order.ascending() }
            ))()
    }
    val punchLists = shared {
        notNullSession().punchLists.query(
            Query(
                condition { it.project inside projectIDs() },
                limit = Int.MAX_VALUE
            )
        )()
    }

    val expanded = shared {
        projectIDs().associateWith { Property(false) }
    }

    override fun ViewWriter.renderMainContent() {
        sizeConstraints(maxWidth = AppDimensions.pageWidth) - col {
            existsDefaultFalse { projects().isEmpty() }
            centered - subTitle("You have no work lists")
        }
        scrolls - unpadded - col {
            ::exists { projects().isNotEmpty() }
            xlTitle("Projects")
            sizeConstraints(maxWidth = AppDimensions.pageWidth) - col {
                spacing = 0.px
                forEachUpdating(projects) { proj ->
                    val line = lineItems.share { list -> list.filter { it.project == proj()._id } }
                    val punch = punchLists.share { list -> list.filter { it.project == proj()._id } }

                    col {
                        spacing = 0.px
                        button {
                            row {
                                centered - expandIcon { expanded()[proj()._id]?.invoke() ?: false }
                                title {
                                    ::content { proj().name }
                                }
                            }
                            action = Action("Expand Project List") {
                                expanded()[proj()._id]?.toggle()
                            }
                        }
                        onlyWhen { expanded()[proj()._id]?.invoke() ?: false } - fullIndentCol {
                            space()
                            projectPunchList(
                                proj,
                                line,
                                punch,
                                lens
                            )
                            space()
                        }
                        separator()
                    }
                }
            }
        }
    }
}