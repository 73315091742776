// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareAccessInfoFields() {
    val props: Array<SerializableProperty<AccessInfo, *>> = arrayOf(AccessInfo_user, AccessInfo_ip, AccessInfo_sessionId, AccessInfo_userAgent, AccessInfo_timestamp)
    AccessInfo.serializer().properties { props }
}
val <K> DataClassPath<K, AccessInfo>.user: DataClassPath<K, UUID> get() = this[AccessInfo_user]
val <K> DataClassPath<K, AccessInfo>.ip: DataClassPath<K, String> get() = this[AccessInfo_ip]
val <K> DataClassPath<K, AccessInfo>.sessionId: DataClassPath<K, UUID?> get() = this[AccessInfo_sessionId]
val <K> DataClassPath<K, AccessInfo>.userAgent: DataClassPath<K, String> get() = this[AccessInfo_userAgent]
val <K> DataClassPath<K, AccessInfo>.timestamp: DataClassPath<K, Instant> get() = this[AccessInfo_timestamp]
inline val AccessInfo.Companion.path: DataClassPath<AccessInfo, AccessInfo> get() = path<AccessInfo>()


object AccessInfo_user: SerializableProperty<AccessInfo, UUID> {
    override val name: String = "user"
    override fun get(receiver: AccessInfo): UUID = receiver.user
    override fun setCopy(receiver: AccessInfo, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = AccessInfo.serializer().tryFindAnnotations("user")
}
object AccessInfo_ip: SerializableProperty<AccessInfo, String> {
    override val name: String = "ip"
    override fun get(receiver: AccessInfo): String = receiver.ip
    override fun setCopy(receiver: AccessInfo, value: String) = receiver.copy(ip = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AccessInfo.serializer().tryFindAnnotations("ip")
}
object AccessInfo_sessionId: SerializableProperty<AccessInfo, UUID?> {
    override val name: String = "sessionId"
    override fun get(receiver: AccessInfo): UUID? = receiver.sessionId
    override fun setCopy(receiver: AccessInfo, value: UUID?) = receiver.copy(sessionId = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = AccessInfo.serializer().tryFindAnnotations("sessionId")
    override val default: UUID? = null
}
object AccessInfo_userAgent: SerializableProperty<AccessInfo, String> {
    override val name: String = "userAgent"
    override fun get(receiver: AccessInfo): String = receiver.userAgent
    override fun setCopy(receiver: AccessInfo, value: String) = receiver.copy(userAgent = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AccessInfo.serializer().tryFindAnnotations("userAgent")
}
object AccessInfo_timestamp: SerializableProperty<AccessInfo, Instant> {
    override val name: String = "timestamp"
    override fun get(receiver: AccessInfo): Instant = receiver.timestamp
    override fun setCopy(receiver: AccessInfo, value: Instant) = receiver.copy(timestamp = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = AccessInfo.serializer().tryFindAnnotations("timestamp")
}
