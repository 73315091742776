// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareDisputeLogFields() {
    val props: Array<SerializableProperty<DisputeLog, *>> = arrayOf(DisputeLog__id, DisputeLog_project, DisputeLog_accessInfo, DisputeLog_feedback, DisputeLog_filedForArbitration, DisputeLog_ended)
    DisputeLog.serializer().properties { props }
}
val <K> DataClassPath<K, DisputeLog>._id: DataClassPath<K, UUID> get() = this[DisputeLog__id]
val <K> DataClassPath<K, DisputeLog>.project: DataClassPath<K, UUID> get() = this[DisputeLog_project]
val <K> DataClassPath<K, DisputeLog>.accessInfo: DataClassPath<K, AccessInfo> get() = this[DisputeLog_accessInfo]
val <K> DataClassPath<K, DisputeLog>.feedback: DataClassPath<K, ClientFeedback?> get() = this[DisputeLog_feedback]
val <K> DataClassPath<K, DisputeLog>.filedForArbitration: DataClassPath<K, FiledForArbitration?> get() = this[DisputeLog_filedForArbitration]
val <K> DataClassPath<K, DisputeLog>.ended: DataClassPath<K, AccessInfo> get() = this[DisputeLog_ended]
inline val DisputeLog.Companion.path: DataClassPath<DisputeLog, DisputeLog> get() = path<DisputeLog>()


object DisputeLog__id: SerializableProperty<DisputeLog, UUID> {
    override val name: String = "_id"
    override fun get(receiver: DisputeLog): UUID = receiver._id
    override fun setCopy(receiver: DisputeLog, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object DisputeLog_project: SerializableProperty<DisputeLog, UUID> {
    override val name: String = "project"
    override fun get(receiver: DisputeLog): UUID = receiver.project
    override fun setCopy(receiver: DisputeLog, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("project")
}
object DisputeLog_accessInfo: SerializableProperty<DisputeLog, AccessInfo> {
    override val name: String = "accessInfo"
    override fun get(receiver: DisputeLog): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: DisputeLog, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> = AccessInfo.serializer()
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("accessInfo")
}
object DisputeLog_feedback: SerializableProperty<DisputeLog, ClientFeedback?> {
    override val name: String = "feedback"
    override fun get(receiver: DisputeLog): ClientFeedback? = receiver.feedback
    override fun setCopy(receiver: DisputeLog, value: ClientFeedback?) = receiver.copy(feedback = value)
    override val serializer: KSerializer<ClientFeedback?> = ClientFeedback.serializer().nullable2
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("feedback")
    override val default: ClientFeedback? = null
}
object DisputeLog_filedForArbitration: SerializableProperty<DisputeLog, FiledForArbitration?> {
    override val name: String = "filedForArbitration"
    override fun get(receiver: DisputeLog): FiledForArbitration? = receiver.filedForArbitration
    override fun setCopy(receiver: DisputeLog, value: FiledForArbitration?) = receiver.copy(filedForArbitration = value)
    override val serializer: KSerializer<FiledForArbitration?> = FiledForArbitration.serializer().nullable2
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("filedForArbitration")
    override val default: FiledForArbitration? = null
}
object DisputeLog_ended: SerializableProperty<DisputeLog, AccessInfo> {
    override val name: String = "ended"
    override fun get(receiver: DisputeLog): AccessInfo = receiver.ended
    override fun setCopy(receiver: DisputeLog, value: AccessInfo) = receiver.copy(ended = value)
    override val serializer: KSerializer<AccessInfo> = AccessInfo.serializer()
    override val annotations: List<Annotation> = DisputeLog.serializer().tryFindAnnotations("ended")
}
