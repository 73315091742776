package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.contains
import com.crowpay.views.theming.alphaTheme
import com.crowpay.views.theming.title
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon


class ImagePreview(val images: List<Attachment>, startingIndex: Int = 0) : Screen {

    val index = Property(startingIndex)

    override fun ViewWriter.render() {
        dismissBackground {
            spacing = 0.dp

            stack {
                spacing = AppDimensions.cornerRadii
                themeChoice += ThemeDerivation {
                    it.copy(
                        background = AppColors.grey.light1,
                        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii)
                    ).withoutBack
                }

                zoomableImage {
                    scaleType = ImageScaleType.Fit
                    ::visible{
                        images.getOrNull(index())?.fileType?.startsWith("image") == true
                    }
                    ::source{
                        images.getOrNull(index())?.file?.location?.let { ImageRemote(it) }
                            ?: ImageRemote(SharedUtils.shrugImage)
                    }
                }

                centered - externalLink {
                    ::visible{
                        images.getOrNull(index())?.fileType?.startsWith("image") == false
                    }
                    h3("Open File")
                    ::to{ images.getOrNull(index())?.file?.location ?: "" }
                    this.newTab = true
                } in themeFromLast(alphaTheme)

                title {
                    ::exists{
                        images.getOrNull(index())?.name?.isNotBlank() == true
                    }
                    ::content{
                        images.getOrNull(index())?.name ?: ""
                    }
                } in
                        themeFromLast(alphaTheme) in
                        gravity(Align.Center, Align.Start)

                // Close Button
                buttonTheme - button {
                    centered - icon(Icon.close, "Close Preview")
                    onClick {
                        screenNavigator.dismiss()
                    }
                } in
                        gravity(Align.End, Align.Start) in
                        themeFromLast(alphaTheme)

                // Previous Button
                button {
                    spacing = 0.dp
                    exists = images.size > 1
                    centered - icon(Icon.chevronLeft, "Preview Image")
                    onClick {
                        val newIndex = index.await() - 1
                        if (newIndex < 0)
                            index.set(images.size - 1)
                        else
                            index.set(newIndex)
                    }

                } in
                        sizeConstraints(width = 3.rem, height = 6.rem) in
                        gravity(Align.Start, Align.Center) in
                        themeFromLast(alphaTheme)

                // Next Button
                button {
                    spacing = 0.dp
                    exists = images.size > 1
                    centered - icon(Icon.chevronRight, "Next Image")
                    onClick {
                        val newIndex = index.await() + 1
                        if (newIndex >= images.size)
                            index.set(0)
                        else
                            index.set(newIndex)
                    }
                } in
                        sizeConstraints(width = 3.rem, height = 6.rem) in
                        gravity(Align.End, Align.Center) in
                        themeFromLast(alphaTheme)

            }
            onClick { screenNavigator.dismiss() }
        }
    }
}