package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.toFileData
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.fileManager
import com.crowpay.views.components.label2
import com.crowpay.views.components.files.resolveProjectAttachments
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.fieldTheme
import com.lightningkite.lightningdb.modification

abstract class LineItemDialog(val lineItems: Readable<List<LineItem>>) : Screen, Validator() {
    val selectedLine = Property<LineItem?>(null)

    fun ViewWriter.selectLineItems() {
        label2("Line Item") {
            fieldTheme - select {
                bind(
                    edits = selectedLine,
                    lineItems,
                    { it?.name ?: "Select a line item" }
                )
            }
        }
    }

    abstract fun ViewWriter.form()

    override fun ViewWriter.render() {
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    form()
                }
            }
        }
    }
}

class RootCreateLogDialog(lineItems: Readable<List<LineItem>>) : LineItemDialog(lineItems) {

    val message = Property("")
    val files = Property<List<FileData>>(emptyList())

    override fun ViewWriter.form() {
        col {
            title("New Note")

            selectLineItems()

            editLineItemLog(null, selectedLine) {
                screenNavigator.dismiss()
            }

        }
    }
}

class CreateEditLogDialog(val itemLog: LineItemLog?, val item: Readable<LineItem>) : Screen {

    override fun ViewWriter.render() {

        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    title {
                        println(itemLog)
                        ::content{
                            println(itemLog)
                            if (itemLog == null) {
                                println("Log is null")
                                "New Note: ${item().name}"
                            } else {
                                println("Log is not null")
                                "Edit Note: ${item().name}"
                            }
                        }
                    }

                    editLineItemLog(itemLog, item) {
                        screenNavigator.dismiss()
                    }
                }
            }
        }
    }
}

fun ViewWriter.editLineItemLog(
    itemLog: LineItemLog?,
    item: Readable<LineItem?>,
    onFinish: () -> Unit,
) {

    val message = Property(itemLog?.message ?: "")
    val files = Property(itemLog?.files?.toFileData() ?: emptyList())

    col {

        label2("Message") {
            sizeConstraints(width = 40.rem, minHeight = 10.rem) - fieldTheme - textArea {
                content bind message
            }
        }

        label2("Files") {
            sizeConstraints(width = 40.rem) - fieldTheme - fileManager(
                files,
                "No Files Uploaded"
            ) { notNullSession().nonCached.lineItem::uploadFileForRequest }
        }

        row {
            spacing = AppDimensions.buttonRowSpacing
            primaryButton - button {
                ::enabled { item() != null && (message().isNotBlank() || files().isNotEmpty()) }

                specCenteredText(if (itemLog == null) "Create Note" else "Save Note")

                onClick {
                    val item = item() ?: return@onClick
                    val m = message()
                    val f = files().resolveProjectAttachments()

                    if (itemLog == null) {
                        notNullSession().lineItemLogs.insert(
                            LineItemLog(
                                lineItem = item._id,
                                project = item.project,
                                message = m,
                                files = f
                            )
                        )
                    } else {
                        notNullSession().lineItemLogs[itemLog._id].modify(
                            modification {
                                it.message assign m
                                it.files assign f
                            }
                        )
                    }

                    onFinish()
                }
            }
            textButton - button {
                specCenteredText("Go Back")
                onClick(action = onFinish)
            }
        }
    }

}

class PunchListItemDialog(lineItems: Readable<List<LineItem>>) : LineItemDialog(lineItems) {
    val name = Property("").validate { it.isNotBlank() }
    val required = Property(false)
    override fun ViewWriter.form() {
        sizeConstraints(width = 30.rem) - col {
            spacing = AppDimensions.sectionIndent
            title("New Punch List Item")

            selectLineItems()

            col {
                spacing = 1.rem

                fieldTheme - validate(name) - textInput {
                    hint = "Name"
                    content bind name
                }

                row {
                    centered - checkbox {
                        checked bind required
                    }
                    centered - body("Required")
                }
            }

            row {
                spacing = AppDimensions.buttonRowSpacing
                primaryButton - button {
                    ::enabled { allValid() }
                    specCenteredText("Save Punch Item")

                    onClick {
                        val n = name()
                        val r = required()

                        val punchItems = lineItems().map { li ->
                            PunchListItem(
                                lineItem = li._id,
                                project = li.project,
                                content = n,
                                required = r
                            )
                        }

                        notNullSession().punchLists.insert(punchItems)

                        screenNavigator.dismiss()
                    }
                }
                textButton - button {
                    specCenteredText("Go Back")
                    onClick { dialogScreenNavigator.clear() }
                }
            }
        }
    }
}
