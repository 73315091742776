package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.rotation
import com.crowpay.extensions.hash
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.lazy
import com.crowpay.utils.renderDollars
import com.crowpay.utils.toggle
import com.crowpay.views.components.*
import com.crowpay.views.dialogs.*
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.modification
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlin.coroutines.resume

@Serializable
enum class ProjectLens {
    Contractor,
    Customer,
    Preview
}

@Serializable
enum class ProjectSection {
    Details,
    Scope,
    PunchLists,
    SpecLibrary,
    Log,
}

fun ViewWriter.projectView(
    project: Readable<Project>,
    client: Readable<Client>,
    contractor: Readable<Contractor>,
    lineItems: Readable<List<LineItem>>,
    itemLogs: Readable<List<LineItemLog>>,
    punchLists: Readable<List<PunchListItem>>,
    changeOrders: Readable<List<ChangeRequest>>,
    lens: ProjectLens,
    selectedTab: Writable<ProjectSection>,
    onAccept: (suspend () -> Unit)? = null,
    onLogin: (suspend () -> Unit)? = null,
    onRegister: (suspend () -> Unit)? = null,
) {


    // Message Visibility Properties
    val needsFundingRequested = shared {
        val p = project()
        p.state == ProjectState.Accepted && p.fullFundingRequested == null && p.fundingNeeded == 0L
    }

    val itemsRequiringReview = shared {
        lineItems().filter { it.state in setOf(LineItemState.NotApproved, LineItemState.RequestingReview) }
    }

    val rejectedItems = shared {
        lineItems().filter { it.state == LineItemState.NotApproved }
    }

    val fundingNeeded = shared { project().fundingNeeded > 0 }

    val inSubstantialCompletion = shared {
        project().state == ProjectState.SubstantialCompletion
    }

    val pendingChangeRequests = shared {
        if (lens == ProjectLens.Customer) changeOrders().filter { it.approved == null } else emptyList()
    }

    val requestedComplete = shared {
        project().state == ProjectState.RequestingComplete
    }

    val jumpTo = Property<UUID?>(null)

    // Action Visibility Properties
    val canRequestComplete = shared { lens == ProjectLens.Contractor && inSubstantialCompletion() }
    val canAcceptComplete = shared { lens == ProjectLens.Customer && requestedComplete() }
    val canMakePayment = shared { lens == ProjectLens.Customer && fundingNeeded() }
    val canRequestFunding = shared { lens == ProjectLens.Contractor && needsFundingRequested() }
    val canCancelFundingRequest =
        shared { lens == ProjectLens.Contractor && project().state == ProjectState.AwaitingFunding }
//    val canFileDispute = shared {
//        lens == ProjectLens.Customer && run {
//            val p = project.await()
//            p.state < ProjectState.Disputed &&
//                    p.state >= ProjectState.InProgress
//        }
//    }
    val canAbandonProject = shared {
        val p = project()
        lens == ProjectLens.Contractor &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited)
    }
    val canCancelProject = shared {
        val p = project()
        p.state >= ProjectState.Accepted &&
                p.state <= ProjectState.AwaitingFunding
    }
    val canAcceptProject = shared {
        val p = project()
        lens == ProjectLens.Preview &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited) &&
                currentSession() != null
    }
    val anonymous = shared {
        val p = project()
        lens == ProjectLens.Preview &&
                (p.state == ProjectState.WaitingApproval || p.state == ProjectState.Invited) &&
                currentSession() == null
    }

    val pullMethods = BasicListenable()
    val needsPaymentMethods = sharedSuspending {
        rerunOn(pullMethods)
        println("Retrieving methods")
        notNullSession().nonCached.userAuth.getPaymentMethods().also { println("Got ${it.size} methods") }.isEmpty()
    }

    suspend fun requestFunds() {
        dialogScreenNavigator.navigate(
            GenericConfirmationDialog(
                header = "Request Funds?",
                message = "You are about to request funds from your client. Make sure you communicate with them to have the money deposited as quickly as possible.",
                confirmationText = "Request Funds",
                messageType = MessageType.Confirmation,
                onSubmit = {
                    if (it) {
                        val session = notNullSession()
                        val id = project.await()._id
                        session.nonCached.project.requestFunding(id)
                        session.projects[id].invalidate()
//                        delay(200)
                    }
                }
            )
        )
    }

    fun ViewWriter.actionBar() {
        //Action Bar
        sizeConstraints(minHeight = AppDimensions.actionBarHeight) - darkSection - stack {
            spacing = AppDimensions.backgroundIndent / 2
            row {
                spacing = AppDimensions.buttonRowSpacing

                space(AppDimensions.backgroundIndent / 2 - AppDimensions.buttonRowSpacing)

                secondaryButton - button {
                    existsDefaultFalse{ canCancelProject() }
                    specCenteredText("Cancel Project")
                    onClick {
                        when (lens) {
                            ProjectLens.Contractor -> {
                                val p = project.await()
                                val mainMessage =
                                    "Canceling a project often offends clients and may result in a bad review. If canceling is absolutely necessary you should communicate and notify client directly prior to clicking \"Cancel Project\" button."
//                                val message = if (p.balance > 0) {
//                                    "$mainMessage Money has already been added into the escrow so a cancellation fee will be charged to you."
//                                } else
//                                    mainMessage
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Cancel Project?",
                                        message = mainMessage,
                                        messageType = MessageType.Danger,
                                        confirmationText = "Cancel Project",
                                        onSubmit = { confirmed ->
                                            if (confirmed) {
                                                val session = notNullSession()
                                                session.nonCached.project.cancelProject(p._id)
                                                session.projects[p._id].invalidate()
                                            }
                                        }
                                    )
                                )
                            }

                            ProjectLens.Customer -> {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Cancel Project?",
                                        message = "Canceling a project delays your project and impacts the contractor who has invested into this project thus far. Reasons for cancellation should be serious, such as lack of professionalism or communication by the contractor; or a homeowner experiencing significant life changes or income loss.",
                                        messageType = MessageType.Danger,
                                        confirmationText = "Cancel Project",
                                        onSubmit = { confirmed ->
                                            if (confirmed) {
                                                val p = project.awaitOnce()
                                                val session = notNullSession()
                                                session.nonCached.project.cancelProject(p._id)
                                                session.projects[p._id].invalidate()
                                            }
                                        }
                                    )
                                )
                            }

                            ProjectLens.Preview -> return@onClick
                        }

                    }
                }

                secondaryButton - button {
                    existsDefaultFalse{ canAbandonProject() }
                    specCenteredText("Abandon Project")
                    onClick {
                        val p = project.await()
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Abandon Project?",
                                message = "Abandoning this project will archive it and any shared links will no longer work. Ensure you clearly communicate your reasons for this action.",
                                messageType = MessageType.Danger,
                                confirmationText = "Abandon Project",
                                onSubmit = { confirmed ->
                                    if (confirmed) {
                                        val session = notNullSession()
                                        session.nonCached.project.cancelProject(p._id)
                                        session.projects[p._id].invalidate()
                                        screenNavigator.goBack()
                                    }
                                }
                            )
                        )
                    }
                }


                if (lens == ProjectLens.Customer)
                    tertiaryButton - button {
                        existsDefaultFalse{ canAcceptComplete() }
                        specCenteredText("Not Ready")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "What's The Hold Up?",
                                    message = "Tell your contractor why you aren’t approving yet so he can respond and after he feels he’s fully ready, he will resubmit for approval.",
                                    confirmationText = "Hold Approval",
                                    messageType = MessageType.Danger,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            suspendCoroutineCancellable<Unit> { cont ->
                                                dialogScreenNavigator.navigate(
                                                    GenericFeedBackDialog(
                                                        header = "Project Feedback",
                                                        required = true,
                                                        allowRemoving = false,
                                                        onSubmit = { feedback ->
                                                            val session = notNullSession()
                                                            session.projects[project.await()._id].modify(modification {
                                                                it.feedback assign feedback?.let {
                                                                    ClientFeedback(
                                                                        message = it
                                                                    )
                                                                }
                                                            })
                                                            cont.resume(Unit)
                                                        },
                                                        onDismiss = {
                                                            cont.resume(Unit)
                                                        }
                                                    )
                                                )
                                                return@suspendCoroutineCancellable { dialogScreenNavigator.clear() }
                                            }
                                        }
                                    }
                                )
                            )
                        }
                    }

                if (lens == ProjectLens.Preview) {
                    expanding - space()

                    row {
                        existsDefaultFalse{ anonymous() }
                        spacing = AppDimensions.buttonSpacing
                        primaryButton - button {
                            specCenteredText("Register")

                            onClick {
                                onRegister?.invoke()
                            }

                        }
                        secondaryButton - button {
                            specCenteredText("Log In")

                            onClick {
                                onLogin?.invoke()
                            }

                        }

                    } in centered

                }
                expanding - space()

                if (lens == ProjectLens.Contractor)
                    primaryButton - button {
                        existsDefaultFalse{ canRequestComplete() }
                        specCenteredText("Request Final Completion")
                        onClick {
                            if (punchLists.await().any { it.required && it.complete == null }) {
                                dialogScreenNavigator.navigate(
                                    GenericDialog(
                                        message = "You still have required punch list items pending. You cannot request review until all required punch list items are complete.",
                                        onDismiss = { }
                                    )
                                )
                            } else {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        header = "Submit For Project Completion?",
                                        message = "Replace text with message about customer needing to approve final completion.",
                                        confirmationText = "Request Project Completion",
                                        messageType = MessageType.Confirmation,
                                        onSubmit = { confirmed ->
                                            if (confirmed) {
                                                val session = notNullSession()
                                                val id = project.await()._id
                                                session.nonCached.project.requestComplete(id)
                                                session.projects[id].invalidate()
                                                delay(200)
                                            }
                                        }
                                    ))
                            }
                        }
                    } in gravity(Align.End, Align.Center)

                if (lens == ProjectLens.Customer)
                    primaryButton - button {
                        existsDefaultFalse{ canAcceptComplete() }
                        specCenteredText("Accept Complete")
                        onClick {
                            val p = project.awaitOnce()
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = if (p.retention != null)
                                        "Accept & Pay Retention?"
                                    else
                                        "Accept Project Completion?",
                                    message = if (p.retention != null)
                                        "By accepting and paying retention, you are releasing the remaining funds and completing a project."
                                    else
                                        "By accepting you are confirming this project is complete with no major work items left to do.",
                                    confirmationText = if (p.retention != null)
                                        "Accept & Pay Retention"
                                    else
                                        "Accept Project Completion",
                                    messageType = MessageType.Confirmation,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            val session = notNullSession()
                                            val p = project.await()
                                            val lineItems = lineItems.await().toSet()
                                            val punchLists = punchLists.await().filter { it.required }.toSet()
                                            session.nonCached.project.acceptComplete(
                                                project.await()._id,
                                                p.hash(lineItems, punchLists)
                                            )
                                            session.projects[p._id].invalidate()
                                            delay(200)
                                        }
                                    }
                                ))
                        }
                    } in gravity(Align.End, Align.Center)

                primaryButton - button {
                    existsDefaultFalse { canRequestFunding() }
                    specCenteredText("Request Funds")
                    onClick(action = ::requestFunds)
                } in gravity(Align.End, Align.Center)


                buttonTheme - important - button {
                    existsDefaultFalse { canCancelFundingRequest() }
                    specCenteredText("Rescind Funds Request")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Rescind Funds Request?",
                                message = "You are about to cancel your request for funds. Make sure to communicate with your client why you are taking this action to prevent confusion.",
                                confirmationText = "Rescind Funds Request",
                                messageType = MessageType.Danger,
                                onSubmit = {
                                    if (it) {
                                        val session = notNullSession()
                                        val id = project.await()._id
                                        session.nonCached.project.cancelFundingRequest(id)
                                        session.projects[id].invalidate()
                                        delay(200)
                                    }
                                }
                            )
                        )
                    }
                } in gravity(Align.End, Align.Center)

                primaryButton - button {
                    existsDefaultFalse{ canMakePayment() }
                    specCenteredText("Deposit Escrow Funds")
                    onClick {
                        dialogScreenNavigator.navigate(MakePaymentDialog(project.await()))
                    }
                } in centered

                if (lens == ProjectLens.Preview)
                    buttonTheme - affirmative - button {
                        existsDefaultFalse { canAcceptProject() }
                        specCenteredText("Accept Project")

                        onClick {
                            onAccept?.invoke()
                        }

                    } in centered


                secondaryButton - button {
                    exists = false
                    existsDefaultFalse{ canAbandonProject() }
                    specCenteredText("Unpublish")
                    onClick {
                        dialogScreenNavigator.navigate(
                            GenericConfirmationDialog(
                                header = "Unpublish Project?",
                                message = "You are about to unpublish this project. Though it may viewable, the customer will no longer be able to accept the project. Are you sure?",
                                confirmationText = "Unpublish Project",
                                messageType = MessageType.Warning,
                                onSubmit = {
                                    if (it) {
                                        val p = project.awaitOnce()
                                        notNullSession()
                                            .projects[p._id]
                                            .modify(modification { it.published assign null })
                                        screenNavigator.replace(
                                            EditProject(
                                                p._id
                                            )
                                        )
                                    }
                                }
                            )
                        )
                    }
                }


                primaryButton - button {
                    exists = false
                    existsDefaultFalse{ canAbandonProject() }
                    specCenteredContent {
                        row {
                            centered - icon(Icon.copy.copy(width = 1.rem, height = 1.rem), "")
                            centered - body("Copy link")
                        }
                    }
                    onClick {
                        val link = notNullSession().nonCached.project.anonymousLink(project.awaitOnce()._id)
                        ExternalServices.setClipboardText(link)
                        dialogScreenNavigator.navigate(GenericDialog("Project Link Copied", {}))
                    }
                }

                space(AppDimensions.backgroundIndent / 2 - AppDimensions.buttonRowSpacing)
            }
        }
    }


    sizeConstraints(width = AppDimensions.pageWidth) - col {
        spacing = AppDimensions.majorColumnSpacing
        reactiveSuspending {
            if (jumpTo() != null)
                selectedTab.set(ProjectSection.Scope)
            launch {
                delay(500)
                jumpTo.set(null)
            }
        }

        rowCollapsingToColumn(AppDimensions.pageWidth) {
            spacing = AppDimensions.backgroundIndent
            col {
                title("Project")
                lightSection - row {
                    spacing = AppDimensions.sectionIndent / 2
                    space(0.dp)
                    col {
                        spacing = 0.35.rem
                        colored(AppColors.purple.dark) - xlTitle { ::content { project().name } }
                        subTitle {
                            ::content {
                                when (lens) {
                                    ProjectLens.Contractor -> client().name
                                    ProjectLens.Customer -> contractor().name
                                    ProjectLens.Preview -> contractor().name
                                }
                            }
                        }
                    }
                    space(0.dp)
                }
            }
            col {
                title("Status")
                lightSection - row {
                    spacing = AppDimensions.sectionIndent / 2
                    space(0.dp)
                    col {
                        spacing = 0.35.rem
                        xlTitle {
                            ::content{ project().state.displayValue }
                            dynamicTheme {
                                val p = project()
                                ThemeDerivation {
                                    stateTheme(it, Color.fromHexString(p.state.displayColor)).withoutBack
                                }
                            }
                        }
                        sizeConstraints(height = AppDimensions.subtitleTextSize / 0.73) - stack {

                            gravity(
                                horizontal = Align.Start,
                                vertical = Align.Center
                            ) - sizeConstraints(height = 1.rem, minWidth = 12.rem) - canvas {
                                existsDefaultFalse{
                                    project().state <= ProjectState.Warranty
                                }
                                reactiveScope {
                                    rerunOn(project)
                                    delegate?.invalidate?.invoke()
                                }
                                delegate = ProgressDelegate(
                                    color = project.lens { Color.fromHexString(it.state.displayColor) },
                                    progress = project.lens { it.state.ordinal.toFloat() / ProjectState.Complete.ordinal.toFloat() }
                                )
                            }
                        }
                    }
                    space(0.dp)
                }
            }
        }

        //Dispute bar
        col {
            exists = false
            ::exists{ project().dispute != null }

            body {
                content =
                    "There is an active dispute in progress. The project has been paused until the dispute is resolved."
            }

            label2("Feedback") {
                body {
                    ::content{
                        project().dispute?.feedback?.message?.takeIf { it.isNotBlank() }
                            ?: "No feedback provided"
                    }
                }
            }

            if (lens == ProjectLens.Customer)
                row {
                    expanding - space()

                    buttonTheme - important - button {
                        body("Update Feedback")
                        onClick {
                            val p = project.await()
                            dialogScreenNavigator.navigate(
                                GenericFeedBackDialog(
                                    header = "Update Dispute Feedback",
                                    initialFeedback = p.dispute?.feedback?.message,
                                    required = false,
                                    allowRemoving = false,
                                    onSubmit = { feedback ->
                                        val session = notNullSession()
                                        feedback?.let {
                                            session.nonCached.project.disputeFeedback(p._id, it)
                                            session.projects[p._id].invalidate()
                                        }
                                    },
                                    onDismiss = {}
                                ))
                        }
                    }

                    buttonTheme - important - button {
                        body("End Dispute")
                        onClick {
                            val p = project.await()
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "End Project Dispute?",
                                    message = "Once you end this dispute the project will no longer be on hold and can move forward. End the project dispute?",
                                    confirmationText = "End Dispute",
                                    messageType = MessageType.Confirmation,
                                    onSubmit = { confirmed ->
                                        if (confirmed) {
                                            val session = notNullSession()
                                            session.nonCached.project.endDispute(p._id)
                                            session.projects[p._id].invalidate()
                                            session.lineItems.totallyInvalidate()
                                        }
                                    },
                                )
                            )
                        }
                    } in gravity(Align.End, Align.Center)
                }

        } in danger

        //Message Bar
        notificationBar(AppColors.blue) - body {
            exists = false
            ::exists{ anonymous() }
            content = "Welcome! Please sign in or register to securely view and accept your project!"
        }

        notificationBar(AppColors.blue) - body {
            exists = false
            ::exists{ canAcceptProject() }
            ::content{
                val title = contractor().preferredTitle.name.lowercase()
                "Accepting a project awards the project to the $title and allows your $title to send you a payment request."
            }
        }

        notificationBar(AppColors.blue) - body {
            exists = false
            ::exists{ needsFundingRequested() }

            ::content{
                if (lens == ProjectLens.Customer) {
                    val c = contractor()
                    "Congratulations! You've awarded the job to ${c.name}. They have been notified of your acceptance and will request project funding within 10 business days before anticipated project start. You are now officially on your ${c.preferredTitle}'s schedule."
                } else {
                    "Congratulations - you've got work! ${client().name} has awarded you \"${project().name}\". Don't forget to request funds before starting."
                }
            }
        }

        notificationBar(AppColors.blue) - row {
            exists = false
            ::exists{ project().state == ProjectState.TransferringFunds }
            sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                Icon.notify,
                "Transferring Funds Info"
            )
            body("Insert \"Project Locked\" Notice Here")
        }

        notificationBar(AppColors.blue) - body {
            exists = false
            ::exists{ project().state == ProjectState.PendingStart }
            content =
                if (lens == ProjectLens.Customer) "Insert “Homeowner Project Start” Notice -\nContractor will start work item to trigger In progress, but is not  the same as on-site"
                else "Insert \"Get Started Best Practices for Contractor\" Encourage Contractor to get started and make sure and communicate to homeowner. Many homeowners get worried if you aren't on site, but are prepping, etc. Explain to them your process."
        }


        notificationBar(AppColors.blue) - col {
            exists = false
            ::exists{ fundingNeeded() }
            if (lens == ProjectLens.Contractor)
                body {
                    existsDefaultFalse{ project().state == ProjectState.AwaitingFunding }
                    content =
                        "Full funding has been requested for the project. No money has been transferred yet. You may cancel the request."
                }
            body {
                ::content{ "Funding Needed: ${project().fundingNeeded.renderDollars()}" }
            }
        }

        if (lens == ProjectLens.Customer)
            notificationBar(AppColors.blue) - row {
                exists = false
                ::exists{ inSubstantialCompletion() }
                sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                    Icon.notify,
                    "Substantial Completion Info"
                )
                body {
                    content =
                        "All line items are finished. The project is undergoing final checks, reviews, or warranty periods. Your contractor will send you a request for final completion."
                }
            }

        if (lens == ProjectLens.Customer)
            notificationBar(AppColors.blue) - row {
                exists = false
                ::exists{ requestedComplete() }
                sizeConstraints(height = 1.rem, width = 1.rem) - centered - icon(
                    Icon.notify,
                    "Request Completion Info"
                )
                body {
                    content = "Accepting a project will release all funds and mark it as \"complete\""
                }
            }

        if (lens == ProjectLens.Customer)
            notificationBar(AppColors.blue) - body {
                exists = false
                ::exists{ project().state == ProjectState.Complete }
                content = "Completion Congratulations Message"
            }


        //TO-DO Bar
        col {
            spacing = 0.dp
            exists = false
            ::exists{
                canAcceptProject() ||
                        canMakePayment() ||
                        canRequestFunding() ||
                        (lens == ProjectLens.Customer && itemsRequiringReview().isNotEmpty()) ||
                        (lens == ProjectLens.Contractor && rejectedItems().isNotEmpty()) ||
                        (lens == ProjectLens.Contractor && project().state == ProjectState.PendingStart) ||
                        pendingChangeRequests().isNotEmpty()
            }
            title {
                ::content{
                    "To-Do (${
                        listOf(
                            canAcceptProject(),
                            canMakePayment(),
                            canRequestFunding(),
                            if (canMakePayment()) needsPaymentMethods.await() else false,
                            project().state == ProjectState.PendingStart,
                            *itemsRequiringReview().map { true }.toTypedArray(),
                            *rejectedItems().map { true }.toTypedArray(),
                            *pendingChangeRequests().map { true }.toTypedArray(),
                        )
                            .filter { it }
                            .size
                    })"
                }
            }

            space(AppDimensions.sectionIndent)

            row {
                spacing = 0.dp

                space(AppDimensions.backgroundIndent)
                expanding - col {

                    if (lens == ProjectLens.Preview)
                        toDoSection(Color.fromHexString(ProjectState.WaitingApproval.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ canAcceptProject() }
                            col {

                                themeChoice = ThemeDerivation { appTheme.withoutBack }

                                notificationBar(AppColors.yellow) - body { ::content{ "Make sure to review all project details carefully. Changes after acceptance will need both yours and the ${contractor().preferredTitle.name.lowercase()}'s agreement" } }

                                subTitle {
                                    ::content{ "Accept Project: ${project().name} ${project().price.renderDollars()}" }
                                }
                                row {
                                    spacing = AppDimensions.buttonSpacing

                                    primaryButton - button {
                                        specCenteredText("Accept Project")

                                        onClick { onAccept?.invoke() }
                                    }

                                    secondaryButton - button {
                                        specCenteredText("Review Project")

                                        onClick {
                                            selectedTab.set(ProjectSection.Details)
                                            // TODO: scroll details tab into view
                                        }
                                    }
                                }
                            }
                        }


                    if (lens == ProjectLens.Customer) {
                        toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse { lens == ProjectLens.Customer && project().state >= ProjectState.Accepted && needsPaymentMethods() }
                            col {
                                notificationBar(AppColors.yellow) - body("You need to connect your bank account before you can deposit money into the escrow.")

                                subTitle("Connect Bank Account")
                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Connect Bank Account")
                                    onClick {
                                        dialogScreenNavigator.navigate(SetupMethodDialog {
                                            println("Inside SetupMethodDialog onComplete")
                                            pullMethods.invokeAll()
                                        })
                                    }
                                }
                            }
                        }

                        toDoSection(Color.fromHexString(ProjectState.AwaitingFunding.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ canMakePayment() }
                            col {

                                notificationBar(AppColors.yellow) - body("Insert Deposit escrow warning, if any.")

                                subTitle("Funds Requested")
                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Deposit Escrow Funds")
                                    onClick {
                                        dialogScreenNavigator.navigate(MakePaymentDialog(project.await()))
                                    }
                                }
                            }
                        }

                        col {
                            existsDefaultFalse{ itemsRequiringReview().isNotEmpty() }
                            forEach(itemsRequiringReview) { line ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle("Approve Work Item \"${line.name}\"")
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("Go To Item")
                                            onClick {
                                                jumpTo.set(line._id)
                                                delay(500)
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        col {
                            existsDefaultFalse{ pendingChangeRequests().isNotEmpty() }
                            forEach(pendingChangeRequests) { change ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle("Approve Change Order \"${change.title}\"")
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("Go To Item")
                                            onClick {
                                                jumpTo.set(change._id)
                                                delay(500)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    if (lens == ProjectLens.Contractor) {
                        toDoSection(Color.fromHexString(ProjectState.Accepted.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ canRequestFunding() }
                            col {

                                notificationBar(AppColors.yellow) - body("We’ve notified your client that funds should not be requested more than 10 days before site arrival or material purchases. Funds take 2-3 days to clear.  Plan accordingly.")

                                subTitle("Funds Needed")
                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Request Funds")
                                    onClick(action = ::requestFunds)
                                }
                            }
                        }

                        toDoSection(Color.fromHexString(ProjectState.PendingStart.displayColor)) - stack {
                            spacing = AppDimensions.sectionIndent
                            existsDefaultFalse{ project().state == ProjectState.PendingStart }
                            col {

                                notificationBar(AppColors.yellow) - body("Insert Prompt Action Warning: id. Starting with in 10 days is highly advised. This sets a positive expectation for the homeowner that you are prompt and professional. Remember that the homeowner can not see you ordering supplies so we recommend you start a work item and let the homeowner know what steps you have teaken to start said project.")

                                gravity(Align.Start, Align.Start) - primaryButton - button {
                                    specCenteredText("Start Work Items")
                                    onClick {
                                        dialogScreenNavigator.navigate(
                                            StartWorkItemsDialog(project(), lineItems())
                                        )
                                    }
                                }
                            }
                        }

                        col {
                            ::exists{ rejectedItems().isNotEmpty() }
                            forEach(rejectedItems) { line ->
                                toDoSection(Color.fromHexString(ProjectState.InProgress.displayColor)) - stack {
                                    spacing = AppDimensions.sectionIndent
                                    col {
                                        subTitle("Rejected Work Item \"${line.name}\"")
                                        gravity(Align.Start, Align.Start) - primaryButton - button {
                                            specCenteredText("Go To Item")
                                            onClick {
                                                jumpTo.set(line._id)
                                                delay(500)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                space(AppDimensions.backgroundIndent)
            }
        }

        actionBar()

        col {
            spacing = 0.px
            rowCollapsingToColumn(AppDimensions.pageWidth) {
                expanding - col {
                    col {
                        spacing = AppDimensions.cornerRadii
                        val expanded = Property(lens == ProjectLens.Preview)
                        buttonTheme - button {
                            row {
                                centered - title("Project Information")
                                centered - icon(Icon.chevronRight, "Open/Close Project Information") {
                                    ::rotation{
                                        if (expanded())
                                            (-90f).degrees
                                        else
                                            90f.degrees
                                    }
                                }
                            }
                            onClick {
                                expanded.toggle()
                            }
                        }
                        separator()
                        onlyWhen { expanded() } - col {
                            keyValue("Project Name") { project().name }
                            keyValue("Address") { project().address.toString() }
                            keyValue("Location") { project().location?.takeIf { it.isNotBlank() } }
                        }
                        space()
                    }
                    if (lens != ProjectLens.Contractor)
                        col {
                            spacing = AppDimensions.cornerRadii
                            val expanded = Property(lens == ProjectLens.Preview)
                            buttonTheme - button {
                                row {
                                    centered - title {
                                        ::content{ "${contractor().preferredTitle} Information" }
                                    }
                                    centered - icon {
                                        source = Icon.chevronRight
                                        ::description{ "Open/Close ${contractor().preferredTitle} Information" }
                                        ::rotation{
                                            if (expanded())
                                                (-90f).degrees
                                            else
                                                90f.degrees
                                        }
                                    }
                                }
                                onClick {
                                    expanded.value = !expanded.value
                                }
                            }
                            separator()
                            onlyWhen { expanded() } - col {
                                keyValue("Name") { contractor().name }
                                keyValue("Address") { contractor().address.toString() }
                                keyValue("Email") { contractor().email }
                                keyValue("Phone") { PhoneNumberFormat.USA.format(contractor().phoneNumber.filter { it.isDigit() }) }
                            }
                            space()
                        }
                }
                expanding - col {
                    spacing = AppDimensions.cornerRadii
                    existsDefaultFalse { !anonymous() }
                    val expanded = Property(lens == ProjectLens.Preview)
                    buttonTheme - button {
                        row {
                            centered - title("Billing Information")
                            centered - icon(Icon.chevronRight, "Open/Close Billing Information") {
                                ::rotation{
                                    if (expanded())
                                        (-90f).degrees
                                    else
                                        90f.degrees
                                }
                            }
                        }
                        onClick {
                            expanded.value = !expanded.value
                        }
                    }
                    separator()
                    onlyWhen { expanded() } - col {
                        keyValue("Name") { client().name }
                        keyValue("Address") { client().address.toString() }
                        keyValue("Email") { client().email }
                        keyValue("Phone") { PhoneNumberFormat.USA.format(client().phoneNumber.filter { it.isDigit() }) }
                    }
                }
            }

            if (lens != ProjectLens.Preview) col {
                spacing = 0.dp
                title("Project Ledger")

                space(AppDimensions.sectionIndent)

                row {
                    spacing = 0.dp
                    space(AppDimensions.backgroundIndent)

                    expanding - col {
                        spacing = 0.5.rem

                        fun ViewWriter.tableItem(
                            key: ReactiveContext.() -> String,
                            description: (ReactiveContext.() -> String)? = null,
                            value: ReactiveContext.() -> String,
                        ) {
                            col {
                                spacing = 0.dp
                                row {
                                    expanding - body { ::content { key() } }
                                    body { ::content { value() } }
                                }
                                separator()

                                if (description != null) {
                                    sizeConstraints(height = 1.9.rem) - smallBody { ::content { "(${description()})" } }
                                } else space(1.5.rem)
                            }
                        }

                        fun ViewWriter.tableItem(
                            key: String,
                            description: String? = null,
                            value: ReactiveContext.() -> String,
                        ) =
                            tableItem({ key }, description?.let { { it } }, value)

                        rowCollapsingToColumn(AppDimensions.wideCollapseWidth) {
                            spacing = 1.rem
                            space(15.dp)

                            expanding - bodyBold("Cost Breakdown")

                            space(2.rem)

                            expanding - bodyBold("Funds Summary")

                            space(15.dp)
                        }
                        lightSection - rowCollapsingToColumn(AppDimensions.wideCollapseWidth) {
                            spacing = 1.rem
                            space(0.dp)

                            expanding - col {
                                spacing = 0.dp
                                tableItem("Original Project Price") { "Under Construction" }
                                tableItem("Accepted Change Amount") { "Under Construction" }
                                tableItem("Active Price") { project().price.renderDollars() }
                                tableItem(
                                    key = { "Retention: ${project().retention ?: 0}%" },
                                    description = { "Hold % to be collected at completion. Extra client security." },
                                    value = {
                                        val p = project()
                                        p.retention?.let {
                                            val r = (it/100) * p.price
                                            r.toLong().renderDollars()
                                        } ?: "N/A"
                                    }
                                )
                            }

                            space(2.rem)

                            expanding - col {
                                spacing = 0.dp

                                tableItem("Escrow Deposits") {
                                    project().run { balance + contractorReceived }.renderDollars()
                                }
                                tableItem(
                                    key = { "Contractor Received" },
                                    description = { "Payments to the contractor for completed Work Items" },
                                    value = { project().contractorReceived.renderDollars() }
                                )
                                tableItem("Escrow Balance") { project().balance.renderDollars() }
                                tableItem("Percent Complete") {
                                    val p = project()
                                    ((p.balance + p.contractorReceived)
                                        .takeIf { it != 0L }
                                        ?.let { p.contractorReceived / it }
                                        ?.times(100)
                                        ?.toString() ?: "0") + "%"
                                }
//                                    tableItem(
//                                        "Pending Payments",
//                                        "Hold % to be collected at completion. Extra client security."
//                                    ) { project().pendingClientPayments.renderDollars() }
                            }

                            space(0.dp)
                        }
                    }

                    space(AppDimensions.backgroundIndent)
                }
            }
        }

        if (lens == ProjectLens.Preview) col {
            existsDefaultFalse { anonymous() }
            spacing = 0.dp
            title {
                ::content { project().name }
            }
            space(AppDimensions.backgroundIndent)
            row {
                spacing = 0.dp
                space(AppDimensions.fullIndent)
                body {
                    ::content {
                        val description = project().description
                        if (description.length > 200)
                            description.take(200) + "..."
                        else
                            description
                    }
                }
                space(AppDimensions.fullIndent)
            }
        }

        col {
            val expandPunchList = Property(false)
            existsDefaultFalse{
                val state = project().state
                state >= ProjectState.PendingStart
            }

            col {
                spacing = 0.dp
                buttonTheme - button {
                    spacing = 0.dp
                    row {
                        title("Punch List")

                        centered - icon(Icon.chevronRight, "Expanded") {
                            ::rotation{
                                if (expandPunchList())
                                    (90f).degrees
                                else
                                    0f.degrees
                            }
                        }
                    }
                    onClick {
                        expandPunchList.toggle()
                    }
                }
                smallBody("(Part of project closeout)")
            }

            val newItems = Property<List<Property<PunchListItem>>>(emptyList())

            row {
                existsDefaultFalse{
                    val state = project().state
                    state < ProjectState.Complete && lens == ProjectLens.Contractor
                }
                space(AppDimensions.fullIndent)
                tertiaryButton - button {
                    spacing = 0.3.rem
                        specCenteredText("+ Punch List Item")
                    onClick {
                        expandPunchList set true
                        newItems.value += Property(
                            PunchListItem(
                                project = project()._id,
                                content = "",
                                required = false
                            )
                        )
                    }
                }
            }

            onlyWhen { expandPunchList() } - col {
                row {
                    spacing = 0.dp
                    space(AppDimensions.fullIndent)
                    expanding - col {
                        punchListCol(
                            items = shared { punchLists().filter { it.lineItem == null || it.required } },
                            newItems = newItems,
                            lens = lens,
                            allowRequired = shared { project().state < ProjectState.Complete }
                        )
                    }
                    space(AppDimensions.backgroundIndent)
                }
            }
        }


        if (lens == ProjectLens.Customer)
            col {
                space()
                space()
                exists = false
                existsDefaultFalse{
                    val state = project().state
                    state >= ProjectState.PendingStart &&
                            state < ProjectState.Complete
                }

                titleLabel("Support") {
                    separator()
                }
                row {
                    spacing = AppDimensions.buttonRowSpacing
                    creamButton - button {
                        body("Question")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Have Questions",
                                    message = "Insert question message here",
                                    messageType = MessageType.Confirmation,
                                    confirmationText = "Insert question text here",
                                    onSubmit = {
                                        dialogScreenNavigator.dismiss()
                                    }
                                )
                            )
                        }
                    }
                    creamButton - button {
                        body("Concern")
                        onClick {
                            dialogScreenNavigator.navigate(
                                GenericConfirmationDialog(
                                    header = "Have a Concern",
                                    message = "Insert concern message here",
                                    messageType = MessageType.Confirmation,
                                    confirmationText = "Notify Contractor",
                                    onSubmit = {
                                        dialogScreenNavigator.dismiss()
                                    }
                                )
                            )
                        }
                    }
                }
            }

        col {
            existsDefaultFalse { !anonymous() }
            tabRow - row {
                expanding - centered - buttonTheme - gravity(
                    Align.Center,
                    Align.End
                ) - radioToggleButton {
                    checked bind selectedTab.equalTo(ProjectSection.Details)
                    subTitle {
                        align = Align.Center
                        content = "Details"
                    }
                }
                expanding - centered - buttonTheme - gravity(
                    Align.Center,
                    Align.End
                ) - radioToggleButton {
                    checked bind selectedTab.equalTo(ProjectSection.Scope)
                    subTitle {
                        align = Align.Center
                        content = "Scope"
                    }
                }
                expanding - centered - buttonTheme - radioToggleButton {
                    checked bind selectedTab.equalTo(ProjectSection.PunchLists)
                    subTitle {
                        align = Align.Center
                        content = "Work Lists"
                    }
                }
            }

            stack {

                lazy(AppState.windowInfo) { window ->
                    swapView {
                        swapping(
                            current = { selectedTab() },
                            views = { tab ->
                                when (tab) {
                                    ProjectSection.Details -> projectDetails(
                                        project,
                                        shared {
                                            changeOrders()
                                                .filter { it.approved == true && it.projectDescription != null }
                                        },
                                        lens
                                    )

                                    ProjectSection.Scope -> scopeV2(
                                        project,
                                        lineItems,
                                        itemLogs,
                                        punchLists,
                                        changeOrders,
                                        jumpTo,
                                        lens,
                                    )

                                    ProjectSection.PunchLists -> projectPunchList(
                                        project,
                                        lineItems,
                                        punchLists,
                                        lens
                                    )

                                    ProjectSection.SpecLibrary -> projectSpecLibrary(project, lineItems)
                                    ProjectSection.Log -> projectLog(project)
                                }
                            }
                        )
                    } in sizeConstraints(minHeight = window.height - AppDimensions.topNavHeight - (AppDimensions.fullIndent * 4) - AppDimensions.actionBarHeight)
                }
            }

        }

        actionBar()

    }
}