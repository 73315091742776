package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.ApiOption
import com.crowpay.sdk.UserSession
import com.crowpay.sdk.notNullSession
import com.crowpay.sdk.selectedApi
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.lazy
import com.crowpay.utils.renderDollars
import com.crowpay.utils.toggle
import com.crowpay.views.components.*
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.ProjectSection
import com.crowpay.views.components.project.projectView
import com.crowpay.views.screens.contractor.CreateProject
import com.crowpay.views.screens.contractor.EditProject
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.forms.FormModule
import com.lightningkite.kiteui.forms.form
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import com.lightningkite.uuid

private suspend fun safeSession(): UserSession {
    if (selectedApi() !in ApiOption.SAFE)
        throw IllegalStateException("Cannot access api ${selectedApi.awaitOnce()}!")

    return notNullSession()
}

private fun ReactiveContext.safeSession(): UserSession {
    if (selectedApi() !in ApiOption.SAFE)
        throw IllegalStateException("Cannot access api ${selectedApi.once()}!")

    return notNullSession()
}

@Routable("select-dev-project")
class SelectDevProjectScreen : Screen {
    val lens = Property(ProjectLens.Contractor)

    val projects = shared {
        val session = safeSession()
        val self = session.self()

        session.projects.query(
            Query(
                condition { (it.client eq self._id) or (it.contractor inside self.memberships.map { it.contractor }) },
                sort { it.name.ascending() }
            )
        )()
    }

    val contacts: Readable<List<Contact>> = shared {
        val session = safeSession()
        val self = session.self()
        val ps = projects()

        val contractors = session.contractors.query(
            Query(condition { it._id inside ps.map { it.contractor }.toSet() })
        )()
        val clients = session.clients.query(
            Query(condition { it._id inside ps.mapNotNull { it.client }.toSet() })
        )()

        contractors + clients
    }

    override fun ViewWriter.render() {
        col {
            spacing = 0.dp
            title("Dev Projects")

            space(AppDimensions.majorColumnSpacing)

            row {
                spacing = 0.dp
                space(AppDimensions.fullIndent - AppDimensions.buttonSpacing)
                primaryButton - button {
                    existsDefaultFalse { selectedContractor() != null }
                    specCenteredText("+ Project to current contractor")
                    onClick {
                        screenNavigator.navigate(CreateProject())
                    }
                }
            }

            space(AppDimensions.majorColumnSpacing)

            centered - subTitle {
                existsDefaultFalse{ projects().isEmpty() }
                content = "You have no projects"
            }

            expanding - recyclerView {
                children(projects) { project ->
                    val contact = shared {
                        val p = project()
                        contacts().find {
                            when (it) {
                                is Client -> {
                                    it._id == p.client
                                }

                                is Contractor -> {
                                    it._id == p.contractor
                                }

                                else -> false
                            }
                        }
                    }
                    sizeConstraints(width = AppDimensions.pageWidth) - row {
                        spacing = 0.dp
                        space(AppDimensions.backgroundIndent)

                        outlined - expanding - button {
                            spacing = AppDimensions.sectionIndent
                            onClick {
                                val p = project()
                                val to =
                                    if (p.state == ProjectState.Creating) EditProject(p._id)
                                    else DevProjectView(p._id)

                                screenNavigator.navigate(to)
                            }
                            col {
                                row {
                                    centered - subTitle {
                                        ::content { project().name }
                                    }

                                    separator()

                                    expanding -
                                            row {
                                                ::visible{ contact() != null }
                                                centered - image {
                                                    ::exists{ contact()?.image != null }
                                                    ::source{ ImageRemote(contact()?.image?.location ?: "") }
                                                } in sizeConstraints(width = 3.rem, height = 3.rem)

                                                centered - contactInfo(contact)
                                            }

                                    centered - icon(Icon.chevronRight, "Open")
                                }

                                separator()

                                row {
                                    expanding - col {
                                        col {
                                            exists = false
                                            ::exists{ project().fundingNeeded > 0 }
                                            keyValue("Funding Needed") { project().fundingNeeded.renderDollars() }
                                        }
                                        keyValue("Total Price") { project().price.renderDollars() }
                                        keyValue("Contractor Payments") { project().contractorReceived.renderDollars() }
                                        keyValue("Escrow Balance") { project().balance.renderDollars() }
                                    }

                                    body {
                                        ::content{ project().state.displayValue }
                                        ::themeChoice{
                                            val p = project()
                                            ThemeDerivation {
                                                stateTheme(it, Color.fromHexString(p.state.displayColor)).withoutBack
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        space(AppDimensions.backgroundIndent)
                    }
                }
            }
        }
    }
}

@Routable("dev-project/{project}")
class DevProjectView(val project: UUID) : Screen {
    val devProject = LazyProperty {
        safeSession().projects[project]() ?: throw IllegalArgumentException("Project Doesn't Exist")
    }
//    val scopeDraft = Draft{
//        LineItem(project = UUID.random())
//    }

    val devLens = LazyProperty {
        if (selectedContractor() != null) ProjectLens.Contractor else ProjectLens.Customer
    }
    val tab = Property(ProjectSection.Details)
    val devLineItem = Property<LineItem?>(null)

    // -- Dependents --
    val liveLineItems = shared {
        safeSession().lineItems
            .query(Query(
                condition = condition<LineItem> { it.project eq project },
                orderBy = sort { it.order.ascending() }
            ))()
            .also { println("Have new line items") }
    }
    val lineItems = Property<List<LineItem>>(emptyList())
    val itemLogs = shared {
        safeSession().lineItemLogs.query(
            Query(
                condition { it.project eq project },
                limit = Int.MAX_VALUE
            )
        )()
    }
    val client = shared {
        devProject().client?.let { notNullSession().clients[it]() } ?: Client(
            contractor = uuid(),
            name = "",
            email = "",
            phoneNumber = "",
            address = Address()
        )
    }
    val contractor = shared {
        notNullSession().contractors[devProject().contractor]() ?: Contractor(
            name = "",
            email = "",
            phoneNumber = "",
            address = Address(),
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactPhoneNumber = "",
            contactAddress = Address(),
            stateEntityNumber = "",
            ein = "",
        )
    }
    val punchLists = shared {
        notNullSession().punchLists.query(
            Query(
                condition { it.project eq project },
                limit = Int.MAX_VALUE
            )
        )()
    }
    val changeOrders = shared {
        notNullSession().changeRequests
            .query(
                Query(
                    condition { it.project eq project },
                    limit = Int.MAX_VALUE
                )
            )()
            .sortedWith(compareBy({ it.accepted?.timestamp }, { it.title }))
    }

    override fun ViewWriter.render() {
        row {

            reactiveScope {
                lineItems.value = liveLineItems()
            }
            reactiveScope {
                val temp = devLineItem() ?: return@reactiveScope
                val lines = lineItems()
                lineItems.value = lines
                    .indexOfFirst { it._id == temp._id }
                    .let { index ->
                        val mutable = lines.toMutableList()
                        mutable.removeAt(index)
                        mutable.add(index, temp)
                        mutable
                    }
            }

            scrolls - col {
                lazy(devLens) {
                    projectView(
                        devProject,
                        client,
                        contractor,
                        lineItems,
                        itemLogs,
                        punchLists,
                        changeOrders,
                        it,
                        tab
                    )
                }
            }
            separator()
            expanding - col {
                spacing = 0.px
                val showProjectOptions = Property(true)
                val showLineItemOptions = Property(true)

                row {
                    button {
                        spacing = 0.px
                        sizeConstraints(height = 4.rem) - row {
                            centered - expandIcon(showProjectOptions)
                            centered - title("Project Options")
                        }
                        onClick { showProjectOptions.toggle() }
                    }
                    button {
                        spacing = 0.25.rem
                        centered - icon(Icon.edit, "Edit project")
                        onClick {
                            dialogScreenNavigator.navigate(ProjectDevForm(devProject))
                        }
                    }
                }
                onlyWhen { showProjectOptions() } - padded - col {
                    label2("Lens") {
                        fieldTheme - select {
                            bind(devLens, Constant(ProjectLens.entries)) { it.name }
                        }
                    }
                    label2("Project State") {
                        fieldTheme - select {
                            bind(
                                edits = devProject.lensPath { it.state },
                                data = Constant(ProjectState.entries),
                                render = { it.displayValue }
                            )
                        }
                    }
                }

                space(AppDimensions.majorColumnSpacing)

                button {
                    spacing = 0.px
                    sizeConstraints(height = 4.rem) - row {
                        centered - expandIcon(showLineItemOptions)
                        centered - title("Work Scope Options")
                        centered - icon(Icon.edit, "Edit Work Scope Options")
                    }
                    onClick { showLineItemOptions.toggle() }
                }
                onlyWhen { showLineItemOptions() } - padded - col {
                    label2("Work Scope") {
                        fieldTheme - select {
                            bind(devLineItem, shared { listOf(null) + lineItems() }) { it?.name ?: "Select Line Item" }
                        }
                    }
                    label2("Work Scope State") {
                        fieldTheme - select {
                            bind(
                                edits = devLineItem.lens(
                                    get = { it?.state ?: LineItemState.NotApproved },
                                    modify = { og, it -> og?.copy(state = it) }
                                ),
                                data = Constant(LineItemState.entries),
                                render = { it.displayValue }
                            )
                        }
                    }
                }

            }
        }
    }

    private class ProjectDevForm(val project: Writable<Project>) : Screen {
        override fun ViewWriter.render() {
            dismissBackground {
                spacing = AppDimensions.fullIndent
                dialog - centered - scrolls - col {
                    val module = FormModule().apply {
                        typeInfo = { null }
                    }
                    form(module, Project.serializer(), project)
                }
            }
        }
    }
}