package com.crowpay.views.theming

import com.crowpay.LineItemState
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.Semantic
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.models.ThemeAndBack

data class LineItemStateSemantic(val state: LineItemState): Semantic {
    override val key: String = state.minified
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = Color.fromHexString(state.displayColor)
    ).withoutBack
}