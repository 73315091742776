// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectLedgerItemFields() {
    val props: Array<SerializableProperty<ProjectLedgerItem, *>> = arrayOf(ProjectLedgerItem__id, ProjectLedgerItem_project, ProjectLedgerItem_lineItem, ProjectLedgerItem_contractor, ProjectLedgerItem_amount, ProjectLedgerItem_intentId, ProjectLedgerItem_state, ProjectLedgerItem_message, ProjectLedgerItem_lastStateChange, ProjectLedgerItem_created)
    ProjectLedgerItem.serializer().properties { props }
}
val <K> DataClassPath<K, ProjectLedgerItem>._id: DataClassPath<K, UUID> get() = this[ProjectLedgerItem__id]
val <K> DataClassPath<K, ProjectLedgerItem>.project: DataClassPath<K, UUID> get() = this[ProjectLedgerItem_project]
val <K> DataClassPath<K, ProjectLedgerItem>.lineItem: DataClassPath<K, UUID?> get() = this[ProjectLedgerItem_lineItem]
val <K> DataClassPath<K, ProjectLedgerItem>.contractor: DataClassPath<K, Boolean> get() = this[ProjectLedgerItem_contractor]
val <K> DataClassPath<K, ProjectLedgerItem>.amount: DataClassPath<K, Long> get() = this[ProjectLedgerItem_amount]
val <K> DataClassPath<K, ProjectLedgerItem>.intentId: DataClassPath<K, IntentId?> get() = this[ProjectLedgerItem_intentId]
val <K> DataClassPath<K, ProjectLedgerItem>.state: DataClassPath<K, PaymentState> get() = this[ProjectLedgerItem_state]
val <K> DataClassPath<K, ProjectLedgerItem>.message: DataClassPath<K, String?> get() = this[ProjectLedgerItem_message]
val <K> DataClassPath<K, ProjectLedgerItem>.lastStateChange: DataClassPath<K, Instant> get() = this[ProjectLedgerItem_lastStateChange]
val <K> DataClassPath<K, ProjectLedgerItem>.created: DataClassPath<K, Instant> get() = this[ProjectLedgerItem_created]
inline val ProjectLedgerItem.Companion.path: DataClassPath<ProjectLedgerItem, ProjectLedgerItem> get() = path<ProjectLedgerItem>()


object ProjectLedgerItem__id: SerializableProperty<ProjectLedgerItem, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ProjectLedgerItem): UUID = receiver._id
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ProjectLedgerItem_project: SerializableProperty<ProjectLedgerItem, UUID> {
    override val name: String = "project"
    override fun get(receiver: ProjectLedgerItem): UUID = receiver.project
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("project")
}
object ProjectLedgerItem_lineItem: SerializableProperty<ProjectLedgerItem, UUID?> {
    override val name: String = "lineItem"
    override fun get(receiver: ProjectLedgerItem): UUID? = receiver.lineItem
    override fun setCopy(receiver: ProjectLedgerItem, value: UUID?) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("lineItem")
    override val default: UUID? = null
}
object ProjectLedgerItem_contractor: SerializableProperty<ProjectLedgerItem, Boolean> {
    override val name: String = "contractor"
    override fun get(receiver: ProjectLedgerItem): Boolean = receiver.contractor
    override fun setCopy(receiver: ProjectLedgerItem, value: Boolean) = receiver.copy(contractor = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("contractor")
    override val default: Boolean = false
}
object ProjectLedgerItem_amount: SerializableProperty<ProjectLedgerItem, Long> {
    override val name: String = "amount"
    override fun get(receiver: ProjectLedgerItem): Long = receiver.amount
    override fun setCopy(receiver: ProjectLedgerItem, value: Long) = receiver.copy(amount = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("amount")
    override val default: Long = 0
}
object ProjectLedgerItem_intentId: SerializableProperty<ProjectLedgerItem, IntentId?> {
    override val name: String = "intentId"
    override fun get(receiver: ProjectLedgerItem): IntentId? = receiver.intentId
    override fun setCopy(receiver: ProjectLedgerItem, value: IntentId?) = receiver.copy(intentId = value)
    override val serializer: KSerializer<IntentId?> = IntentId.serializer().nullable2
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("intentId")
    override val default: IntentId? = null
}
object ProjectLedgerItem_state: SerializableProperty<ProjectLedgerItem, PaymentState> {
    override val name: String = "state"
    override fun get(receiver: ProjectLedgerItem): PaymentState = receiver.state
    override fun setCopy(receiver: ProjectLedgerItem, value: PaymentState) = receiver.copy(state = value)
    override val serializer: KSerializer<PaymentState> = PaymentState.serializer()
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("state")
}
object ProjectLedgerItem_message: SerializableProperty<ProjectLedgerItem, String?> {
    override val name: String = "message"
    override fun get(receiver: ProjectLedgerItem): String? = receiver.message
    override fun setCopy(receiver: ProjectLedgerItem, value: String?) = receiver.copy(message = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("message")
    override val default: String? = null
}
object ProjectLedgerItem_lastStateChange: SerializableProperty<ProjectLedgerItem, Instant> {
    override val name: String = "lastStateChange"
    override fun get(receiver: ProjectLedgerItem): Instant = receiver.lastStateChange
    override fun setCopy(receiver: ProjectLedgerItem, value: Instant) = receiver.copy(lastStateChange = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("lastStateChange")
    override val default: Instant = now()
}
object ProjectLedgerItem_created: SerializableProperty<ProjectLedgerItem, Instant> {
    override val name: String = "created"
    override fun get(receiver: ProjectLedgerItem): Instant = receiver.created
    override fun setCopy(receiver: ProjectLedgerItem, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ProjectLedgerItem.serializer().tryFindAnnotations("created")
    override val default: Instant = now()
}
