package com.crowpay.views.screens.common

import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.selectedContractor
import com.crowpay.views.screens.auth.AuthScreen
import com.crowpay.views.screens.auth.ContractorRegistration
import com.crowpay.views.screens.auth.LogInScreen
import com.crowpay.views.screens.client.ClientDashboard
import com.crowpay.views.screens.contractor.ContractorDashboard
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

@Routable("/")
class LandingScreen() : Screen, AuthScreen {
    override fun ViewWriter.render() {
        // route the user to correct location
        stack {
            centered - sizeConstraints(width = 5.rem, height = 5.rem) - activityIndicator()
            launch {
                delay(500)
                try {
                    val self = notNullSession.await().self.await()
                    val selected = selectedContractor.await()
                    if (self.memberships.any { it.contractor == selected }) {
                        screenNavigator.reset(ContractorDashboard())
                    } else {
                        selectedContractor.set(null)
                        screenNavigator.reset(ClientDashboard())
                    }
                } catch (e: Exception) {
                    screenNavigator.reset(LogInScreen())
                }
            }
        }
    }
}