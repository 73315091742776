// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientTermsAndConditionsVersionFields() {
    val props: Array<SerializableProperty<ClientTermsAndConditionsVersion, *>> = arrayOf(ClientTermsAndConditionsVersion__id, ClientTermsAndConditionsVersion_establishedAt, ClientTermsAndConditionsVersion_contents)
    ClientTermsAndConditionsVersion.serializer().properties { props }
}
val <K> DataClassPath<K, ClientTermsAndConditionsVersion>._id: DataClassPath<K, Instant> get() = this[ClientTermsAndConditionsVersion__id]
val <K> DataClassPath<K, ClientTermsAndConditionsVersion>.establishedAt: DataClassPath<K, Instant> get() = this[ClientTermsAndConditionsVersion_establishedAt]
val <K> DataClassPath<K, ClientTermsAndConditionsVersion>.contents: DataClassPath<K, String> get() = this[ClientTermsAndConditionsVersion_contents]
inline val ClientTermsAndConditionsVersion.Companion.path: DataClassPath<ClientTermsAndConditionsVersion, ClientTermsAndConditionsVersion> get() = path<ClientTermsAndConditionsVersion>()


object ClientTermsAndConditionsVersion__id: SerializableProperty<ClientTermsAndConditionsVersion, Instant> {
    override val name: String = "_id"
    override fun get(receiver: ClientTermsAndConditionsVersion): Instant = receiver._id
    override fun setCopy(receiver: ClientTermsAndConditionsVersion, value: Instant) = receiver.copy(_id = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ClientTermsAndConditionsVersion.serializer().tryFindAnnotations("_id")
    override val default: Instant = now()
}
object ClientTermsAndConditionsVersion_establishedAt: SerializableProperty<ClientTermsAndConditionsVersion, Instant> {
    override val name: String = "establishedAt"
    override fun get(receiver: ClientTermsAndConditionsVersion): Instant = receiver.establishedAt
    override fun setCopy(receiver: ClientTermsAndConditionsVersion, value: Instant) = receiver.copy(establishedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ClientTermsAndConditionsVersion.serializer().tryFindAnnotations("establishedAt")
    override val default: Instant = now()
}
object ClientTermsAndConditionsVersion_contents: SerializableProperty<ClientTermsAndConditionsVersion, String> {
    override val name: String = "contents"
    override fun get(receiver: ClientTermsAndConditionsVersion): String = receiver.contents
    override fun setCopy(receiver: ClientTermsAndConditionsVersion, value: String) = receiver.copy(contents = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ClientTermsAndConditionsVersion.serializer().tryFindAnnotations("contents")
}
