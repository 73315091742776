// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareDisputeFields() {
    val props: Array<SerializableProperty<Dispute, *>> = arrayOf(Dispute_accessInfo, Dispute_feedback, Dispute_filedForArbitration)
    Dispute.serializer().properties { props }
}
val <K> DataClassPath<K, Dispute>.accessInfo: DataClassPath<K, AccessInfo> get() = this[Dispute_accessInfo]
val <K> DataClassPath<K, Dispute>.feedback: DataClassPath<K, ClientFeedback?> get() = this[Dispute_feedback]
val <K> DataClassPath<K, Dispute>.filedForArbitration: DataClassPath<K, FiledForArbitration?> get() = this[Dispute_filedForArbitration]
inline val Dispute.Companion.path: DataClassPath<Dispute, Dispute> get() = path<Dispute>()


object Dispute_accessInfo: SerializableProperty<Dispute, AccessInfo> {
    override val name: String = "accessInfo"
    override fun get(receiver: Dispute): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: Dispute, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> = AccessInfo.serializer()
    override val annotations: List<Annotation> = Dispute.serializer().tryFindAnnotations("accessInfo")
}
object Dispute_feedback: SerializableProperty<Dispute, ClientFeedback?> {
    override val name: String = "feedback"
    override fun get(receiver: Dispute): ClientFeedback? = receiver.feedback
    override fun setCopy(receiver: Dispute, value: ClientFeedback?) = receiver.copy(feedback = value)
    override val serializer: KSerializer<ClientFeedback?> = ClientFeedback.serializer().nullable2
    override val annotations: List<Annotation> = Dispute.serializer().tryFindAnnotations("feedback")
    override val default: ClientFeedback? = null
}
object Dispute_filedForArbitration: SerializableProperty<Dispute, FiledForArbitration?> {
    override val name: String = "filedForArbitration"
    override fun get(receiver: Dispute): FiledForArbitration? = receiver.filedForArbitration
    override fun setCopy(receiver: Dispute, value: FiledForArbitration?) = receiver.copy(filedForArbitration = value)
    override val serializer: KSerializer<FiledForArbitration?> = FiledForArbitration.serializer().nullable2
    override val annotations: List<Annotation> = Dispute.serializer().tryFindAnnotations("filedForArbitration")
    override val default: FiledForArbitration? = null
}
