// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectAttachmentFields() {
    val props: Array<SerializableProperty<ProjectAttachment, *>> = arrayOf(ProjectAttachment_name, ProjectAttachment_fileType, ProjectAttachment_file, ProjectAttachment_preview)
    ProjectAttachment.serializer().properties { props }
}
val <K> DataClassPath<K, ProjectAttachment>.name: DataClassPath<K, String> get() = this[ProjectAttachment_name]
val <K> DataClassPath<K, ProjectAttachment>.fileType: DataClassPath<K, String> get() = this[ProjectAttachment_fileType]
val <K> DataClassPath<K, ProjectAttachment>.file: DataClassPath<K, ServerFile> get() = this[ProjectAttachment_file]
val <K> DataClassPath<K, ProjectAttachment>.preview: DataClassPath<K, ServerFile?> get() = this[ProjectAttachment_preview]
inline val ProjectAttachment.Companion.path: DataClassPath<ProjectAttachment, ProjectAttachment> get() = path<ProjectAttachment>()


object ProjectAttachment_name: SerializableProperty<ProjectAttachment, String> {
    override val name: String = "name"
    override fun get(receiver: ProjectAttachment): String = receiver.name
    override fun setCopy(receiver: ProjectAttachment, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ProjectAttachment.serializer().tryFindAnnotations("name")
}
object ProjectAttachment_fileType: SerializableProperty<ProjectAttachment, String> {
    override val name: String = "fileType"
    override fun get(receiver: ProjectAttachment): String = receiver.fileType
    override fun setCopy(receiver: ProjectAttachment, value: String) = receiver.copy(fileType = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ProjectAttachment.serializer().tryFindAnnotations("fileType")
}
object ProjectAttachment_file: SerializableProperty<ProjectAttachment, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: ProjectAttachment): ServerFile = receiver.file
    override fun setCopy(receiver: ProjectAttachment, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = ProjectAttachment.serializer().tryFindAnnotations("file")
}
object ProjectAttachment_preview: SerializableProperty<ProjectAttachment, ServerFile?> {
    override val name: String = "preview"
    override fun get(receiver: ProjectAttachment): ServerFile? = receiver.preview
    override fun setCopy(receiver: ProjectAttachment, value: ServerFile?) = receiver.copy(preview = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = ProjectAttachment.serializer().tryFindAnnotations("preview")
    override val default: ServerFile? = null
}
