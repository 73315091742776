// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLicenseFields() {
    val props: Array<SerializableProperty<License, *>> = arrayOf(License__id, License_contractor, License_number, License_city, License_state, License_file, License_fileType, License_preview, License_type)
    License.serializer().properties { props }
}
val <K> DataClassPath<K, License>._id: DataClassPath<K, UUID> get() = this[License__id]
val <K> DataClassPath<K, License>.contractor: DataClassPath<K, UUID> get() = this[License_contractor]
val <K> DataClassPath<K, License>.number: DataClassPath<K, String> get() = this[License_number]
val <K> DataClassPath<K, License>.city: DataClassPath<K, String?> get() = this[License_city]
val <K> DataClassPath<K, License>.state: DataClassPath<K, State> get() = this[License_state]
val <K> DataClassPath<K, License>.file: DataClassPath<K, ServerFile> get() = this[License_file]
val <K> DataClassPath<K, License>.fileType: DataClassPath<K, String> get() = this[License_fileType]
val <K> DataClassPath<K, License>.preview: DataClassPath<K, ServerFile?> get() = this[License_preview]
val <K> DataClassPath<K, License>.type: DataClassPath<K, LicenseType> get() = this[License_type]
inline val License.Companion.path: DataClassPath<License, License> get() = path<License>()


object License__id: SerializableProperty<License, UUID> {
    override val name: String = "_id"
    override fun get(receiver: License): UUID = receiver._id
    override fun setCopy(receiver: License, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object License_contractor: SerializableProperty<License, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: License): UUID = receiver.contractor
    override fun setCopy(receiver: License, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("contractor")
}
object License_number: SerializableProperty<License, String> {
    override val name: String = "number"
    override fun get(receiver: License): String = receiver.number
    override fun setCopy(receiver: License, value: String) = receiver.copy(number = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("number")
}
object License_city: SerializableProperty<License, String?> {
    override val name: String = "city"
    override fun get(receiver: License): String? = receiver.city
    override fun setCopy(receiver: License, value: String?) = receiver.copy(city = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("city")
    override val default: String? = null
}
object License_state: SerializableProperty<License, State> {
    override val name: String = "state"
    override fun get(receiver: License): State = receiver.state
    override fun setCopy(receiver: License, value: State) = receiver.copy(state = value)
    override val serializer: KSerializer<State> = State.serializer()
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("state")
}
object License_file: SerializableProperty<License, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: License): ServerFile = receiver.file
    override fun setCopy(receiver: License, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("file")
}
object License_fileType: SerializableProperty<License, String> {
    override val name: String = "fileType"
    override fun get(receiver: License): String = receiver.fileType
    override fun setCopy(receiver: License, value: String) = receiver.copy(fileType = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("fileType")
}
object License_preview: SerializableProperty<License, ServerFile?> {
    override val name: String = "preview"
    override fun get(receiver: License): ServerFile? = receiver.preview
    override fun setCopy(receiver: License, value: ServerFile?) = receiver.copy(preview = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("preview")
    override val default: ServerFile? = null
}
object License_type: SerializableProperty<License, LicenseType> {
    override val name: String = "type"
    override fun get(receiver: License): LicenseType = receiver.type
    override fun setCopy(receiver: License, value: LicenseType) = receiver.copy(type = value)
    override val serializer: KSerializer<LicenseType> = LicenseType.serializer()
    override val annotations: List<Annotation> = License.serializer().tryFindAnnotations("type")
}
