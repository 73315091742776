package com.crowpay.views.screens.client

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.currentSession
import com.crowpay.views.dialogs.EditMethodDialog
import com.crowpay.views.dialogs.SetupMethodDialog
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch

class ClientPaymentMethodForm() : Screen {

    override val title: Readable<String> = Constant("Payment Methods")

    val allMethods = LateInitProperty<List<PaymentMethod>>()

    suspend fun retrievePaymentMethods() {
        allMethods.unset()
        allMethods.set(currentSession.await()?.nonCached?.userAuth?.getPaymentMethods() ?: emptyList())
    }

    override fun ViewWriter.render() {

        col {
            spacing = AppDimensions.fullIndent

            launch {
                retrievePaymentMethods()
            }

            centered - activityIndicator {
                reactiveScope {
                    exists = true
                    try {
                        allMethods()
                    } catch (_: Exception) {
                    }
                    exists = false
                }
            } in sizeConstraints(width = 40.rem)

            col {
                reactiveScope {
                    exists = false
                    try {
                        allMethods()
                    } catch (_: Exception) {
                    }
                    exists = true
                }
                row {
                    expanding - space()
                    tertiaryButton - button {
                        specCenteredText("+ Add Method")
                        onClick {
                            dialogScreenNavigator.navigate(SetupMethodDialog { retrievePaymentMethods() })
                        }
                    }
                }

                col {
                    forEach(shared { allMethods().chunked(2) }) { methods ->
                        val method1 = methods.first()
                        val method2 = methods.getOrNull(1)
                        fun ViewWriter.renderMethod(method: PaymentMethod) {
                            section - button {
                                spacing = AppDimensions.fullIndent
                                col {
                                    subTitle(method.name ?: " ")
                                    body(method.bank?.bankName ?: " ")
                                    body("*** *** ${method.bank?.accountNumber ?: " "}")
                                }
                                onClick {
                                    dialogScreenNavigator.navigate(
                                        EditMethodDialog(
                                            method,
                                            { retrievePaymentMethods() }
                                        )
                                    )
                                }
                            }
                        }
                        row {
                            expanding - stack {
                                spacing = 0.dp
                                renderMethod(method1)
                            }
                            expanding - stack {
                                spacing = 0.dp
                                method2?.also {
                                    renderMethod(it)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}