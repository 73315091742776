// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareChangeRequestFields() {
    val props: Array<SerializableProperty<ChangeRequest, *>> = arrayOf(ChangeRequest__id, ChangeRequest_project, ChangeRequest_title, ChangeRequest_projectDescription, ChangeRequest_newItems, ChangeRequest_itemChanges, ChangeRequest_feedback, ChangeRequest_published, ChangeRequest_accepted, ChangeRequest_denied, ChangeRequest_approved)
    ChangeRequest.serializer().properties { props }
}
val <K> DataClassPath<K, ChangeRequest>._id: DataClassPath<K, UUID> get() = this[ChangeRequest__id]
val <K> DataClassPath<K, ChangeRequest>.project: DataClassPath<K, UUID> get() = this[ChangeRequest_project]
val <K> DataClassPath<K, ChangeRequest>.title: DataClassPath<K, String> get() = this[ChangeRequest_title]
val <K> DataClassPath<K, ChangeRequest>.projectDescription: DataClassPath<K, String?> get() = this[ChangeRequest_projectDescription]
val <K> DataClassPath<K, ChangeRequest>.newItems: DataClassPath<K, List<LineItem>> get() = this[ChangeRequest_newItems]
val <K> DataClassPath<K, ChangeRequest>.itemChanges: DataClassPath<K, List<ItemChange>> get() = this[ChangeRequest_itemChanges]
val <K> DataClassPath<K, ChangeRequest>.feedback: DataClassPath<K, ClientFeedback?> get() = this[ChangeRequest_feedback]
val <K> DataClassPath<K, ChangeRequest>.published: DataClassPath<K, Instant?> get() = this[ChangeRequest_published]
val <K> DataClassPath<K, ChangeRequest>.accepted: DataClassPath<K, AccessInfo?> get() = this[ChangeRequest_accepted]
val <K> DataClassPath<K, ChangeRequest>.denied: DataClassPath<K, AccessInfo?> get() = this[ChangeRequest_denied]
val <K> DataClassPath<K, ChangeRequest>.approved: DataClassPath<K, Boolean?> get() = this[ChangeRequest_approved]
inline val ChangeRequest.Companion.path: DataClassPath<ChangeRequest, ChangeRequest> get() = path<ChangeRequest>()


object ChangeRequest__id: SerializableProperty<ChangeRequest, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ChangeRequest): UUID = receiver._id
    override fun setCopy(receiver: ChangeRequest, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ChangeRequest_project: SerializableProperty<ChangeRequest, UUID> {
    override val name: String = "project"
    override fun get(receiver: ChangeRequest): UUID = receiver.project
    override fun setCopy(receiver: ChangeRequest, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("project")
}
object ChangeRequest_title: SerializableProperty<ChangeRequest, String> {
    override val name: String = "title"
    override fun get(receiver: ChangeRequest): String = receiver.title
    override fun setCopy(receiver: ChangeRequest, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("title")
    override val default: String = ""
}
object ChangeRequest_projectDescription: SerializableProperty<ChangeRequest, String?> {
    override val name: String = "projectDescription"
    override fun get(receiver: ChangeRequest): String? = receiver.projectDescription
    override fun setCopy(receiver: ChangeRequest, value: String?) = receiver.copy(projectDescription = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("projectDescription")
    override val default: String? = null
}
object ChangeRequest_newItems: SerializableProperty<ChangeRequest, List<LineItem>> {
    override val name: String = "newItems"
    override fun get(receiver: ChangeRequest): List<LineItem> = receiver.newItems
    override fun setCopy(receiver: ChangeRequest, value: List<LineItem>) = receiver.copy(newItems = value)
    override val serializer: KSerializer<List<LineItem>> = ListSerializer(LineItem.serializer())
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("newItems")
    override val default: List<LineItem> = emptyList()
}
object ChangeRequest_itemChanges: SerializableProperty<ChangeRequest, List<ItemChange>> {
    override val name: String = "itemChanges"
    override fun get(receiver: ChangeRequest): List<ItemChange> = receiver.itemChanges
    override fun setCopy(receiver: ChangeRequest, value: List<ItemChange>) = receiver.copy(itemChanges = value)
    override val serializer: KSerializer<List<ItemChange>> = ListSerializer(ItemChange.serializer())
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("itemChanges")
    override val default: List<ItemChange> = emptyList()
}
object ChangeRequest_feedback: SerializableProperty<ChangeRequest, ClientFeedback?> {
    override val name: String = "feedback"
    override fun get(receiver: ChangeRequest): ClientFeedback? = receiver.feedback
    override fun setCopy(receiver: ChangeRequest, value: ClientFeedback?) = receiver.copy(feedback = value)
    override val serializer: KSerializer<ClientFeedback?> = ClientFeedback.serializer().nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("feedback")
    override val default: ClientFeedback? = null
}
object ChangeRequest_published: SerializableProperty<ChangeRequest, Instant?> {
    override val name: String = "published"
    override fun get(receiver: ChangeRequest): Instant? = receiver.published
    override fun setCopy(receiver: ChangeRequest, value: Instant?) = receiver.copy(published = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("published")
    override val default: Instant? = null
}
object ChangeRequest_accepted: SerializableProperty<ChangeRequest, AccessInfo?> {
    override val name: String = "accepted"
    override fun get(receiver: ChangeRequest): AccessInfo? = receiver.accepted
    override fun setCopy(receiver: ChangeRequest, value: AccessInfo?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("accepted")
    override val default: AccessInfo? = null
}
object ChangeRequest_denied: SerializableProperty<ChangeRequest, AccessInfo?> {
    override val name: String = "denied"
    override fun get(receiver: ChangeRequest): AccessInfo? = receiver.denied
    override fun setCopy(receiver: ChangeRequest, value: AccessInfo?) = receiver.copy(denied = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("denied")
    override val default: AccessInfo? = null
}
object ChangeRequest_approved: SerializableProperty<ChangeRequest, Boolean?> {
    override val name: String = "approved"
    override fun get(receiver: ChangeRequest): Boolean? = receiver.approved
    override fun setCopy(receiver: ChangeRequest, value: Boolean?) = receiver.copy(approved = value)
    override val serializer: KSerializer<Boolean?> = Boolean.serializer().nullable2
    override val annotations: List<Annotation> = ChangeRequest.serializer().tryFindAnnotations("approved")
    override val default: Boolean? = null
}
