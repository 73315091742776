// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientTermsAndConditionsAgreementFields() {
    val props: Array<SerializableProperty<ClientTermsAndConditionsAgreement, *>> = arrayOf(ClientTermsAndConditionsAgreement__id, ClientTermsAndConditionsAgreement_accessInfo, ClientTermsAndConditionsAgreement_version)
    ClientTermsAndConditionsAgreement.serializer().properties { props }
}
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>._id: DataClassPath<K, UUID> get() = this[ClientTermsAndConditionsAgreement__id]
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>.accessInfo: DataClassPath<K, AccessInfo> get() = this[ClientTermsAndConditionsAgreement_accessInfo]
val <K> DataClassPath<K, ClientTermsAndConditionsAgreement>.version: DataClassPath<K, Instant> get() = this[ClientTermsAndConditionsAgreement_version]
inline val ClientTermsAndConditionsAgreement.Companion.path: DataClassPath<ClientTermsAndConditionsAgreement, ClientTermsAndConditionsAgreement> get() = path<ClientTermsAndConditionsAgreement>()


object ClientTermsAndConditionsAgreement__id: SerializableProperty<ClientTermsAndConditionsAgreement, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ClientTermsAndConditionsAgreement): UUID = receiver._id
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ClientTermsAndConditionsAgreement.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ClientTermsAndConditionsAgreement_accessInfo: SerializableProperty<ClientTermsAndConditionsAgreement, AccessInfo> {
    override val name: String = "accessInfo"
    override fun get(receiver: ClientTermsAndConditionsAgreement): AccessInfo = receiver.accessInfo
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: AccessInfo) = receiver.copy(accessInfo = value)
    override val serializer: KSerializer<AccessInfo> = AccessInfo.serializer()
    override val annotations: List<Annotation> = ClientTermsAndConditionsAgreement.serializer().tryFindAnnotations("accessInfo")
}
object ClientTermsAndConditionsAgreement_version: SerializableProperty<ClientTermsAndConditionsAgreement, Instant> {
    override val name: String = "version"
    override fun get(receiver: ClientTermsAndConditionsAgreement): Instant = receiver.version
    override fun setCopy(receiver: ClientTermsAndConditionsAgreement, value: Instant) = receiver.copy(version = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ClientTermsAndConditionsAgreement.serializer().tryFindAnnotations("version")
}
