package com.crowpay.views.theming

import com.crowpay.AppColors
import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.models.Semantic
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.models.ThemeAndBack
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.Stack
import com.lightningkite.kiteui.views.direct.stack

data object AuthBackgroundSemantic : Semantic {
    override val key: String = "authback"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = AppColors.grey.light1,
        revert = true
    ).withBack
}