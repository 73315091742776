package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.extensions.contactFullName
import com.crowpay.extensions.withSpacing
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.*
import com.crowpay.views.components.files.*
import com.crowpay.views.dialogs.ImagePreview
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.requestFile
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.*

@Routable("/contractor/profile")
class ContractorProfile() : ContractorScreen {

    override val title: Readable<String> = Constant("Profile")

    val contractor = Draft { notNullSession().contractors[notNullContractor()]()!! }

    override fun ViewWriter.renderMainContent() {

        scrolls - col {
            spacing = 0.dp

            title("Profile")

            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent

                rowCollapsingToColumn(AppDimensions.normalCollapseWidth) {

                    section - expanding - stack {
                        spacing = AppDimensions.sectionIndent
                        val editingContractor = Property(false)
                        col {

                            title {
                                ::content { "${contractor.published().preferredTitle.name} Information" }
                            }

                            onlyWhen { !editingContractor() } - col {
                                col {
                                    label2("Name") {
                                        body {
                                            ::content{ contractor.published().name }
                                        }
                                    }

                                    label2("Email") {
                                        body {
                                            ::content{ contractor.published().email }
                                        }
                                    }

                                    label2("Phone Number") {
                                        body {
                                            ::content{ PhoneNumberFormat.USA.format(contractor.published().phoneNumber.filter { it.isDigit() }) }
                                        }
                                    }

                                    label2("Address") {
                                        body {
                                            ::content{ contractor.published().address.toString() }
                                        }
                                    }

                                    label2("Logo") {
                                        image {
                                            scaleType = ImageScaleType.Fit
                                            description = "Logo"
                                            ::source{
                                                contractor.published().image?.let { ImageRemote(it.location) }
                                            }
                                        } in sizeConstraints(width = 5.rem, height = 5.rem)
                                    }

                                    label2("Preferred Title") {
                                        body {
                                            ::content{ contractor.published().preferredTitle.toString() }
                                        }
                                    }

                                    label2("Trade") {
                                        body {
                                            ::content{ contractor.published().trade ?: "" }
                                        }
                                    }

                                    space()

                                    subTitle("Other Legal Information")

                                    label2("State Entity Number") {
                                        body {
                                            ::content{ contractor.published().stateEntityNumber }
                                        }
                                    }
                                    label2("EIN/Social/Tax ID") {
                                        body {
                                            ::content{ contractor.published().ein }
                                        }
                                    }

                                    tertiaryButton - button {
                                        icon(Icon.edit, "Edit Business Information")
                                        onClick {
                                            editingContractor.set(true)
                                        }
                                    } in gravity(Align.End, Align.Center)
                                }
                            }


                            onlyWhen { editingContractor() } - col {
                                col {
                                    validating {

                                        val availableTrades = shared {
                                            notNullSession().trades
                                                .query(
                                                    Query(
                                                        condition(
                                                            true
                                                        ), sort { it._id.ascending() })
                                                )()
                                                .map { it._id }
                                        }


                                        val displayImage = LazyProperty {
                                            contractor().image?.let { FilePreview.RemoteFile(it.location) }
                                                ?: FilePreview.Empty
                                        }

                                        label2("Name") {
                                            val value =
                                                contractor
                                                    .lens(
                                                        get = { it.name },
                                                        modify = { og, it -> og.copy(name = it) }
                                                    )
                                                    .validate { it.isNotBlank() }
                                            fieldTheme - validate(value) - textField {
                                                hint = "Name"
                                                content.bind(value)
                                            }
                                        }

                                        label2("Email") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.email },
                                                    modify = { og, it -> og.copy(email = it) }
                                                )
                                                .validate { it.matches(SharedUtils.emailPattern) }
                                            fieldTheme - validate(value) - textField {
                                                hint = "Email"
                                                content.bind(value)
                                            }
                                        }

                                        label2("Phone Number") {
                                            val value = contractor
                                                .lensPath { it.phoneNumber }
                                                .validate { it.length == 10 }
                                            fieldTheme - validate(value) - formattedTextInput {
                                                hint = "Phone Number"
                                                format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                                                content.bind(value)
                                            }
                                        }

                                        space()

                                        h4("Address")

                                        rowCollapsingToColumn(AppDimensions.pageWidth * 0.8) {
                                            expanding - label2("Line 1") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.line1 },
                                                        modify = { og, it ->
                                                            og.copy(
                                                                address = og.address.copy(
                                                                    line1 = it
                                                                )
                                                            )
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Line 1"
                                                    content.bind(value)
                                                }
                                            }
                                            expanding - label2("Line 2 (Optional)") {
                                                fieldTheme - textField {
                                                    hint = "Line 2"
                                                    content bind contractor
                                                        .lens(
                                                            get = { it.address.line2 ?: "" },
                                                            modify = { og, it ->
                                                                og.copy(
                                                                    address = og.address.copy(
                                                                        line2 = it.takeIf { it.isNotBlank() })
                                                                )
                                                            }
                                                        )
                                                }
                                            }
                                        }
                                        rowCollapsingToColumn(AppDimensions.pageWidth * 0.8) {
                                            expanding - label2("City") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.city },
                                                        modify = { og, it ->
                                                            og.copy(
                                                                address = og.address.copy(
                                                                    city = it
                                                                )
                                                            )
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "City"
                                                    content.bind(value)
                                                }
                                            }
                                            expanding - label2("State") {
                                                fieldTheme - select {
                                                    bind(
                                                        contractor
                                                            .lens(
                                                                get = { it.address.state },
                                                                modify = { og, it ->
                                                                    og.copy(address = og.address.copy(state = it))
                                                                }
                                                            ),
                                                        Constant(State.entries)
                                                    ) { it.display }
                                                }
                                            }
                                            expanding - label2("Zipcode") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.zipcode },
                                                        modify = { og, it ->
                                                            og.copy(address = og.address.copy(zipcode = it))
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Zipcode"
                                                    content.bind(value)
                                                }
                                            }
                                        }

                                        label2("Logo") {
                                            val value = displayImage.validate { it !is FilePreview.Empty }
                                            fieldTheme - validate(value) - withSpacing(0.0) - button {
                                                stack {
                                                    val split = split()

                                                    reactiveScope {
                                                        this@stack.clearChildren()
                                                        val filePreview = value()

                                                        with(split) {
                                                            renderFilePreview(filePreview, 6.rem)
                                                        }
                                                    }

                                                    body {
                                                        ::exists{ value() == FilePreview.Empty }
                                                        content = "Image\n Upload"
                                                    }
                                                }
                                                onClick {
                                                    ExternalServices.requestFile(listOf("image/*")) { it: FileReference? ->
                                                        it?.let { result ->
                                                            launch {
                                                                value.set(
                                                                    FilePreview.LocalFile(it)
                                                                    .also { it.upload(notNullSession().nonCached.contractor::uploadFileForRequest) })
                                                            }
                                                        }
                                                    }

                                                }
                                            } in sizeConstraints(width = 6.rem, height = 6.rem)
                                        }

                                        label2("Preferred Title") {
                                            fieldTheme - select {
                                                bind(
                                                    edits = contractor
                                                        .lens(
                                                            get = { it.preferredTitle },
                                                            modify = { og, it -> og.copy(preferredTitle = it) }
                                                        ),
                                                    data = shared { PreferredTitle.entries },
                                                    render = { it.toString() })
                                            }
                                        }

                                        label2("Trade") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.trade },
                                                    modify = { og, it -> og.copy(trade = it) }
                                                )
                                                .validate { it != null }
                                            fieldTheme - validate(value) - select {
                                                bind(
                                                    edits = value,
                                                    data = shared { listOf(null) + availableTrades() },
                                                    render = { it ?: "Select a Trade" })
                                            }
                                        }

                                        space()

                                        h4("Other Legal Information")

                                        label2("State Entity Number") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.stateEntityNumber },
                                                    modify = { og, it -> og.copy(stateEntityNumber = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            fieldTheme - validate(value) - textField {
                                                hint = "State Entity Number"
                                                content.bind(value)
                                            }
                                        }

                                        label2("EIN/Social/Tax ID") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.ein },
                                                    modify = { og, it -> og.copy(ein = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            fieldTheme - validate(value) - textField {
                                                hint = "EIN/Social/Tax IDr"
                                                content.bind(value)
                                            }
                                        }
                                        row {
                                            spacing = AppDimensions.buttonRowSpacing
                                            textButton - button {
                                                specCenteredText("Cancel")
                                                onClick {
                                                    editingContractor.set(false)
                                                    displayImage.reset()
                                                    contractor.cancel()
                                                }
                                            }
                                            tertiaryButton - button {

                                                ::enabled{ allValid() }
                                                specCenteredText("Save")

                                                onClick {
                                                    val image = displayImage.awaitOnce()
                                                    val imageMod: Modification<Contractor>? =
                                                        if (image is FilePreview.LocalFile) {
                                                            if (image.uploaded.await().awaitNotNull()) {
                                                                modification { it.image assign image.mainFile!! }
                                                            } else
                                                                null
                                                        } else null

                                                    val current = contractor.published.awaitOnce()
                                                    val mods = (modification(current, contractor())
                                                            as? Modification.Chain)
                                                        ?.let {
                                                            if (imageMod != null)
                                                                Modification.Chain(
                                                                    it.modifications.plus(
                                                                        imageMod
                                                                    )
                                                                )
                                                            else
                                                                it
                                                        }
                                                        ?: Modification.Chain(emptyList())

                                                    if (mods.modifications.isNotEmpty()) {
                                                        notNullSession().contractors[current._id].modify(mods)
                                                        contractor.cancel()
                                                    }
                                                    editingContractor.set(false)
                                                }
                                            }
                                        } in gravity(Align.End, Align.Center)
                                    }
                                }
                            }
                        }
                    }

                    section - expanding - stack {
                        spacing = AppDimensions.sectionIndent
                        val editingContact = Property(false)
                        col {
                            title {
                                ::content { "Contact Information" }
                            }
                            onlyWhen { !editingContact() } - col {
                                col {

                                    label2("Name") {
                                        body {
                                            ::content{ contractor().contactFullName }
                                        }
                                    }

                                    label2("Email") {
                                        body {
                                            ::content{ contractor().contactEmail }
                                        }
                                    }

                                    label2("Phone Number") {
                                        body {
                                            ::content{ PhoneNumberFormat.USA.format(contractor().contactPhoneNumber.filter { it.isDigit() }) }
                                        }
                                    }

                                    label2("Address") {
                                        body {
                                            ::content{ contractor().contactAddress.toString() }
                                        }
                                    }

                                    tertiaryButton - button {
                                        icon(Icon.edit, "Edit Contact Information")
                                        onClick {
                                            editingContact.set(true)
                                        }
                                    } in gravity(Align.End, Align.Center)
                                }
                            }
                            onlyWhen { editingContact() } - col {
                                col {
                                    validating {

                                        label2("First Name") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.contactFirstName },
                                                    modify = { og, it -> og.copy(contactFirstName = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            fieldTheme - validate(value) - textField {
                                                hint = "First Name"
                                                content.bind(value)
                                            }
                                        }

                                        label2("Last Name") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.contactLastName },
                                                    modify = { og, it -> og.copy(contactLastName = it) }
                                                )
                                                .validate { it.isNotBlank() }
                                            fieldTheme - validate(value) - textField {
                                                hint = "Last Name"
                                                content.bind(value)
                                            }
                                        }

                                        label2("Email") {
                                            val value = contractor
                                                .lens(
                                                    get = { it.email },
                                                    modify = { og, it -> og.copy(email = it) }
                                                )
                                                .validate { it.matches(SharedUtils.emailPattern) }
                                            fieldTheme - validate(value) - textField {
                                                hint = "Email"
                                                content.bind(value)
                                            }
                                        }

                                        label2("Phone Number") {
                                            val value = contractor
                                                .lensPath { it.contactPhoneNumber }
                                                .validate { it.length == 10 }
                                            fieldTheme - validate(value) - formattedTextInput {
                                                hint = "Phone Number"
                                                format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                                                content.bind(value)
                                            }
                                        }

                                        space()
                                        subTitle("Address")

                                        rowCollapsingToColumn(AppDimensions.pageWidth * 0.8) {
                                            expanding - label2("Line 1") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.line1 },
                                                        modify = { og, it ->
                                                            og.copy(
                                                                address = og.address.copy(
                                                                    line1 = it
                                                                )
                                                            )
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Line 1"
                                                    content.bind(value)
                                                }
                                            }
                                            expanding - label2("Line 2 (Optional)") {
                                                fieldTheme - textField {
                                                    hint = "Line 2"
                                                    content bind contractor
                                                        .lens(
                                                            get = { it.address.line2 ?: "" },
                                                            modify = { og, it ->
                                                                og.copy(
                                                                    address = og.address.copy(
                                                                        line2 = it.takeIf { it.isNotBlank() })
                                                                )
                                                            }
                                                        )

                                                }
                                            }
                                        }
                                        rowCollapsingToColumn(AppDimensions.pageWidth * 0.8) {
                                            expanding - label2("City") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.city },
                                                        modify = { og, it ->
                                                            og.copy(
                                                                address = og.address.copy(
                                                                    city = it
                                                                )
                                                            )
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "City"
                                                    content.bind(value)
                                                }
                                            }
                                            expanding - label2("State") {
                                                fieldTheme - select {
                                                    bind(
                                                        contractor.lens(
                                                            get = { it.address.state },
                                                            modify = { og, it ->
                                                                og.copy(address = og.address.copy(state = it))
                                                            }
                                                        ),
                                                        Constant(State.entries)
                                                    ) { it.display }
                                                }
                                            }
                                            expanding - label2("Zipcode") {
                                                val value = contractor
                                                    .lens(
                                                        get = { it.address.zipcode },
                                                        modify = { og, it ->
                                                            og.copy(address = og.address.copy(zipcode = it))
                                                        }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Zipcode"
                                                    content.bind(value)
                                                }
                                            }
                                        }
                                        row {
                                            spacing = AppDimensions.buttonRowSpacing
                                            textButton - button {
                                                specCenteredText("Cancel")
                                                onClick {
                                                    editingContact.set(false)
                                                    contractor.cancel()
                                                }
                                            }
                                            tertiaryButton - button {

                                                ::enabled{ allValid() }
                                                specCenteredText("Save")
                                                onClick {
                                                    val current = contractor.published.awaitOnce()
                                                    val mods = modification(old = current, new = contractor())
                                                            as? Modification.Chain
                                                        ?: Modification.Chain(emptyList())

                                                    if (mods.modifications.isNotEmpty()) {
                                                        notNullSession().contractors[current._id].modify(mods)
                                                        contractor.cancel()
                                                    }
                                                    editingContact.set(false)

                                                }
                                            }
                                        } in gravity(Align.End, Align.Center)
                                    }
                                }
                            }
                        }
                    }

                }
            }

            space(AppDimensions.majorColumnSpacing)

            title("Licenses")

            val licenses = shared {
                notNullSession().licenses.query(Query(condition { it.contractor eq guaranteedContractor() }))()
            }
            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent
                section - expanding - renderLicenses(licenses)
            }

            space(AppDimensions.majorColumnSpacing)


            title("Other Documents")

            val docs = shared {
                notNullSession().contractorDocuments
                    .query(Query(condition {
                        it.contractor eq guaranteedContractor<UUID>()
                    }))
                    .await()
            }
            sizeConstraints(width = AppDimensions.pageWidth) - padded - stack {
                spacing = AppDimensions.backgroundIndent
                section - renderContractorDocuments(docs)
            }
        }
    }
}