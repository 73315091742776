package com.crowpay.views.theming

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.Semantic
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.models.ThemeAndBack
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter

class ForegroundSemantic(val color: Color): Semantic {
    override val key: String = "foreground${color.toWeb().filter { it.isLetterOrDigit() }}"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = color
    ).withoutBack
}

@ViewModifierDsl3
fun ViewWriter.colored(color: Color): ViewWrapper = ForegroundSemantic(color).onNext