package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.utils.lazy
import com.crowpay.views.components.files.renderAttachment
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.projectSpecLibrary(project: Readable<Project>, lineItems: Readable<List<LineItem>>) {

    val files: Readable<List<Attachment>> = shared {
        project().files + lineItems().flatMap { it.files }
    }

    col {
        lazy(AppState.windowInfo) { window ->
            val raw = AppDimensions.largeImagePreview.px
            val count =
                if (window.width > AppDimensions.pageWidth + AppDimensions.leftNavWidth) {
                    AppDimensions.pageWidth.px / raw
                } else {
                    (window.width.px - AppDimensions.leftNavWidth.px) / raw
                }.toInt() - 1

            val chunked = shared {
                files().chunked(count)
            }

            forEachUpdating(chunked) { chunk ->
                row {
                    spacing = 8.dp
                    forEachUpdating(chunk) { attachment ->
                        expanding - stack {
                            centered - renderAttachment(attachment, files, AppDimensions.largeImagePreview)
                        }
                    }
                }
            }
        }
    }
}