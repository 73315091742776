package com.crowpay.views.dialogs

import com.crowpay.AppColors
import com.crowpay.actuals.AppDimensions
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.dialog
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.themeFromLast


enum class MessageType {
    Plain,
    Confirmation,
    Warning,
    Danger,
}

class GenericConfirmationDialog(
    val header: String?,
    val message: String,
    val question: String? = null,
    val confirmationText: String,
    val declineText: String = "Go Back",
    val messageType: MessageType = MessageType.Plain,
    val allowDismiss: Boolean = true,
    val onSubmit: suspend (Boolean) -> Unit,
) : Screen {
    override fun ViewWriter.render() {

        suspend fun submit(value: Boolean) {
            onSubmit(value)
            screenNavigator.dismiss()
        }
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    if (header != null)
                        title {
                            content = header
                        }

                    body {
                        content = message
                    } in
                            sizeConstraints(maxWidth = 40.rem, minWidth = 10.rem)

                    body {
                        exists = question != null
                        content = question ?: ""
                    } in
                            sizeConstraints(maxWidth = 40.rem, minWidth = 10.rem)

                    row {
                        spacing = AppDimensions.buttonRowSpacing

                        button {
                            themeChoice += when (messageType) {
                                MessageType.Plain -> TertiaryButtonSemantic
                                MessageType.Confirmation -> PrimaryButtonSemantic
                                MessageType.Warning -> PrimaryButtonSemantic
                                MessageType.Danger -> WarningButtonSemantic
                            }

                            specCenteredText(confirmationText)
                            onClick {
                                submit(true)
                            }
                        }

                        textButton - button {
                            specCenteredText(declineText)
                            onClick {
                                submit(false)
                            }
                        } /*in
                                                themeFromLast {
                                                    when (messageType) {
                                                        MessageType.Confirmation -> it.button().warning()
                                                        MessageType.Warning, MessageType.Danger -> it.button()
                                                            .affirmative()

                                                        MessageType.Plain -> it.card()
                                                    }
                                                }*/
                    }
                }
            }
            onClick {
                if (allowDismiss) {
                    onSubmit(false)
                    screenNavigator.dismiss()
                }
            }
        }
    }
}