package com.crowpay.extensions

import com.crowpay.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.files.ServerFile
import kotlinx.datetime.Instant
import org.kotlincrypto.hash.sha2.Bit32Digest


internal infix fun Bit32Digest.hash(value: Boolean?) {
    when (value) {
        true -> update(1)
        false -> update(0)
        null -> {} //noop
    }
}

internal infix fun Bit32Digest.hash(value: Float?) =
    value?.toByteArray()?.also { update(it) } ?: run { update(22) } // The null data is just a random constant Byte.

internal infix fun Bit32Digest.hash(value: Long?) =
    value?.toByteArray()?.also { update(it) } ?: run { update(100) } // The null data is just a random constant Byte.

internal infix fun Bit32Digest.hash(value: Int?) =
    value?.toByteArray()?.also { update(it) } ?: run { update(-52) } // The null data is just a random constant Byte.

internal infix fun Bit32Digest.hash(value: String?) = value?.encodeToByteArray()?.also { update(it) } ?: run {
    update(
        127
    )
} // The null data is just a random constant Byte.
internal infix fun Bit32Digest.hash(value: Instant?) = this hash value?.toEpochMilliseconds()

internal infix fun Bit32Digest.hash(value: UUID?) = this hash value?.toString()
internal infix fun Bit32Digest.hash(value: AccessInfo?) {
    // We cannot hash the ip, sessionId, or userAgent because those are masked.
    this hash value?.user
    this hash value?.timestamp
}

internal infix fun Bit32Digest.hash(value: Attachment?) {
    this hash value?.name
    this hash value?.file
    this hash value?.fileType
    this hash value?.preview
}

internal infix fun Bit32Digest.hash(value: ServerFile?) {
    this hash value?.location?.substringBefore('?')
}

internal infix fun Bit32Digest.hash(value: PunchListItem) {
    this hash value._id
    this hash value.project
    this hash value.lineItem
    this hash value.content
    this hash value.required
    this hash value.complete
}

internal infix fun Bit32Digest.hash(value: ClientFeedback?) {
    value
        ?.also {
            this hash it.message
            this hash it.at
        }
        ?: run { update(-66) } // The null data is just a random constant Byte.

}

internal infix fun Bit32Digest.hash(value: LineItem) {

    this hash value._id
    this hash value.project
    this hash value.name
    this hash value.description
    this hash value.price
    this hash value.order
    value.files.forEach { attachment: Attachment ->
        this hash attachment
    }
    this hash value.changeRequest

    this hash value.started
    this hash value.notApproved
    this hash value.requestingReview
    this hash value.actionRequiredBy
    this hash value.complete
    this hash value.ledgerItem
    this hash value.cancelled

}

internal infix fun Bit32Digest.hash(value: ItemChange) {
    this hash value.itemId
    this hash value.description
    this hash value.price
    this hash value.cancelled
}