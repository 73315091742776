package com.crowpay.views.theming

import com.crowpay.AppColors
import com.crowpay.Resources
import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.TextView
import com.lightningkite.kiteui.views.direct.text

fun FontAndStyle.copy(
    font: Font = this.font,
    size: Dimension = this.size,
    italic: Boolean = this.italic,
    bold: Boolean,
    allCaps: Boolean = this.allCaps,
    lineSpacingMultiplier: Double = this.lineSpacingMultiplier,
    additionalLetterSpacing: Dimension = this.additionalLetterSpacing,
) = copy(
    font = font,
    italic = italic,
    allCaps = allCaps,
    lineSpacingMultiplier = lineSpacingMultiplier,
    additionalLetterSpacing = additionalLetterSpacing,
    size = size,
    weight = if (bold) 700 else 400
)

object XLTitleSemantic : Semantic {
    override val key: String = "XLTitle"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(
            font = Resources.fontsMontserratBold,
            size = AppDimensions.xlTitleTextSize,
        )
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.xlTitle(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += XLTitleSemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.xlTitle(content: String) {
    text {
        themeChoice += XLTitleSemantic
        this.content = content
    }
}

object TitleSemantic : Semantic {
    override val key: String = "title"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        font = theme.font.copy(
            font = Resources.fontsMontserrat,
            size = AppDimensions.titleTextSize,
            bold = true,
        ),
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.title(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += TitleSemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.title(content: String) {
    text {
        themeChoice += TitleSemantic
        this.content = content
    }
}

object SubtitleSemantic : Semantic {
    override val key: String = "subTitle"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "subTitle",
        font = theme.font.copy(size = AppDimensions.subtitleTextSize)
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.subTitle(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += SubtitleSemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.subTitle(content: String) {
    text {
        themeChoice += SubtitleSemantic
        this.content = content
    }
}

object BodySemantic : Semantic {
    override val key: String = "body"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "body",
        font = theme.font.copy(size = AppDimensions.bodyTextSize)
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.body(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += BodySemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.body(content: String) {
    text {
        themeChoice += BodySemantic
        this.content = content
    }
}

object BodyBoldSemantic : Semantic {
    override val key: String = "bodybold"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "bodyBold",
        font = theme.font.copy(size = AppDimensions.bodyTextSize, bold = true)
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.bodyBold(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += BodyBoldSemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.bodyBold(content: String) {
    text {
        themeChoice += BodyBoldSemantic
        this.content = content
    }
}
@ViewDsl inline fun ViewWriter.bodyBold(content: String, setup: TextView.() -> Unit) {
    text {
        themeChoice += BodyBoldSemantic
        this.content = content
        setup()
    }
}

object SmallBodySemantic : Semantic {
    override val key: String = "smallBody"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "smallBody",
        font = theme.font.copy(size = AppDimensions.smallBodyTextSize),
        foreground = AppColors.grey.main
    ).withoutBack
}
@ViewDsl inline fun ViewWriter.smallBody(crossinline setup: TextView.() -> Unit = {}) {
    text {
        themeChoice += SmallBodySemantic
        setup()
    }
}
@ViewDsl inline fun ViewWriter.smallBody(content: String) {
    text {
        themeChoice += SmallBodySemantic
        this.content = content
    }
}