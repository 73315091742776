package com.crowpay.extensions

import com.crowpay.ProjectAttachment
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.FilePreview
import com.lightningkite.kiteui.reactive.Property

fun List<ProjectAttachment>.toFileData(): List<FileData> = map { attachment ->
    FileData(
        name = Property(attachment.name),
        preview = FilePreview.RemoteAttachment(attachment),
        original = FilePreview.RemoteAttachment(attachment),
    )
}