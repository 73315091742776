package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.files.resolveProjectAttachments
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.modification

@Routable("/contractor/project/{projectId}/create-change-order")
class CreateChangeOrder(val projectId: UUID) : AbstractChangeOrder() {

    val _change: Property<ChangeRequest?> = Property(null)
    override val change: Readable<ChangeRequest> = Constant(ChangeRequest(project = projectId))
    override val allowDelete: Readable<Boolean> = shared { _change() != null }

    override val project = sharedSuspending {
        notNullSession().nonCached.project.detail(projectId)
    }

    override suspend fun save() {
        val r = _change.await()
        val session = currentSession() ?: return

        if (r == null) {
            val newChangeRequest = change.awaitOnce().copy(
                project = projectId,
                title = form.requestTitle.await(),
                projectDescription = form.projectDescription.value.trim().takeIf { it.isNotBlank() },
                newItems = form.newLineForms.await().values.map { form ->
                    form.draft.publish()
                    val line = form.draft.await()
                    val newFiles = form.files.awaitOnce().resolveProjectAttachments()
                    line.copy(files = newFiles)
                },
                itemChanges = form.itemChangeForms.await().values.map {
                    it.draft.publish();
                    it.draft.await()
                },
            )

            val result = session.changeRequests.insert(newChangeRequest).await()!!
            session.changeRequests.localSignalUpdate(
                matching = { it._id == result._id },
                modify = { result }
            )
            _change.value = result
        } else {
            val result = session.changeRequests[r._id].modify(modification {
                it.title assign form.requestTitle.await()
                it.projectDescription assign form.projectDescription.value.trim().takeIf { it.isNotBlank() }
                it.newItems assign form.newLineForms.await().values.map { form ->
                    form.draft.publish()
                    val line = form.draft.await()
                    val newFiles = form.files.awaitOnce().resolveProjectAttachments()
                    line.copy(files = newFiles)
                }
                it.itemChanges assign form.itemChangeForms.await().values.map {
                    it.draft.publish()
                    it.draft.await()
                }
            })!!
            session.changeRequests.localSignalUpdate(
                matching = { it._id == result._id },
                modify = { result }
            )
            _change.value = result
        }
    }


    override suspend fun publish() {
        if (_change.await() == null) {
            save()
        }
        super.publish()
    }

}