// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareClientFields() {
    val props: Array<SerializableProperty<Client, *>> = arrayOf(Client__id, Client_contractor, Client_name, Client_email, Client_phoneNumber, Client_address)
    Client.serializer().properties { props }
}
val <K> DataClassPath<K, Client>._id: DataClassPath<K, UUID> get() = this[Client__id]
val <K> DataClassPath<K, Client>.contractor: DataClassPath<K, UUID> get() = this[Client_contractor]
val <K> DataClassPath<K, Client>.name: DataClassPath<K, String> get() = this[Client_name]
val <K> DataClassPath<K, Client>.email: DataClassPath<K, String> get() = this[Client_email]
val <K> DataClassPath<K, Client>.phoneNumber: DataClassPath<K, String> get() = this[Client_phoneNumber]
val <K> DataClassPath<K, Client>.address: DataClassPath<K, Address> get() = this[Client_address]
inline val Client.Companion.path: DataClassPath<Client, Client> get() = path<Client>()


object Client__id: SerializableProperty<Client, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Client): UUID = receiver._id
    override fun setCopy(receiver: Client, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Client_contractor: SerializableProperty<Client, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: Client): UUID = receiver.contractor
    override fun setCopy(receiver: Client, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("contractor")
}
object Client_name: SerializableProperty<Client, String> {
    override val name: String = "name"
    override fun get(receiver: Client): String = receiver.name
    override fun setCopy(receiver: Client, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("name")
}
object Client_email: SerializableProperty<Client, String> {
    override val name: String = "email"
    override fun get(receiver: Client): String = receiver.email
    override fun setCopy(receiver: Client, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("email")
}
object Client_phoneNumber: SerializableProperty<Client, String> {
    override val name: String = "phoneNumber"
    override fun get(receiver: Client): String = receiver.phoneNumber
    override fun setCopy(receiver: Client, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("phoneNumber")
}
object Client_address: SerializableProperty<Client, Address> {
    override val name: String = "address"
    override fun get(receiver: Client): Address = receiver.address
    override fun setCopy(receiver: Client, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Client.serializer().tryFindAnnotations("address")
}
