package com.crowpay.views.dialogs

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.paymentSetup
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.lazy
import com.crowpay.views.components.label2
import com.crowpay.views.theming.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch

data class PaymentError(
    val type: String?,
    val code: String?,
    val message: String?,
)

data class PaymentResult(
    val method: String?,
    val error: PaymentError?,
)

data class PaymentConfig(
    val token: String,
    val publicKey: String,
    val user: User,
)

class SetupMethodDialog(
    val onComplete: suspend ViewWriter.(PaymentMethod) -> Unit,
) : Screen {

    val name = Property("")
    val done = Property(false)

    override fun ViewWriter.render() {

        val keys = sharedSuspending {
            val session = notNullSession()
            PaymentConfig(
                session.nonCached.userAuth.getPaymentSetupToken(),
                session.nonCached.userAuth.getPaymentPublishableKey(),
                session.self.await()
            )
        }
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                col {
                    themeChoice = ThemeDerivation(appTheme)
                    lazy(keys) { (setup, public, user) ->
                        if (setup == SharedUtils.mockPayment || public == SharedUtils.mockPayment) {
                            body {
                                align = Align.Center
                                content =
                                    "We are currently using mock payments. We will automatically create a mock payment object. No money will be transferred, and no banking information gathered."
                            } in sizeConstraints(maxWidth = 20.rem, minWidth = 10.rem)

                            space()

                            tertiaryButton - button {
                                specCenteredText ("Ok")
                                onClick {
                                    notNullSession().nonCached.userAuth.createMockBank()
                                    screenNavigator.dismiss()
                                }
                            }
                        } else {

                            title("Payment Setup")

                            label2("Method Name") {
                                fieldTheme - textField {
                                    hint = "Method Name"
                                    keyboardHints = KeyboardHints.title
                                    content bind name
                                }
                            }

                            smallBody("Powered by Stripe")

                            val confirmSetup = paymentSetup(
                                setupToken = setup,
                                publicKey = public,
                                user = user,
                                onComplete = { isComplete ->
                                    launch {
                                        done.set(isComplete)
                                    }
                                },
                            )

                            row {
                                spacing = AppDimensions.buttonRowSpacing

                                primaryButton - button {
                                    ::enabled{ done() }
                                    specCenteredText("Done")
                                    onClick {
                                        val result = confirmSetup()
                                        if (result.method != null) {
                                            val result = notNullSession().nonCached.userAuth
                                                    .updatePaymentMethodName(result.method, name.value)
                                            onComplete(result)
                                            screenNavigator.dismiss()
                                        } else {
                                            screenNavigator.reset(
                                                GenericDialog(
                                                    result.error?.message ?: SharedUtils.unknownError
                                                )
                                            )
                                        }
                                    }
                                }

                                textButton - button {
                                    specCenteredText("Go Back")
                                    onClick {
                                        screenNavigator.dismiss()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}