package com.crowpay.actuals

import com.lightningkite.kiteui.models.Angle
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.transform


actual var RView.rotation: Angle
    get() = throw NotImplementedError()
    set(value) {
        this.native.style.transform = "rotate(${value.turns}turn)"
    }