package com.crowpay.views.components

import com.crowpay.actuals.AppDimensions
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding


@ViewDsl
inline fun RowOrCol.space(value: Dimension) {
    sizeConstraints(minWidth = if(vertical) 1.dp else value, minHeight = if(vertical) value else 1.dp) - space()
}

@ViewDsl
inline fun RowCollapsingToColumn.space(value: Dimension) {
    sizeConstraints(minWidth = value, minHeight = value) - space()
}

enum class Indent(val before: Dimension, val after: Dimension) {
    Full(AppDimensions.fullIndent, AppDimensions.backgroundIndent),
    Section(AppDimensions.sectionIndent, 0.px)
}

@ViewDsl
inline fun ViewWriter.indentedCol(
    beforeSpace: Dimension,
    afterSpace: Dimension,
    setup: RowOrCol.() -> Unit
) {
    stack {     // stack is here for animation purposes
        row {
            space(beforeSpace)
            expanding - col(setup)
            space(afterSpace)
        }
    }
}
@ViewDsl
inline fun ViewWriter.indentedCol(
    indent: Indent,
    setup: RowOrCol.() -> Unit
) = indentedCol(indent.before, indent.after, setup)

@ViewDsl inline fun ViewWriter.sectionIndentCol(setup: RowOrCol.()->Unit) = indentedCol(Indent.Section, setup)
@ViewDsl inline fun ViewWriter.fullIndentCol(setup: RowOrCol.() -> Unit) = indentedCol(Indent.Full, setup)