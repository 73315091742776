package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.json
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.ReorderDirection
import com.crowpay.views.components.files.FilePreview
import com.crowpay.views.components.space
import com.crowpay.views.theming.specCenteredText
import com.crowpay.views.theming.tertiaryButton
import com.lightningkite.UUID
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.forEach
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.typed.BulkRequest
import kotlinx.serialization.encodeToString


class EditLineItems(val projectId: UUID, val allowSave: Readable<Boolean> = Constant(true)) : Screen {

    // There has got to be a better way than this, but the async function is unreliable.
    val drafts: MutableMap<UUID, Draft<Client>> = mutableMapOf()

    val temp = sharedSuspending {

        notNullSession().nonCached.lineItem
            .query(Query(
                condition = condition<LineItem> { it.project eq projectId },
                orderBy = sort { it.order.ascending() }
            ))
            .map { line -> createForm(line) }
    }

    val forms = LazyProperty { temp() }
//    val forms = LazyProperty {
//        val temp: List<LineItem> = async {
//            notNullSession().nonCached.lineItem
//                .query(Query(
//                    condition = condition<LineItem> { it.project eq projectId },
//                    orderBy = sort { it.order.ascending() }
//                ))
//        }
//            temp.map { line -> createForm(line) }
//    }

    fun createForm(line: LineItem? = null): EditLineItem {
        return EditLineItem(
            ogLine = line,
            projectId = projectId,
            allowSave = allowSave,
            reorder = reorder@{ formId, direction ->
                val allForms = forms.value
                val currentIndex =
                    allForms
                        .indexOfFirst { it.draft()._id == formId }
                        .takeUnless { it < 0 }
                        ?: return@reorder
                forms.set(
                    when {
                        direction == ReorderDirection.UP && currentIndex != 0 -> {
                            val temp = allForms.toMutableList()
                            val toMove = temp.removeAt(currentIndex)
                            temp.add(currentIndex - 1, toMove)
                            temp
                        }

                        direction == ReorderDirection.DOWN && currentIndex != allForms.lastIndex -> {
                            val temp = allForms.toMutableList()
                            val toMove = temp.removeAt(currentIndex)
                            temp.add(currentIndex + 1, toMove)
                            temp
                        }

                        else -> {
                            allForms
                        }
                    }
                )

                orderEdited.invokeAll()
            },
            onRemove = { formId ->
                forms.value = forms.value.filter { it.draft()._id != formId }
            },
        )
    }

    val orderEdited = BasicListenable()
    var justAdded: UUID? = null

    suspend fun hasUnsavedChanges(): Boolean {
        return forms.awaitOnce().any { form ->
            val files = form.files.awaitOnce()
            val og = form.draft.awaitOnce()
            form.draft.changesMade.await() ||
                    og.files.size != files.size ||
                    files.any { it.preview is FilePreview.LocalFile }
        }
    }

    override fun ViewWriter.render() {

        col {
            spacing = 0.dp

            var initialLoad = true
            reactiveSuspending {
                rerunOn(orderEdited.debounce(800))
                if (initialLoad) {
                    initialLoad = false
                    return@reactiveSuspending
                }
                notNullSession()
                    .bulkRequest2(
                        forms.value
                            .mapIndexed { index, form ->
                                val id = form.draft()._id
                                id.toString() to BulkRequest(
                                    "/line-items/rest/${id}",
                                    HttpMethod.PATCH.name,
                                    json.encodeToString(modification<LineItem> { it.order assign index })
                                )
                            }
                            .toMap()
                    )
            }

            space(AppDimensions.majorColumnSpacing - 1.25.rem)

            tertiaryButton - gravity(Align.End, Align.Center) - button {
                specCenteredText("+ New Work Item")
                onClick {
                    val newForm = createForm(null)
                    forms.value += newForm
                    justAdded = newForm.draft()._id
                    newForm.expanded.value = true
                }
            }

            space(AppDimensions.majorColumnSpacing)

            col {
                spacing = 0.px
                forEach(forms) { form ->
                    col {
                        spacing = 0.px
                        form.render(this)
                        separator()
                    }
                }
            }
        }
    }
}