package com.crowpay.views.components

import com.crowpay.actuals.rotation
import com.lightningkite.kiteui.models.Angle
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.direct.icon

fun RView.expandIcon(expanded: Readable<Boolean>) {
    icon {
        source = Icon.chevronRight
        ::description { if (expanded()) "collapse" else "expand" }
        ::rotation { if (expanded()) Angle.quarterTurn else Angle.zero }
    }
}

fun RView.expandIcon(expanded: ReactiveContext.()->Boolean) {
    icon {
        source = Icon.chevronRight
        ::description { if (expanded()) "collapse" else "expand" }
        ::rotation { if (expanded()) Angle.quarterTurn else Angle.zero }
    }
}