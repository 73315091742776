// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLineItemFields() {
    val props: Array<SerializableProperty<LineItem, *>> = arrayOf(LineItem__id, LineItem_project, LineItem_changeRequest, LineItem_name, LineItem_description, LineItem_price, LineItem_order, LineItem_files, LineItem_started, LineItem_state, LineItem_notApproved, LineItem_requestingReview, LineItem_actionRequiredBy, LineItem_complete, LineItem_ledgerItem, LineItem_cancelled)
    LineItem.serializer().properties { props }
}
val <K> DataClassPath<K, LineItem>._id: DataClassPath<K, UUID> get() = this[LineItem__id]
val <K> DataClassPath<K, LineItem>.project: DataClassPath<K, UUID> get() = this[LineItem_project]
val <K> DataClassPath<K, LineItem>.changeRequest: DataClassPath<K, UUID?> get() = this[LineItem_changeRequest]
val <K> DataClassPath<K, LineItem>.name: DataClassPath<K, String> get() = this[LineItem_name]
val <K> DataClassPath<K, LineItem>.description: DataClassPath<K, String> get() = this[LineItem_description]
val <K> DataClassPath<K, LineItem>.price: DataClassPath<K, Long> get() = this[LineItem_price]
val <K> DataClassPath<K, LineItem>.order: DataClassPath<K, Int> get() = this[LineItem_order]
val <K> DataClassPath<K, LineItem>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[LineItem_files]
val <K> DataClassPath<K, LineItem>.started: DataClassPath<K, Instant?> get() = this[LineItem_started]
val <K> DataClassPath<K, LineItem>.state: DataClassPath<K, LineItemState> get() = this[LineItem_state]
val <K> DataClassPath<K, LineItem>.notApproved: DataClassPath<K, ClientFeedback?> get() = this[LineItem_notApproved]
val <K> DataClassPath<K, LineItem>.requestingReview: DataClassPath<K, AccessInfo?> get() = this[LineItem_requestingReview]
val <K> DataClassPath<K, LineItem>.actionRequiredBy: DataClassPath<K, Instant?> get() = this[LineItem_actionRequiredBy]
val <K> DataClassPath<K, LineItem>.complete: DataClassPath<K, AccessInfo?> get() = this[LineItem_complete]
val <K> DataClassPath<K, LineItem>.ledgerItem: DataClassPath<K, UUID?> get() = this[LineItem_ledgerItem]
val <K> DataClassPath<K, LineItem>.cancelled: DataClassPath<K, Instant?> get() = this[LineItem_cancelled]
inline val LineItem.Companion.path: DataClassPath<LineItem, LineItem> get() = path<LineItem>()


object LineItem__id: SerializableProperty<LineItem, UUID> {
    override val name: String = "_id"
    override fun get(receiver: LineItem): UUID = receiver._id
    override fun setCopy(receiver: LineItem, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object LineItem_project: SerializableProperty<LineItem, UUID> {
    override val name: String = "project"
    override fun get(receiver: LineItem): UUID = receiver.project
    override fun setCopy(receiver: LineItem, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("project")
}
object LineItem_changeRequest: SerializableProperty<LineItem, UUID?> {
    override val name: String = "changeRequest"
    override fun get(receiver: LineItem): UUID? = receiver.changeRequest
    override fun setCopy(receiver: LineItem, value: UUID?) = receiver.copy(changeRequest = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("changeRequest")
    override val default: UUID? = null
}
object LineItem_name: SerializableProperty<LineItem, String> {
    override val name: String = "name"
    override fun get(receiver: LineItem): String = receiver.name
    override fun setCopy(receiver: LineItem, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("name")
    override val default: String = ""
}
object LineItem_description: SerializableProperty<LineItem, String> {
    override val name: String = "description"
    override fun get(receiver: LineItem): String = receiver.description
    override fun setCopy(receiver: LineItem, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("description")
    override val default: String = ""
}
object LineItem_price: SerializableProperty<LineItem, Long> {
    override val name: String = "price"
    override fun get(receiver: LineItem): Long = receiver.price
    override fun setCopy(receiver: LineItem, value: Long) = receiver.copy(price = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("price")
    override val default: Long = 0L
}
object LineItem_order: SerializableProperty<LineItem, Int> {
    override val name: String = "order"
    override fun get(receiver: LineItem): Int = receiver.order
    override fun setCopy(receiver: LineItem, value: Int) = receiver.copy(order = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("order")
    override val default: Int = 0
}
object LineItem_files: SerializableProperty<LineItem, List<ProjectAttachment>> {
    override val name: String = "files"
    override fun get(receiver: LineItem): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: LineItem, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> = ListSerializer(ProjectAttachment.serializer())
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("files")
    override val default: List<ProjectAttachment> = listOf()
}
object LineItem_started: SerializableProperty<LineItem, Instant?> {
    override val name: String = "started"
    override fun get(receiver: LineItem): Instant? = receiver.started
    override fun setCopy(receiver: LineItem, value: Instant?) = receiver.copy(started = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("started")
    override val default: Instant? = null
}
object LineItem_state: SerializableProperty<LineItem, LineItemState> {
    override val name: String = "state"
    override fun get(receiver: LineItem): LineItemState = receiver.state
    override fun setCopy(receiver: LineItem, value: LineItemState) = receiver.copy(state = value)
    override val serializer: KSerializer<LineItemState> = LineItemState.serializer()
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("state")
    override val default: LineItemState = LineItemState.NotStarted
}
object LineItem_notApproved: SerializableProperty<LineItem, ClientFeedback?> {
    override val name: String = "notApproved"
    override fun get(receiver: LineItem): ClientFeedback? = receiver.notApproved
    override fun setCopy(receiver: LineItem, value: ClientFeedback?) = receiver.copy(notApproved = value)
    override val serializer: KSerializer<ClientFeedback?> = ClientFeedback.serializer().nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("notApproved")
    override val default: ClientFeedback? = null
}
object LineItem_requestingReview: SerializableProperty<LineItem, AccessInfo?> {
    override val name: String = "requestingReview"
    override fun get(receiver: LineItem): AccessInfo? = receiver.requestingReview
    override fun setCopy(receiver: LineItem, value: AccessInfo?) = receiver.copy(requestingReview = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("requestingReview")
    override val default: AccessInfo? = null
}
object LineItem_actionRequiredBy: SerializableProperty<LineItem, Instant?> {
    override val name: String = "actionRequiredBy"
    override fun get(receiver: LineItem): Instant? = receiver.actionRequiredBy
    override fun setCopy(receiver: LineItem, value: Instant?) = receiver.copy(actionRequiredBy = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("actionRequiredBy")
    override val default: Instant? = null
}
object LineItem_complete: SerializableProperty<LineItem, AccessInfo?> {
    override val name: String = "complete"
    override fun get(receiver: LineItem): AccessInfo? = receiver.complete
    override fun setCopy(receiver: LineItem, value: AccessInfo?) = receiver.copy(complete = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("complete")
    override val default: AccessInfo? = null
}
object LineItem_ledgerItem: SerializableProperty<LineItem, UUID?> {
    override val name: String = "ledgerItem"
    override fun get(receiver: LineItem): UUID? = receiver.ledgerItem
    override fun setCopy(receiver: LineItem, value: UUID?) = receiver.copy(ledgerItem = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("ledgerItem")
    override val default: UUID? = null
}
object LineItem_cancelled: SerializableProperty<LineItem, Instant?> {
    override val name: String = "cancelled"
    override fun get(receiver: LineItem): Instant? = receiver.cancelled
    override fun setCopy(receiver: LineItem, value: Instant?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = LineItem.serializer().tryFindAnnotations("cancelled")
    override val default: Instant? = null
}
