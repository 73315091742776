package com.crowpay.views.screens.contractor

import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.screens.common.ProjectPage
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable


@Routable("contractor/projects/{projectId}")
class ContractorProject(projectId: UUID) : ProjectPage(projectId) {
    override val lens: ProjectLens = ProjectLens.Contractor
}