package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.*
import com.crowpay.utils.validation.interceptWrite
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.Indent
import com.crowpay.views.components.expandIcon
import com.crowpay.views.components.indentedCol
import com.crowpay.views.components.space
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.delay

private val rowHeight = 2.5.rem
private val rowSpacing = 0.5.rem
private val checkboxSize = rowHeight - (rowSpacing *2.5)

fun ViewWriter.renderPunchListRow(
    title: Readable<String>,
    expanded: Writable<Boolean> = Property(false),
    items: Readable<List<PunchListItem>>,
    lineId: Readable<UUID?>,
    projectId: Readable<UUID>,
    allowNew: Readable<Boolean>,
    allowRequired: Readable<Boolean>,
    allowReorder: Boolean = true,
    lens: ProjectLens,
    indent: Indent = Indent.Full,
) {
    val newItems = Property(emptyList<Property<PunchListItem>>())
    col {
        spacing = when (indent) {
            Indent.Full -> 0.px
            Indent.Section -> 1.rem
        }
        row {
            expanding - button {
                row {
                    centered - expandIcon(expanded)
                    centered - subTitle {
                        ::content{ title() }
                    }
                }
                onClick {
                    expanded.toggle()
                }
            }

            sizeConstraints(width = 11.rem) - centered - tertiaryButton - button {
                existsDefaultFalse { lens == ProjectLens.Contractor && allowNew() }
                specCenteredText("+ Punch List Item")
                onClick {
                    expanded set true
                    newItems.value += Property(
                        PunchListItem(
                            project = projectId.await(),
                            lineItem = lineId.awaitOnce(),
                            content = "",
                            required = false
                        )
                    )
                }
            }
        }

        onlyWhen { expanded() } - indentedCol(indent) {
            space()
            punchListCol(items, newItems, lens, allowRequired, allowReorder)
            if (indent == Indent.Full) space(0.6.rem)
        }

        separator()
    }
}

fun ViewWriter.punchListCol(
    items: Readable<List<PunchListItem>>,
    newItems: Property<List<Property<PunchListItem>>>,
    lens: ProjectLens,
    allowRequired: Readable<Boolean>,
    allowReorder: Boolean = true,
) {
    col {
        spacing = 0.8.dp
        subTitle {
            exists = false
            ::exists {
                items().isEmpty() and newItems().isEmpty()
            }
            content = "No items"
        }

        renderNewPunchListItems(newItems, allowRequired)
        space { ::exists { newItems().isNotEmpty() } }

        col {
            spacing = 8.dp
            val active = shared {
                items()
                    .filter { it.complete == null }
                    .sortedWith(
                        compareByDescending<PunchListItem> { it.required }
                            .thenByDescending { it.order }
                            .thenBy { it.content }
                    )
            }
            forEach(active) { punchItem ->
                punchListItem(
                    punchItem,
                    lens,
                    allowReorder,
                    canMoveUp = {
                        val a = active()
                        val (req, not) = a.partition { it.required }
                        punchItem._id != req.firstOrNull()?._id && punchItem._id != not.firstOrNull()?._id
                    },
                    canMoveDown = {
                        val a = active()
                        val (req, not) = a.partition { it.required }
                        punchItem._id != req.lastOrNull()?._id && punchItem._id != not.lastOrNull()?._id
                    }
                )
            }
        }

        space()

        col {
            spacing = 8.dp
            val completed = shared {
                items()
                    .filter { it.complete != null }
                    .sortedWith(
                        compareByDescending<PunchListItem> { it.required }
                            .thenByDescending { it.order }
                            .thenBy { it.content }
                    )
            }
            forEach(completed) { punchItem ->
                punchListItem(
                    punchItem,
                    lens,
                    false,
                    canMoveUp = { false },
                    canMoveDown = { false }
                )
            }
        }
    }
}

fun ViewWriter.punchListItem(
    value: PunchListItem,
    viewType: ProjectLens,
    allowReorder: Boolean,
    canMoveUp: ReactiveContext.()->Boolean,
    canMoveDown: ReactiveContext.()->Boolean,
) {
    val completed = Property(value.complete != null).interceptWrite { checked ->
        val session = notNullSession()
        session.nonCached.punchListItem.run {
            if (checked) completePunchListItem(value._id)
            else resetPunchListItem(value._id)
        }

        delay(100)
        session.punchLists.totallyInvalidate()
    }

    lightSection - row {
        spacing = rowSpacing
        sizeConstraints(width = checkboxSize, height = checkboxSize) - centered - checkbox {
            spacing = 0.px
            themeChoice += ThemeDerivation { it.copy(foreground = AppColors.grey.dark).withoutBack }
            checked bind completed
            enabled = (viewType == ProjectLens.Contractor)
        }
        expanding - centered - body(value.run { if (required) "$content (Required)" else content })
        if (viewType == ProjectLens.Contractor) textButton("Delete") {
            onClick {
                notNullSession().punchLists[value._id].delete()
            }
        }
        if (allowReorder) col {
            expanding - button {
                spacing = 0.px
                ::enabled.invoke(canMoveUp)
                iconAndAction(Icon.chevronUp, "Move up") {
                    value.moveUp()
                }
            }
            expanding - button {
                spacing = 0.px
                ::enabled.invoke(canMoveDown)
                iconAndAction(Icon.chevronDown, "Move Down") {
                    value.moveDown()
                }
            }
        }
    }
}

fun ViewWriter.renderNewPunchListItems(
    items: Property<List<Property<PunchListItem>>>,
    allowRequired: Readable<Boolean>
) {

    col {
        spacing = 8.dp
        existsDefaultFalse { items().isNotEmpty() }

        forEach(items) { item ->
            suspend fun save() {
                notNullSession().punchLists.insert(item())
                items.modify { it - item }
            }

            validating {
                lightSection - row {
                    spacing = rowSpacing

                    centered - sizeConstraints(width = checkboxSize, height = checkboxSize) - checkbox {
                        existsDefaultFalse { allowRequired() }
                        checked bind item.lensPath { it.required }
                    }
                    centered - body {
                        existsDefaultFalse { allowRequired() }
                        content = "Required"
                    }

                    val name = item.lensPath { it.content }.validate { it.isNotBlank() }
                    expanding - PunchFieldSemantic.onNext - validate(name) - textField {
                        spacing = 0.5.rem
                        hint = "Name"
                        content bind name
                        action = Action("Save", ::save)
                    }

                    tertiaryButton - button {
                        specCenteredText("Cancel")
                        onClick { items.modify { it - item } }
                    }
                    tertiaryButton - button {
                        ::enabled { allValid() }
                        specCenteredText("Save")
                        onClick(action = ::save)
                    }
                }
            }
        }
    }
}

// ORDERING
// --------
// All punch item orders are on a scale from 0-1, starting at 0.5. When you move an item up it looks for the two
// next highest items, and then sets its priority to be between them. If there is only one item with a higher priority
// then it sets it priority as the average between the next highest and 1. If there is none higher, then the priority
// is set to the average of its own priority and 1. Moving down does the same thing but averaging toward 0.
suspend fun PunchListItem.moveUp() {
    notNullSession().punchLists.run {
        val nextHighest = query(
            Query(
                condition { (it.lineItem eq lineItem) and (it.order gt order) and (it.required eq required) },
                sort { it.order.ascending() },
                limit = 2
            )
        ).awaitOnce()

//        println("nextHighest: ${nextHighest.size}")

        val newOrder = when (nextHighest.size) {
            0 -> 0.5*(order + 1.0)
            1 -> 0.5*(nextHighest.first().order + 1.0)
            else -> 0.5*(nextHighest[0].order + nextHighest[1].order)
        }

//        println("Current: $order  Next: ${nextHighest.map { it.order }}  New: $newOrder")

        get(_id).modify(
            modification { it.order assign newOrder }
        )
    }
}

suspend fun PunchListItem.moveDown() {
    notNullSession().punchLists.run {
        val nextLowest = query(
            Query(
                condition { (it.lineItem eq lineItem) and (it.order lt order) and (it.required eq required) },
                sort { it.order.descending() },
                limit = 2
            )
        ).awaitOnce()

        val newOrder = when (nextLowest.size) {
            0 -> 0.5*(order)
            1 -> 0.5*(nextLowest.first().order)
            else -> 0.5*(nextLowest[0].order + nextLowest[1].order)
        }

//        println("Current: $order  Next: ${nextLowest.map { it.order }}  New: $newOrder")

        get(_id).modify(
            modification { it.order assign newOrder }
        )
    }
}


fun ViewWriter.punchList(value: PunchListItem, viewType: ProjectLens, allowRequired: Readable<Boolean>) {

    row {
        lazy(allowRequired) { allowRequired ->

            suspend fun handleCheck() {
                val session = notNullSession()
                if (value.complete == null) {
                    session.nonCached.punchListItem.completePunchListItem(value._id)
                } else {
                    session.nonCached.punchListItem.resetPunchListItem(value._id)
                }
                delay(100)
                session.punchLists.totallyInvalidate()
            }

            buttonTheme - button {
                spacing = 0.dp
                sizeConstraints(width = rowHeight, height = rowHeight) - icon(Icon.close, "Remove Punch Item")
                visible = value.complete == null && viewType == ProjectLens.Contractor
                onClick {
                    notNullSession().punchLists[value._id].delete()
                }
            }

            when {
                viewType == ProjectLens.Contractor && (allowRequired || !value.required) -> {
                    centered - button {
                        themeChoice =
                            ThemeDerivation {
                                if (value.complete == null)
                                    it[ButtonSemantic].theme.copy(
                                        spacing = 0.dp,
                                        outlineWidth = AppDimensions.outlineWidth,
                                    )
                                        .withBack
                                else
                                    it[ButtonSemantic].theme.copy(
                                        spacing = 0.dp,
                                        outlineWidth = AppDimensions.outlineWidth,
                                        background = AppColors.grey.light2,
                                    )
                                        .withBack

                            }

                        if (value.complete != null) {
                            icon(Icon.done, "Done") in sizeConstraints(width = rowHeight, height = rowHeight)
                        }
                        onClick(action = ::handleCheck)
                    } in sizeConstraints(width = rowHeight, height = rowHeight)


                    centered - hiddenButton - expanding - button {
                        spacing = 0.dp
                        body {
                            themeChoice += ThemeDerivation {
                                if (value.required)
                                    it
                                        .copy(
                                            outlineWidth = 0.dp,
                                            foreground = AppColors.red.dark,
                                            body = it.font.copy(bold = true)
                                        )
                                        .withoutBack
                                else
                                    it
                                        .copy(outlineWidth = 0.dp)
                                        .withoutBack
                            }
                            content = value.run { if (required) "$content (Required)" else content }
                        }
                        onClick(action = ::handleCheck)
                    }
                }

                else -> {
                    if (value.complete != null)
                        centered -
                                sizeConstraints(width = rowHeight, height = rowHeight) -
                                icon(Icon.done, "Done") {
                                    themeChoice += ThemeDerivation {
                                        it[ButtonSemantic].theme.copy(
                                            spacing = 0.dp,
                                            outlineWidth = AppDimensions.outlineWidth,
                                            background = AppColors.grey.light2,
                                        )
                                            .withBack
                                    }
                                }
                    else {
                        outlined -
                                centered -
                                sizeConstraints(width = rowHeight, height = rowHeight) -
                                stack {
                                    spacing = 0.dp
                                }
                    }


                    centered - expanding - body {
                        body {
                            themeChoice += ThemeDerivation {
                                if (value.required)
                                    it
                                        .copy(
                                            outlineWidth = 0.dp,
                                            foreground = AppColors.red.dark,
                                            body = it.font.copy(bold = true)
                                        )
                                        .withoutBack
                                else
                                    it
                                        .copy(outlineWidth = 0.dp)
                                        .withoutBack
                            }
                            content = value.run { if (required) "$content (Required)" else content }
                        }
                    }

                }
            }
        }

    }
}