package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.actuals.rotation
import com.crowpay.extensions.withSpacing
import com.crowpay.utils.dynamicLens
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.label2
import com.crowpay.views.components.space
import com.crowpay.views.theming.body
import com.crowpay.views.theming.redText
import com.crowpay.views.theming.smallBody
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon


class AdjustLineItem(
    ogChange: ItemChange,
    val allLines: Readable<List<LineItem>>,
    val editedLines: Readable<Set<UUID>>,
    val onRemove: suspend () -> Unit,
) : Validator(), Screen {

    val draft = Draft(Property(ogChange))
    val ogId = draft.lens(
        get = { it.itemId },
        modify = { og, it -> og.copy(itemId = it) }
    )
    val originalName = shared {
        allLines().find { it._id == ogId() }?.name ?: "Select Work Item"
    }
    val cancelled = draft.lens(
        get = { it.cancelled },
        modify = { og, it -> og.copy(cancelled = it) }
    )
    val expanded = Property(false)

    val description = draft.lens(
        get = { it.description },
        modify = { og, it -> og.copy(description = it) }
    )
        .validate { cancelled() || it.isNotBlank() }

    override fun ViewWriter.render() {

        col {
            spacing = 0.px

            withSpacing(0.dp) - row {
                expanding - buttonTheme - button {
                    spacing = 0.dp
                    row {
                        centered - icon(Icon.chevronRight, "") {
                            ::rotation{ if (expanded()) (-90).degrees else 0.degrees }
                        }
                        centered - body { ::content{ originalName() } }
                        centered - redText - onlyWhen { cancelled() } - body {
                            content = "(Cancelled)"
                        }
                        expanding - buttonTheme - stack {
                            visible = false
                            icon(Icon.chevronRight, "")
                        }
                    }
                    onClick {
                        expanded.set(!expanded.await())
                    }
                }
                space(AppDimensions.buttonRowSpacing)
                buttonTheme - centered - button {
                    stack {
                        onlyWhen { cancelled() } -
                                sizeConstraints(width = 1.5.rem, height = 1.5.rem) -
                                body("Undo Cancel")
                        onlyWhen { !cancelled() } -
                                redText - centered - body("Cancel")
                    }
                    onClick {
                        cancelled.set(!cancelled.await())
                        description.refresh()
                    }
                }
                space(AppDimensions.buttonRowSpacing)
                buttonTheme - button {
                    sizeConstraints(width = 1.5.rem, height = 1.5.rem) - icon(Icon.trash, description = "Remove Item")
                    onClick(action = onRemove)
                }
            }
            onlyWhen { expanded() } - padded - stack {
                spacing = AppDimensions.fullIndent
                col {
                    label2("Work Item") {
                        val value = ogId.validate { it != SharedUtils.nullUUID }
                        fieldTheme - validate(value) - expanding - select {
                            bind(
                                value.dynamicLens(
                                    get = { allLines().find { line -> line._id == it } },
                                    set = { it?._id ?: SharedUtils.nullUUID }
                                ),
                                shared {
                                    listOf(null) + allLines()
                                        .filter { it._id !in editedLines().minus(ogId()) }
                                }
                            ) { it?.name ?: "Select Work Item" }
                        }
                    }
                    onlyWhen { !cancelled() } - col {
                        spacing = 0.px

                        label2("Price (Dollars)") {
                            fieldTheme - numberInput {
                                keyboardHints = KeyboardHints.integer
                                hint = "Price"
                                content bind draft
                                    .lens(
                                        get = { it.price.toDouble() },
                                        modify = { o, it -> o.copy(price = it?.toLong() ?: 0L) }
                                    )
                            }
                        }

                        label2("Description") {
                            fieldTheme - validate(description) - textArea {
                                hint = "Description"
                                content bind description
                            } in sizeConstraints(minHeight = 7.rem)
                        }
                    }
                    onlyWhen { cancelled() } - col {
                        spacing = 0.dp
                        body {
                            align = Align.Center
                            content = "Work Item to be Cancelled"
                        }
                        smallBody {
                            align = Align.Center
                            content = "(Cancelled work item message here)"
                        }
                    }

                }
            }
        }
    }
}