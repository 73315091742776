package com.crowpay.views.components.project

import com.crowpay.*
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.views.theming.body
import com.crowpay.views.theming.notificationBar
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.projectPunchList(
    project: Readable<Project>,
    lineItems: Readable<List<LineItem>>,
    punchLists: Readable<List<PunchListItem>>,
    lens: ProjectLens,
) {
    col {
        spacing = 0.dp
        val expandedPunchLists = Property<Set<UUID?>>(emptySet())


        notificationBar(AppColors.blue) - body {
            existsDefaultFalse{
                lens == ProjectLens.Contractor &&
                        project().state < ProjectState.Accepted
            }
            content = "You cannot create work items until the project has been accepted"
        }

        notificationBar(AppColors.blue) - body {
            existsDefaultFalse{
                lens == ProjectLens.Contractor &&
                        project().state >= ProjectState.Complete
            }
            content = "You cannot create work items after the project has been completed"
        }

        col {
            spacing = 0.dp
            renderPunchListRow(
                title = Constant("Punch List"),
                expanded = shared { expandedPunchLists().contains(null) }
                    .withWrite { if (it) expandedPunchLists.value += null else expandedPunchLists.value -= null },
                items = shared { punchLists().filter { it.lineItem == null || it.required } },
                lineId = Constant(null),
                projectId = shared { project()._id },
                allowNew = shared {
                    val p = project()
                    lens == ProjectLens.Contractor &&
                            p.state >= ProjectState.Accepted &&
                            p.state < ProjectState.Complete
                },
                allowRequired = shared { project().state < ProjectState.Complete },
                allowReorder = false,
                lens = lens
            )
            col {
                spacing = 0.dp
                forEachUpdating(lineItems) { item ->
                    renderPunchListRow(
                        title = shared { item().name },
                        expanded = shared { expandedPunchLists().contains(item()._id) }
                            .withWrite { if (it) expandedPunchLists.value += item.awaitOnce()._id else expandedPunchLists.value -= item.awaitOnce()._id },
                        items = shared { punchLists().filter { it.lineItem == item()._id } },
                        lineId = shared { item()._id },
                        projectId = shared { project()._id },
                        allowNew = shared {
                            val p = project()
                            lens == ProjectLens.Contractor &&
                                    p.state >= ProjectState.Accepted &&
                                    p.state < ProjectState.Complete &&
                                    item().state < LineItemState.Complete
                        },
                        allowRequired = shared { item().requestingReview == null },
                        lens = lens
                    )
                }
            }
        }
    }
}