package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.sdk.currentSession
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.project.AdjustLineItem
import com.crowpay.views.components.changeOrders.ChangeOrderLineEdit
import com.crowpay.views.components.files.resolveProjectAttachments
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.modification

@Routable("/contractor/change-order/{changeOrderId}/edit")
class EditChangeOrder(val changeOrderId: UUID) : AbstractChangeOrder() {

    override val change: Readable<ChangeRequest> = sharedSuspending {
        notNullSession().nonCached.changeRequest.detail(changeOrderId)
    }
    override val allowDelete: Readable<Boolean> = shared { change().denied == null }

    override val project = sharedSuspending {
        notNullSession().nonCached.project.detail(change().project)
    }

    override suspend fun save() {
        val session = currentSession() ?: return
        val result = session.changeRequests[changeOrderId].modify(modification {
            it.title assign form.requestTitle.await()
            it.projectDescription assign form.projectDescription.value.trim().takeIf { it.isNotBlank() }
            it.itemChanges assign form.itemChangeForms.await().values.map { form: AdjustLineItem ->
                form.draft.publish()
                form.draft.await()
            }
            it.newItems assign form.newLineForms.await().values.map { form: ChangeOrderLineEdit ->
                form.draft.publish()
                val line = form.draft.await()
                val newFiles = form.files.awaitOnce().resolveProjectAttachments()
                line.copy(files = newFiles)
            }
        })!!

        session.changeRequests.localSignalUpdate(
            matching = { it._id == result._id },
            modify = { result }
        )
    }

}