
package com.crowpay.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import kotlin.String
import com.crowpay.RegisterContractor
import kotlin.Unit
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.crowpay.User
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.exceptions.ReportedExceptionGroup
import kotlin.Long
import com.crowpay.ChangeRequest
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.crowpay.ChangeDenyRequest
import com.crowpay.ClientTermsAndConditionsAgreement
import com.crowpay.ClientTermsAndConditionsVersion
import kotlinx.datetime.Instant
import com.crowpay.Client
import com.crowpay.Contractor
import com.lightningkite.lightningserver.files.UploadInformation
import com.crowpay.AccountInfo
import com.crowpay.PaymentSetupRequest
import com.crowpay.PendingCashOut
import com.crowpay.ContractorDocument
import com.crowpay.ContractorTermsAndConditionsAgreement
import com.crowpay.ContractorTermsAndConditionsVersion
import com.crowpay.DisputeLog
import com.crowpay.License
import com.crowpay.LineItem
import com.crowpay.LineItemLog
import com.crowpay.Payout
import com.crowpay.Project
import com.crowpay.PaymentRequest
import com.crowpay.ProjectLedgerItem
import com.crowpay.FiledForArbitration
import com.crowpay.PunchListItem
import com.crowpay.Trade
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.crowpay.PaymentMethod
import com.lightningkite.lightningserver.auth.subject.Session
import com.crowpay.FullProject
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp

open class AbstractAnonymousSession(val api: Api) {
    val adminReports: AbstractAnonymousSessionAdminReportsApi = AbstractAnonymousSessionAdminReportsApi(api.adminReports)
    val changeRequest: AbstractAnonymousSessionChangeRequestApi = AbstractAnonymousSessionChangeRequestApi(api.changeRequest)
    val clientTermsAndConditionsAgreement: AbstractAnonymousSessionClientTermsAndConditionsAgreementApi = AbstractAnonymousSessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement)
    val clientTermsAndConditionsVersion: AbstractAnonymousSessionClientTermsAndConditionsVersionApi = AbstractAnonymousSessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion)
    val client: AbstractAnonymousSessionClientApi = AbstractAnonymousSessionClientApi(api.client)
    val contractor: AbstractAnonymousSessionContractorApi = AbstractAnonymousSessionContractorApi(api.contractor)
    val contractorDocument: AbstractAnonymousSessionContractorDocumentApi = AbstractAnonymousSessionContractorDocumentApi(api.contractorDocument)
    val contractorTermsAndConditionsAgreement: AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi = AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement)
    val contractorTermsAndConditionsVersion: AbstractAnonymousSessionContractorTermsAndConditionsVersionApi = AbstractAnonymousSessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion)
    val disputeLog: AbstractAnonymousSessionDisputeLogApi = AbstractAnonymousSessionDisputeLogApi(api.disputeLog)
    val license: AbstractAnonymousSessionLicenseApi = AbstractAnonymousSessionLicenseApi(api.license)
    val lineItem: AbstractAnonymousSessionLineItemApi = AbstractAnonymousSessionLineItemApi(api.lineItem)
    val lineItemLog: AbstractAnonymousSessionLineItemLogApi = AbstractAnonymousSessionLineItemLogApi(api.lineItemLog)
    val payout: AbstractAnonymousSessionPayoutApi = AbstractAnonymousSessionPayoutApi(api.payout)
    val project: AbstractAnonymousSessionProjectApi = AbstractAnonymousSessionProjectApi(api.project)
    val projectLedgerItem: AbstractAnonymousSessionProjectLedgerItemApi = AbstractAnonymousSessionProjectLedgerItemApi(api.projectLedgerItem)
    val punchListItem: AbstractAnonymousSessionPunchListItemApi = AbstractAnonymousSessionPunchListItemApi(api.punchListItem)
    val trade: AbstractAnonymousSessionTradeApi = AbstractAnonymousSessionTradeApi(api.trade)
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val otpSecret: AbstractAnonymousSessionOtpSecretApi = AbstractAnonymousSessionOtpSecretApi(api.otpSecret)
    val oneTimePasswordProof: AbstractAnonymousSessionOneTimePasswordProofApi = AbstractAnonymousSessionOneTimePasswordProofApi(api.oneTimePasswordProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    open class AbstractAnonymousSessionAdminReportsApi(val api: Api.AdminReportsApi) {
    }
    open class AbstractAnonymousSessionChangeRequestApi(val api: Api.ChangeRequestApi) {
    }
    open class AbstractAnonymousSessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi) {
    }
    open class AbstractAnonymousSessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi) {
    }
    open class AbstractAnonymousSessionClientApi(val api: Api.ClientApi) {
    }
    open class AbstractAnonymousSessionContractorApi(val api: Api.ContractorApi) {
        suspend fun sendRegistrationEmail(input: String): String = api.sendRegistrationEmail(input)
        suspend fun register(input: RegisterContractor): Unit = api.register(input)
    }
    open class AbstractAnonymousSessionContractorDocumentApi(val api: Api.ContractorDocumentApi) {
    }
    open class AbstractAnonymousSessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi) {
    }
    open class AbstractAnonymousSessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi) {
    }
    open class AbstractAnonymousSessionDisputeLogApi(val api: Api.DisputeLogApi) {
    }
    open class AbstractAnonymousSessionLicenseApi(val api: Api.LicenseApi) {
    }
    open class AbstractAnonymousSessionLineItemApi(val api: Api.LineItemApi) {
    }
    open class AbstractAnonymousSessionLineItemLogApi(val api: Api.LineItemLogApi) {
    }
    open class AbstractAnonymousSessionPayoutApi(val api: Api.PayoutApi) {
    }
    open class AbstractAnonymousSessionProjectApi(val api: Api.ProjectApi) {
    }
    open class AbstractAnonymousSessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi) {
    }
    open class AbstractAnonymousSessionPunchListItemApi(val api: Api.PunchListItemApi) {
    }
    open class AbstractAnonymousSessionTradeApi(val api: Api.TradeApi) {
    }
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionOtpSecretApi(val api: Api.OtpSecretApi) {
    }
    open class AbstractAnonymousSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        suspend fun register(input: User): Unit = api.register(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
}

abstract class AbstractUserSession(api: Api, userToken: String, userAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val userToken: String
    abstract val userAccessToken: suspend () -> String
    val adminReports: UserSessionAdminReportsApi = UserSessionAdminReportsApi(api.adminReports, userToken, userAccessToken)
    val changeRequest: UserSessionChangeRequestApi = UserSessionChangeRequestApi(api.changeRequest, userToken, userAccessToken)
    val clientTermsAndConditionsAgreement: UserSessionClientTermsAndConditionsAgreementApi = UserSessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement, userToken, userAccessToken)
    val clientTermsAndConditionsVersion: UserSessionClientTermsAndConditionsVersionApi = UserSessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion, userToken, userAccessToken)
    val client: UserSessionClientApi = UserSessionClientApi(api.client, userToken, userAccessToken)
    val contractor: UserSessionContractorApi = UserSessionContractorApi(api.contractor, userToken, userAccessToken)
    val contractorDocument: UserSessionContractorDocumentApi = UserSessionContractorDocumentApi(api.contractorDocument, userToken, userAccessToken)
    val contractorTermsAndConditionsAgreement: UserSessionContractorTermsAndConditionsAgreementApi = UserSessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement, userToken, userAccessToken)
    val contractorTermsAndConditionsVersion: UserSessionContractorTermsAndConditionsVersionApi = UserSessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion, userToken, userAccessToken)
    val disputeLog: UserSessionDisputeLogApi = UserSessionDisputeLogApi(api.disputeLog, userToken, userAccessToken)
    val license: UserSessionLicenseApi = UserSessionLicenseApi(api.license, userToken, userAccessToken)
    val lineItem: UserSessionLineItemApi = UserSessionLineItemApi(api.lineItem, userToken, userAccessToken)
    val lineItemLog: UserSessionLineItemLogApi = UserSessionLineItemLogApi(api.lineItemLog, userToken, userAccessToken)
    val payout: UserSessionPayoutApi = UserSessionPayoutApi(api.payout, userToken, userAccessToken)
    val project: UserSessionProjectApi = UserSessionProjectApi(api.project, userToken, userAccessToken)
    val projectLedgerItem: UserSessionProjectLedgerItemApi = UserSessionProjectLedgerItemApi(api.projectLedgerItem, userToken, userAccessToken)
    val punchListItem: UserSessionPunchListItemApi = UserSessionPunchListItemApi(api.punchListItem, userToken, userAccessToken)
    val trade: UserSessionTradeApi = UserSessionTradeApi(api.trade, userToken, userAccessToken)
    val user: UserSessionUserApi = UserSessionUserApi(api.user, userToken, userAccessToken)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, userToken, userAccessToken)
    val otpSecret: UserSessionOtpSecretApi = UserSessionOtpSecretApi(api.otpSecret, userToken, userAccessToken)
    val oneTimePasswordProof: UserSessionOneTimePasswordProofApi = UserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, userToken, userAccessToken)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, userToken, userAccessToken)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, userToken, userAccessToken)
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth(userAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun listRecentExceptions(): List<ReportedExceptionGroup> = api.listRecentExceptions(userAccessToken)
    class UserSessionAdminReportsApi(val api: Api.AdminReportsApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun totalInEscrow(): Long = api.totalInEscrow(userAccessToken)
        suspend fun totalCashOutPending(): Long = api.totalCashOutPending(userAccessToken)
        suspend fun totalInProgress(): Long = api.totalInProgress(userAccessToken)
        suspend fun totalSubstantialCompletion(): Long = api.totalSubstantialCompletion(userAccessToken)
        suspend fun totalByState(): Map<String, Long> = api.totalByState(userAccessToken)
    }
    class UserSessionChangeRequestApi(val api: Api.ChangeRequestApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ChangeRequest, UUID> {
        override suspend fun default(): ChangeRequest = api.default(userAccessToken)
        override suspend fun query(input: Query<ChangeRequest>): List<ChangeRequest> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ChangeRequest>): List<Partial<ChangeRequest>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ChangeRequest = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ChangeRequest>): List<ChangeRequest> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ChangeRequest): ChangeRequest = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ChangeRequest): ChangeRequest = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ChangeRequest>): List<ChangeRequest> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ChangeRequest): ChangeRequest = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ChangeRequest>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ChangeRequest>): EntryChange<ChangeRequest> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ChangeRequest>): ChangeRequest = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ChangeRequest>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ChangeRequest>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ChangeRequest>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ChangeRequest>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ChangeRequest>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun publishChangeRequest(id: UUID): ChangeRequest = api.publishChangeRequest(id, userAccessToken)
        suspend fun unPublishChangeRequest(id: UUID): ChangeRequest = api.unPublishChangeRequest(id, userAccessToken)
        suspend fun acceptChangeRequest(id: UUID, input: String): ChangeRequest = api.acceptChangeRequest(id, input, userAccessToken)
        suspend fun denyChangeRequest(id: UUID, input: ChangeDenyRequest): ChangeRequest = api.denyChangeRequest(id, input, userAccessToken)
    }
    class UserSessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ClientTermsAndConditionsAgreement, UUID> {
        override suspend fun default(): ClientTermsAndConditionsAgreement = api.default(userAccessToken)
        override suspend fun query(input: Query<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsAgreement>): List<Partial<ClientTermsAndConditionsAgreement>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ClientTermsAndConditionsAgreement = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsAgreement>): List<ClientTermsAndConditionsAgreement> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ClientTermsAndConditionsAgreement): ClientTermsAndConditionsAgreement = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsAgreement>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>): EntryChange<ClientTermsAndConditionsAgreement> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ClientTermsAndConditionsAgreement>): ClientTermsAndConditionsAgreement = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsAgreement>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ClientTermsAndConditionsAgreement>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsAgreement>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsAgreement>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsAgreement>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ClientTermsAndConditionsVersion, Instant> {
        override suspend fun default(): ClientTermsAndConditionsVersion = api.default(userAccessToken)
        override suspend fun query(input: Query<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ClientTermsAndConditionsVersion>): List<Partial<ClientTermsAndConditionsVersion>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: Instant): ClientTermsAndConditionsVersion = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.insert(input, userAccessToken)
        override suspend fun upsert(id: Instant, input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ClientTermsAndConditionsVersion>): List<ClientTermsAndConditionsVersion> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: Instant, input: ClientTermsAndConditionsVersion): ClientTermsAndConditionsVersion = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ClientTermsAndConditionsVersion>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ClientTermsAndConditionsVersion>): EntryChange<ClientTermsAndConditionsVersion> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: Instant, input: Modification<ClientTermsAndConditionsVersion>): ClientTermsAndConditionsVersion = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ClientTermsAndConditionsVersion>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: Instant): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ClientTermsAndConditionsVersion>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ClientTermsAndConditionsVersion>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ClientTermsAndConditionsVersion>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ClientTermsAndConditionsVersion>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionClientApi(val api: Api.ClientApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Client, UUID> {
        override suspend fun default(): Client = api.default(userAccessToken)
        override suspend fun query(input: Query<Client>): List<Client> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Client>): List<Partial<Client>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Client = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Client>): List<Client> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Client): Client = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Client): Client = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Client>): List<Client> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Client): Client = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Client>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Client>): EntryChange<Client> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Client>): Client = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Client>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Client>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Client>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Client>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Client>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionContractorApi(val api: Api.ContractorApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Contractor, UUID> {
        override suspend fun default(): Contractor = api.default(userAccessToken)
        override suspend fun query(input: Query<Contractor>): List<Contractor> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Contractor>): List<Partial<Contractor>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Contractor = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Contractor>): List<Contractor> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Contractor): Contractor = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Contractor): Contractor = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Contractor>): List<Contractor> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Contractor): Contractor = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Contractor>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Contractor>): EntryChange<Contractor> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Contractor>): Contractor = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Contractor>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Contractor>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Contractor>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Contractor>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Contractor>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(userAccessToken)
        suspend fun submitForApproval(id: UUID): Unit = api.submitForApproval(id, userAccessToken)
        suspend fun getPaymentInfo(id: UUID): AccountInfo? = api.getPaymentInfo(id, userAccessToken)
        suspend fun setupPaymentInfo(id: UUID, input: PaymentSetupRequest): AccountInfo = api.setupPaymentInfo(id, input, userAccessToken)
        suspend fun clearPaymentInfo(id: UUID): Unit = api.clearPaymentInfo(id, userAccessToken)
        suspend fun pendingCashOut(id: UUID): PendingCashOut = api.pendingCashOut(id, userAccessToken)
        suspend fun requestCashOut(id: UUID): Unit = api.requestCashOut(id, userAccessToken)
        suspend fun sendRegistrationEmail(input: String): String = api.sendRegistrationEmail(input)
        suspend fun register(input: RegisterContractor): Unit = api.register(input)
    }
    class UserSessionContractorDocumentApi(val api: Api.ContractorDocumentApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ContractorDocument, UUID> {
        override suspend fun default(): ContractorDocument = api.default(userAccessToken)
        override suspend fun query(input: Query<ContractorDocument>): List<ContractorDocument> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ContractorDocument>): List<Partial<ContractorDocument>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ContractorDocument = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ContractorDocument>): List<ContractorDocument> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ContractorDocument): ContractorDocument = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ContractorDocument): ContractorDocument = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ContractorDocument>): List<ContractorDocument> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ContractorDocument): ContractorDocument = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ContractorDocument>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorDocument>): EntryChange<ContractorDocument> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ContractorDocument>): ContractorDocument = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ContractorDocument>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ContractorDocument>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ContractorDocument>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ContractorDocument>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorDocument>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(userAccessToken)
    }
    class UserSessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ContractorTermsAndConditionsAgreement, UUID> {
        override suspend fun default(): ContractorTermsAndConditionsAgreement = api.default(userAccessToken)
        override suspend fun query(input: Query<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsAgreement>): List<Partial<ContractorTermsAndConditionsAgreement>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ContractorTermsAndConditionsAgreement = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsAgreement>): List<ContractorTermsAndConditionsAgreement> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ContractorTermsAndConditionsAgreement): ContractorTermsAndConditionsAgreement = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsAgreement>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>): EntryChange<ContractorTermsAndConditionsAgreement> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ContractorTermsAndConditionsAgreement>): ContractorTermsAndConditionsAgreement = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsAgreement>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ContractorTermsAndConditionsAgreement>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsAgreement>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsAgreement>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsAgreement>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ContractorTermsAndConditionsVersion, Instant> {
        override suspend fun default(): ContractorTermsAndConditionsVersion = api.default(userAccessToken)
        override suspend fun query(input: Query<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ContractorTermsAndConditionsVersion>): List<Partial<ContractorTermsAndConditionsVersion>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: Instant): ContractorTermsAndConditionsVersion = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.insert(input, userAccessToken)
        override suspend fun upsert(id: Instant, input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ContractorTermsAndConditionsVersion>): List<ContractorTermsAndConditionsVersion> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: Instant, input: ContractorTermsAndConditionsVersion): ContractorTermsAndConditionsVersion = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ContractorTermsAndConditionsVersion>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>): EntryChange<ContractorTermsAndConditionsVersion> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: Instant, input: Modification<ContractorTermsAndConditionsVersion>): ContractorTermsAndConditionsVersion = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ContractorTermsAndConditionsVersion>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: Instant): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ContractorTermsAndConditionsVersion>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ContractorTermsAndConditionsVersion>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ContractorTermsAndConditionsVersion>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ContractorTermsAndConditionsVersion>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionDisputeLogApi(val api: Api.DisputeLogApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<DisputeLog, UUID> {
        override suspend fun default(): DisputeLog = api.default(userAccessToken)
        override suspend fun query(input: Query<DisputeLog>): List<DisputeLog> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<DisputeLog>): List<Partial<DisputeLog>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): DisputeLog = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<DisputeLog>): List<DisputeLog> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: DisputeLog): DisputeLog = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: DisputeLog): DisputeLog = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<DisputeLog>): List<DisputeLog> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: DisputeLog): DisputeLog = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<DisputeLog>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DisputeLog>): EntryChange<DisputeLog> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<DisputeLog>): DisputeLog = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<DisputeLog>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<DisputeLog>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<DisputeLog>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<DisputeLog>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DisputeLog>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionLicenseApi(val api: Api.LicenseApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<License, UUID> {
        override suspend fun default(): License = api.default(userAccessToken)
        override suspend fun query(input: Query<License>): List<License> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<License>): List<Partial<License>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): License = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<License>): List<License> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: License): License = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: License): License = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<License>): List<License> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: License): License = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<License>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<License>): EntryChange<License> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<License>): License = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<License>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<License>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<License>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<License>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<License>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(userAccessToken)
    }
    class UserSessionLineItemApi(val api: Api.LineItemApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<LineItem, UUID> {
        override suspend fun default(): LineItem = api.default(userAccessToken)
        override suspend fun query(input: Query<LineItem>): List<LineItem> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<LineItem>): List<Partial<LineItem>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): LineItem = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<LineItem>): List<LineItem> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: LineItem): LineItem = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: LineItem): LineItem = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<LineItem>): List<LineItem> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: LineItem): LineItem = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<LineItem>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItem>): EntryChange<LineItem> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<LineItem>): LineItem = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<LineItem>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<LineItem>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<LineItem>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<LineItem>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItem>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun forceRefreshLineItemState(id: UUID): LineItem = api.forceRefreshLineItemState(id, userAccessToken)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(userAccessToken)
        suspend fun requestReview(id: UUID): LineItem = api.requestReview(id, userAccessToken)
        suspend fun rejectComplete(id: UUID, input: String): LineItem = api.rejectComplete(id, input, userAccessToken)
        suspend fun complete(id: UUID): LineItem = api.complete(id, userAccessToken)
    }
    class UserSessionLineItemLogApi(val api: Api.LineItemLogApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<LineItemLog, UUID> {
        override suspend fun default(): LineItemLog = api.default(userAccessToken)
        override suspend fun query(input: Query<LineItemLog>): List<LineItemLog> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<LineItemLog>): List<Partial<LineItemLog>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): LineItemLog = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<LineItemLog>): List<LineItemLog> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: LineItemLog): LineItemLog = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: LineItemLog): LineItemLog = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<LineItemLog>): List<LineItemLog> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: LineItemLog): LineItemLog = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<LineItemLog>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LineItemLog>): EntryChange<LineItemLog> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<LineItemLog>): LineItemLog = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<LineItemLog>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<LineItemLog>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<LineItemLog>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<LineItemLog>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LineItemLog>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionPayoutApi(val api: Api.PayoutApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Payout, Instant> {
        override suspend fun default(): Payout = api.default(userAccessToken)
        override suspend fun query(input: Query<Payout>): List<Payout> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Payout>): List<Partial<Payout>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: Instant): Payout = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Payout>): List<Payout> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Payout): Payout = api.insert(input, userAccessToken)
        override suspend fun upsert(id: Instant, input: Payout): Payout = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Payout>): List<Payout> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: Instant, input: Payout): Payout = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Payout>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: Instant, input: Modification<Payout>): EntryChange<Payout> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: Instant, input: Modification<Payout>): Payout = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Payout>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: Instant): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Payout>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Payout>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Payout>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Payout>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionProjectApi(val api: Api.ProjectApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Project, UUID> {
        override suspend fun default(): Project = api.default(userAccessToken)
        override suspend fun query(input: Query<Project>): List<Project> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Project>): List<Partial<Project>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Project = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Project>): List<Project> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Project): Project = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Project): Project = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Project>): List<Project> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Project): Project = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Project>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Project>): EntryChange<Project> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Project>): Project = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Project>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Project>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Project>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Project>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Project>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun forceRefreshProjectState(project: UUID): Project = api.forceRefreshProjectState(project, userAccessToken)
        suspend fun cancelProject(project: UUID): Project = api.cancelProject(project, userAccessToken)
        suspend fun publishProject(project: UUID): Project = api.publishProject(project, userAccessToken)
        suspend fun anonymousLink(project: UUID): String = api.anonymousLink(project, userAccessToken)
        suspend fun acceptProject(project: UUID, input: String): Project = api.acceptProject(project, input, userAccessToken)
        suspend fun requestFunding(project: UUID): Project = api.requestFunding(project, userAccessToken)
        suspend fun cancelFundingRequest(project: UUID): Project = api.cancelFundingRequest(project, userAccessToken)
        suspend fun payIntoEscrow(project: UUID, input: PaymentRequest): ProjectLedgerItem? = api.payIntoEscrow(project, input, userAccessToken)
        suspend fun disputeProject(project: UUID, input: String): Project = api.disputeProject(project, input, userAccessToken)
        suspend fun disputeFeedback(project: UUID, input: String): Project = api.disputeFeedback(project, input, userAccessToken)
        suspend fun endDispute(project: UUID): Project = api.endDispute(project, userAccessToken)
        suspend fun addArbitrationDocuments(project: UUID, input: FiledForArbitration): Project = api.addArbitrationDocuments(project, input, userAccessToken)
        suspend fun requestComplete(project: UUID): Project = api.requestComplete(project, userAccessToken)
        suspend fun acceptComplete(project: UUID, input: String): Project = api.acceptComplete(project, input, userAccessToken)
        suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest(userAccessToken)
    }
    class UserSessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ProjectLedgerItem, UUID> {
        override suspend fun default(): ProjectLedgerItem = api.default(userAccessToken)
        override suspend fun query(input: Query<ProjectLedgerItem>): List<ProjectLedgerItem> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ProjectLedgerItem>): List<Partial<ProjectLedgerItem>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ProjectLedgerItem = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ProjectLedgerItem>): List<ProjectLedgerItem> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ProjectLedgerItem): ProjectLedgerItem = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ProjectLedgerItem): ProjectLedgerItem = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ProjectLedgerItem>): List<ProjectLedgerItem> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ProjectLedgerItem): ProjectLedgerItem = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ProjectLedgerItem>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProjectLedgerItem>): EntryChange<ProjectLedgerItem> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ProjectLedgerItem>): ProjectLedgerItem = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ProjectLedgerItem>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ProjectLedgerItem>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ProjectLedgerItem>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ProjectLedgerItem>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProjectLedgerItem>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionPunchListItemApi(val api: Api.PunchListItemApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<PunchListItem, UUID> {
        override suspend fun default(): PunchListItem = api.default(userAccessToken)
        override suspend fun query(input: Query<PunchListItem>): List<PunchListItem> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<PunchListItem>): List<Partial<PunchListItem>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): PunchListItem = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<PunchListItem>): List<PunchListItem> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: PunchListItem): PunchListItem = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: PunchListItem): PunchListItem = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<PunchListItem>): List<PunchListItem> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: PunchListItem): PunchListItem = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<PunchListItem>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PunchListItem>): EntryChange<PunchListItem> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<PunchListItem>): PunchListItem = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<PunchListItem>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<PunchListItem>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<PunchListItem>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<PunchListItem>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PunchListItem>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun completePunchListItem(id: UUID): PunchListItem = api.completePunchListItem(id, userAccessToken)
        suspend fun resetPunchListItem(id: UUID): PunchListItem = api.resetPunchListItem(id, userAccessToken)
    }
    class UserSessionTradeApi(val api: Api.TradeApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Trade, String> {
        override suspend fun default(): Trade = api.default(userAccessToken)
        override suspend fun query(input: Query<Trade>): List<Trade> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Trade>): List<Partial<Trade>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): Trade = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Trade>): List<Trade> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Trade): Trade = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: Trade): Trade = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Trade>): List<Trade> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: Trade): Trade = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Trade>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<Trade>): EntryChange<Trade> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<Trade>): Trade = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Trade>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Trade>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Trade>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Trade>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Trade>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionUserApi(val api: Api.UserApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<User, UUID> {
        override suspend fun default(): User = api.default(userAccessToken)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): User = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: User): User = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<User>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val userToken:String, val userAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class UserSessionOtpSecretApi(val api: Api.OtpSecretApi,val userToken:String, val userAccessToken: suspend () -> String) {
    }
    class UserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val userToken:String, val userAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID>, AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, userAccessToken)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        override suspend fun getSelf(): User = api.getSelf(userAccessToken)
        override suspend fun terminateSession(): Unit = api.terminateSession(userAccessToken)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, userAccessToken)
        suspend fun register(input: User): Unit = api.register(input)
        suspend fun getPaymentSetupToken(): String = api.getPaymentSetupToken(userAccessToken)
        suspend fun getPaymentPublishableKey(): String = api.getPaymentPublishableKey(userAccessToken)
        suspend fun getPaymentMethods(): List<PaymentMethod> = api.getPaymentMethods(userAccessToken)
        suspend fun getPaymentMethod(methodId: String): PaymentMethod = api.getPaymentMethod(methodId, userAccessToken)
        suspend fun updatePaymentMethodName(methodId: String, input: String): PaymentMethod = api.updatePaymentMethodName(methodId, input, userAccessToken)
        suspend fun deletePaymentMethod(methodId: String): Unit = api.deletePaymentMethod(methodId, userAccessToken)
        suspend fun createMockBank(): Unit = api.createMockBank(userAccessToken)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
}

abstract class AbstractProjectUserSession(api: Api, projectUserToken: String, projectUserAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val projectUserToken: String
    abstract val projectUserAccessToken: suspend () -> String
    val adminReports: ProjectUserSessionAdminReportsApi = ProjectUserSessionAdminReportsApi(api.adminReports, projectUserToken, projectUserAccessToken)
    val changeRequest: ProjectUserSessionChangeRequestApi = ProjectUserSessionChangeRequestApi(api.changeRequest, projectUserToken, projectUserAccessToken)
    val clientTermsAndConditionsAgreement: ProjectUserSessionClientTermsAndConditionsAgreementApi = ProjectUserSessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement, projectUserToken, projectUserAccessToken)
    val clientTermsAndConditionsVersion: ProjectUserSessionClientTermsAndConditionsVersionApi = ProjectUserSessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion, projectUserToken, projectUserAccessToken)
    val client: ProjectUserSessionClientApi = ProjectUserSessionClientApi(api.client, projectUserToken, projectUserAccessToken)
    val contractor: ProjectUserSessionContractorApi = ProjectUserSessionContractorApi(api.contractor, projectUserToken, projectUserAccessToken)
    val contractorDocument: ProjectUserSessionContractorDocumentApi = ProjectUserSessionContractorDocumentApi(api.contractorDocument, projectUserToken, projectUserAccessToken)
    val contractorTermsAndConditionsAgreement: ProjectUserSessionContractorTermsAndConditionsAgreementApi = ProjectUserSessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement, projectUserToken, projectUserAccessToken)
    val contractorTermsAndConditionsVersion: ProjectUserSessionContractorTermsAndConditionsVersionApi = ProjectUserSessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion, projectUserToken, projectUserAccessToken)
    val disputeLog: ProjectUserSessionDisputeLogApi = ProjectUserSessionDisputeLogApi(api.disputeLog, projectUserToken, projectUserAccessToken)
    val license: ProjectUserSessionLicenseApi = ProjectUserSessionLicenseApi(api.license, projectUserToken, projectUserAccessToken)
    val lineItem: ProjectUserSessionLineItemApi = ProjectUserSessionLineItemApi(api.lineItem, projectUserToken, projectUserAccessToken)
    val lineItemLog: ProjectUserSessionLineItemLogApi = ProjectUserSessionLineItemLogApi(api.lineItemLog, projectUserToken, projectUserAccessToken)
    val payout: ProjectUserSessionPayoutApi = ProjectUserSessionPayoutApi(api.payout, projectUserToken, projectUserAccessToken)
    val project: ProjectUserSessionProjectApi = ProjectUserSessionProjectApi(api.project, projectUserToken, projectUserAccessToken)
    val projectLedgerItem: ProjectUserSessionProjectLedgerItemApi = ProjectUserSessionProjectLedgerItemApi(api.projectLedgerItem, projectUserToken, projectUserAccessToken)
    val punchListItem: ProjectUserSessionPunchListItemApi = ProjectUserSessionPunchListItemApi(api.punchListItem, projectUserToken, projectUserAccessToken)
    val trade: ProjectUserSessionTradeApi = ProjectUserSessionTradeApi(api.trade, projectUserToken, projectUserAccessToken)
    val user: ProjectUserSessionUserApi = ProjectUserSessionUserApi(api.user, projectUserToken, projectUserAccessToken)
    val emailProof: ProjectUserSessionEmailProofApi = ProjectUserSessionEmailProofApi(api.emailProof, projectUserToken, projectUserAccessToken)
    val otpSecret: ProjectUserSessionOtpSecretApi = ProjectUserSessionOtpSecretApi(api.otpSecret, projectUserToken, projectUserAccessToken)
    val oneTimePasswordProof: ProjectUserSessionOneTimePasswordProofApi = ProjectUserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, projectUserToken, projectUserAccessToken)
    val userAuth: ProjectUserSessionUserAuthApi = ProjectUserSessionUserAuthApi(api.userAuth, projectUserToken, projectUserAccessToken)
    val userSession: ProjectUserSessionUserSessionApi = ProjectUserSessionUserSessionApi(api.userSession, projectUserToken, projectUserAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    class ProjectUserSessionAdminReportsApi(val api: Api.AdminReportsApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionChangeRequestApi(val api: Api.ChangeRequestApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionClientApi(val api: Api.ClientApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionContractorApi(val api: Api.ContractorApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
        suspend fun sendRegistrationEmail(input: String): String = api.sendRegistrationEmail(input)
        suspend fun register(input: RegisterContractor): Unit = api.register(input)
    }
    class ProjectUserSessionContractorDocumentApi(val api: Api.ContractorDocumentApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionDisputeLogApi(val api: Api.DisputeLogApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionLicenseApi(val api: Api.LicenseApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionLineItemApi(val api: Api.LineItemApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionLineItemLogApi(val api: Api.LineItemLogApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionPayoutApi(val api: Api.PayoutApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionProjectApi(val api: Api.ProjectApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
        suspend fun viewProjectAnonymously(): FullProject = api.viewProjectAnonymously(projectUserAccessToken)
    }
    class ProjectUserSessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionPunchListItemApi(val api: Api.PunchListItemApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionTradeApi(val api: Api.TradeApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionUserApi(val api: Api.UserApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionEmailProofApi(val api: Api.EmailProofApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class ProjectUserSessionOtpSecretApi(val api: Api.OtpSecretApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
    class ProjectUserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class ProjectUserSessionUserAuthApi(val api: Api.UserAuthApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        suspend fun register(input: User): Unit = api.register(input)
    }
    class ProjectUserSessionUserSessionApi(val api: Api.UserSessionApi,val projectUserToken:String, val projectUserAccessToken: suspend () -> String) {
    }
}

abstract class AbstractAnySession(api: Api, anyToken: String, anyAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val anyToken: String
    abstract val anyAccessToken: suspend () -> String
    val adminReports: AnySessionAdminReportsApi = AnySessionAdminReportsApi(api.adminReports, anyToken, anyAccessToken)
    val changeRequest: AnySessionChangeRequestApi = AnySessionChangeRequestApi(api.changeRequest, anyToken, anyAccessToken)
    val clientTermsAndConditionsAgreement: AnySessionClientTermsAndConditionsAgreementApi = AnySessionClientTermsAndConditionsAgreementApi(api.clientTermsAndConditionsAgreement, anyToken, anyAccessToken)
    val clientTermsAndConditionsVersion: AnySessionClientTermsAndConditionsVersionApi = AnySessionClientTermsAndConditionsVersionApi(api.clientTermsAndConditionsVersion, anyToken, anyAccessToken)
    val client: AnySessionClientApi = AnySessionClientApi(api.client, anyToken, anyAccessToken)
    val contractor: AnySessionContractorApi = AnySessionContractorApi(api.contractor, anyToken, anyAccessToken)
    val contractorDocument: AnySessionContractorDocumentApi = AnySessionContractorDocumentApi(api.contractorDocument, anyToken, anyAccessToken)
    val contractorTermsAndConditionsAgreement: AnySessionContractorTermsAndConditionsAgreementApi = AnySessionContractorTermsAndConditionsAgreementApi(api.contractorTermsAndConditionsAgreement, anyToken, anyAccessToken)
    val contractorTermsAndConditionsVersion: AnySessionContractorTermsAndConditionsVersionApi = AnySessionContractorTermsAndConditionsVersionApi(api.contractorTermsAndConditionsVersion, anyToken, anyAccessToken)
    val disputeLog: AnySessionDisputeLogApi = AnySessionDisputeLogApi(api.disputeLog, anyToken, anyAccessToken)
    val license: AnySessionLicenseApi = AnySessionLicenseApi(api.license, anyToken, anyAccessToken)
    val lineItem: AnySessionLineItemApi = AnySessionLineItemApi(api.lineItem, anyToken, anyAccessToken)
    val lineItemLog: AnySessionLineItemLogApi = AnySessionLineItemLogApi(api.lineItemLog, anyToken, anyAccessToken)
    val payout: AnySessionPayoutApi = AnySessionPayoutApi(api.payout, anyToken, anyAccessToken)
    val project: AnySessionProjectApi = AnySessionProjectApi(api.project, anyToken, anyAccessToken)
    val projectLedgerItem: AnySessionProjectLedgerItemApi = AnySessionProjectLedgerItemApi(api.projectLedgerItem, anyToken, anyAccessToken)
    val punchListItem: AnySessionPunchListItemApi = AnySessionPunchListItemApi(api.punchListItem, anyToken, anyAccessToken)
    val trade: AnySessionTradeApi = AnySessionTradeApi(api.trade, anyToken, anyAccessToken)
    val user: AnySessionUserApi = AnySessionUserApi(api.user, anyToken, anyAccessToken)
    val emailProof: AnySessionEmailProofApi = AnySessionEmailProofApi(api.emailProof, anyToken, anyAccessToken)
    val otpSecret: AnySessionOtpSecretApi = AnySessionOtpSecretApi(api.otpSecret, anyToken, anyAccessToken)
    val oneTimePasswordProof: AnySessionOneTimePasswordProofApi = AnySessionOneTimePasswordProofApi(api.oneTimePasswordProof, anyToken, anyAccessToken)
    val userAuth: AnySessionUserAuthApi = AnySessionUserAuthApi(api.userAuth, anyToken, anyAccessToken)
    val userSession: AnySessionUserSessionApi = AnySessionUserSessionApi(api.userSession, anyToken, anyAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    class AnySessionAdminReportsApi(val api: Api.AdminReportsApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionChangeRequestApi(val api: Api.ChangeRequestApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionClientTermsAndConditionsAgreementApi(val api: Api.ClientTermsAndConditionsAgreementApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionClientTermsAndConditionsVersionApi(val api: Api.ClientTermsAndConditionsVersionApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionClientApi(val api: Api.ClientApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionContractorApi(val api: Api.ContractorApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
        suspend fun sendRegistrationEmail(input: String): String = api.sendRegistrationEmail(input)
        suspend fun register(input: RegisterContractor): Unit = api.register(input)
    }
    class AnySessionContractorDocumentApi(val api: Api.ContractorDocumentApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionContractorTermsAndConditionsAgreementApi(val api: Api.ContractorTermsAndConditionsAgreementApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionContractorTermsAndConditionsVersionApi(val api: Api.ContractorTermsAndConditionsVersionApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDisputeLogApi(val api: Api.DisputeLogApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionLicenseApi(val api: Api.LicenseApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionLineItemApi(val api: Api.LineItemApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionLineItemLogApi(val api: Api.LineItemLogApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionPayoutApi(val api: Api.PayoutApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionProjectApi(val api: Api.ProjectApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionProjectLedgerItemApi(val api: Api.ProjectLedgerItemApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionPunchListItemApi(val api: Api.PunchListItemApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionTradeApi(val api: Api.TradeApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionUserApi(val api: Api.UserApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionEmailProofApi(val api: Api.EmailProofApi,val anyToken:String, val anyAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class AnySessionOtpSecretApi(val api: Api.OtpSecretApi,val anyToken:String, val anyAccessToken: suspend () -> String): ClientModelRestEndpoints<OtpSecret, UUID> {
        override suspend fun query(input: Query<OtpSecret>): List<OtpSecret> = api.query(input, anyAccessToken)
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>): List<Partial<OtpSecret>> = api.queryPartial(input, anyAccessToken)
        override suspend fun detail(id: UUID): OtpSecret = api.detail(id, anyAccessToken)
        override suspend fun insertBulk(input: List<OtpSecret>): List<OtpSecret> = api.insertBulk(input, anyAccessToken)
        override suspend fun insert(input: OtpSecret): OtpSecret = api.insert(input, anyAccessToken)
        override suspend fun upsert(id: UUID, input: OtpSecret): OtpSecret = api.upsert(id, input, anyAccessToken)
        override suspend fun bulkReplace(input: List<OtpSecret>): List<OtpSecret> = api.bulkReplace(input, anyAccessToken)
        override suspend fun replace(id: UUID, input: OtpSecret): OtpSecret = api.replace(id, input, anyAccessToken)
        override suspend fun bulkModify(input: MassModification<OtpSecret>): Int = api.bulkModify(input, anyAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>): EntryChange<OtpSecret> = api.modifyWithDiff(id, input, anyAccessToken)
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>): OtpSecret = api.modify(id, input, anyAccessToken)
        override suspend fun bulkDelete(input: Condition<OtpSecret>): Int = api.bulkDelete(input, anyAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, anyAccessToken)
        override suspend fun count(input: Condition<OtpSecret>): Int = api.count(input, anyAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>): Map<String, Int> = api.groupCount(input, anyAccessToken)
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>): Double? = api.aggregate(input, anyAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>): Map<String, Double?> = api.groupAggregate(input, anyAccessToken)
    }
    class AnySessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val anyToken:String, val anyAccessToken: suspend () -> String): AuthenticatedOneTimePasswordProofClientEndpoints, OneTimePasswordProofClientEndpoints {
        override suspend fun establishOneTimePassword(input: EstablishOtp): String = api.establishOneTimePassword(input, anyAccessToken)
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class AnySessionUserAuthApi(val api: Api.UserAuthApi,val anyToken:String, val anyAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        suspend fun register(input: User): Unit = api.register(input)
    }
    class AnySessionUserSessionApi(val api: Api.UserSessionApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
}

