package com.crowpay.views.theming

import com.crowpay.AppColors
import com.crowpay.actuals.AppDimensions
import com.crowpay.ColorSet
import com.crowpay.Resources
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast
import com.lightningkite.kiteui.views.tweakTheme


@ViewModifierDsl3
fun ViewWriter.notificationBar(colors: ColorSet): ViewWrapper = themeFromLast { theme ->
    theme.copy(
        background = colors.light3,
        outline = colors.light1,
        outlineWidth = AppDimensions.outlineWidth,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        spacing = 1.rem
    )
}


@ViewModifierDsl3
fun ViewWriter.todoBar(colors: ColorSet): ViewWrapper = themeFromLast { theme ->
    theme.copy(
        background = Color.white,
        outline = colors.main,
        outlineWidth = AppDimensions.outlineWidth,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        spacing = 2.rem
    )
}

@ViewModifierDsl3
val outlineTheme: Theme.() -> Theme = {
    copy(
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        outlineWidth = AppDimensions.outlineWidth,
    )
}

@ViewModifierDsl3
val ViewWriter.outlined: ViewWrapper get() = themeFromLast(outlineTheme)



@ViewModifierDsl3
val lightSectionTheme: (Theme) -> Theme = {
    it.copy(
        background = AppColors.grey.light3,
    )
}

@ViewModifierDsl3
val ViewWriter.lightSection: ViewWrapper get() = themeFromLast(lightSectionTheme)

data object SectionSemantic : Semantic {
    override val key = "sctn"
    override fun default(theme: Theme): ThemeAndBack {
        val currentIsDark = theme.background.closestColor().toHSP().brightness < 0.95
        return theme.copy(
            background = if (currentIsDark) Color.white else AppColors.grey.light2,
        ).withBack
    }
}

@ViewModifierDsl3
val ViewWriter.section: ViewWrapper get() = SectionSemantic.onNext

@ViewModifierDsl3
val darkSectionTheme: (Theme) -> Theme = {
    val currentIsDark = it.background.closestColor().toHSP().brightness < 0.95
    it.copy(
        background = if (currentIsDark) Color.white else AppColors.grey.light1,
    )
}

@ViewModifierDsl3
val ViewWriter.darkSection: ViewWrapper get() = themeFromLast(darkSectionTheme)

val redTextTheme: (Theme) -> Theme = {
    it.copy(foreground = AppColors.red.main)
}

@ViewModifierDsl3
val ViewWriter.redText: ViewWrapper get() = tweakTheme(redTextTheme)

fun ViewWriter.coloredText(color: Color): ViewWrapper = tweakTheme {
    it.copy(foreground = color)
}


@ViewModifierDsl3
fun ViewWriter.toDoSection(color: Color): ViewWrapper = themeFromLast {
    it.copy(
        outlineWidth = 2.dp,
        cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
        outline = color
    )
}

object AuthDialogSemantic: Semantic {
    override val key: String = "athdlg"
    override fun default(theme: Theme): ThemeAndBack =
        theme.copy(
            id = "athdlg",
            cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
            outlineWidth = AppDimensions.outlineWidth,
            outline = AppColors.grey.main,
            background = Color.white,
            revert = true,
            derivations = mapOf(
                CardSemantic to { it.withoutBack }
            )
        )
            .withBack
}

object HiddenButtonSemantic : Semantic {
    override val key: String = "hdnBtn"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "hdnBtn",
        derivations = mapOf(
            ButtonSemantic to {
                it.withBack
            },
            HoverSemantic to {
                it.withBack
            },
            DownSemantic to {
                it.withBack
            },
        )
    ).withoutBack
}

@ViewModifierDsl3
val ViewWriter.hiddenButton: ViewWrapper get() = HiddenButtonSemantic.onNext

object TabSemantics : Semantic {
    override val key: String = "tabs"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = "tabs",
        spacing = 4.dp,
        background = AppColors.grey.light2,
        derivations = mapOf(
            ButtonSemantic to {
                it
                    .copy(
                        id = "btn",
                        spacing = 0.95.rem
                    )
                    .withBack
            },
            HoverSemantic to {
                val back = it.background.closestColor()
                it
                    .copy(id = "hvr", background = if (back == Color.white) back else AppColors.grey.light3)
                    .withBack
            },
            DownSemantic to {
                val back = it.background.closestColor()
                it
                    .copy(id = "dwn", background = if (back == Color.white) back else AppColors.grey.light2)
                    .withBack
            },
            SelectedSemantic to {
                it
                    .copy(
                        id = "tabSel",
                        background = Color.white,
                        outlineWidth = 0.dp
                    )
                    .withBack
            },
            UnselectedSemantic to {
                it
                    .copy(
                        id = "tabUnsel",
                        outlineWidth = 0.dp
                    ).withBack
            },
        ),
    ).withBack
}

val ViewWriter.tabRow: ViewWrapper get() = TabSemantics.onNext

data object PunchFieldSemantic: Semantic {
    override val key: String = "pnchfld"
    override fun default(theme: Theme): ThemeAndBack = theme[FieldSemantic].theme.copy(
        id = key,
        background = AppColors.grey.light2
    ).withBack
}

val appTheme = Theme(
    id = "main",
    font = FontAndStyle(font = Resources.fontsRobotoRegular),
    foreground = Color.black,
    background = Color.white,
    elevation = 0.dp,
    cornerRadii = CornerRadii.Constant(0.px),
    outlineWidth = 0.dp,
    outline = AppColors.grey.light1,
    derivations = mapOf(
        ErrorSemantic to {
            it.copy(
                foreground = AppColors.red.main
            ).withoutBack
        },
        ButtonSemantic to {
            it
                .copy(
                    cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
                    spacing = AppDimensions.buttonSpacing,
                )
                .withBack
        },
        HoverSemantic to {
            it
                .copy(background = it.background.darken(0.2f))
                .withBack
        },
        DownSemantic to {
            it
                .copy(background = it.background.darken(0.4f))
                .withBack
        },
        CardSemantic to {
            val currentIsDark = it.background.closestColor().toHSP().brightness < 0.95
            println("Card found that ${it.id} currentIsDark = $currentIsDark")
            outlineTheme(it)
                .copy(
                    id = "crd",
                    background = if (currentIsDark) Color.white else AppColors.grey.light2
                )
                .withBack
        },
        FieldSemantic to {
            outlineTheme(it)
                .withBack
        },
        FocusSemantic to {
            it.copy(
                id = "fcs",
                outline = if (it.outline.closestColor() == AppColors.red.light2) AppColors.red.light1 else AppColors.grey.main
            ).withBack
        },
        DialogSemantic to {
            it.copy(
                id = "dlg",
                cornerRadii = CornerRadii.Constant(AppDimensions.cornerRadii),
                outlineWidth = AppDimensions.outlineWidth,
                derivations = mapOf(
                    CardSemantic to { it.withoutBack }
                )
            )
                .withBack
        },
        NavSemantic to {
            it.copy(
                id = "nav",
                background = AppColors.primary.dark,
                foreground = Color.white,
                iconOverride = Color.white,
                derivations = mapOf(
                    HoverSemantic to {
                        it.copy(background = AppColors.purple.main).withBack
                    },
                    DownSemantic to {
                        it.copy(background = AppColors.purple.dark).withBack
                    },
                    SelectedSemantic to {
                        it.copy(background = AppColors.purple.dark).withBack
                    }
                ),
            ).withBack
        },
        InvalidSemantic to {
            it.copy(
                id = "inv",
                outline = AppColors.red.light2
            ).withBack
        },
        SelectedSemantic to {
            it
                .copy(
                    id = "sel",
                    background = Color.white,
                    outlineWidth = AppDimensions.outlineWidth,
                    outline = Color.black
                )
                .withBack
        },
        UnselectedSemantic to {
            it
                .copy(
                    id = "unsel",
                    background = Color.white,
                    outlineWidth = 1.dp,
                    outline = Color.black
                ).withBack
        },
    ),
)


val alphaTheme: (Theme) -> Theme = {
    it.copy(
        id = "alpha",
        background = it.background
            .closestColor()
            .copy(alpha = 0.7f),
        derivations = mapOf(
            HoverSemantic to alphaHoverTheme,
            DownSemantic to alphaDownTheme,
        ),
    )
}

val alphaHoverTheme: (Theme) -> ThemeAndBack = {
    it
        .copy(background = it.background.closestColor().copy(alpha = 0.6f))
        .withBack
}

val alphaDownTheme: (Theme) -> ThemeAndBack = {
    it
        .copy(background = it.background.closestColor().copy(alpha = 0.5f))
        .withBack
}

val stateTheme: (Theme, Color) -> Theme = { theme, color ->
    theme.copy(
        body = theme.font.copy(bold = true),
        foreground = color,
        outline = color
    )
}