package com.crowpay.views.screens.contractor

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.flatLens
import com.crowpay.utils.query
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.label2
import com.crowpay.views.components.space
import com.crowpay.views.dialogs.GenericConfirmationDialog
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.MessageType
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.minus
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.launch


@Routable("/contractor/clients")
class ContractorClients() : ContractorScreen {

    override val title: Readable<String> = Constant("Clients")

    val drafts: MutableMap<UUID, Draft<Client>> = mutableMapOf()

    val clients: Readable<List<Draft<Client>>> = shared {
        val c = notNullContractor()
        notNullSession().clients
            .query(Query(condition { it.contractor eq c }, orderBy = sort { it.name.ascending() }))()
            .map { drafts.getOrPut(it._id) { Draft(it) } }
    }

    override fun ViewWriter.renderMainContent() {
        var newClient: UUID? = null
        val editingClients = Property(emptySet<UUID>())

        col {
            spacing = 0.dp
            title("Clients")

            space(AppDimensions.majorColumnSpacing)

            row {
                spacing = 0.dp
                space(AppDimensions.fullIndent - AppDimensions.buttonSpacing)
                primaryButton - button {
                    existsDefaultFalse { selectedContractor() != null }
                    specCenteredText("+ Client")
                    onClick {
                        notNullSession().clients.insert(
                            Client(
                                contractor = guaranteedContractor.await(),
                                name = "",
                                email = "",
                                phoneNumber = "",
                                address = Address()
                            ).also {
                                newClient = it._id
                                editingClients.set(editingClients.value.plus(it._id))
                            }
                        )
                    }
                }
            }

            space(AppDimensions.majorColumnSpacing)

            subTitle {
                ::exists{
                    clients().isEmpty()
                }
                content = "You have no Clients yet. Create a new Client."
            } in centered

            expanding - recyclerView {

                children(clients) { client: Readable<Draft<Client>> ->

                    sizeConstraints(width = AppDimensions.pageWidth) - row {
                        spacing = 0.dp
                        space(AppDimensions.backgroundIndent)

                        launch {
                            if (client()()._id == newClient)
                                scrollIntoView(Align.Start, Align.Start)
                        }

                        outlined - expanding - stack {
                            spacing = AppDimensions.sectionIndent
                            col {
                                spacing = 0.dp

                                row {
                                    existsDefaultFalse{ !editingClients().contains(client()()._id) }

                                    expanding - col {
                                        keyValue("Client Name") { client()().name }
                                        keyValue("Email") { client()().email }
                                        keyValue("Phone") { PhoneNumberFormat.USA.format(client()().phoneNumber.filter { it.isDigit() }) }
                                        keyValue("Address") { client()().address.toString() }
                                    }


                                    tertiaryButton - button {
                                        icon(Icon.edit, "Edit Client")
                                        onClick {
                                            editingClients.set(editingClients.value.plus(client()()._id))
                                        }
                                    } in gravity(Align.End, Align.End)
                                }
                                validating {
                                    debug = ConsoleRoot

                                    col {
                                        existsDefaultFalse{ editingClients().contains(client()()._id) }

                                        subTitle("Contact Info")

                                        rowCollapsingToColumn(AppDimensions.pageWidth) {
                                            expanding - label2("Name") {
                                                val name =
                                                    client
                                                        .flatLens(
                                                            get = { it.name },
                                                            modify = { og, it -> og.copy(name = it) }
                                                        )
                                                        .validate { it.isNotBlank() }
                                                fieldTheme - validate(name) - textField {
                                                    hint = "Name"
                                                    content bind name
                                                }
                                            }
                                            expanding - label2("Email") {
                                                val email = client
                                                    .flatLens(
                                                        get = { it.email },
                                                        modify = { og, it -> og.copy(email = it) }
                                                    )
                                                    .validate { it.matches(SharedUtils.emailPattern) }
                                                fieldTheme - validate(email) - textField {
                                                    hint = "Email"
                                                    content bind email
                                                }
                                            }
                                        }
                                        rowCollapsingToColumn(AppDimensions.pageWidth) {
                                            expanding - label2("Phone Number") {
                                                val phone = client
                                                    .flatLens(
                                                        get = { it.phoneNumber },
                                                        modify = { og, it -> og.copy(phoneNumber = it) }
                                                    )
                                                    .validate { it.length == 10 }
                                                fieldTheme - validate(phone) - formattedTextInput {
                                                    hint = "Phone Number"
                                                    format(PhoneNumberFormat.USA::isRawData, PhoneNumberFormat.USA::format)
                                                    content bind phone
                                                }
                                            }
                                            expanding - space()
                                        }

                                        subTitle("Address")

                                        rowCollapsingToColumn(AppDimensions.pageWidth) {
                                            expanding - label2("Line 1") {
                                                val value = client
                                                    .flatLens(
                                                        get = { it.address.line1 },
                                                        modify = { og, it -> og.copy(address = og.address.copy(line1 = it)) }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Line 1"
                                                    content bind value
                                                }
                                            }
                                            expanding - label2("Line 2 (Optional)") {
                                                fieldTheme - textField {
                                                    hint = "Line 2"
                                                    content bind client
                                                        .flatLens(
                                                            get = { it.address.line2 ?: "" },
                                                            modify = { og, it -> og.copy(address = og.address.copy(line2 = it.takeIf { it.isNotBlank() })) }
                                                        )
                                                }
                                            }
                                        }
                                        rowCollapsingToColumn(AppDimensions.pageWidth) {
                                            expanding - label2("City") {
                                                val value = client
                                                    .flatLens(
                                                        get = { it.address.city },
                                                        modify = { og, it -> og.copy(address = og.address.copy(city = it)) }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "City"
                                                    content bind value
                                                }
                                            }
                                            expanding - label2("State") {
                                                fieldTheme - select {
                                                    bind(
                                                        client
                                                            .flatLens(
                                                                get = { it.address.state },
                                                                modify = { og, it ->
                                                                    og.copy(address = og.address.copy(state = it))
                                                                }
                                                            ),
                                                        Constant(State.entries)
                                                    ) { it.display }
                                                }
                                            }
                                            expanding - label2("Zipcode") {
                                                val value = client
                                                    .flatLens(
                                                        get = { it.address.zipcode },
                                                        modify = { og, it -> og.copy(address = og.address.copy(zipcode = it)) }
                                                    )
                                                    .validate { it.isNotBlank() }
                                                fieldTheme - validate(value) - textField {
                                                    hint = "Zipcode"
                                                    content bind value
                                                }
                                            }
                                        }
                                        row {
                                            spacing = AppDimensions.buttonRowSpacing

                                            expanding - space()

                                            textButton - button {
                                                specCenteredText("Delete")
                                                onClick {
                                                    val c = client.await()
                                                    val projects =
                                                        notNullSession().nonCached.project.query(condition { it.client eq c()._id })
                                                    if (projects.isEmpty())

                                                        dialogScreenNavigator.navigate(
                                                            GenericConfirmationDialog(
                                                                header = "Delete Client?",
                                                                message = "Deleting a client cannot be undone. Are you sure you want to delete this client?",
                                                                confirmationText = "Delete Client",
                                                                messageType = MessageType.Warning,
                                                            ) { confirmed ->
                                                                if (confirmed)
                                                                    notNullSession().clients[c()._id].delete()
                                                            }

                                                        )
                                                    else
                                                        dialogScreenNavigator.navigate(
                                                            GenericDialog(
                                                                "This client is a part of existing projects and cannot be deleted."
                                                            )
                                                        )
                                                }
                                            }

                                            tertiaryButton - button {
                                                specCenteredText("Cancel")
                                                onClick {
                                                    editingClients.set(editingClients.value.minus(client.awaitOnce()()._id))
                                                    client().cancel()
                                                }
                                            }

                                            tertiaryButton - button {
                                                ::enabled{ allValid() }
                                                specCenteredText("Save")
                                                onClick {
                                                    val draft = client()
                                                    val current = draft.published.await()

                                                    val mods =
                                                        modification(current, draft()) as? Modification.Chain<Client>
                                                            ?: Modification.Chain(emptyList())

                                                    draft.publish()

                                                    if (mods.modifications.isNotEmpty())
                                                        notNullSession().clients[current._id].modify(mods)


                                                    editingClients.set(editingClients.value.minus(current._id))
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        space(AppDimensions.backgroundIndent)
                    }
                }
            }
        }
    }
}