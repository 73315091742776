package com.crowpay.actuals

import com.crowpay.SharedUtils
import com.crowpay.User
import com.crowpay.extensions.fullName
import com.crowpay.views.dialogs.PaymentError
import com.crowpay.views.dialogs.PaymentResult
import com.lightningkite.kiteui.await
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import org.w3c.dom.HTMLDivElement


@ViewDsl
actual fun ViewWriter.paymentSetup(
    setupToken: String,
    publicKey: String,
    user: User,
    onComplete: (Boolean) -> Unit,
): suspend () -> PaymentResult {

    addChild(object : RView(context) {
        init {
            native.tag = "div"
            native.classes.add("kiteui-stack")
            native.create()
            native.id = "payment-element"
        }
    })

    var outerStripe: Stripe? = null
    var outerElements: StripeElements? = null
    var complete = false

    loadStripe(publicKey, object : StripeConstructorOptions {})
        .then { stripe ->
            outerStripe = stripe
            if (stripe == null) return@then
            val elementOptions =
                object : StripeElementsOptionsClientSecret {}.apply {
                    clientSecret = setupToken
                }
            val elements = stripe.elements(elementOptions)
            outerElements = elements
            val paymentOptions = object:StripePaymentElementOptions {}.apply {
                defaultValues = object:DefaultValuesOption{}.apply {
                    billingDetails = object:BillingDetails{}.apply {
                        name = user.fullName
                        email = user.email
                    }
                }
            }
            val payElement = elements
                .create("payment", paymentOptions)
                .on("change") { event ->
                    complete = event.complete
                    onComplete(event.complete)
                }
            payElement.asDynamic().mount("#payment-element")
        }
        .catch { }

    return doneLambda@{
        val s = outerStripe ?: return@doneLambda PaymentResult(null, PaymentError(null, null, SharedUtils.unknownError))
        val e = outerElements ?: return@doneLambda PaymentResult(null, PaymentError(null, null, SharedUtils.unknownError))
        if (!complete) {
            return@doneLambda PaymentResult(null, PaymentError(null, null, SharedUtils.unknownError))
        }

        val error = e.submit().await()
        if(error.error != null){
            return@doneLambda PaymentResult(null, PaymentError(
                type = error.error?.type,
                code = error.error?.code,
                message = error.error?.message,
            ))
        }

        return@doneLambda try {
            println("Making Confirm Call")
            e
            val result =
                s.confirmSetup(object:ConfirmSetupOptions{}.apply {
                    elements = e
                    clientSecret = setupToken
                    confirmParams = object : ConfirmPaymentData {}.apply { return_url = "" }
                    redirect = "if_required"
                }).await()
            println("Received Confirm Call")
            PaymentResult(result.setupIntent?.payment_method, result.error?.let {
                PaymentError(
                    type = it.type,
                    code = it.code,
                    message = it.message,
                )
            })
        } catch (e: Exception) {
            println("Caught Confirm Exception")
            PaymentResult(null, PaymentError(null, null, e.message))
        }

    }
}

//internal actual suspend fun stripeSetup(setupToken: String, publicKey: String) {
//    val stripe = loadStripe(publicKey, (object {}).unsafeCast<StripeConstructorOptions>()).await() ?: return
//    val elementOptions: dynamic = object {}
//    elementOptions["clientSecret"] = setupToken
//    val elements = stripe.elements(elementOptions.unsafeCast<StripeElementsOptionsClientSecret>())
//    val paymentOptions: dynamic = object {}
//    val payElement = elements.create("payment", paymentOptions.unsafeCast<StripePaymentElementOptions>())
//    payElement.asDynamic().mount("#payment-element")
//}


//internal actual suspend fun plaidSetup(link: String): PaymentResult {
//    return suspendCoroutineCancellable { cont ->
//
//        val plaidConfig: dynamic = object {}
//        plaidConfig["token"] = link
//        plaidConfig["onSuccess"] = { publicToken: String?, _: MetaObject ->
//            cont.resume(PaymentResult(ExternalResult.Completed, publicToken))
//        }
//        plaidConfig["onLoad"] = { }
//        plaidConfig["onExit"] = { _: ErrorObject, _: MetaObject ->
//            cont.resume(PaymentResult(ExternalResult.Canceled, null))
//        }
//        plaidConfig["onEvent"] = { _: String, _: MetaObject -> }
//
//        val handler = Plaid.create(plaidConfig.unsafeCast<PlaidConfig>())
//
//        handler.open()
//
//        return@suspendCoroutineCancellable {
//            handler.destroy
//        }
//    }
//}
//
//internal actual suspend fun plaidTransfer(link: String): PaymentResult {
//    return suspendCoroutineCancellable { cont ->
//
//        val plaidConfig: dynamic = object {}
//        plaidConfig["token"] = link
//        plaidConfig["onSuccess"] = { publicToken: String?, meta: MetaObject ->
//            try {
//                val status = meta.transfer_status?.lowercase()
//                cont.resume(
//                    PaymentResult(
//                        when (status) {
//                            "incomplete" -> ExternalResult.Failed
//                            "complete" -> ExternalResult.Completed
//                            else -> ExternalResult.UnknownError
//                        },
//                        publicToken
//                    )
//                )
//            } catch (e: Exception) {
//                cont.resumeWithException(e)
//            }
//        }
//        plaidConfig["onLoad"] = { }
//        plaidConfig["onExit"] = { _: ErrorObject, _: MetaObject ->
//            cont.resume(PaymentResult(ExternalResult.Canceled, null))
//        }
//        plaidConfig["onEvent"] = { _: String, _: MetaObject -> }
//
//        val handler = Plaid.create(plaidConfig.unsafeCast<PlaidConfig>())
//
//        handler.open()
//
//        return@suspendCoroutineCancellable {
//            handler.destroy
//        }
//    }
//}