// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareProjectFields() {
    val props: Array<SerializableProperty<Project, *>> = arrayOf(Project__id, Project_contractor, Project_customer, Project_client, Project_name, Project_description, Project_address, Project_location, Project_jobNumber, Project_files, Project_retention, Project_privateNotes, Project_published, Project_feedback, Project_created, Project_cancelled, Project_viewedAnonymously, Project_approved, Project_fullFundingRequested, Project_dispute, Project_terminated, Project_complete, Project_state, Project_price, Project_fundingNeeded, Project_balance, Project_pendingClientPayments, Project_contractorReceived, Project_funded, Project_started, Project_substantialCompletion)
    Project.serializer().properties { props }
}
val <K> DataClassPath<K, Project>._id: DataClassPath<K, UUID> get() = this[Project__id]
val <K> DataClassPath<K, Project>.contractor: DataClassPath<K, UUID> get() = this[Project_contractor]
val <K> DataClassPath<K, Project>.customer: DataClassPath<K, UUID?> get() = this[Project_customer]
val <K> DataClassPath<K, Project>.client: DataClassPath<K, UUID?> get() = this[Project_client]
val <K> DataClassPath<K, Project>.name: DataClassPath<K, String> get() = this[Project_name]
val <K> DataClassPath<K, Project>.description: DataClassPath<K, String> get() = this[Project_description]
val <K> DataClassPath<K, Project>.address: DataClassPath<K, Address> get() = this[Project_address]
val <K> DataClassPath<K, Project>.location: DataClassPath<K, String?> get() = this[Project_location]
val <K> DataClassPath<K, Project>.jobNumber: DataClassPath<K, String?> get() = this[Project_jobNumber]
val <K> DataClassPath<K, Project>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[Project_files]
val <K> DataClassPath<K, Project>.retention: DataClassPath<K, Float?> get() = this[Project_retention]
val <K> DataClassPath<K, Project>.privateNotes: DataClassPath<K, String> get() = this[Project_privateNotes]
val <K> DataClassPath<K, Project>.published: DataClassPath<K, Instant?> get() = this[Project_published]
val <K> DataClassPath<K, Project>.feedback: DataClassPath<K, ClientFeedback?> get() = this[Project_feedback]
val <K> DataClassPath<K, Project>.created: DataClassPath<K, Instant> get() = this[Project_created]
val <K> DataClassPath<K, Project>.cancelled: DataClassPath<K, AccessInfo?> get() = this[Project_cancelled]
val <K> DataClassPath<K, Project>.viewedAnonymously: DataClassPath<K, Instant?> get() = this[Project_viewedAnonymously]
val <K> DataClassPath<K, Project>.approved: DataClassPath<K, AccessInfo?> get() = this[Project_approved]
val <K> DataClassPath<K, Project>.fullFundingRequested: DataClassPath<K, AccessInfo?> get() = this[Project_fullFundingRequested]
val <K> DataClassPath<K, Project>.dispute: DataClassPath<K, Dispute?> get() = this[Project_dispute]
val <K> DataClassPath<K, Project>.terminated: DataClassPath<K, Instant?> get() = this[Project_terminated]
val <K> DataClassPath<K, Project>.complete: DataClassPath<K, CompletionRequest?> get() = this[Project_complete]
val <K> DataClassPath<K, Project>.state: DataClassPath<K, ProjectState> get() = this[Project_state]
val <K> DataClassPath<K, Project>.price: DataClassPath<K, Long> get() = this[Project_price]
val <K> DataClassPath<K, Project>.fundingNeeded: DataClassPath<K, Long> get() = this[Project_fundingNeeded]
val <K> DataClassPath<K, Project>.balance: DataClassPath<K, Long> get() = this[Project_balance]
val <K> DataClassPath<K, Project>.pendingClientPayments: DataClassPath<K, Long> get() = this[Project_pendingClientPayments]
val <K> DataClassPath<K, Project>.contractorReceived: DataClassPath<K, Long> get() = this[Project_contractorReceived]
val <K> DataClassPath<K, Project>.funded: DataClassPath<K, Instant?> get() = this[Project_funded]
val <K> DataClassPath<K, Project>.started: DataClassPath<K, Instant?> get() = this[Project_started]
val <K> DataClassPath<K, Project>.substantialCompletion: DataClassPath<K, Instant?> get() = this[Project_substantialCompletion]
inline val Project.Companion.path: DataClassPath<Project, Project> get() = path<Project>()


object Project__id: SerializableProperty<Project, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Project): UUID = receiver._id
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Project_contractor: SerializableProperty<Project, UUID> {
    override val name: String = "contractor"
    override fun get(receiver: Project): UUID = receiver.contractor
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(contractor = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("contractor")
}
object Project_customer: SerializableProperty<Project, UUID?> {
    override val name: String = "customer"
    override fun get(receiver: Project): UUID? = receiver.customer
    override fun setCopy(receiver: Project, value: UUID?) = receiver.copy(customer = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("customer")
    override val default: UUID? = null
}
object Project_client: SerializableProperty<Project, UUID?> {
    override val name: String = "client"
    override fun get(receiver: Project): UUID? = receiver.client
    override fun setCopy(receiver: Project, value: UUID?) = receiver.copy(client = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("client")
    override val default: UUID? = null
}
object Project_name: SerializableProperty<Project, String> {
    override val name: String = "name"
    override fun get(receiver: Project): String = receiver.name
    override fun setCopy(receiver: Project, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("name")
}
object Project_description: SerializableProperty<Project, String> {
    override val name: String = "description"
    override fun get(receiver: Project): String = receiver.description
    override fun setCopy(receiver: Project, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("description")
}
object Project_address: SerializableProperty<Project, Address> {
    override val name: String = "address"
    override fun get(receiver: Project): Address = receiver.address
    override fun setCopy(receiver: Project, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("address")
}
object Project_location: SerializableProperty<Project, String?> {
    override val name: String = "location"
    override fun get(receiver: Project): String? = receiver.location
    override fun setCopy(receiver: Project, value: String?) = receiver.copy(location = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("location")
    override val default: String? = null
}
object Project_jobNumber: SerializableProperty<Project, String?> {
    override val name: String = "jobNumber"
    override fun get(receiver: Project): String? = receiver.jobNumber
    override fun setCopy(receiver: Project, value: String?) = receiver.copy(jobNumber = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("jobNumber")
    override val default: String? = null
}
object Project_files: SerializableProperty<Project, List<ProjectAttachment>> {
    override val name: String = "files"
    override fun get(receiver: Project): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: Project, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> = ListSerializer(ProjectAttachment.serializer())
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("files")
    override val default: List<ProjectAttachment> = listOf()
}
object Project_retention: SerializableProperty<Project, Float?> {
    override val name: String = "retention"
    override fun get(receiver: Project): Float? = receiver.retention
    override fun setCopy(receiver: Project, value: Float?) = receiver.copy(retention = value)
    override val serializer: KSerializer<Float?> = Float.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("retention")
    override val default: Float? = null
}
object Project_privateNotes: SerializableProperty<Project, String> {
    override val name: String = "privateNotes"
    override fun get(receiver: Project): String = receiver.privateNotes
    override fun setCopy(receiver: Project, value: String) = receiver.copy(privateNotes = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("privateNotes")
}
object Project_published: SerializableProperty<Project, Instant?> {
    override val name: String = "published"
    override fun get(receiver: Project): Instant? = receiver.published
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(published = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("published")
    override val default: Instant? = null
}
object Project_feedback: SerializableProperty<Project, ClientFeedback?> {
    override val name: String = "feedback"
    override fun get(receiver: Project): ClientFeedback? = receiver.feedback
    override fun setCopy(receiver: Project, value: ClientFeedback?) = receiver.copy(feedback = value)
    override val serializer: KSerializer<ClientFeedback?> = ClientFeedback.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("feedback")
    override val default: ClientFeedback? = null
}
object Project_created: SerializableProperty<Project, Instant> {
    override val name: String = "created"
    override fun get(receiver: Project): Instant = receiver.created
    override fun setCopy(receiver: Project, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("created")
    override val default: Instant = now()
}
object Project_cancelled: SerializableProperty<Project, AccessInfo?> {
    override val name: String = "cancelled"
    override fun get(receiver: Project): AccessInfo? = receiver.cancelled
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("cancelled")
    override val default: AccessInfo? = null
}
object Project_viewedAnonymously: SerializableProperty<Project, Instant?> {
    override val name: String = "viewedAnonymously"
    override fun get(receiver: Project): Instant? = receiver.viewedAnonymously
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(viewedAnonymously = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("viewedAnonymously")
    override val default: Instant? = null
}
object Project_approved: SerializableProperty<Project, AccessInfo?> {
    override val name: String = "approved"
    override fun get(receiver: Project): AccessInfo? = receiver.approved
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(approved = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("approved")
    override val default: AccessInfo? = null
}
object Project_fullFundingRequested: SerializableProperty<Project, AccessInfo?> {
    override val name: String = "fullFundingRequested"
    override fun get(receiver: Project): AccessInfo? = receiver.fullFundingRequested
    override fun setCopy(receiver: Project, value: AccessInfo?) = receiver.copy(fullFundingRequested = value)
    override val serializer: KSerializer<AccessInfo?> = AccessInfo.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("fullFundingRequested")
    override val default: AccessInfo? = null
}
object Project_dispute: SerializableProperty<Project, Dispute?> {
    override val name: String = "dispute"
    override fun get(receiver: Project): Dispute? = receiver.dispute
    override fun setCopy(receiver: Project, value: Dispute?) = receiver.copy(dispute = value)
    override val serializer: KSerializer<Dispute?> = Dispute.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("dispute")
    override val default: Dispute? = null
}
object Project_terminated: SerializableProperty<Project, Instant?> {
    override val name: String = "terminated"
    override fun get(receiver: Project): Instant? = receiver.terminated
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(terminated = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("terminated")
    override val default: Instant? = null
}
object Project_complete: SerializableProperty<Project, CompletionRequest?> {
    override val name: String = "complete"
    override fun get(receiver: Project): CompletionRequest? = receiver.complete
    override fun setCopy(receiver: Project, value: CompletionRequest?) = receiver.copy(complete = value)
    override val serializer: KSerializer<CompletionRequest?> = CompletionRequest.serializer().nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("complete")
    override val default: CompletionRequest? = null
}
object Project_state: SerializableProperty<Project, ProjectState> {
    override val name: String = "state"
    override fun get(receiver: Project): ProjectState = receiver.state
    override fun setCopy(receiver: Project, value: ProjectState) = receiver.copy(state = value)
    override val serializer: KSerializer<ProjectState> = ProjectState.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("state")
    override val default: ProjectState = ProjectState.Creating
}
object Project_price: SerializableProperty<Project, Long> {
    override val name: String = "price"
    override fun get(receiver: Project): Long = receiver.price
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(price = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("price")
    override val default: Long = 0
}
object Project_fundingNeeded: SerializableProperty<Project, Long> {
    override val name: String = "fundingNeeded"
    override fun get(receiver: Project): Long = receiver.fundingNeeded
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(fundingNeeded = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("fundingNeeded")
    override val default: Long = 0L
}
object Project_balance: SerializableProperty<Project, Long> {
    override val name: String = "balance"
    override fun get(receiver: Project): Long = receiver.balance
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(balance = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("balance")
    override val default: Long = 0L
}
object Project_pendingClientPayments: SerializableProperty<Project, Long> {
    override val name: String = "pendingClientPayments"
    override fun get(receiver: Project): Long = receiver.pendingClientPayments
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(pendingClientPayments = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("pendingClientPayments")
    override val default: Long = 0L
}
object Project_contractorReceived: SerializableProperty<Project, Long> {
    override val name: String = "contractorReceived"
    override fun get(receiver: Project): Long = receiver.contractorReceived
    override fun setCopy(receiver: Project, value: Long) = receiver.copy(contractorReceived = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("contractorReceived")
    override val default: Long = 0L
}
object Project_funded: SerializableProperty<Project, Instant?> {
    override val name: String = "funded"
    override fun get(receiver: Project): Instant? = receiver.funded
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(funded = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("funded")
    override val default: Instant? = null
}
object Project_started: SerializableProperty<Project, Instant?> {
    override val name: String = "started"
    override fun get(receiver: Project): Instant? = receiver.started
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(started = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("started")
    override val default: Instant? = null
}
object Project_substantialCompletion: SerializableProperty<Project, Instant?> {
    override val name: String = "substantialCompletion"
    override fun get(receiver: Project): Instant? = receiver.substantialCompletion
    override fun setCopy(receiver: Project, value: Instant?) = receiver.copy(substantialCompletion = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("substantialCompletion")
    override val default: Instant? = null
}
