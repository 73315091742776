// Automatically generated based off databaseModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.max
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareLineItemLogFields() {
    val props: Array<SerializableProperty<LineItemLog, *>> = arrayOf(LineItemLog__id, LineItemLog_lineItem, LineItemLog_project, LineItemLog_message, LineItemLog_files, LineItemLog_at)
    LineItemLog.serializer().properties { props }
}
val <K> DataClassPath<K, LineItemLog>._id: DataClassPath<K, UUID> get() = this[LineItemLog__id]
val <K> DataClassPath<K, LineItemLog>.lineItem: DataClassPath<K, UUID> get() = this[LineItemLog_lineItem]
val <K> DataClassPath<K, LineItemLog>.project: DataClassPath<K, UUID> get() = this[LineItemLog_project]
val <K> DataClassPath<K, LineItemLog>.message: DataClassPath<K, String> get() = this[LineItemLog_message]
val <K> DataClassPath<K, LineItemLog>.files: DataClassPath<K, List<ProjectAttachment>> get() = this[LineItemLog_files]
val <K> DataClassPath<K, LineItemLog>.at: DataClassPath<K, Instant> get() = this[LineItemLog_at]
inline val LineItemLog.Companion.path: DataClassPath<LineItemLog, LineItemLog> get() = path<LineItemLog>()


object LineItemLog__id: SerializableProperty<LineItemLog, UUID> {
    override val name: String = "_id"
    override fun get(receiver: LineItemLog): UUID = receiver._id
    override fun setCopy(receiver: LineItemLog, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("_id")
    override val default: UUID = UUID.random()
}
object LineItemLog_lineItem: SerializableProperty<LineItemLog, UUID> {
    override val name: String = "lineItem"
    override fun get(receiver: LineItemLog): UUID = receiver.lineItem
    override fun setCopy(receiver: LineItemLog, value: UUID) = receiver.copy(lineItem = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("lineItem")
}
object LineItemLog_project: SerializableProperty<LineItemLog, UUID> {
    override val name: String = "project"
    override fun get(receiver: LineItemLog): UUID = receiver.project
    override fun setCopy(receiver: LineItemLog, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("project")
}
object LineItemLog_message: SerializableProperty<LineItemLog, String> {
    override val name: String = "message"
    override fun get(receiver: LineItemLog): String = receiver.message
    override fun setCopy(receiver: LineItemLog, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("message")
}
object LineItemLog_files: SerializableProperty<LineItemLog, List<ProjectAttachment>> {
    override val name: String = "files"
    override fun get(receiver: LineItemLog): List<ProjectAttachment> = receiver.files
    override fun setCopy(receiver: LineItemLog, value: List<ProjectAttachment>) = receiver.copy(files = value)
    override val serializer: KSerializer<List<ProjectAttachment>> = ListSerializer(ProjectAttachment.serializer())
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("files")
    override val default: List<ProjectAttachment> = emptyList()
}
object LineItemLog_at: SerializableProperty<LineItemLog, Instant> {
    override val name: String = "at"
    override fun get(receiver: LineItemLog): Instant = receiver.at
    override fun setCopy(receiver: LineItemLog, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = LineItemLog.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
