package com.crowpay.views.screens.common

import com.crowpay.*
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.ProjectSection
import com.crowpay.views.components.project.projectView
import com.crowpay.views.screens.client.ClientProject
import com.crowpay.views.screens.contractor.MightBeContractorScreen
import com.lightningkite.UUID
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.sort
import com.lightningkite.uuid
import kotlinx.coroutines.launch


abstract class ProjectPage(val projectId: UUID) : Screen, MightBeContractorScreen {

    @QueryParameter
    val selectedTab = Property(ProjectSection.Details)

    override fun ViewWriter.renderMainContent() {

        val project = shared {
            notNullSession().projects[projectId]() ?: run {
                screenNavigator.reset(LandingScreen())
                throw CancelledException("Project not found")
            }
        }
        val lineItems = shared {
            notNullSession().lineItems
                .query(Query(
                    condition = condition<LineItem> { it.project eq projectId },
                    orderBy = sort { it.order.ascending() }
                ))()
                .also { println("Have new line items") }
        }
        val itemLogs = shared {
            notNullSession().lineItemLogs.query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )()
        }
        val client = shared {
            project().client?.let { notNullSession().clients[it]() } ?: Client(
                contractor = uuid(),
                name = "",
                email = "",
                phoneNumber = "",
                address = Address()
            )
        }

        val contractor = shared {
            notNullSession().contractors[project().contractor]() ?: Contractor(
                name = "",
                email = "",
                phoneNumber = "",
                address = Address(),
                contactFirstName = "",
                contactLastName = "",
                contactEmail = "",
                contactPhoneNumber = "",
                contactAddress = Address(),
                stateEntityNumber = "",
                ein = "",
            )
        }

        val punchLists = shared {
            notNullSession().punchLists.query(
                Query(
                    condition { it.project eq projectId },
                    limit = Int.MAX_VALUE
                )
            )()
        }

        val changeOrders =
            shared {
                notNullSession().changeRequests
                    .query(
                        Query(
                            condition { it.project eq project()._id },
                            limit = Int.MAX_VALUE
                        )
                    )()
                    .sortedWith(compareBy({ it.accepted?.timestamp }, { it.title }))
            }


        scrolls - col {
            spacing = 0.dp
            launch {
                val p = project.await()
                if (lens == ProjectLens.Contractor && selectedContractor.await() != p.contractor) {
                    val self = notNullSession.await().self.await()
                    if (self.memberships.any { it.contractor == p.contractor })
                        selectedContractor.set(p.contractor)
                    else
                        screenNavigator.reset(ClientProject(projectId))
                }
            }
            projectView(
                project,
                client,
                contractor,
                lineItems,
                itemLogs,
                punchLists,
                changeOrders,
                lens,
                selectedTab
            )
        }
    }
}