// Automatically generated based off supportModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.crowpay

import com.lightningkite.UUID
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareItemChangeFields() {
    val props: Array<SerializableProperty<ItemChange, *>> = arrayOf(ItemChange_id, ItemChange_itemId, ItemChange_description, ItemChange_price, ItemChange_cancelled)
    ItemChange.serializer().properties { props }
}
val <K> DataClassPath<K, ItemChange>.id: DataClassPath<K, UUID> get() = this[ItemChange_id]
val <K> DataClassPath<K, ItemChange>.itemId: DataClassPath<K, UUID> get() = this[ItemChange_itemId]
val <K> DataClassPath<K, ItemChange>.description: DataClassPath<K, String> get() = this[ItemChange_description]
val <K> DataClassPath<K, ItemChange>.price: DataClassPath<K, Long> get() = this[ItemChange_price]
val <K> DataClassPath<K, ItemChange>.cancelled: DataClassPath<K, Boolean> get() = this[ItemChange_cancelled]
inline val ItemChange.Companion.path: DataClassPath<ItemChange, ItemChange> get() = path<ItemChange>()


object ItemChange_id: SerializableProperty<ItemChange, UUID> {
    override val name: String = "id"
    override fun get(receiver: ItemChange): UUID = receiver.id
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ItemChange.serializer().tryFindAnnotations("id")
}
object ItemChange_itemId: SerializableProperty<ItemChange, UUID> {
    override val name: String = "itemId"
    override fun get(receiver: ItemChange): UUID = receiver.itemId
    override fun setCopy(receiver: ItemChange, value: UUID) = receiver.copy(itemId = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ItemChange.serializer().tryFindAnnotations("itemId")
}
object ItemChange_description: SerializableProperty<ItemChange, String> {
    override val name: String = "description"
    override fun get(receiver: ItemChange): String = receiver.description
    override fun setCopy(receiver: ItemChange, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ItemChange.serializer().tryFindAnnotations("description")
    override val default: String = ""
}
object ItemChange_price: SerializableProperty<ItemChange, Long> {
    override val name: String = "price"
    override fun get(receiver: ItemChange): Long = receiver.price
    override fun setCopy(receiver: ItemChange, value: Long) = receiver.copy(price = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = ItemChange.serializer().tryFindAnnotations("price")
    override val default: Long = 0L
}
object ItemChange_cancelled: SerializableProperty<ItemChange, Boolean> {
    override val name: String = "cancelled"
    override fun get(receiver: ItemChange): Boolean = receiver.cancelled
    override fun setCopy(receiver: ItemChange, value: Boolean) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = ItemChange.serializer().tryFindAnnotations("cancelled")
    override val default: Boolean = false
}
