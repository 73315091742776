package com.crowpay.sdk

import com.lightningkite.kiteui.reactive.PersistentProperty
import kotlinx.browser.window
import kotlinx.serialization.Serializable

actual fun getDefaultServerBackend(): ApiOption {
    val host = window.location.hostname
    return when {
        host.contains("lightningkite") -> ApiOption.Dev
        host.contains("staging") -> ApiOption.Staging
        host.contains("localhost") -> ApiOption.Local
        host.contains("bsvedin") -> ApiOption.BSvedin
        else -> ApiOption.Staging
    }.also { println("Host is $host, default is $it") }
}