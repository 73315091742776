package com.crowpay.actuals

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.canvas.DrawingContext2D
import kotlin.coroutines.resume
import kotlin.math.min
import kotlinx.browser.document
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.Image
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.FilePropertyBag
import org.w3c.files.FileReader

private fun getWidthAndHeight(oWidth: Int, oHeight: Int, maxSize: Int): Pair<Int, Int> {
    val ratio: Float = min(maxSize / oWidth.toFloat(), maxSize / oHeight.toFloat())
    if (ratio > 1) return oWidth to oHeight

    return (oWidth * ratio).toInt() to (oHeight * ratio).toInt()
}

actual suspend fun FileReference.renderImageThumbnail(maxSize: Int): FileReference {
    val reader = FileReader()
    val image = Image()

    return suspendCoroutineCancellable { cont ->
        reader.onload = { rEvent ->
            image.onload = { _ ->

                val canvas: HTMLCanvasElement = document.createElement("canvas") as HTMLCanvasElement

                val (width, height) = getWidthAndHeight(image.width, image.height, maxSize)

                canvas.width = width
                canvas.height = height
                canvas.getContext("2d").apply {
                    this as DrawingContext2D
                    drawImage(image, 0.0, 0.0, width.toDouble(), height.toDouble())
                    canvas.toBlob(
                        _callback = {
                            cont.resume(it?.let { blob: Blob ->
                                val temp = File(
                                    fileBits = arrayOf(blob),
                                    fileName = "thumbnail.webp",
                                    options = FilePropertyBag(type = "image/webp")
                                )
                                temp
                            } ?: throw Exception("Failed to render Image"))
                        },
                        type = "image/webp",
                        quality = 0.75
                    )

                }

                Unit
            }
            image.src = rEvent.target?.asDynamic()?.result.unsafeCast<String>()

            Unit
        }

        reader.readAsDataURL(this)

        return@suspendCoroutineCancellable {
            reader.abort()
        }
    }
}