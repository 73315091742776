package com.crowpay.views.screens.client

import com.crowpay._id
import com.crowpay.customer
import com.crowpay.sdk.notNullSession
import com.crowpay.views.components.project.ProjectLens
import com.crowpay.views.components.project.projectList
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.inside

@Routable("/client/projects")
class ClientProjects() : Screen {
    override val title: Readable<String> = Constant("Your Projects")

    val projects = shared {
        val session = notNullSession()
        session.projects
            .query(
                Query(
                    condition = condition { it.customer eq session.userId },
                )
            )()
            .sortedWith(compareBy({ it.state }, { it.name }))
    }

    val contractors = shared {
        notNullSession().contractors
            .query(Query(condition { it._id inside projects.await().map { it.contractor }.toSet() }))()
    }

    override fun ViewWriter.render() {
        projectList(
            lens = ProjectLens.Customer,
            projects,
            contractors,
        )
    }
}