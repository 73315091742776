package com.crowpay.views.components.files

import com.crowpay.*
import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.existsDefaultFalse
import com.crowpay.utils.lazy
import com.crowpay.utils.validation.validate
import com.crowpay.utils.validation.validating
import com.crowpay.views.components.field2
import com.crowpay.views.components.keyValue
import com.crowpay.views.components.label2
import com.crowpay.views.dialogs.GenericDialog
import com.crowpay.views.dialogs.ImagePreview
import com.crowpay.views.theming.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.mimeType
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.requestFile
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import kotlinx.coroutines.launch


fun ViewWriter.renderLicense(license: License) {
    stack {
        spacing = AppDimensions.sectionIndent
        col {
            keyValue("Type", license.type.name)
            keyValue("Number", license.number)
            keyValue(
                "Region",
                if (license.city != null)
                    "${license.city}, ${license.state.abbreviated}"
                else license.state.display
            )

            withSpacing(0.0) - sizeConstraints(
                width = 10.rem,
                height = 10.rem
            ) - button {

                renderAttachmentImage(license)

                onClick {
                    if (license.fileType.startsWith("image"))
                        dialogScreenNavigator.navigate(
                            ImagePreview(listOf(license), 0)
                        )
                    else
                        ExternalServices.openTab(license.file.location)
                }
            }


            tertiaryButton - button {
                icon(Icon.edit, "Edit License")
                onClick {
                    dialogScreenNavigator.navigate(LicenseFormDialog(guaranteedContractor(), license))
                }
            } in gravity(Align.End, Align.Center)
        }
    }
}


fun ViewWriter.renderLicenses(licences: Readable<List<License>>) {
    col {
        body {
            exists = false
            ::exists{ licences().isEmpty() }
            align = Align.Center
            content = "No Licenses uploaded"
        }

        col {
            ::exists{ licences().isNotEmpty() }

            val count = shared {
                val window = AppState.windowInfo()
                val raw = AppDimensions.largeImagePreview.px
                if (window.width > AppDimensions.pageWidth + AppDimensions.leftNavWidth) {
                    AppDimensions.pageWidth.px / raw
                } else {
                    (window.width.px - AppDimensions.leftNavWidth.px) / raw
                }.toInt() - 1
            }
            val chunked = shared { licences().chunked(count()) }

            forEachUpdating(chunked, placeholdersWhileLoading = 1) { chunk ->
                row {
                    forEach(chunk) {
                        expanding - stack {
                            sizeConstraints(width = AppDimensions.pageWidth / 3 - (appTheme.spacing * 2)) - centered - lightSection - renderLicense(it)
                        }
                    }
                }
            }
        }

        tertiaryButton - button {
            icon(Icon.add, "Add License")
            onClick {
                dialogScreenNavigator.navigate(LicenseFormDialog(guaranteedContractor(), null))
            }
        } in gravity(Align.End, Align.Center)
    }
}

class LicenseFormDialog(val contractorId: UUID, val license: License?) : Screen {
    override fun ViewWriter.render() {
        dismissBackground {
            dialog - centered - stack {
                spacing = AppDimensions.fullIndent
                sizeConstraints(width = AppDimensions.pageWidth * 0.85) - col {

                    title {
                        content = if (license == null) "New License" else "Edit License"
                    }

                    validating {

                        val type = Property(license?.type ?: LicenseType.Business)
                        val number = Property(license?.number ?: "").validateNotBlank()
                        val city = Property(license?.city)
                        val state = Property(license?.state).validateNotNull()
                        val licenseImage =
                            Property(license?.let { FilePreview.RemoteAttachment(it) } ?: FilePreview.Empty)
                                .validate(true) { it !is FilePreview.Empty }

                        field2("Type") {
                            select {
                                bind(type, Constant(LicenseType.entries)) { it.name }
                            }
                        }
                        field2("Number") {
                            validate(number) - textInput {
                                hint = "License Number"
                                content bind number
                            }
                        }
                        field2("City (Optional)") {
                            textInput {
                                hint = "City"
                                content bind city.nullToBlank()
                            }
                        }

                        field2("State") {
                            validate(state) - select {
                                bind(state, Constant(listOf(null) + State.entries)) { it?.display ?: "Unselected" }
                            }
                        }

                        field2("Image") {
                            validate(licenseImage) - button {
                                spacing = 0.dp
                                stack {
                                    lazy(licenseImage) { filePreview ->
                                        renderFilePreview(filePreview, AppDimensions.smallImagePreview)
                                        centered - col {
                                            spacing = 0.5.rem
                                            existsDefaultFalse { licenseImage() == FilePreview.Empty }
                                            centered - body("Image")
                                            centered - body("Source")
                                        }
                                    }
                                }
                                onClick {
                                    ExternalServices.requestFile(listOf("image/*")) { it: FileReference? ->
                                        it?.let { result ->
                                            launch{
                                                licenseImage.set(
                                                    FilePreview.LocalFile(it)
                                                        .also { it.uploadAttachment(notNullSession().nonCached.contractor::uploadFileForRequest) }
                                                )
                                            }
                                        }
                                    }
                                }
                            } in sizeConstraints(
                                width = AppDimensions.smallImagePreview,
                                height = AppDimensions.smallImagePreview
                            )
                        }

                        row {
                            textButton - button {
                                exists = license != null
                                specCenteredText("Delete")
                                onClick {
                                    notNullSession().licenses[license!!._id].delete()
                                    screenNavigator.dismiss()
                                }
                            }
                            expanding - space()
                            secondaryButton - button {
                                specCenteredText("Cancel")
                                onClick {
                                    screenNavigator.dismiss()
                                }
                            }
                            primaryButton - button {
                                ::enabled{ allValid() }
                                specCenteredText("Save")
                                onClick {

                                    // Validate Form
                                    val i = licenseImage.awaitOnce()
                                    val errors = buildList<String> {
                                        if (number.awaitOnce().isBlank()) add("License Number must not be empty")
                                        if (i is FilePreview.Empty) add("You must upload an image")
                                        if (i is FilePreview.LocalFile && i.uploaded.await()
                                                .await() != true
                                        ) add("Your image failed to upload. Please try again")
                                    }

                                    if (errors.isNotEmpty()) {
                                        dialogScreenNavigator.navigate(
                                            GenericDialog(
                                                "There are issues with your License: \n\n ${
                                                    errors.joinToString(
                                                        "\n"
                                                    ) { it }
                                                }"
                                            )
                                        )
                                    } else {
                                        val state = state() ?: throw IllegalStateException("State must be selected")
                                        if (license != null) {
                                            val mods = Modification.Chain<License>(buildList {
                                                if (license.number != number.awaitOnce()) add(modification { it.number assign number.awaitOnce() })
                                                if (license.city != city.awaitOnce()) add(
                                                    modification { it.city assign city.awaitOnce() })
                                                if (license.state != state) add(modification { it.state assign state })
                                                if (license.type != type.value) add(modification { it.type assign type.value })
                                                (i as? FilePreview.LocalFile)?.let {
                                                    add(modification { it.file assign i.mainFile!! })
                                                    add(modification { it.preview assign i.thumbnail })
                                                    add(modification { it.fileType assign i.value.mimeType() })
                                                }
                                            })
                                            if (mods.modifications.isNotEmpty()) {
                                                notNullSession().licenses[license._id].modify(mods)
                                            }

                                        } else {
                                            val i = i as FilePreview.LocalFile
                                            notNullSession().licenses.insert(
                                                License(
                                                    contractor = contractorId,
                                                    number = number.awaitOnce(),
                                                    city = city.awaitOnce(),
                                                    state = state,
                                                    type = type.value,
                                                    file = i.mainFile!!,
                                                    preview = i.thumbnail,
                                                    fileType = i.value.mimeType()
                                                )
                                            )

                                        }
                                        dialogScreenNavigator.dismiss()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}