package com.crowpay.views.components.project

import com.crowpay.actuals.AppDimensions
import com.crowpay.sdk.notNullSession
import com.crowpay.utils.validation.Validator
import com.crowpay.utils.validation.validate
import com.crowpay.views.components.files.FileData
import com.crowpay.views.components.files.fileManager
import com.crowpay.views.components.label2
import com.crowpay.views.components.space
import com.lightningkite.kiteui.models.minus
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.textArea
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme


fun ViewWriter.projectDetailForm(
    description: Writable<String>,
    privateNotes: Writable<String>,
    files: Writable<List<FileData>>,
    validator: Validator,
) {
    col {
        spacing = 1.rem
        space(AppDimensions.majorColumnSpacing - spacing!!)
        with(validator) {
            label2("Project Description") {
                val value = description.validate { it.isNotBlank() }
                sizeConstraints(minHeight = 7.rem) - fieldTheme - validate(value) - expanding -
                        textArea {
                            hint = "Project Description"
                            content bind value
                        }
            }

            label2("Contractor Notes (Visible only to you)") {
                sizeConstraints(minHeight = 7.rem) - fieldTheme - expanding -
                        textArea {
                            hint = "Private Notes"
                            content bind privateNotes
                        }
            }

            label2("Project Files (Images, Bids, Plans, Specs)") {
                fieldTheme - fileManager(
                    files,
                    "No Files Uploaded"
                ) { notNullSession().nonCached.project::uploadFileForRequest }
            }
        }
    }
}